<template>
    <div class="page-title">
        <h1><i v-if="icon" :class="icon"></i> {{ main }}</h1>
        <h2>{{ sub }}</h2>
    </div>
</template>

<script>
export default {
    name: 'PageTitle',
    props: ['icon', 'main', 'sub']
}
</script>

<style>
    .page-title {
        margin-bottom: 30px;
    }
    .page-title h1 {
        margin: 0px;
        margin-top: -10px;
        font-size: 1.9rem;
        color: #59586b;
    }

    .page-title h1 i {
        width: 40px;
    }

    .page-title h2 {
        color: #59586b;
        margin: 0px 0 0 48px;    
        font-size: 1rem;
    }

    .page-title hr {
        margin: 0px;
        margin-top: 10px;
        margin-bottom: 10px;
        border: 0px;
        border-top: 2px solid #0b2e5850;
    }

    
</style>
