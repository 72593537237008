<template>
    <div class="cidades-relatorio"> 
        {{relatorio}}
        <b-table hover striped :items="relatorio" :fields="fields">  
            
        </b-table>                  
    </div>
</template>

<script>

export default {
    name: 'CidadesPages',
    data() {
        return {
            relatorio: [],
            fields: [
                {key: 'id_cidade', label: 'Código', sortable: true},
                {key: 'nome', label: 'Nome', sortable: true},
                {key: 'sigla', label: 'UF', sortable: true},              
                {key: 'ativo', label: 'Ativo', sortable: false, formatter: value => value ==='S' ? 'Sim' : 'Não'},
                {key: 'actions', label: 'Ações'}
            ] 
        }
    },
    methods: {
        dados() {
           this.relatorio = this.$router.query;
        }    
    },
    mounted() {
        this.dados()
    }



}
</script>

<style>

</style>