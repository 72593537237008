<template>
    <div class="cadastro-condicoes">
        <b-card class="cadastros-card-pesquisa" v-if="show">
            <b-row align-v="center">
                <b-col md="2" sm="12" class="cadastros-pesquisa-coluna">
                    <b-button class="cadastros-button-novo" variant="primary" 
                        @click="show = !show">
                            <i class="fa fa-plus button-icons"></i>
                            Novo
                    </b-button>
                </b-col>
                <b-col md="5" sm="12" class="cadastros-pesquisa-coluna">                        
                    <b-input-group >
                        <b-form-input id="localizar-condicao" type="search"
                            :formatter="upperCase"
                            v-model="parcial"
                            :readonly="mode === 'remove'"
                            placeholder="Informe os dados da pesquisa..." />
                        <b-input-group-append> 
                            <b-button variant="primary" @click="novaConsulta">
                                <i class="fa fa-search button-icons"></i>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>                      
                </b-col> 
                <b-col md="3" sm="12" class="radio-consulta">
                    <b-form-group  v-slot="{ ariaDescribedby }">
                        <b-form-radio-group 
                            id="radio-slots-plano-contas-condicao"
                            v-model="ativos"
                            :aria-describedby="ariaDescribedby"
                            name="radio-options-slots-plano-contas-condicao"
                        >
                            <b-form-radio value="S">Ativos</b-form-radio>
                            <b-form-radio value="N">Inativos</b-form-radio>
                            <b-form-radio value="T">Todos</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
                <b-col md="2" sm="12" class="d-flex justify-content-end cadastros-pesquisa-coluna">                        
                    <div class="cadastro-total-card"> {{ count }} <br> cadastros </div>                     
                </b-col> 
            </b-row>
        </b-card>

        <b-card class="cadastros-card-corpo-condicao" v-show="!show">
            <b-form>
                <input id="cartao-condicao-id" type="hidden" v-model="cartaoCondicao.id_cartao_bandeira" />

                <b-row>

                    <b-col md="4" sm="12">
                        <b-form-group label="Administradora" label-for="cartao-administradora">
                            <b-input-group  id="cartao-administradora" class="mt-2">
                                <b-form-select :disabled="mode === 'remove'" v-model="cartaoCondicao.id_cartao_adm" :options="administradoras" />
                                <b-input-group-append>
                                    <b-button :disabled="mode === 'remove'" variant="success" @click="loadAdministradoras()"><i class="fa fa-refresh atualizar"></i></b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>

                    <b-col md="4" sm="12">
                        <b-form-group label="Descrição" label-for="nome-condicao">
                            <b-form-input id="nome-condicao" type="text"
                                :formatter="upperCase"
                                v-model="cartaoCondicao.descricao" required
                                :readonly="mode === 'remove'" />
                        </b-form-group>
                    </b-col> 
                    <b-col md="2" sm="12">
                        <b-form-group label="Nº Min. Parc" label-for="min-condicao">
                            <b-form-input id="min-condicao" type="number"
                                :formatter="upperCase"
                                v-model="cartaoCondicao.num_parc_min" required
                                :readonly="mode === 'remove'" />
                        </b-form-group>
                    </b-col>  
                    <b-col md="2" sm="12">
                        <b-form-group label="Nº Máx. Parc" label-for="max-condicao">
                            <b-form-input id="max-condicao" type="number"
                                :formatter="upperCase"
                                v-model="cartaoCondicao.num_parc_max" required
                                :readonly="mode === 'remove'" />
                        </b-form-group>
                    </b-col>                                     
                </b-row>   

                <b-row>
                    <b-col md="2" sm="12" v-if="false">
                        <b-form-group label="Quant. Parcelas" label-for="quant-parcelas-condicao">
                            <b-form-input id="quant-parcelas-condicao" type="number"
                                :formatter="upperCase"
                                v-model="cartaoCondicao.qtd_parcelas" required
                                :readonly="mode === 'remove'" />
                        </b-form-group>
                    </b-col> 
                    <b-col md="2" sm="12">
                        <b-form-group label="Prazo (dias)" label-for="prazo-condicao">
                            <b-form-input id="prazo-condicao" type="number"
                                :formatter="upperCase"
                                v-model="cartaoCondicao.prazo" required
                                :readonly="mode === 'remove'" />
                        </b-form-group>
                    </b-col> 
                    <b-col md="3" sm="12">
                        <b-form-group label="Taxa Admin." label-for="taxa-adminsitradora">
                            <money id="taxa-adminsitradora" v-model="cartaoCondicao.taxa_adm" v-bind="percent" class="form-control"/>
                        </b-form-group>
                    </b-col> 
                    <b-col md="3" sm="12">
                        <b-form-group label="Tarifa Admin." label-for="tarifa-adiantamento">
                            <money id="tarifa-adiantamento" v-model="cartaoCondicao.tarifa_adiantamento" v-bind="money" class="form-control"/>
                        </b-form-group>
                    </b-col> 
                </b-row>          

                <b-card class="cadastros-opcoes-row-card">
                    <b-row class="cadastros-opcoes-row" align-h="around">                        
                        <b-col md="2" sm="12">
                            <b-form-checkbox id="condicoes-cartao-ativo" v-show="mode === 'save'" v-model="cartaoCondicao.ativo" class="mt-3 mb-3" switch>Ativo</b-form-checkbox>
                        </b-col>                      
                    </b-row>
                </b-card>
                
                <br>
                <hr>
                <b-row>
                    <b-col md="10" sm="10">
                        <b-button variant="primary" v-if="mode === 'save'"
                            @click="save">Salvar</b-button>
                        <b-button variant="danger" v-if="mode === 'remove'"
                            @click="remove">Excluir</b-button>
                        <b-button class="ml-2" @click="reset">Cancelar</b-button>
                    </b-col>

                    <b-col md="2" sm="2" class="d-flex justify-content-end">                        

                        <i id="popover-target-condicao"  class="fa fa-question-circle info-alteracoes"></i>

                        <b-popover target="popover-target-condicao" triggers="hover" placement="top">
                            <template #title>Alterações</template>
                            <b>Usuário Cadastro:</b> {{cartaoCondicao.usu_cad}} <br>
                            <b>Data de Cadastro:</b> {{cartaoCondicao.data_cad}} <br>
                            <b>Usuário Alteração:</b> {{cartaoCondicao.usu_alt}} <br>
                            <b>Data de Alteração:</b> {{cartaoCondicao.data_alt}}
                        </b-popover>                        
                    </b-col>

                </b-row>

            </b-form>
        </b-card>      

        <b-card class="cadastros-card-corpo" v-show="show">
            <b-table hover striped :items="cartaoCondicoes" :fields="fields">  
                <template v-slot:cell(actions)="data">
                    <b-button variant="warning" @click="loadCartaoCondicao(data.item)" class="mr-2">
                        <i class="fa fa-pencil"></i>
                    </b-button>
                    <b-button variant="danger" @click="loadCartaoCondicao(data.item, 'remove')">
                        <i class="fa fa-trash"></i>
                    </b-button>
                </template>
            </b-table>
            <hr>

            <b-pagination 
                align="center" 
                size="md" 
                v-model="page"  
                :total-rows="count" 
                :per-page="limit" 
                first-number 
                last-number>
            </b-pagination>
        </b-card>  
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import {VMoney} from 'v-money'
import axios from 'axios'

export default {
    name: 'CondicoesCartoes',
    data: function() {
        return {
            //controle
            mode: 'save',
            page: 1,
            count: 0,
            limit: 0,
            show: true,
            //pesquisa
            parcial: '',
            ativos: 'S',
            cartaoCondicoes: [],
            administradoras: [],
            cartaoCondicao: {                
                idpessoa: 0,
                descricao: '',
                prazo: 0, 
                qtd_parcelas : 1,
                num_parc_min: 1, 
                num_parc_max: 1, 
                taxa_adm: 0, 
                tarifa_adiantamento: 0,
                ativo: true
            },
            money: {
                decimal: ',',
                thousands: '.',
                //prefix: 'R$ ',
                //suffix: '%',
                precision: 2,
                masked: false
            },
            percent: {
                decimal: ',',
                thousands: '.',
                //prefix: 'R$ ',
                suffix: '%',
                precision: 2,
                masked: false
            },
            fields: [
                {key: 'id_cartao_bandeira', label: 'Código', sortable: true},
                {key: 'id_cartao_adm', label: 'Código Adm', sortable: true},
                {key: 'descricao', label: 'Nome', sortable: true},
                {key: 'ativo', label: 'Ativo', sortable: false, formatter: value => value ==='S' ? 'Sim' : 'Não'},
                {key: 'actions', label: 'Ações'}
            ]     
        }
    },
    directives: {
        money: VMoney
    },
    methods: {
        upperCase(value) {
            return value.toUpperCase()
        },
        lowerCase(value) {
            return value.toLowerCase()
        },
        novaConsulta() {
            this.page = 1 
            this.loadcartaoCondicoes()
        },
        loadcartaoCondicoes() { 
            const parc = (this.parcial.trim().length > 0) ? this.parcial : 'xxxxxxxxxx' 
            const url = `${baseApiUrl}/cartao_bandeiras/consulta/${parc}/ativos/${this.ativos}/?page=${this.page}`    

            axios.get(url).then(res => {
                this.cartaoCondicoes = res.data.data
                this.count = res.data.count 
                this.limit = res.data.limit
            })            
        },
        loadAdministradoras() {
            const url = `${baseApiUrl}/cartao_adm`

            axios.get(url).then(res => {
                this.administradoras = res.data.map(administradora => {
                    return { ...administradora, value: administradora.id_cartao_adm, text: `${administradora.id_cartao_adm} | ${administradora.descricao}` }
                })
            })
        },
        reset() {
            this.mode = 'save'

            this.cartaoCondicao = {                
                idpessoa: 0,
                descricao: '',
                prazo: 0, 
                qtd_parcelas : 1,
                num_parc_min: 1, 
                num_parc_max: 1, 
                taxa_adm: 0, 
                tarifa_adiantamento: 0,
                ativo: true
            }

            this.loadcartaoCondicoes()
            this.show = !this.show
        },
        save() {
            const method = this.cartaoCondicao.id_cartao_bandeira ? 'put' : 'post'
            const idcartaoCondicao = this.cartaoCondicao.id_cartao_bandeira ? `/${this.cartaoCondicao.id_cartao_bandeira}` : ''
            this.cartaoCondicao.ativo == true ? this.cartaoCondicao.ativo='S' : this.cartaoCondicao.ativo='N'           
            
            //TEMPORÁRIO!
            this.cartaoCondicao.usu_cad = 1
            this.cartaoCondicao.id_filial = 2

            axios[method](`${baseApiUrl}/cartao_bandeiras${idcartaoCondicao}`, this.cartaoCondicao)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        remove() {
            const idcartaoCondicao = this.cartaoCondicao.id_cartao_bandeira
            axios.delete(`${baseApiUrl}/cartao_bandeiras/${idcartaoCondicao}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        loadCartaoCondicao(cartaoCondicao, mode = 'save') {
            this.mode = mode
            this.cartaoCondicao = { ...cartaoCondicao }
            this.cartaoCondicao.ativo==='S' ? this.cartaoCondicao.ativo=true : this.cartaoCondicao.ativo= false

            this.show = !this.show
        }
    },
    watch: {
        page() {
            this.loadcartaoCondicoes()
        }
    },
    mounted() {
        this.loadcartaoCondicoes(),
        this.loadAdministradoras() 
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.7rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

    @keyframes rodar {
        0% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .atualizar:hover {
        animation: rodar 1s;
    }

</style>