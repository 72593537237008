<template>
    <div class="paises-pages"> 
        <PageTitle icon="fa fa-globe" main="Países"
            sub="Cadastros" />       
                         
    </div>
</template>

<script>
import PageTitle from '@/components/template/PageTitle'

export default {
    name: 'PaisesPages',
    components: { PageTitle } 
}
</script>

<style>

</style>
