<template>
    <div class="cadastro-cidades" >   

        <!-- GERAR RELATÓRIO  -->
        <b-button v-if="false" variant="primary" @click="relatorio">Relatório</b-button>
        <b-modal size="huge" scrollable ref="relatorio-modal" >
            
            <template #modal-header="{ close }" >
                <b-row align-v="center" style="padding-left: 20px">
                    <b-col md="11" sm="11">
                        <b-button variant="primary" 
                            @click="()=>{gerarPdf = gerarPdf + 1 }">
                                <i class="fa fa-print" ></i>
                                Imprimir
                        </b-button>
                    </b-col>
                    <b-col md="1" sm="1">
                        <b-button size="sm" variant="outline-default" @click="close()">
                            <i class="fa fa-times" style="font-size: 22px"></i>
                        </b-button>
                    </b-col>
                </b-row>
            </template>

            <RelatorioCidades :itens="relatorioCidades" :gerarPdf="gerarPdf" :config="configRel"/>

        </b-modal>
        <!-- *********************** -->

        <b-card class="cadastros-card-pesquisa" v-show="show && !invisivel">
            <b-row align-v="center">
                <b-col md="2" sm="12" class="cadastros-pesquisa-coluna">
                    <b-button class="cadastros-button-novo" variant="primary" 
                        @click="show = !show">
                            <i class="fa fa-plus button-icons"></i>
                            Novo
                    </b-button>
                </b-col>
                <b-col md="3" sm="12" class="cadastros-pesquisa-coluna">                        
                    <b-input-group >
                        <b-form-input id="localizar" type="search"
                            :formatter="upperCase"
                            v-model="parcial"
                            :readonly="mode === 'remove'"
                            placeholder="Informe os dados da pesquisa..." />
                        <b-input-group-append> 
                            <b-button variant="primary" @click="novaConsulta">
                                <i class="fa fa-search button-icons"></i>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>                      
                </b-col> 
                <b-col md="3" sm="12">
                        <b-form-group label="Estado" label-for="cidade-cons-uf">
                            <b-form-select id="cidade-cons-uf" v-model="uf" :options="estadosConsulta" />
                        </b-form-group>
                    </b-col> 
                <b-col md="2" sm="12" class="radio-consulta">
                    <b-form-group  v-slot="{ ariaDescribedby }">
                        <b-form-radio-group 
                            id="radio-slots"
                            v-model="ativos"
                            :aria-describedby="ariaDescribedby"
                            name="radio-options-slots"
                        >
                            <b-form-radio value="S">Ativos</b-form-radio>
                            <b-form-radio value="N">Inativos</b-form-radio>
                            <b-form-radio value="T">Todos</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
                <b-col md="2" sm="12" class="d-flex justify-content-end cadastros-pesquisa-coluna">                        
                    <div class="cadastro-total-card"> {{ count }} <br> cadastros </div>                     
                </b-col> 
            </b-row>
        </b-card>

        <b-card class="cadastros-card-corpo" v-show="!show || invisivel">
            <b-form>
                <input id="cidade-id" type="hidden" v-model="cidade.id_cidade" />

                <b-row>                       
                    <b-col md="5" sm="12">
                        <b-form-group label="Nome" label-for="cidade-nome"> 
                            <b-form-input id="cidade-nome" type="text"
                                :formatter="upperCase"
                                v-model="cidade.nome" required
                                :readonly="mode === 'remove'"/>
                        </b-form-group>
                    </b-col>
                    <b-col md="3" sm="12">
                        <b-form-group label="UF" label-for="cidade-uf">
                            <b-form-select id="cidade-uf" v-model="cidade.id_estado" :options="estados" />
                        </b-form-group>
                    </b-col>  
                    <b-col md="2" sm="12">
                        <b-form-group label="Cod. IBGE" label-for="ibge-cidade"> 
                            <b-form-input id="ibge-cidade" type="text"
                                :formatter="upperCase"
                                v-model="cidade.cod_ibge"
                                :readonly="mode === 'remove'" />
                        </b-form-group>
                    </b-col> 
                    <b-col md="2" sm="12">
                        <b-form-group label="Cod. TOM" label-for="tom-cidade"> 
                            <b-form-input id="tom-cidade" type="text"
                                :formatter="upperCase"
                                v-model="cidade.cod_tom"
                                :readonly="mode === 'remove'" />
                        </b-form-group>
                    </b-col>   
                </b-row> 

                <b-card class="cadastros-opcoes-row-card">
                    <b-row class="cadastros-opcoes-row" align-h="around">                        
                        <b-col md="2" sm="12">
                            <b-form-checkbox id="cidade-ativo" checked v-show="mode === 'save'" v-model="cidade.ativo" class="mt-3 mb-3" switch>Ativo</b-form-checkbox>
                        </b-col>                        
                    </b-row>
                </b-card>
                
                <br>
                <hr>
                <b-row>
                    <b-col md="10" sm="10">
                        <b-button variant="primary" v-if="mode === 'save'"
                            @click="save">Salvar</b-button>
                        <b-button variant="danger" v-if="mode === 'remove'"
                            @click="remove">Excluir</b-button>
                        <b-button class="ml-2" @click="reset">Cancelar</b-button>
                    </b-col>

                    <b-col md="2" sm="2" class="d-flex justify-content-end">                        

                        <i id="popover-target-1"  class="fa fa-question-circle info-alteracoes" v-show="!invisivel"></i>

                        <b-popover target="popover-target-1" triggers="hover" placement="top">
                            <template #title>Alterações</template>
                            <b>Usuário Cadastro:</b> {{cidade.usu_cad}} <br>
                            <b>Data de Cadastro:</b> {{cidade.data_cad}} <br>
                            <b>Usuário Alteração:</b> {{cidade.usu_alt}} <br>
                            <b>Data de Alteração:</b> {{cidade.data_alt}}
                        </b-popover>                        
                    </b-col>

                </b-row>

            </b-form>
        </b-card>      

        <b-card class="cadastros-card-corpo" v-show="show && !invisivel" >
            <b-table hover striped :items="cidades" :fields="fields" id="print">  
                <template v-slot:cell(actions)="data">
                    <b-button variant="warning" @click="loadCidade(data.item)" class="mr-2">
                        <i class="fa fa-pencil"></i>
                    </b-button>
                    <b-button variant="danger" @click="loadCidade(data.item, 'remove')">
                        <i class="fa fa-trash"></i>
                    </b-button>
                </template>
            </b-table>
            <hr>
            <b-pagination 
                align="center" 
                size="md" 
                v-model="page"  
                :total-rows="count" 
                :per-page="limit" 
                first-number 
                last-number>
            </b-pagination>
        </b-card>  
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'
import RelatorioCidades from './report/RelatorioCidades.vue'

export default {
    name: 'CadastroCidades',
    props: ['invisivel'],
    components: {
       RelatorioCidades
    },
    data: function() {
        return {
            iframe: {
                src: window.location.href,
                style: null,
                wrapperStyle: null,
            },            
            // RELATÓRIO
            gerarPdf: 0,
            relatorioCidades: [],
            configRel: {
                headers: {},
                params: {
                    id_cidade: 0,
                    nome: '',
                    uf: '',
                    ativo: '',
                    page: 1,
                    limit: 10,
                    sort: 'id_cidade',
                    order: 'asc'
                }
            },            
            //----------------------
            mode: 'save',
            cidade: {ativo: true},
            cidades: [],            
            page: 1,
            count: 0,
            limit: 0,
            estados: [],
            estadosConsulta: [],
            parcial: '',
            ativos: 'S',
            uf: '',
            show: true,
            fields: [
                {key: 'id_cidade', label: 'Código', sortable: true},
                {key: 'nome', label: 'Nome', sortable: true},
                {key: 'sigla', label: 'UF', sortable: true},              
                {key: 'ativo', label: 'Ativo', sortable: false, formatter: value => value ==='S' ? 'Sim' : 'Não'},
                {key: 'actions', label: 'Ações'}
            ]        
        }
    },
    methods: {        
        relatorio: async function() {

            this.configRel = {
                headers: [
                    {
                    text: 'Nome',
                    align: 'start',
                    value: 'nome',
                    groupable: false,
                    },
                    { text: 'Estado', value: 'nome_estado', align: 'right' },
                    { text: 'UF', value: 'sigla', align: 'right' },
                ],
                param: {
                    titulo: 'RELATÓRIO DE CIDADES',
                    arquivo: 'relatorio-de-cidades',
                    
                }
            }
            

            //TEMPORÁRIO
            const idEstado = 'PR'    

            const url = `${baseApiUrl}/cidades/estado/${idEstado}/?nome=${this.parcial}`    

            await axios.get(url).then(res => {
                this.relatorioCidades = res.data
            }).then(() => {               
                this.$refs['relatorio-modal'].show()
            }).catch(err => {
                showError(err)
            })           
            
        },
        upperCase(value) {
            return value.toUpperCase()
        },
        lowerCase(value) {
            return value.toLowerCase()
        },
        novaConsulta() {
            this.page = 1 
            this.loadCidades()
        },
        loadCidades() { 
            const parc = (this.parcial.trim().length > 0) ? this.parcial : 'xxxxxxxxxx'             
            const url = `${baseApiUrl}/cidades/consulta/${parc}/ativos/${this.ativos}/?page=${this.page}&uf=${this.uf}`    

            axios.get(url).then(res => {
                this.cidades = res.data.data
                this.count = res.data.count 
                this.limit = res.data.limit
            })           
        },
        loadEstados() {
            const url = `${baseApiUrl}/estados`
            axios.get(url).then(res => {
                this.estados = res.data.map(estado => {
                    return { ...estado, value: estado.id_estado, text: `${estado.sigla} | ${estado.nome}` }
                })
                this.estadosConsulta = res.data.map(estado => {
                    return { ...estado, value: estado.id_estado, text: `${estado.sigla} | ${estado.nome}` }
                })

                this.estadosConsulta.unshift({value: '', text: 'TODOS'})

            })
        },
        reset() {
            this.mode = 'save'

            this.cidade = {}
            this.cidade.ativo = true

            this.loadCidades()
            this.show = !this.show
        },
        save() {
            const method = this.cidade.id_cidade ? 'put' : 'post'
            const idcidade = this.cidade.id_cidade ? `/${this.cidade.id_cidade}` : ''
            this.cidade.ativo == true ? this.cidade.ativo='S' : this.cidade.ativo='N'           
            
            //TEMPORÁRIO!
            this.cidade.usu_cad = 1
            this.cidade.idfilial = 2

            axios[method](`${baseApiUrl}/cidades${idcidade}`, this.cidade)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        remove() {
            const idcidade = this.cidade.id_cidade
            axios.delete(`${baseApiUrl}/cidades/${idcidade}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        loadCidade(cidade, mode = 'save') {
            this.mode = mode
            this.cidade = { ...cidade }
            this.cidade.ativo==='S' ? this.cidade.ativo=true : this.cidade.ativo= false

            this.show = !this.show
        }
    },
    watch: {
        page() {
            this.loadCidades()
        }
    },
    mounted() {
        this.loadCidades(),
        this.loadEstados()
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
    
    /* DEFINIR A LARGURA DO MODAL DO RELATÓRIO */
    .modal .modal-huge {
        max-width: 840px;
        width: 840px;
    }
    /* *************************************** */

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.9rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

    



</style>