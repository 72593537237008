<template>
    <div class="relative">
        <div class="relative rounded-md">
            <b-input-group  class="mt-3 input-auto" >                
                <input 
                    style="text-transform:uppercase"
                    class="form-control input-auto"
                    prepend="Label"
                    type="text"
                    ref="input"
                    :readonly="readyOnlyX === 'true'"
                    :value="keyword"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    @input="onInput($event.target.value)"
                    @blur="onBlur"
                    @keydown="onKeydown"
                />
                <b-input-group-append class="input-auto" v-if="keyword">
                    <b-button :disabled="readyOnlyX === 'true' || disabled" variant="outline-danger" @click="onClear()">X</b-button>
                </b-input-group-append>
            </b-input-group>
        </div>

        <div
            v-show="mutableOptions.length"
            class="div-opcoes right-0 mt-2 w-full rounded-md shadow-sm z-50 overflow-y-scroll"
            style="position: absolute; background-color: white; z-index:1000;"
        >
            <ul class="card-opcoes py-1 rounded-md bg-white shadow-xs" >
                <li
                    v-for="(opt, index) in mutableOptions"
                    :key="opt[valueKey]"
                    :ref="`option_${index}`"
                    class="opcoes autocomplete-item block px-4 py-2 text-sm leading-5 text-gray-700"
                    :class="{ 'bg-gray-200': arrowCounter === index }"
                    tabindex="0"
                    @click="onSelect()"
                    @mouseover="setArrowCounter(index)"
                >
                    <span
                        class="font-normal"
                        v-html="opt[`${labelKey}_highlighted`] || opt[labelKey]"
                    />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import './css/tailwind.css';

    export default {
        name: 'Autocomplete',
        props: {
            value: {
                type: undefined,
                default: '',
            },
            placeholder: {
                type: String,
                default: '',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            options: {
                type: Array,
                default: () => [],
            },
            labelKey: {
                type: String,
                default: 'label',
            },
            valueKey: {
                type: String,
                default: 'id',
            },
            searchMinLength: {
                type: Number,
                default: 3,
            },
            readyOnlyX: {
                type: String,
                default: 'false',
            },
        },
        data() {
            return {
                keyword: '',
                arrowCounter: 0,
                originalOptions: [],
                mutableOptions: [],
            };
        },
        computed: {
            inputClassList() {
                return [
                    'appearance-none rounded w-full transition duration-150 ease-in-out',
                    this.getTextSizeClass,
                    this.getTextColorClass,
                    this.getBorderColorClass,
                    this.getPaddingClass,
                ];
            },
            getTextSizeClass() {
                return 'text-sm leading-5';
            },
            getTextColorClass() {
                return 'text-gray-800 placeholder-gray-400';
            },
            getBorderColorClass() {
                return 'focus:outline-none border border-gray-40 focus:border-blue-400';
            },
            getPaddingClass() {
                return 'h-10 pr-6 pl-8';
            },
        },
        watch: {
            value(value) {
                this.keyword = value;
            },
            options() {
                this.cloneOptions();
            },
        },
        created() {
            this.keyword = this.value;
            if (this.options.length) {
                this.cloneOptions();
            }
        },
        methods: {
            onInput(vl) {
                this.keyword = vl;
                this.emitInput();
                if (vl.length >= this.searchMinLength) {
                    if (!this.originalOptions.length) {
                        this.$emit('shouldSearch', vl);
                    } else {
                        this.searchInternally();
                    }
                } else {
                    this.resetOptions();
                }
            },
            searchInternally() {
                const search = this.keyword;
                this.mutableOptions = this.originalOptions.filter(o => o[this.labelKey].toLowerCase().search(search.toLowerCase()) >= 0);
                this.highlightOptions();
            },
            highlightOptions() {
                const search = this.keyword;
                const query = new RegExp(search, 'i');
                this.mutableOptions.forEach((o) => {
                    this.$set(o, `${this.labelKey}_highlighted`, o[this.labelKey].replace(query, '<span class="font-bold">$&</span>'));
                });
            },
            cloneOptions() {
                this.originalOptions = JSON.parse(JSON.stringify(this.options));
                this.mutableOptions = JSON.parse(JSON.stringify(this.options));
                this.searchInternally();
            },
            resetOptions() {
                this.originalOptions = [];
                this.mutableOptions = [];
            },
            onKeydown(evt) {
                if (!this.mutableOptions.length) { return; }
                switch (evt.code) {
                    case 'ArrowDown':
                        evt.preventDefault();
                        this.onArrowDown();
                        break;
                    case 'ArrowUp':
                        evt.preventDefault();
                        this.onArrowUp();
                        break;
                    case 'Enter':
                        this.onSelect();
                        break;
                    case 'Escape':
                        this.onEsc();
                        break;
                }
            },
            onEsc() {
                this.$refs.input.blur();
                this.resetArrowCounter();
                this.resetOptions();
            },
            onArrowDown() {
                if (this.arrowCounter < this.mutableOptions.length - 1) {
                    this.arrowCounter += 1;
                }
                this.fixScrolling();
            },
            onArrowUp() {
                if (this.arrowCounter > 0) {
                    this.arrowCounter -= 1;
                }
                this.fixScrolling();
            },
            onBlur(evt) {
                const tgt = evt.relatedTarget;
                if (tgt && tgt.classList.contains('autocomplete-item')) { return; }
                this.resetOptions();
                this.resetArrowCounter();
            },
            setArrowCounter(number) {
                this.arrowCounter = number;
            },
            fixScrolling() {
                this.$refs[`option_${this.arrowCounter}`][0].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            },
            resetArrowCounter() {
                this.arrowCounter = 0;
            },
            onSelect() {
                const selected = this.mutableOptions[this.arrowCounter];
                const selectedOption = this.options.find(o => o[this.valueKey] === selected[this.valueKey]);
                if (selectedOption) {
                    this.$emit('select', selectedOption);
                    this.keyword = selectedOption[this.labelKey];
                    this.emitInput();
                    this.resetOptions();
                    this.resetArrowCounter();
                }
            },
            emitInput() {
                this.$emit('b-form-input', this.keyword);
            },
            resetKeyword() {
                this.keyword = '';
                this.emitInput();
            },
            onClear() {
                this.$emit('select', '');
                this.resetKeyword();
                this.resetOptions();
            },
        },
    };
</script>

<style>
    .input-auto {
        margin-top: -8px;
    }

    .div-opcoes {
        position: sticky;
        z-index: 1;
        width: 100%;
    }

    .card-opcoes {
        list-style-type: none;
    }

    .opcoes{
        cursor: pointer;
        margin-bottom: 2px;
        text-decoration: none;
    }

    .opcoes:hover{
        background: rgb(240, 239, 239);

    }
</style>