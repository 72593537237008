<template>
    <div class="grupo-plano-de-contas">

        <div v-if="show">
            <b-row align-v="center">
                <b-col class="d-flex justify-content-md-center">
                    <b-button-toolbar>                                                               
                        <b-button-group class="mx-1">
                            <b-input-group>                                                               
                                <b-form-radio-group
                                    class="mr-2"
                                    id="btn-radios-tipo-dre"
                                    v-model="ativos"
                                    :options="optionsStatus"
                                    button-variant="outline-secondary"
                                    name="radios-btn-tipo-dre"
                                    buttons
                                    @change="novaConsulta"
                                ></b-form-radio-group>
                            </b-input-group>

                            <b-input-group>
                                <b-form-input 

                                    id="localizar" 
                                    type="search"
                                    :formatter="upperCase"
                                    v-model="locateGrupoPlanoConta"
                                    :readonly="mode === 'remove'"
                                    placeholder="Pesquisar..." 
                                />
                                <b-input-group-append>
                                    <b-button @click="locateGruposPlanosContas" variant="primary"><i class="fa fa-search button-icons"></i></b-button>
                                </b-input-group-append>
                            </b-input-group>

                        </b-button-group>

                        <b-button-group class="mx-1">    
                            <b-button style="width: 100px" class="cadastros-button-novo" variant="primary" 
                                @click="show = !show">
                                    <i class="fa fa-plus button-icons"></i>
                                    Novo
                            </b-button>
                        </b-button-group> 
                    </b-button-toolbar>
                </b-col>    
            </b-row>  
            <br> 

            <b-table hover small sticky-header="60vh" striped :items="gruposPlanosContas" :fields="fields">  
                <template v-slot:cell(actions)="data">
                    <b-button size="sm" variant="warning" @click="loadGrupoPlanoConta(data.item)" class="mr-2">
                        <i class="fa fa-pencil"></i>
                    </b-button>
                    <b-button size="sm" variant="danger" @click="loadGrupoPlanoConta(data.item, 'remove')">
                        <i class="fa fa-trash"></i>
                    </b-button>
                </template>
            </b-table>       
        </div>

        

        <div v-show="!show" id="hide" class="grupos-planos-cadastro"> 
            <b-form>
                <input id="grupo-plano-conta-id" type="hidden" v-model="grupoPlanoConta.id_plano_conta_grupo" />

                <b-row>
                    <b-col md="8" sm="12">
                            <h3>Cadastro de Grupos de planos de conta</h3>
                    </b-col> 
                    <b-col xs="12" class="d-flex justify-content-end">
                        <b-button variant="primary" v-if="mode === 'save'"
                            @click="save">Salvar</b-button>
                        <b-button variant="danger" v-if="mode === 'remove'"
                            @click="remove">Excluir</b-button>
                        <b-button class="ml-2" @click="reset">Cancelar</b-button>
                    </b-col>             
                </b-row>                            
                <hr>

                <b-row>
                    <b-col md="6" sm="12">
                        <b-form-group label="Descrição" label-for="grupo-plano-conta-descricao">
                            <b-form-input id="grupo-plano-conta-descricao" type="text"
                                v-model="grupoPlanoConta.descricao" required
                                :readonly="mode === 'remove'"
                                placeholder="Informe a descrição do grupo..." />
                        </b-form-group>
                    </b-col>                  
                    <b-col md="3" sm="12">
                        <b-form-group label="Sequência" label-for="grupo-plano-conta-sequencia">
                            <b-form-input id="grupo-plano-conta-sequencia" type="number"
                                v-model="grupoPlanoConta.sequencia" required
                                :readonly="mode === 'remove'"/>
                        </b-form-group>
                    </b-col>                  
                </b-row> 
                <hr>

                <b-row>
                    <b-col md="3" sm="12">
                        <b-form-checkbox id="grupo-plano-ativo" v-show="mode === 'save'" v-model="grupoPlanoConta.ativo" class="mt-3 mb-3">Ativo</b-form-checkbox>
                    </b-col>
                </b-row>
                <br>            
            </b-form>        
        </div>        
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'

export default {
    name: 'CadGrupoPlanoContas',
    data: function() {
        return {
            mode: 'save',
            show: true, 
            locateGrupoPlanoConta: '',
            grupoPlanoConta: {
                descricao: '',
                ativo: true,
            },
            gruposPlanosContas: [],
            fields: [
                {key: 'id_plano_conta_grupo', label: 'Código', sortable: true},
                {key: 'sequencia', label: 'Seq', sortable: true},
                {key: 'descricao', label: 'Descrição', sortable: true},
                {key: 'ativo', label: 'Ativo', sortable: true, formatter: value => value ==='S' ? 'Sim' : 'Não'},
                {key: 'actions', label: 'Ações'}
            ]            
        }
    },
    methods: {
        upperCase(value) {
            return value.toUpperCase()
        },
        lowerCase(value) {
            return value.toLowerCase()
        },
        locateGruposPlanosContas() { 
            const parc = (this.locateGrupoPlanoConta.trim().length > 0) ? this.locateGrupoPlanoConta : 'xxxxxxxxxx' 
            const url = `${baseApiUrl}/plano_contas_grupos/consulta/${parc}/ativos/${this.ativos}`           

            axios.get(url).then(res => {
                this.gruposPlanosContas = res.data.data
            })
            .catch(err => {
                if (err.response.status === 401) {
                    showError('Sua sessão expirou, por favor, realize o login novamente.')
                } else {
                    showError('Nenhum grupo de plano de conta encontrado.')
                    this.gruposPlanosContas = []
                }
            })
        },
        reset() {
            this.mode = 'save'
            this.grupoPlanoConta = {
                descricao: '',
                ativo: true
            }
            this.locateGruposPlanosContas()
            this.show = !this.show
        },
        save() {
            const method = this.grupoPlanoConta.id_plano_conta_grupo ? 'put' : 'post'
            const idGrupoPlanoConta = this.grupoPlanoConta.id_plano_conta_grupo ? `/${this.grupoPlanoConta.id_plano_conta_grupo}` : ''
            this.grupoPlanoConta.ativo == true ? this.grupoPlanoConta.ativo='S' : this.grupoPlanoConta.ativo='N' 
            
            axios[method](`${baseApiUrl}/plano_contas_grupos${idGrupoPlanoConta}`, this.grupoPlanoConta)
                .then(() => {
                    this.$toasted.global.defaultSuccess()  
                    this.reset()                  
                })
                .catch(showError)
        },
        remove() {
            const idGrupoPlanoConta = this.grupoPlanoConta.id_plano_conta_grupo
            axios.delete(`${baseApiUrl}/plano_contas_grupos/${idGrupoPlanoConta}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()   
                    this.reset()                 
                })
                .catch(err => {
                    showError(err)

                })            
        },
        loadGrupoPlanoConta(grupoPlanoConta, mode = 'save') {
            this.mode = mode
            this.grupoPlanoConta = { ...grupoPlanoConta }
            this.show = !this.show
            this.grupoPlanoConta.ativo==='S' ? this.grupoPlanoConta.ativo=true : this.grupoPlanoConta.ativo= false
        }
    },
    mounted() {
        this.locateGruposPlanosContas()
    }
}
</script>

<style>

</style>