<template>
    <div class="fluxo-caixa-pages">
        <PageTitle icon="fa fa-file-text-o" main="Fluxo de Caixa"
            sub="Relatóriso para fluxo de caixa" />
        
        <FluxoCaixa/>   
    </div>
</template>

<script>
import PageTitle from '@/components/template/PageTitle'
import FluxoCaixa from './FluxoCaixa.vue'

export default {
    name: 'FluxoCaixaPages',
    components: { PageTitle, FluxoCaixa }
}
</script>

<style>

</style>
