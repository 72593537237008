<template>
    <div class="servicos-pages">
        <PageTitle icon="fa fa-wrench" main="Servicos"
            sub="Cadastros dos serviços utilizados em contratos" />
        
        <CadastroServicos />   
    </div>
</template>

<script>
import PageTitle from '../template/PageTitle'
import CadastroServicos from './CadastroServicos.vue'

export default {
    name: 'ServicosPages',
    components: { PageTitle, CadastroServicos }
}
</script>

<style>

</style>
