<template>
    <div class="cadastro-servicos">
        
        <div class="acoesTopo">             
            <b-button variant="secondary " class="botoesAcao" v-if="(mode === 'save') || (mode === 'remove')"
                @click="reset">
                    <i class="fa fa-times button-icons"></i>
                    Cancelar
            </b-button>
            <b-button variant="danger" class="botoesAcao" v-if="mode === 'remove'"
                @click="remove">
                    <i class="fa fa-trash button-icons"></i>
                    Excluir
            </b-button>
            <b-button variant="success" class="botoesAcao" v-if="mode === 'save'" 
                @click="save">
                    <i class="fa fa-check button-icons"></i>
                    Salvar
            </b-button>            
            <b-button variant="primary" class="botoesAcao" v-if="mode === 'new'"
                @click="novoServico">
                    <i class="fa fa-plus button-icons"></i>
                    Novo
            </b-button>   
        </div>

        <b-card class="cadastros-card-pesquisa" v-if="show">
            
            <b-row align-v="center">
                <b-col md="5" sm="12" class="cadastros-pesquisa-coluna"> 
                    <b-form-input id="localizar" type="search"
                        :formatter="upperCase"
                        v-model="parcial"
                        :readonly="mode === 'remove'"
                        placeholder="Informe parte do nome do serviço..." />                     
                </b-col> 
                <b-col md="4" sm="12" class="radio-consulta">
                    <b-form-group  v-slot="{ ariaDescribedby }">
                        <b-form-radio-group 
                            id="radio-slots"
                            v-model="ativos"
                            :aria-describedby="ariaDescribedby"
                            name="radio-options-slots"
                        >
                            <b-form-radio value="S">Ativos</b-form-radio>
                            <b-form-radio value="N">Inativos</b-form-radio>
                            <b-form-radio value="T">Todos</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
                <b-col md="3" sm="12" class="d-flex justify-content-end">
                    <b-button variant="info" @click="novaConsulta">
                        <i class="fa fa-search button-icons"></i> Consultar
                    </b-button>
                </b-col>
            </b-row>
        </b-card>

        <b-overlay :show="showOver" rounded="sm">
            <b-card class="cadastros-card-corpo" v-show="!show">
                <b-form>
                    <input id="servicos-id" type="hidden" v-model="servico.id_servico" />

                    <b-row>                       
                        <b-col md="6" sm="12">
                            <b-form-group label="Descrição" label-for="servicos-descricao"> 
                                <b-form-input id="servicos-descricao" type="text"
                                    :formatter="upperCase"
                                    v-model="servico.descricao" required
                                    :readonly="mode === 'remove'"
                                    placeholder="Informe a descrição do servico..." />
                            </b-form-group>
                        </b-col> 
                        <b-col md="3" sm="12">
                            <b-form-group label="Código Serviço (Prefeitura)" label-for="servicos-cod-pref"> 
                                <b-form-input id="servicos-cod-pref" type="text"
                                    v-model="servico.cod_servico_prefeitura"  />
                            </b-form-group>
                        </b-col> 
                        <b-col md="3" sm="12">
                            <b-form-group label="Unidade" label-for="servico-unidade">
                                <b-form-select id="servico-unidade" v-model="servico.id_unidade_medida" :options="unidadesMedida" />
                            </b-form-group>
                        </b-col> 
                    </b-row>

                        <!-- <b-col md="4" sm="12">
                            <b-form-group label="Grupo fiscal ISS" label-for="grupo-icms-ipi">
                                <b-form-select id="grupo-icms-ipi" v-model="servico.id_grupo_icms_ipi" :options="gruposIcmsIpi" />
                            </b-form-group>
                            <b-form-group label="Grupo fiscal PIS/COFINS" label-for="grupo-pis-cofins">
                                <b-form-select id="grupo-pis-cofins" v-model="servico.id_grupo_pis_cofins" :options="gruposPisCofins" />
                            </b-form-group>
                        </b-col>   --> 

                    <b-row> 
                        <b-col md="4" sm="12">
                            <b-form-group label="Custo" label-for="servico-custo">
                                <money v-model="servico.custo" v-bind="money" class="form-control"/>
                            </b-form-group>
                        </b-col>

                        <b-col md="4" sm="12">                        
                            <b-form-group label="Preço" label-for="servico-preco">
                                <money v-model="servico.preco" v-bind="money" class="form-control"/>
                            </b-form-group>
                        </b-col>

                        <b-col md="4" sm="12">
                            <b-form-group label="Código NBS (NFS-e Nacional)" label-for="servicos-nbs"> 
                                <b-form-input id="servicos-nbs" type="text"
                                    v-model="servico.cod_nbs" 
                                    :readonly="mode === 'remove'"/>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row> 
                        <b-col md="12" sm="12"> 
                            <b-card sub-title="Regime do ISS">
                                <hr style="margin-top: 0">
                                <b-row>
                                    <b-col md="4" sm="12"> 
                                        <b-form-group label="Tributação" label-for="nfse-trib-regime-iss">
                                            <b-form-select id="nfse-trib-regime-iss" v-model="servico.id_tributacao" :options="tributacoes" />
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="4" sm="12"> 
                                        <b-form-group label="Tipo de Tributação" label-for="nfse-tipo-regime-iss">
                                            <b-form-select id="nfse-tipo-regime-iss" v-model="servico.tipo_tributacao_iss" :options="tipoTribIss" />
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="4" sm="12"> 
                                        <b-form-group label="Exigibilidade" label-for="exigibilidade-iss">
                                            <b-form-select id="exigibilidade-iss" v-model="servico.exigibilidade" :options="exigibilidadeIss" />
                                        </b-form-group>
                                    </b-col>                                
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>  

                    <b-row>                 
                        <b-col md="12" sm="12">
                            <b-form-group label="Detalhes" label-for="servico-detalhes">
                                <VueEditor v-model="servico.detalhes" placeholder="Informe os detalhes do serviço..." />
                            </b-form-group>
                        </b-col>               
                    </b-row>
                    
                    <b-card class="cadastros-opcoes-row-card">
                        <b-row class="cadastros-opcoes-row" align-h="around">                            
                            <b-col md="2" sm="12">
                                <b-form-checkbox id="servicos-ativo" checked v-show="mode === 'save'" v-model="servico.ativo" class="mt-3 mb-3" switch>Ativo</b-form-checkbox>
                            </b-col>                            
                        </b-row>
                    </b-card>
                    
                    <br>
                    <b-row>
                        <b-col md="12" sm="12" class="d-flex justify-content-end">                        

                            <i id="popover-target-1"  class="fa fa-exclamation-circle info-alteracoes"></i>

                            <b-popover target="popover-target-1" triggers="hover" placement="top">
                                <template #title>Alterações</template>
                                <b>Usuário Cadastro:</b> {{servico.usu_cad}} <br>
                                <b>Data de Cadastro:</b> {{servico.data_cad}} <br>
                                <b>Usuário Alteração:</b> {{servico.usu_alt}} <br>
                                <b>Data de Alteração:</b> {{servico.data_alt}}
                            </b-popover>                        
                        </b-col>

                    </b-row>

                </b-form>
            </b-card>
        </b-overlay>   

        <b-card v-show="!show" style="margin-top: 10px">
                <div class="acoesRodape">                 
                <b-button variant="secondary " class="botoesAcaoRodape" v-if="(mode === 'save') || (mode === 'remove')"
                    @click="reset">
                        <i class="fa fa-times button-icons"></i>
                        Cancelar
                </b-button>
                <b-button variant="danger" class="botoesAcaoRodape" v-if="mode === 'remove'"
                    @click="remove">
                        <i class="fa fa-trash button-icons"></i>
                        Excluir
                </b-button>
                <b-button variant="success" class="botoesAcaoRodape" v-if="mode === 'save'"
                    @click="save">
                        <i class="fa fa-check button-icons"></i>
                        Salvar
                </b-button>            
                <b-button variant="primary" class="botoesAcaoRodape" v-if="mode === 'new'"
                    @click="novoServico">
                        <i class="fa fa-plus button-icons"></i>
                        Novo
                </b-button>   
            </div>
        </b-card>   

        <b-card class="cadastros-card-corpo" v-show="show">
            <b-table hover striped :items="servicos" :fields="fields">  
                <template v-slot:cell(actions)="data">
                    <b-button variant="warning" @click="loadServico(data.item)" class="mr-2">
                        <i class="fa fa-pencil"></i>
                    </b-button>
                    <b-button variant="danger" @click="loadServico(data.item, 'remove')">
                        <i class="fa fa-trash"></i>
                    </b-button>
                </template>
            </b-table>
            <hr>
            <b-pagination align="center" size="md" v-model="page"  :total-rows="count" :per-page="limit" first-number last-number></b-pagination>
        </b-card>  
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import {VMoney} from 'v-money'
import { VueEditor } from "vue2-editor"
const moment = require('moment')
import axios from 'axios'

export default {
    name: 'CadastroServicos',
    components: { VueEditor },
    data: function() {
        return {
            teste: {},
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                //suffix: ' #',
                precision: 2,
                masked: false
            },
            mode: 'new',
            servico: {
                id_servico: '',
                ativo: true,
            },
            servicos: [],
            page: 1,
            count: 0,
            limit: 0,
            unidades: [],            
            parcial: '',
            ativos: 'S',
            show: true,
            showOver: false,
            tributacoes: [],
            tipoTribIss: [
                { value: '0', text: '0 | Não definido' },
                { value: '1', text: '1 | Isento de ISS' },
                { value: '2', text: '2 | Imune' },
                { value: '3', text: '3 | Não Incidência no Município' },
                { value: '4', text: '4 | Não Tributável' },
                { value: '5', text: '5 | Retido' },
                { value: '6', text: '6 | Tributável Dentro do Município' },
                { value: '7', text: '7 | Tributável Fora do Município' },
                { value: '8', text: '8 | Tributável Dentro do Município pelo tomador' }
            ],
            exigibilidadeIss: [
                { value: '1', text: '1 | Exigível'},
                { value: '2', text: '2 | Não Incidência' },
                { value: '3', text: '3 | Isenção' },
                { value: '4', text: '4 | Exportação' },
                { value: '5', text: '5 | Imunidade' },
                { value: '6', text: '6 | Supenso por Ação Judicial' },
                { value: '7', text: '7 | Suspenso por Ação Administrativa' }
            ],
            
            fields: [
                {key: 'id_servico', label: 'Código', sortable: true},
                {key: 'descricao', label: 'Nome / Razão Social', sortable: true},
                {key: 'preco', label: 'Preço', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                },               
                {key: 'ativo', label: 'Ativo', sortable: false, formatter: value => value ==='S' ? 'Sim' : 'Não'},
                {key: 'actions', label: ''}
            ],
            gruposIcmsIpi: [],         
            gruposPisCofins: [],         
            unidadesMedida: []         
        }
    },
    directives: {
        money: VMoney
    },
    methods: {
        novoServico() {
            this.reset()
            this.mode = 'save'
            // this.loadGruposIcmsIpi(),
            // this.loadGruposPisCofins()
            this.loadTributacoes()
            this.loadUnidades()
        },
        upperCase(value) {
            return value.toUpperCase()
        },
        lowerCase(value) {
            return value.toLowerCase()
        },
        novaConsulta() {
            this.page = 1 
            this.loadServicos()
        },        
        loadServicos() {  
            const url = `${baseApiUrl}/consulta/cadastros/?page=${this.page}&ativos=${this.ativos}&parcial=${this.parcial}&tipo=cadservico` 

            axios.get(url).then(res => {
                this.servicos = res.data.data
                this.count = res.data.count 
                this.limit = res.data.limit
            }) 
            .catch(showError)           
        },
        reset() {
            this.mode = 'new'
            
            this.servico = {
                id_servico: '',
                descricao: '',
                id_unidade_medida: '',
                id_grupo_icms_ipi: '',
                id_grupo_pis_cofins: '',
                custo: 0,
                preco:0
            }

            this.servico.ativo = true

            this.loadServicos()
            this.show = !this.show
        },
        save() {
            const method = this.servico.id_servico ? 'put' : 'post'
            const idservico = this.servico.id_servico ? `/${this.servico.id_servico}` : ''
            this.servico.ativo == true ? this.servico.ativo='S' : this.servico.ativo='N'            

            axios[method](`${baseApiUrl}/servicos${idservico}`, this.servico)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)

            this.servico.ativo == 'S' ? this.servico.ativo = true : this.servico.ativo = false
            
        },
        remove() {
            const idservico = this.servico.id_servico
            axios.delete(`${baseApiUrl}/servicos/${idservico}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        loadServico(servico, mode = 'save') {
            this.show = !this.show 
            this.showOver = !this.showOver 

            const url = `${baseApiUrl}/servicos/${servico.id_servico}`    

            axios.get(url).then(res => {
                
                this.showOver = !this.showOver 
                
                this.mode = mode
                this.servico = { ...res.data }
                this.servico.data_cad = moment(this.servico.data_cad).format('DD/MM/YYYY HH:MM:SS')
                this.servico.data_alt = moment(this.servico.data_alt).format('DD/MM/YYYY HH:MM:SS')
                this.servico.ativo ==='S' ? this.servico.ativo=true : this.servico.ativo= false 

                // this.loadGruposIcmsIpi(),
                // this.loadGruposPisCofins()
                this.loadTributacoes()
                this.loadUnidades()

            }) 
            .catch(err => {
                showError(err)
                this.show = !this.show 
                this.showOver = !this.showOver 
            })
        },
        loadTributacoes() { 
            const url = `${baseApiUrl}/tributacoes`
            axios.get(url).then(res => {
                this.tributacoes = res.data.map(trib => {
                    return { ...trib, value: trib.id_tributacao, text: `${trib.id_tributacao} | ${trib.descricao}` }
                })
            })
        },
        loadGruposIcmsIpi() {
            const url = `${baseApiUrl}/gruposicmsipi`
            axios.get(url).then(res => {
                this.gruposIcmsIpi = res.data.map(grupoIcmsIpi => {
                    return { ...grupoIcmsIpi, value: grupoIcmsIpi.id_grupo_icms_ipi, text: grupoIcmsIpi.descricao }
                })
            })
        },
        loadGruposPisCofins() {
            const url = `${baseApiUrl}/grupospiscofins`
            axios.get(url).then(res => {
                this.gruposPisCofins = res.data.map(grupoPisCofins => {
                    return { ...grupoPisCofins, value: grupoPisCofins.id_grupo_pis_cofins, text: grupoPisCofins.descricao }
                })
            })
        },
        loadUnidades() {
            const url = `${baseApiUrl}/unidadesmedida`
            axios.get(url).then(res => {
                this.unidadesMedida = res.data.map(unidade => {
                    return { ...unidade, value: unidade.id_unidade_medida, text: `${unidade.und} | ${unidade.descricao}` }
                })
            })
        },
    
    },
    watch: {
        page() {
            this.loadServicos()
        }
    },
    mounted() {
        this.loadServicos()
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.9rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

    
    



</style>