<template>
    <div class="conciliacao-bancaria-pages">
        <PageTitle icon="fa fa-check-square-o" main="Conciliações bancárias"
            sub="Manutenção e conciliação de movimentações bancárias" />
        
        <CadConciliacaoBancaria />
    </div>
</template>

<script>
import PageTitle from '@/components/template/PageTitle'
import CadConciliacaoBancaria from './CadConciliacaoBancaria'

export default {
    name: 'ContasBancariasPages',
    components: { PageTitle, CadConciliacaoBancaria }
}
</script>

<style>

</style>