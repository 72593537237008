<template>
    <footer class="footer">
        <span>Rexskale Tecnologia da Informacao LTDA <strong>© 2021</strong></span>
    </footer>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style>
    .footer {
        grid-area: footer;
        background-color: #E7EEED;
        color: #333;

        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 10px;
        box-shadow: 0px 0px 5px 0px rgba(95, 95, 95, 0.39);
    }
</style>
