<template>
    <div :class="{'content': user, 'animate-content': !user}">
        <LicenceAlert v-if="user && !user.alterSenha"/>
        <router-view></router-view>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import LicenceAlert from "@/components/template/LicenceAlert.vue"

export default {
    name: 'Content',
    components: { LicenceAlert },
    computed: mapState(['user']),
}
</script>

<style>
    .content {
        grid-area: content;
        background-color: #f3f3f5;
        padding: 20px;
        padding-top: 40px;
    }

    .animate-content {
        grid-area: content;
        padding: 20px;

        background: linear-gradient(45deg, #ffffff, #efefef, #c0c0c0, #ffffff);
        background-size: 300% 300%;
        /* animation: colors 15s ease infinite */
    }

    @keyframes colors {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }

</style>
