<template>
    <div class="auth-content">
        <div class="auth-modal">  
            
            <img src="../../../public/rexLogoLogin.png" alt="Logo" >
        
            
            <hr class="hr-zinha" >
            <span v-if="false" style="margin-top: -15px; font-size: 12px"><strong> versão beta (0.0.1)</strong></span>
            <hr>
            
            <b-input-group class="mb-3" v-if="!alterarSenha">
                <b-input-group-prepend is-text>
                    <i class='fa fa-building'></i>                    
                </b-input-group-prepend>
                <b-form-input v-model="user.idempresa" name="id-empresa" type="text" placeholder="CPF ou CNPJ da Empresa" v-on:keyup.enter="signin"></b-form-input>
            </b-input-group>

            <b-input-group class="mb-3" v-if="!alterarSenha">
                <b-input-group-prepend is-text>
                    <i class='fa fa-user' ></i>
                </b-input-group-prepend>
                <b-form-input v-model="user.email" name="email" type="text" placeholder="E-mail" v-on:keyup.enter="signin"></b-form-input>
            </b-input-group>            
            
            <span style="color: red; text-align: center; margin-bottom: 10px" v-if="alterarSenha">No primeiro acesso é obrigatório a alteração da senha, por favor forneça a nova senha e confirmação. </span>

            <b-input-group class="mb-3">
                <b-input-group-prepend is-text>
                    <i class='fa fa-lock' ></i>
                </b-input-group-prepend>
                <b-form-input v-model="user.password" name="password" type="password" placeholder="Senha" v-on:keyup.enter="signin"></b-form-input>
            </b-input-group>

            <b-input-group v-if="alterarSenha" class="mb-3">
                <b-input-group-prepend is-text>
                    <i class='fa fa-lock' ></i>
                </b-input-group-prepend>
                <b-form-input v-model="usuario.confirmar_senha" name="password" type="password" placeholder="Confirmação" v-on:keyup.enter="signin"></b-form-input>
            </b-input-group>

            <b-button v-if="!alterarSenha" block variant="primary" @click="signin">Entrar</b-button>
            <b-button v-if="alterarSenha" block variant="primary" @click="alterSenha">Alterar</b-button>

            <div class="esqueci-dados">
                <a href="https://rexskale.com">Esqueci meus dados de acesso</a>
            </div>
        </div>
    </div>
</template>

<script>
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'

export default {
    name: 'Auth',
    data: function() {
        return {
            alterarSenha: false,
            teste: '',
            user: {
                password: '',
            },
            idUserr: '',
            usuario: {
                senha: '',
                confirmar_senha: ''
            }                   
        }
    },
    methods: {
        alterSenha() {
            this.alterarSenha = !this.alterarSenha
        
            this.usuario.senha = this.user.password 
            
            axios['put'](`${baseApiUrl}/usuarios/senha/${this.idUserr}`, this.usuario)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    
                    this.user = {
                        password: '',
                    }
                    this.usuario = {
                        senha: '',
                        confirmar_senha: ''
                    }
                })
                .catch(showError)
        },
        signin() {
            axios.post(`${baseApiUrl}/signin`, this.user)
                .then(res => {
                    if (res.data.alterSenha) {
                        this.idUserr = res.data.id
                        this.alterarSenha = true
                        this.user.password = ''
                        this.usuario.confirmar_senha = ''
                        this.$store.commit('setUser', res.data)
                        
                    } else {
                        this.$store.commit('setUser', res.data)
                        localStorage.setItem(userKey, JSON.stringify(res.data))
                        localStorage.setItem('__lancamentos_caixa', JSON.stringify({idCaixa: 0, idUsuario: res.data.id}))
                        this.$router.push({ path: '/' })

                        this.logarCaixa()                        
                    }                    
                })
                .catch(showError)
        },
        logarCaixa() {
            const s = localStorage.getItem('__lancamentos_user') 
            const idUsuario = s ? JSON.parse(s).id : 0

            const url = `${baseApiUrl}/caixa/logar/?idUsuario=${idUsuario}`

            axios.get(url).then(res => {
                localStorage.setItem('__lancamentos_caixa', JSON.stringify(
                    {
                        idCaixa: res.data.idCaixa ? res.data.idCaixa : 0, 
                        idUsuario: res.data.idUsuario ? res.data.idUsuario : 0,
                        idFilial: res.data.idFilial ? res.data.idFilial : 0,
                        dataCaixa: res.data.dataCaixa ? res.data.dataCaixa : null
                    })
                )
            }) 
            .catch(() => {
                localStorage.setItem('__lancamentos_caixa', JSON.stringify(
                    {
                        idCaixa: 0, 
                        idUsuario: 0,
                        idFilial: 0,
                        dataCaixa: null
                    })
                )
                
                showError
            })
        },        
    }
}
</script>

<style>
    .auth-content {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;        
    }

    .auth-modal {
        background-color: #FFF;
        width: 350px;
        padding: 35px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .auth-modal img {
        width: 150px;
        margin-bottom: 20px;
    }

    .auth-title {
        font-size: 1.2rem;
        font-weight: 100;
        margin-top: 10px;
        margin-bottom: 15px;
    }

    .auth-modal input {
        border: 1px solid #BBB;
        width: 100%;
        margin-bottom: 15px;
        padding: 3px 8px;
        outline-color: #2460ae;
    }

    .auth-modal button {
        background-color: #2460ae;
        color: #FFF;
        padding: 5px 15px;
    }

    .auth-modal a {
        margin-top: 35px;
    }

    .hr-zinha{
        border: 0;
        width: 100%;
        height: 1px;
        background-image: linear-gradient(to right,
            rgba(120, 120, 120, 0),
            rgba(120, 120, 120, 0.75),
            rgba(120, 120, 120, 0));
    }

    .esqueci-dados {
        margin-top: 20px;
        font-size: 0.8rem;
        color: #2460ae;
    }
</style>
