<template>
    <div class="fluxo-caixas"> 
        
        <b-card class="cadastros-card-corpo">
            <b-row align-v="center">
                <b-col class="d-flex justify-content-md-center">
                    <b-button-toolbar>                                                               
                        <b-button-group class="mx-1">
                            <b-input-group prepend="Filial">
                                <b-form-select style="width: 200px; margin-right: 10px;" id="filtro-filial" v-model="filtrosProjecao.idFilial" :options="filiais"/>                                
                            </b-input-group>
                            <b-input-group prepend="Tipo">
                                <b-form-select style="width: 135px; margin-right: 10px;" id="filtro-tiporel" v-model="filtrosProjecao.tipoRel" :options="tiposOptions"/>                                
                            </b-input-group>
                            <b-input-group prepend="Período">
                                <b-form-input type="date" v-model="filtrosProjecao.dataIni"></b-form-input>
                                <b-form-input type="date" v-model="filtrosProjecao.dataFin"></b-form-input>
                                <b-input-group-append>
                                <b-button @click="loadProjecao()" variant="primary"><i class="fa fa-search button-icons"></i></b-button>
                                </b-input-group-append>
                            </b-input-group>

                        </b-button-group> 
                        <b-button-group class="mx-1">
                            <b-button @click="salvarPdf('PROJECAO_FINANCEIRA','proj-fin-render-pdf')" variant="primary">
                                <i class="fa fa-cloud-download button-icons"></i>
                            </b-button> 
                        </b-button-group> 
                    </b-button-toolbar>
                </b-col>    
            </b-row>
            <b-row align-v="center">
                <b-col class="d-flex justify-content-md-center">
                    <b-button-toolbar>                                                               
                        <b-button-group class="mx-1">
                            <b-input-group prepend="Receita Futuras" class="mr-2">
                                <money type="text" style="width: 150px" v-model="filtrosProjecao.recFuturas" v-bind="money" class="form-control" />                               
                            </b-input-group>
                            <b-input-group prepend="Despesas Futuras">
                                <money type="text" style="width: 150px" v-model="filtrosProjecao.despFuturas" v-bind="money" class="form-control" />                               
                            </b-input-group>
                        </b-button-group>
                    </b-button-toolbar>
                    <div style="height: 30px; margin: -10px 0 0 10px;">

                        <b-form-checkbox 
                            style="margin: -15px 0 0 0;"
                            value="S" 
                            unchecked-value="N" 
                            id="conta-bancaria-ativo" 
                            v-model="filtrosProjecao.margCres" 
                            class="mt-3 mb-3" 
                            switch
                        >
                            Aplicar % de Crescimento
                        </b-form-checkbox>
                    </div>
                </b-col>    
            </b-row>
            <hr>

            <div class="d-flex justify-content-md-center">
                <div style="width: 775px; padding: 5px;" id="proj-fin-render-pdf">  

                    <b-row style="margin: 0">
                        <b-col md="12" sm="12" class="d-flex justify-content-md-center"> 
                            <h3>{{cabecalhoProjecao.titulo}}</h3>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6" sm="12"> 
                            <h5>{{cabecalhoProjecao.filial}}</h5>
                        </b-col>
                        <b-col md="6" sm="12" class="d-flex justify-content-end"> 
                            <h5>{{cabecalhoProjecao.dataIni}} até {{cabecalhoProjecao.dataFin}}</h5>  
                        </b-col>
                    </b-row>
                    <hr>

                    <b-row>
                        <b-col md="6" sm="12">
                            <h4 style="font-size: 1.7rem" >Período Anterior</h4>
                            <b-list-group>
                                <b-list-group-item v-for="(a, mes_ano) in antes" v-bind:key="mes_ano" class="projecao-item">
                                    <h5>{{a.mes_ano}}</h5>
                                    <b-row class="projecao-linha">
                                        <b-col>
                                            Receitas: {{a.valor_recebido}} <br>
                                            Despesas: {{a.valor_pago}} <br>
                                        </b-col>
                                        <b-col>
                                            <i style="color: red" class="fa fa-arrow-down" v-if="a.margem_rec < 0"></i>
                                            <i style="color: green" class="fa fa-arrow-up" v-else></i>
                                            {{a.margem_rec.toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits: 2})+'%'}} <br>

                                            <i style="color: red" class="fa fa-arrow-down" v-if="a.margem_des < 0"></i>
                                            <i style="color: green" class="fa fa-arrow-up" v-else></i>
                                            {{a.margem_des.toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits: 2})+'%'}} <br>
                                        </b-col>
                                    </b-row>
                                </b-list-group-item>
                            </b-list-group>
                        </b-col>

                        <b-col md="6" sm="12">
                            <h4 style="font-size: 1.7rem">Projeção</h4>
                            <b-list-group>
                                <b-list-group-item v-for="(a, mes_ano) in projecao" v-bind:key="mes_ano" class="projecao-item">
                                    <h5>{{a.mes_ano}}</h5>
                                    <b-row class="projecao-linha">
                                        <b-col>
                                            Receitas: {{a.valor_recebido}} <br>
                                            Despesas: {{a.valor_pago}} <br>
                                        </b-col>
                                    </b-row>
                                </b-list-group-item>
                            </b-list-group>
                        </b-col>
                    </b-row>  

                </div>

            </div>            

        </b-card>      
       
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
const moment = require('moment')
import axios from 'axios'
import html2pdf from "html2pdf.js"
import {VMoney} from 'v-money'

export default {
    name: 'ProjecaoFinanceira',
    // components: { Doughnut },
    data: function() {
        return {            
            money: {
                decimal: ',',
                thousands: '.',
                // prefix: 'R$ ',
                //suffix: ' #',
                precision: 2,
                masked: false
            },

            filtrosProjecao: {
                idConsultoria: 0,
                idFilial: 0,
                dataIni: moment().add(-13, 'months').format('yyyy-MM-DD'),
                dataFin: moment().add(-1, 'months').format('yyyy-MM-DD'),
                tipoRel: 'media',
                margCres: 'S',
                recFuturas: 0,
                despFuturas: 0,
            },

            cabecalhoProjecao: {
                filial: '',
                dataIni: '',
                dataFin: '',
                titulo: '',
                total: 0
            },

            antes: [],
            projecao: [],
            filiais: [],

            fieldsAntes: [
                {key: 'mes_ano', label: 'Mês/Ano', sortable: false},
                {key: 'valor_recebido', label: 'Receitas', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                }, 
                {key: 'margem_rec', label: 'Crescimento', sortable: true, 
                    formatter: value => { 
                        return `${value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}%`  
                    }
                }, 
                {key: 'valor_pago', label: 'Despesas', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                }, 
                {key: 'margem_des', label: 'Crescimento', sortable: true, 
                    formatter: value => { 
                        return `${value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}%`  
                    }
                },
                {key: 'valor_diferenca', label: 'Diferenca', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                }                
            ], 

            fieldsProjecao: [
                {key: 'mes_ano', label: 'Mês/Ano', sortable: false},
                {key: 'valor_recebido', label: 'Receitas', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                },                 
                {key: 'valor_pago', label: 'Despesas', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                },                
                {key: 'valor_diferenca', label: 'Diferenca', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                }                
            ], 

            tiposOptions: [
                { value: 'media', text: 'Média' },
                { value: 'equivalente', text: 'Equivalente'},
            ],            
        }
    },
    directives: {
        money: VMoney
    },
    methods: {
        loadProjecao() {

            axios.get(`${baseApiUrl}/caixa/projecaofinanceira`, {
                params: {...this.filtrosProjecao}
            })
            .then(async res => {
                await this.loadFiliais()
                this.antes = [ ...res.data.anterior ]
                this.projecao = [ ...res.data.futura ]

                const f = this.filiais.find(obj => {
                    return obj.value === parseInt(this.filtrosProjecao.idFilial);
                });
                this.cabecalhoProjecao.filial = f.text             
                this.cabecalhoProjecao.titulo = 'Projeção financeira'             
                
                this.cabecalhoProjecao.dataIni = moment(this.filtrosProjecao.dataIni).format('DD/MM/YYYY')
                this.cabecalhoProjecao.dataFin = moment(this.filtrosProjecao.dataFin).format('DD/MM/YYYY') 

            }) 
            .catch(err => {
                showError(err)
                this.antes = []
            })
        },

        async loadFiliais() {
            const url = `${baseApiUrl}/filiais`
            await axios.get(url).then(res => {
                this.filiais = res.data.map(filial => {
                    return { ...filial,  value: filial.idfilial, text: `${filial.idfilial} | ${filial.razao_social}`}
                })
                this.filiais.unshift({value: 0, text: 'TODAS' })
            })
            .catch(err => {
                showError(err)
            }) 
        },
        
        salvarPdf(nome, id){
            var element = document.getElementById(id);
            var pagebreak = { mode: 'avoid-all' };

            // Gerar o PDF.
            html2pdf().from(element).set({
                margin:[0.1,0.1,0.1,0.1],
                filename: `${nome}.pdf`,
                pagebreak: pagebreak,
                jsPDF: {orientation: 'portrait', unit: 'in', format: 'a4', compressPDF: true}
            })
            .toPdf()
            .get('pdf').then(function (pdf) {
                    var totalPages = pdf.internal.getNumberOfPages();

                    for (let i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(8);
                        pdf.setTextColor(75);
                        pdf.text('Página ' + i + ' de ' + totalPages, ((pdf.internal.pageSize.getWidth()/2)-0.4) , (pdf.internal.pageSize.getHeight()-0.2));
                    }
            })
            .save();
        },
    
    },
    mounted() {
        this.loadFiliais()
    },
    watch: {
        fluxoFilial() {
            this.fluxoCaixa()
        },
    },
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .opcoes-faturar {
        display: flex;
        align-items: center;
        width: 200px;
        padding: 25px;
        background-color: beige;
    }

    .opcoes-faturar:hover {
        background-color: rgb(197, 197, 197);
    }

    .opcoes-faturar-f {
        width: 30%;
    }

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.9rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

    .projecao-item {
        padding: 5px;
        margin-bottom: 5px;
    }
    .projecao-item h5 {
        font-size: 1.5rem;
        font-weight: 600;
    }

    .projecao-linha {
        font-size: 1rem;
        font-weight: 600;
    }

    
    



</style>