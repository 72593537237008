<template>
    <div class="pessoas-pages"> 
        <PageTitle icon="fa fa-address-book" main="Pessoas"
            sub="Manutenção e cadastro de pessoas" />       
        <CadastroPessoas />                    
    </div>
</template>

<script>
import PageTitle from '@/components/template/PageTitle'
import CadastroPessoas from './CadastroPessoas.vue'

export default {
    name: 'PessoasPages',
    components: { PageTitle, CadastroPessoas }
}
</script>

<style>

</style>
