<template>
    <div class="unidades-pages">
        <PageTitle icon="fa fa-balance-scale" main="Unidades de medida"
            sub="Cadastros & Cia" />
        
        <CadastroUnidades />
    </div>
</template>

<script>
import PageTitle from '@/components/template/PageTitle'
import CadastroUnidades from './CadastroUnidades'

export default {
    name: 'BancosPages',
    components: { PageTitle, CadastroUnidades }
}
</script>

<style>

</style>