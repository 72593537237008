<template>
    <div class="plano-contas-pages">
        <PageTitle icon="fa fa-clipboard" main="Plano de contas"
            sub="Cadastros & Cia" />
        <div class="plano-contas-pages-tabs">
            <b-card no-body>
                <b-tabs card>
                    <b-tab title="Plano de contas">
                        <CadPlanoContas />
                    </b-tab>
                    <b-tab title="Grupos">
                        <CadGrupoPlanoContas />
                    </b-tab>                    
                </b-tabs>
            </b-card>
        </div>        
        
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import { mapState } from 'vuex'
import axios from 'axios'
import PageTitle from '@/components/template/PageTitle'
import CadPlanoContas from './CadPlanoContas'
import CadGrupoPlanoContas from './CadGrupoPlanoContas'
const moment = require('moment')

export default {
    name: 'PlanoContasPages',
    computed: mapState(['user']),
    components: { PageTitle, CadPlanoContas, CadGrupoPlanoContas },
    data: function(){
        return {
            gerarPdf: 0,
            relatorioDRE: [],
            dreDataInicial: moment().add(-1, 'M').format('YYYY-MM-01'),
            dreDataFinal: moment().add(-1, 'M').endOf('month').format('YYYY-MM-DD'),
            configRel: {
                headers: {},
                param: {}
            },
        }
    },
    methods:{
        relatorio: async function() {

            this.configRel.headers = [
                {
                    text: '',
                    align: 'start',
                    value: 'agrupador',
                    groupable: false,
                },
                { text: '', value: 'CONTA', align: 'left' },
                { text: '', value: 'DESCRICAO', align: 'left' },
                { text: '', value: 'valor', align: 'right' },
                { text: '', value: 'porc', align: 'right' },
            ]
            this.configRel.param = {  
                titulo: 'DRE - Demonstrativo de resultados',
                subtitulo: `Período: ${moment(this.dreDataInicial).format('DD/MM/YYYY')} à ${moment(this.dreDataFinal).format('DD/MM/YYYY')}`,
                arquivo: 'dre-demonstrativo-de-resultados',
                datainicial: this.dreDataInicial,
                datafinal: this.dreDataFinal,
                agrupador: 'AGRUPADOR',
                ordenar: '',
                
                page: 1,
                limit: 10,
                //order: 'asc'
            }           

            const url = `${baseApiUrl}/dre/?datainicial=${this.configRel.param.datainicial}&datafinal=${this.configRel.param.datafinal}`    

            await axios.get(url).then(res => {
                this.relatorioDRE = res.data
            }).then(() => {               
                this.$refs['relatorio-modal'].show()
            }).catch(err => {
                showError(err)
            })           
            
        },
    }
}
</script>

<style>

</style>
