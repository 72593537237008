<template>
    <div class="tributacoes-page">
        
        <b-card class="cadastros-card-pesquisa" v-if="show">
            <b-row align-v="center">                
                <b-col md="6" sm="12" class="cadastros-pesquisa-coluna">                        
                    <b-input-group >
                        <b-form-input id="localizar" type="search"
                            :formatter="upperCase"
                            v-model="parcial"
                            :readonly="mode === 'remove'"
                            placeholder="Informe parte da descrição..." />
                        <b-input-group-append> 
                            <b-button variant="primary" @click="novaConsulta">
                                <i class="fa fa-search button-icons"></i>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>                      
                </b-col> 

                <b-col md="4" sm="12" class="cadastros-pesquisa-coluna">
                </b-col>
                <b-col md="2" sm="12" class="cadastros-pesquisa-coluna">
                    <b-button class="cadastros-button-novo" variant="primary" 
                        @click="novaTributacao">
                            <i class="fa fa-plus button-icons"></i>
                            Novo
                    </b-button>
                </b-col>
            </b-row>
        </b-card>

        <b-card class="cadastros-card-corpo" v-if="!show">
            <b-form>
                <input id="tributacao-id" type="hidden" v-model="tributacao.id_tributacao" />

                <b-row>                    
                    <b-col md="8" sm="12">
                        <b-form-group label="Descricao" label-for="desc-tributacao">
                            <b-form-input id="desc-tributacao" type="text"
                                v-model="tributacao.descricao" required
                            />
                        </b-form-group>
                    </b-col>  
                    <b-col md="4" sm="12">
                        <b-form-group label="id_externo" label-for="trib-id_externo"> 
                            <b-form-input id="trib-id_externo" type="text"
                                v-model="tributacao.id_externo"/>
                        </b-form-group>
                    </b-col>                   
                </b-row> 

                <b-row> 
                    <!--********* ICMS *********** -->            
                    <b-col md="3" sm="12">
                        <b-form-group label="% Base ICMS" label-for="trib-per_base_icms"> 
                            <b-form-input id="trib-per_base_icms" type="number"
                                v-model="tributacao.per_base_icms"/>
                        </b-form-group>
                    
                        <b-form-group label="% Aliq. ICMS" label-for="trib-per_icms"> 
                            <b-form-input id="trib-per_icms" type="number"
                                v-model="tributacao.per_icms"/>
                        </b-form-group>

                        <b-form-group label="% Aliq. ICMS (Efetiva)" label-for="trib-per_icms_efetiva"> 
                            <b-form-input id="trib-per_icms_efetiva" type="number"
                                v-model="tributacao.per_icms_efetiva"/>
                        </b-form-group>

                        <b-form-group label="% Suspenso" label-for="trib-per_suspenso"> 
                            <b-form-input id="trib-per_suspenso" type="number"
                                v-model="tributacao.per_suspenso"/>
                        </b-form-group>

                        <b-form-group label="% Isento" label-for="trib-per_isento"> 
                            <b-form-input id="trib-per_isento" type="number"
                                v-model="tributacao.per_isento"/>
                        </b-form-group>

                        <b-form-group label="% Outros" label-for="trib-per_outros"> 
                            <b-form-input id="trib-per_outros" type="number"
                                v-model="tributacao.per_outros"/>
                        </b-form-group>

                        <b-form-group label="% Crédito ICMS" label-for="trib-per_credito_icms"> 
                            <b-form-input id="trib-per_credito_icms" type="number"
                                v-model="tributacao.per_credito_icms"/>
                        </b-form-group>
                    </b-col>

                    <!--********* ST *********** --> 
                    <b-col md="3" sm="12">
                        <b-form-group label="% Base ICMS ST" label-for="trib-per_base_st"> 
                            <b-form-input id="trib-per_base_st" type="number"
                                v-model="tributacao.per_base_st"/>
                        </b-form-group>

                        <b-form-group label="% Aliq. ICMS ST" label-for="trib-per_st"> 
                            <b-form-input id="trib-per_st" type="number"
                                v-model="tributacao.per_st"/>
                        </b-form-group>

                        <b-form-group label="% ST Estadual Simples" label-for="trib-per_st_est_simples"> 
                            <b-form-input id="trib-per_st_est_simples" type="number"
                                v-model="tributacao.per_st_est_simples"/>
                        </b-form-group>
                    </b-col>  


                    <!--********* IPI *********** -->
                    <b-col md="3" sm="12">
                        <b-form-group label="% Base IPI" label-for="trib-per_base_ipi"> 
                            <b-form-input id="trib-per_base_ipi" type="number"
                                v-model="tributacao.per_base_ipi"/>
                        </b-form-group>

                        <b-form-group label="% Aliq. IPI" label-for="trib-per_ipi"> 
                            <b-form-input id="trib-per_ipi" type="number"
                                v-model="tributacao.per_ipi"/>
                        </b-form-group>                        

                        <b-form-group label="% Isento IPI" label-for="trib-per_isento_ipi"> 
                            <b-form-input id="trib-per_isento_ipi" type="number"
                                v-model="tributacao.per_isento_ipi"/>
                        </b-form-group>

                        <b-form-group label="% Outros IPI" label-for="trib-per_outros_ipi"> 
                            <b-form-input id="trib-per_outros_ipi" type="number"
                                v-model="tributacao.per_outros_ipi"/>
                        </b-form-group>
                    </b-col>   

                    <!--********* ISSQN *********** -->
                    <b-col md="3" sm="12">
                        <b-form-group label="% Base ISSQN" label-for="trib-per_base_issqn"> 
                            <b-form-input id="trib-per_base_issqn" type="number"
                                v-model="tributacao.per_base_issqn"/>
                        </b-form-group>
                        <b-form-group label="% Aliq. ISSQN" label-for="trib-per_issqn"> 
                            <b-form-input id="trib-per_issqn" type="number"
                                v-model="tributacao.per_issqn"/>
                        </b-form-group>
                        <b-form-group label="% IR" label-for="trib-per_ir"> 
                            <b-form-input id="trib-per_ir" type="number"
                                v-model="tributacao.per_ir"/>
                        </b-form-group>

                    </b-col>                
                </b-row> 
               
                <b-row>              
                    <b-col md="3" sm="12">
                        <b-form-group label="% Redução Base DIFAL" label-for="trib-per_redbc_difal"> 
                            <b-form-input id="trib-per_redbc_difal" type="number"
                                v-model="tributacao.per_redbc_difal"/>
                        </b-form-group>
                    </b-col>                    
                </b-row>  
                <br>
                <hr>
                <b-row>
                    <b-col md="10" sm="10">
                        <b-button variant="primary" v-if="mode === 'save'"
                            @click="save">Salvar</b-button>
                        <b-button variant="danger" v-if="mode === 'remove'"
                            @click="remove">Excluir</b-button>
                        <b-button class="ml-2" @click="reset">Cancelar</b-button>
                    </b-col>

                    <b-col md="2" sm="2" class="d-flex justify-content-end">                        

                        <i id="popover-target-tributacao"  class="fa fa-question-circle info-alteracoes"></i>

                        <b-popover target="popover-target-tributacao" triggers="hover" placement="top">
                            <template #title>Alterações</template>
                            <b>Usuário Cadastro:</b> {{tributacao.usu_cad}} <br>
                            <b>Data de Cadastro:</b> {{tributacao.data_cad}} <br>
                            <b>Usuário Alteração:</b> {{tributacao.usu_alt}} <br>
                            <b>Data de Alteração:</b> {{tributacao.data_alt}}
                        </b-popover>                        
                    </b-col>
                </b-row>

            </b-form>
        </b-card> 

        <b-modal id="modal-exclue-trib" title="Excluir tributação" v-model="modalExcluirTrib"   >
            <p class="my-4">Deseja realmente excluir esta tributação?</p>
            <template #modal-footer>
                <div class="w-100">                    
                    <b-button
                        variant="primary"
                        size="md"
                        class="float-right ml-3"
                        @click="remove()"                            
                    >
                        Sim
                    </b-button>
                    <b-button
                        variant="danger"
                        size="md"
                        class="float-right"
                        @click="$bvModal.hide('modal-exclue-trib')"
                    >
                        Não
                    </b-button>
                </div>
            </template>

        </b-modal>     

        <b-card class="cadastros-card-corpo" v-show="show">
            <b-table hover striped :items="tributacoes" :fields="fieldsTributacoes">  
                <template v-slot:cell(actions)="data">
                    <b-button variant="warning" @click="loadTributacao(data.item)" class="mr-2">
                        <i class="fa fa-pencil"></i>
                    </b-button>
                    <b-button variant="danger" @click="excluirTrib(data.item)">
                        <i class="fa fa-trash"></i>
                    </b-button>
                </template>
            </b-table>
            <hr>

            <b-pagination 
                align="center" 
                size="md" 
                v-model="page"  
                :total-rows="count" 
                :per-page="limit" 
                first-number 
                last-number>
            </b-pagination>
        </b-card>  
    </div>
</template>

<script>
import { baseApiUrl, showError, showSuccess } from '@/global'
import axios from 'axios'

export default {
    name: 'GruposPisCofins',
    data: function() {
        return {
            mode: 'save',
            tributacao: {},
            tributacoes: [],  
            modalExcluirTrib: false,          
            page: 1,
            count: 0,
            limit: 0,
            parcial: '',
            show: true,
            fieldsTributacoes: [
                {key: 'id_tributacao', label: 'Código', sortable: true},
                {key: 'descricao', label: 'Descrição', sortable: true},
                {key: 'actions', label: 'Ações'}
            ]     
        }
    },
    methods: {
        novaTributacao() {
            this.reset()
            this.show = !this.show       
        },
        upperCase(value) {
            return value.toUpperCase()
        },
        lowerCase(value) {
            return value.toLowerCase()
        },
        novaConsulta() {
            this.page = 1 
            this.loadTributacoes()
        },
        loadTributacoes() {            
            const url = `${baseApiUrl}/tributacoes`    

            axios.get(url).then(res => {
                this.tributacoes = res.data
                this.count = res.data.count 
                this.limit = res.data.limit
            })            
        },
        reset() {
            this.show = true
            this.mode = 'save'
            this.tributacao = {}
            this.loadTributacoes()
        },
        save() {
            const method = this.tributacao.id_tributacao ? 'put' : 'post'
            const idtributacao = this.tributacao.id_tributacao ? `/${this.tributacao.id_tributacao}` : ''

            axios[method](`${baseApiUrl}/tributacoes${idtributacao}`, this.tributacao)
            .then(() => {
                this.$toasted.global.defaultSuccess()
                this.reset()
            })
            .catch(showError)
        },
        excluirTrib(t) {
            this.tributacao.id_tributacao = t.id_tributacao
            this.modalExcluirTrib = true
        },
        remove() {
            const idtributacao = this.tributacao.id_tributacao
            axios.delete(`${baseApiUrl}/tributacoes/${idtributacao}`)
                .then(() => {
                    showSuccess('Tributação excluída com sucesso!') 
                    this.reset()
                    this.modalExcluirTrib = false
                })
            .catch(err => {
                showError(err)
            })
        },
        loadTributacao(t) {           

            const url = `${baseApiUrl}/tributacoes/${t.id_tributacao}`    

            axios.get(url).then(res => {
                this.tributacao = { ...res.data }  
                this.show = !this.show               
            }) 
            .catch(err => {
                showError(err)
            })
        },
    },
    watch: {
        page() {
            this.loadTributacoes()
        }
    },
    mounted() {
        this.loadTributacoes()
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.7rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

</style>