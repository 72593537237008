<template>
    <div class="gerar-faturas">
        
        <div class="acoesTopo">             
            <b-button variant="secondary " class="botoesAcao" v-if="(mode === 'save') || (mode === 'remove')"
                @click="reset">
                    <i class="fa fa-times button-icons"></i>
                    Cancelar
            </b-button>
            <b-button variant="danger" class="botoesAcao" v-if="mode === 'remove'"
                @click="remove">
                    <i class="fa fa-trash button-icons"></i>
                    Excluir
            </b-button>
            <b-button variant="success" class="botoesAcao" v-if="mode === 'save'" 
                @click="save">
                    <i class="fa fa-check button-icons"></i>
                    Salvar
            </b-button>            
            <b-button variant="primary" class="botoesAcao" v-if="mode === 'new'"
                @click="gerarFaturas">
                    <i class="fa fa-cogs button-icons"></i>
                    Gerar Faturas
            </b-button>   
        </div>

        <div class="gerar-faturas-tabs">
            <b-card no-body>
                <b-tabs card v-model="tabFaturas">
                    <b-tab title="Contratos">
                        <b-card class="cadastros-card-pesquisa" v-if="show">            
                            <b-row align-v="center">
                                <b-col md="5" sm="12" class="cadastros-pesquisa-coluna" style="margin-top: 12px"> 
                                    <b-form-input id="localizar" type="search"
                                        :formatter="upperCase"
                                        v-model="parcial"
                                        placeholder="Informe o nome do cliente..."
                                        :readonly="mode === 'remove'"/>                     
                                </b-col> 
                                <b-col md="2" sm="12">
                                    <b-form-group>
                                        Data inicial
                                        <b-form-input id="data-inicial" type="date"
                                            v-model="dataInicio"/>
                                    </b-form-group>
                                </b-col>
                                <b-col md="2" sm="12">
                                    <b-form-group>
                                        Data final
                                        <b-form-input id="data-final" type="date"
                                            v-model="dataFim"/>
                                    </b-form-group>
                                </b-col>

                                <b-col md="3" sm="12" class="d-flex justify-content-end">
                                    <b-button variant="info" @click="novaConsulta">
                                        <i class="fa fa-search button-icons"></i> Consultar
                                    </b-button>
                                </b-col>
                                
                            </b-row> 
                        </b-card> 

                        <b-card class="cadastros-card-corpo">
                            <b-table 
                                sort-icon-left
                                :busy="isBusy"
                                hover 
                                striped 
                                :items="contratos" 
                                :fields="fields"
                                :select-mode="'multi'"
                                @row-selected="onRowSelected"                                
                                ref="selectableTableContratos"                            
                                selectable
                                responsive
                                borderless
                            >  
                                <template #head(selected) v-if="contratos.length > 0">                                    
                                    <template v-if="contratos.length === selecteds.length">
                                        <div style="margin-bottom: -5px">
                                            <span  aria-hidden="true" @click="clearSelected"><i style="font-size: 25px; color: #5d5cfc" class="fa fa-check-square-o"></i></span>
                                        </div>
                                    </template>
                                    <template v-if="contratos.length !== selecteds.length"> 
                                        <div style="margin-bottom: -5px">
                                            <span aria-hidden="true" @click="selectAllRows"><i style="font-size: 25px; color: #5d5cfc" class="fa fa-square-o"></i></span>
                                        </div>
                                    </template>
                                </template>

                                <template #cell(selected)="{ rowSelected }">
                                    <template v-if="rowSelected">
                                        <span aria-hidden="true"><i style="font-size: 25px; color: #5d5cfc" class="fa fa-check"></i></span>
                                    </template>
                                    <template v-else>
                                        <span aria-hidden="true">&nbsp;</span>
                                    </template>
                                </template>

                                <template #table-busy>
                                    <div class="text-center text-primary my-2">
                                    <b-spinner class="align-middle mr-1"></b-spinner>
                                    <strong>Aguarde...</strong>
                                    </div>
                                </template>

                                <template #cell(actions)="row">
                                    <b-button size="sm" @click="toggleDetails(row.item)" class="mr-2">
                                        {{ row.detailsShowing ? 'Ocultar' : 'Mostrar'}} Detalhes
                                    </b-button>
                                </template>                                                  

                                <template #row-details="row">
                                    <b-card>
                                        <b-row >
                                            <b-col sm="4" class="text-sm-left">
                                                <b-list-group>
                                                    <b-list-group-item href="#"><b class="det-title">Primeiro vencimento:</b> <p class="detalhes-orc">{{ row.item.primeiro_vencimento }}</p> </b-list-group-item>
                                                    <b-list-group-item href="#"><b class="det-title">Periodicidade:</b> <p class="detalhes-orc">{{ row.item.periodicidade }} dias</p></b-list-group-item>
                                                    <b-list-group-item href="#"><b class="det-title">Qtde Parcelas:</b> <p class="detalhes-orc">{{ row.item.qtde_parcelas }}</p></b-list-group-item>
                                                    <b-list-group-item href="#"><b class="det-title">Desc. até vencimento:</b> <p class="detalhes-orc">{{ row.item.desc_ate_vencto }}%</p></b-list-group-item>
                                                </b-list-group>
                                            </b-col>
                                            <b-col sm="8" class="text-sm-left">
                                                <div style="margin-top: 15px">
                                                    <b class="det-title" >Serviços</b>
                                                </div>                                                
                                                <ul class="ml-3" v-if="!isBusyServ">
                                                    <li v-for="item in row.item.servicos" :key="item.id_servico">
                                                        <b>{{ item.id_servico }} | {{ item.descricao }} - Total: {{ item.qtde *  item.unitario }}</b> 
                                                        <p>{{ item.detalhes }}</p>
                                                    </li>
                                                </ul>
                                                <div v-else>
                                                    <b-spinner small  variant="primary" label="Spinning"></b-spinner> 
                                                </div>
                                                <hr>
                                                <div style="margin-top: 15px">
                                                    <b class="det-title" >Observações</b>
                                                </div>  
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <b-badge class="mr-2" v-if="row.item.e_cobranca_boleto === 'S'" pill variant="primary">Cobrança via boleto</b-badge>
                                                <b-badge class="mr-2" v-if="row.item.e_vencto_ultimo_dia_mes === 'S'" pill variant="secondary">Venc. último dia do mês</b-badge>
                                                <b-badge class="mr-2" v-if="row.item.e_conciliado === 'S'" pill variant="success">Conciliado</b-badge>
                                                <b-badge class="mr-2" v-if="row.item.e_reter_impostos === 'S'" pill variant="danger">Reter impostos</b-badge>
                                                <b-badge class="mr-2" v-if="row.item.e_prepago === 'S'" pill variant="info">Pré-pago</b-badge>
                                            </b-col>
                                        </b-row>  
                                    </b-card>
                                </template>
                            </b-table>
                            <hr>
                            <b-pagination align="center" size="md" v-model="page"  :total-rows="count" :per-page="limit" first-number last-number></b-pagination>
                        </b-card> 

                    </b-tab>

                    <b-tab title="Faturas">

                        <b-tabs pills card v-model="tabFat">
                            <b-tab title="Faturas geradas">
                                <b-table :items="faturas" :fields="fieldsFaturas" responsive striped hover borderless fixed :busy="isBusyFat" sort-icon-left>                                
                                    <template #table-busy>
                                        <div class="text-center text-primary my-2">
                                        <b-spinner class="align-middle mr-1"></b-spinner>
                                        <strong>Aguarde...</strong>
                                        </div>
                                    </template>
                                </b-table>
                                <hr>
                                <b-pagination 
                                    align="center" 
                                    size="md" 
                                    v-model="page"  
                                    :total-rows="count" 
                                    :per-page="limit" 
                                    first-number 
                                    last-number>
                                </b-pagination> 
                            </b-tab>

                            <b-tab title="Contratos não faturados">
                                <b-table :items="naoFaturados" :fields="fieldsNaoFaturados" responsive striped hover borderless fixed :busy="isBusyFat" sort-icon-left>                                
                                    <template #table-busy>
                                        <div class="text-center text-primary my-2">
                                        <b-spinner class="align-middle mr-1"></b-spinner>
                                        <strong>Aguarde...</strong>
                                        </div>
                                    </template>
                                </b-table>
                                <hr>
                                <b-pagination 
                                    align="center" 
                                    size="md" 
                                    v-model="page"  
                                    :total-rows="count" 
                                    :per-page="limit" 
                                    first-number 
                                    last-number>
                                </b-pagination>
                            </b-tab>
                        </b-tabs>
                        
                    </b-tab>                    
                </b-tabs>
            </b-card>
        </div> 

        <b-card v-show="!show" style="margin-top: 10px">
                <div class="acoesRodape">                 
                <b-button variant="secondary " class="botoesAcaoRodape" v-if="(mode === 'save') || (mode === 'remove')"
                    @click="reset">
                        <i class="fa fa-times button-icons"></i>
                        Cancelar
                </b-button>
                <b-button variant="danger" class="botoesAcaoRodape" v-if="mode === 'remove'"
                    @click="remove">
                        <i class="fa fa-trash button-icons"></i>
                        Excluir
                </b-button>
                <b-button variant="success" class="botoesAcaoRodape" v-if="mode === 'save'"
                    @click="save">
                        <i class="fa fa-check button-icons"></i>
                        Salvar
                </b-button>            
                <b-button variant="primary" class="botoesAcaoRodape" v-if="mode === 'new'"
                    @click="gerarFaturas">
                        <i class="fa fa-cogs button-icons"></i>
                        Gerar Faturas
                </b-button>   
            </div>
        </b-card>            
    </div>
</template>

<script>
import { baseApiUrl, showError, showAlert, showSuccess } from '@/global'
import {VMoney} from 'v-money'
const moment = require('moment')
import axios from 'axios'

export default {
    name: 'GerarFaturas',
    data: function() {
        return {
            isBusyServ: false,
            teste: {},
            selecteds: [],
            contratosSelecionados: [],
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                //suffix: ' #',
                precision: 2,
                masked: false
            },
            mode: 'new',
            contratos: [],
            faturas: [],
            naoFaturados: [],
            fatura: {},
            tabFaturas: 0,
            tabFat: 0,
            page: 1,
            count: 0,
            limit: 0,           
            parcial: '',
            dataInicio: moment().add(-1, 'months').format('yyyy-MM-DD'),
            dataFim: moment().format('yyyy-MM-DD'),
            show: true,
            showOver: false,
            isBusy: false,
            isBusyFat: false,

            fields: [
                {key: 'selected', label: '', sortable: false},
                {key: 'num_contrato', label: 'Nº Contrato', sortable: true},
                {key: 'razao_social', label: 'Nome', sortable: true},
                {key: 'data', label: 'Data do Contrato', sortable: false, 
                    formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')                        
                    }
                },                
                {key: 'encerramento', label: 'Encerramento', sortable: false,  
                    formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')                        
                    }
                },
                {key: 'actions', label: 'Ações'}
            ],       
            fieldsFaturas: [
                {key: 'id_conta_receber', label: 'Fatura', sortable: true},
                {key: 'razao_social', label: 'Nome', sortable: true},
                {key: 'data_competencia', label: 'Competência', sortable: true, 
                    formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')                        
                    }
                },
                {key: 'vencimento', label: 'Vencimento', sortable: true, 
                    formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')                        
                    }
                },
                {key: 'valor', label: 'Valor', sortable: true, formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                        
                    }
                },
                {key: 'descricao', label: 'Descrição', sortable: true},
            ],        
            fieldsNaoFaturados: [
                {key: 'num_contrato', label: 'Contrato', sortable: true},
                {key: 'data', label: 'Data', sortable: true, 
                    formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')                        
                    }
                },
                {key: 'razao_social', label: 'Nome', sortable: true},
                {key: 'motivo', label: 'Motivo', sortable: false},
            ]        
        }
    },
    directives: {
        money: VMoney
    },
    methods: {
        async toggleDetails(item) {              
            
            if (item["_showDetails"]) {
                item["_showDetails"] = false
            }
            else {
                this.$set(item, "_showDetails", true)
                this.isBusyServ = true
                
                const url = `${baseApiUrl}/contratoItens/${item.id_contrato}` 
                axios.get(url).then(res => {
                    item.servicos = res.data
                    this.isBusyServ = false
                }) 
                .catch(err => {
                    showError(err)
                    this.isBusyServ = false
                })          
            }
        },
        onRowSelected(s) { 
            
            this.selecteds = s
            
            this.contratosSelecionados = this.selecteds.map(c=> {return c.id_contrato}) 
        },
        selectAllRows() {
            this.$refs.selectableTableContratos.selectAllRows()
        },
        clearSelected() {
            this.$refs.selectableTableContratos.clearSelected()
        },
        gerarFaturas() {              
            this.isBusyFat = true               

            axios['post'](`${baseApiUrl}/gerar_faturas`, this.contratosSelecionados)
                .then((res) => {
                    this.isBusyFat = false
                    this.faturas = res.data.faturas
                    this.naoFaturados = res.data.naoFaturados 
                    
                     
                    if (this.faturas.length > 0) {

                        if (this.naoFaturados.length > 0) {
                            this.tabFaturas = 1
                            this.tabFat = 1
                            showAlert('Alguns contratos não foram faturados! <br>Verifique o motivo na aba contratos não faturados')
                        } else {
                            this.tabFaturas = 1
                            this.tabFat = 0
                            showSuccess('Contratos faturados com sucesso')
                        }                        
                    } else {
                        this.tabFaturas = 1
                        this.tabFat = 1 
                        showError('Nenhuma fatura gerada! <br>Verifique o motivo na aba contratos não faturados')
                    }              
                    
                    this.reset()
                })
            .catch(err => {
                this.isBusyFat = false
                this.tabFaturas = 0
                showError(err)
            })  
        },
        upperCase(value) {
            return value.toUpperCase()
        },
        lowerCase(value) {
            return value.toLowerCase()
        },
        novaConsulta() {
            this.page = 1 
            this.loadContratos()
        },        
        loadContratos() { 
            this.isBusy = true
            const pg = this.page
            const p = this.parcial
            const t = 'cadfatcontrato'
            const di = this.dataInicio
            const df = this.dataFim

            const url = `${baseApiUrl}/consulta/cadastros/?page=${pg}&parcial=${p}&tipo=${t}&dataInicio=${di}&dataFim=${df}` 

            axios.get(url).then(res => {
                this.contratos = res.data.data
                this.contratos.forEach(c=> {c.primeiro_vencimento = moment(c.primeiro_vencimento).format('DD/MM/YYYY')})
                this.count = res.data.count 
                this.limit = res.data.limit
                this.isBusy = false
            }) 
            .catch(err => {
                this.isBusy = false
                showError(err)
            })         
        },
        reset() {
            this.mode = 'new'
            
            this.contratos = []
            this.contratosSelecionados = [],

            this.loadContratos()
        },
        remove() {
            const idservico = this.servico.id_servico
            axios.delete(`${baseApiUrl}/servicos/${idservico}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        loadFatura(contaReceber) {             

            const url = `${baseApiUrl}/contratos/${contaReceber.id_conta_receber}`    

            axios.get(url).then(res => {
                this.fatura = { ...res.data }   
              
            }) 
            .catch(err => {
                showError(err)
            })

        },
        loadContrato(contrato, mode = 'save') {
            this.show = !this.show 
            this.showOver = !this.showOver 

            const url = `${baseApiUrl}/contratos/${contrato.id_contrato}`    

            axios.get(url).then(res => {
                
                this.showOver = !this.showOver 
                
                this.mode = mode
                this.contrato = { ...res.data }
                this.contrato.data = moment(this.contrato.data).format('yyyy-MM-DD')
                this.contrato.encerramento = moment(this.contrato.encerramento).format('yyyy-MM-DD')
                this.contrato.primeiro_vencimento = moment(this.contrato.primeiro_vencimento).format('yyyy-MM-DD')                

                this.contrato.e_cobranca_boleto === 'S' ? this.contrato.e_cobranca_boleto = true : this.contrato.e_cobranca_boleto = false 
                this.contrato.e_vencto_ultimo_dia_mes === 'S' ? this.contrato.e_vencto_ultimo_dia_mes = true : this.contrato.e_vencto_ultimo_dia_mes = false 
                this.contrato.e_conciliado === 'S' ? this.contrato.e_conciliado = true : this.contrato.e_conciliado = false 
                this.contrato.e_reter_impostos === 'S' ? this.contrato.e_reter_impostos = true : this.contrato.e_reter_impostos = false 
                this.contrato.e_prepago === 'S' ? this.contrato.e_prepago = true : this.contrato.e_prepago = false

                this.loadPlanoContas()
                this.loadFormas()
                this.enumeraItens()   

                this.contrato.itens.forEach(i=> {
                    i.total = i.qtde * i.unitario
                })
            }) 
            .catch(err => {
                showError(err)
                this.show = !this.show 
                this.showOver = !this.showOver 
            })
        },
        loadGruposIcmsIpi() {
            const url = `${baseApiUrl}/gruposicmsipi`
            axios.get(url).then(res => {
                this.gruposIcmsIpi = res.data.map(grupoIcmsIpi => {
                    return { ...grupoIcmsIpi, value: grupoIcmsIpi.id_grupo_icms_ipi, text: grupoIcmsIpi.descricao }
                })
            })
        },
        loadGruposPisCofins() {
            const url = `${baseApiUrl}/grupospiscofins`
            axios.get(url).then(res => {
                this.gruposPisCofins = res.data.map(grupoPisCofins => {
                    return { ...grupoPisCofins, value: grupoPisCofins.id_grupo_pis_cofins, text: grupoPisCofins.descricao }
                })
            })
        },
        loadUnidades() {
            const url = `${baseApiUrl}/unidadesmedida`
            axios.get(url).then(res => {
                this.unidadesMedida = res.data.map(unidade => {
                    return { ...unidade, value: unidade.id_unidade_medida, text: `${unidade.und} | ${unidade.descricao}` }
                })
            })
        },
    
    },
    watch: {
        page() {
            this.loadContratos()
        }
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.9rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

    .detalhes-orc {
        font-size: 1.3rem;
        font-weight: 600;
        margin: 0;
        
    }

    .det-title {
        font-size: 0.9rem;
        color: rgb(90, 90, 90);
    }

    
    



</style>