<template>
    <div class="config-pages">
        <PageTitle icon="fa fa-cog" main="Parâmetros do Sistema"
            sub="Configurações gerais" />
        
        <CadastroConfig />   
    </div>
</template>

<script>
import PageTitle from '../template/PageTitle'
import CadastroConfig from './CadastroConfig.vue'

export default {
    name: 'ConfigPages',
    components: { PageTitle, CadastroConfig }
}
</script>

<style>

</style>
