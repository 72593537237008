import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"pdf-item"},[_c('div',{staticClass:"myStyle"},[_c('div',{staticClass:"containerPdf",attrs:{"id":"renderPDF"}},[_c('h2',[_vm._v(_vm._s(_vm.config.param.titulo))]),_c('hr'),_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.relacao,"item-key":"name","sort-by":"name","group-by":"nome_estado","hide-default-footer":"","dense":"","items-per-page":1000},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
var items = ref.items;
return [_c('td',{attrs:{"colspan":headers.length}},[_c(VBtn,{ref:group,attrs:{"x-small":"","icon":""},on:{"click":toggle}},[(isOpen)?_c(VIcon,[_vm._v("mdi-minus")]):_c(VIcon,[_vm._v("mdi-plus")])],1),_c('span',{staticClass:"mx-1 font-weight-bold"},[_vm._v(_vm._s(group))]),_c('span',{staticClass:"mx-5 font-weight-bold"},[_vm._v("(Soma: "+_vm._s(items.map(function (item) { return item.id_estado; }).reduce(function (prev, curr) { return prev + curr; }, 0))+")")])],1)]}}],null,true)})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }