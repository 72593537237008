<template>
    <div class="cont-indic" :style="style">
        <div class="indicador">
            <div class="indic-info-title">
                <span class="indic-title">{{ title }}</span>                
            </div> 
            <div class="indic-info">
                <span class="indic-value" v-if="value !== null || value !== undefined">{{ value.toLocaleString('pt-br',{minimumFractionDigits: `${fracao1}`, maximumFractionDigits: 2, currency: 'BRL'}) }}</span>
                <span class="indic-sub" v-if="value2 !== null || value2 !== undefined">{{ value2 }}</span>
                <span class="indic-sub" v-if="perc">{{ perc.toLocaleString('pt-br',{minimumFractionDigits: `${fracao2}`, maximumFractionDigits: 2, currency: 'BRL'}) }}%</span>                
            </div>             
        </div>
    </div>
    
</template>

<script>
export default {
    name: 'Indicador',
    props: ['title', 'value', 'value2', 'perc', 'color', 'fracao1', 'fracao2'],
    computed: {
        style() {
            return "background-color: " + (this.color || "#000")
        }
    }
    
}
</script>

<style>
    .cont-indic {
        flex: 1;
        margin: 20px 20px 20px 0;
        padding: 10px 15px 15px 15px;
        background-color: #fff;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.096);
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.164);
        min-width: 15rem;
        max-height: 8rem;
    }

    .cont-indic hr {
        margin-top: 25px;
    }

    .indicador {
        flex: 1;
        display: flex;   
        flex-direction: column;     
    }    

    .indic-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .indic-info-title {
        margin-top: -10px;
    }

    .indic-title {
        font-size: 0.8rem;
        color: #fff;
    }

    .indic-value {
        font-size: 2.2rem;
        color: #fff;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }
    .indic-sub {
        font-size: 0.9rem;
        margin: -3px;
        color: #fff;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }
</style>
