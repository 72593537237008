<template>    
    <div id="wrapper" >          
        <div class="acoesTopo">             
            <b-button variant="secondary " class="botoesAcao" v-if="(mode === 'save') || (mode === 'remove')"
                @click="reset">
                    <i class="fa fa-times button-icons"></i>
                    Cancelar
            </b-button>
            <b-button variant="danger" class="botoesAcao" v-if="mode === 'remove'"
                @click="remove">
                    <i class="fa fa-trash button-icons"></i>
                    Excluir
            </b-button>
            <b-button variant="success" class="botoesAcao" v-if="mode === 'save'" 
                @click="save">
                    <i class="fa fa-check button-icons"></i>
                    Salvar
            </b-button>            
            <b-button variant="primary" class="botoesAcao" v-if="!showRelato"
                @click="showRelato = !showRelato">
                    <i class="fa fa-arrow-left button-icons"></i>
                    Voltar
            </b-button>   
            <b-button variant="primary" class="botoesAcao" v-if="(mode === 'new') && (showRelato)"
                @click="showRelato = !showRelato">
                    <i class="fa fa-file-text-o button-icons"></i>
                    Relatórios
            </b-button>   
            <b-button variant="primary" class="botoesAcao" v-if="(mode === 'new') && (showRelato)"
                @click="novaVenda">
                    <i class="fa fa-plus button-icons"></i>
                    Novo
            </b-button>   
        </div>  

        <div v-if="showRelato"> 
            <!-- CONSULTA VENDA -->
            <b-card class="cadastros-card-pesquisa" v-if="show">
                <b-row>
                    <b-col md="4" sm="12">
                        <b-form-group>
                            Localizar
                            <b-form-input id="localizar" type="search"
                                :formatter="upperCase"
                                v-model="locateVenda"
                                :readonly="mode === 'remove'"
                                placeholder="Informe os dados da pesquisa..." />
                        </b-form-group>
                    </b-col> 

                    <b-col md="3" sm="12">
                        <b-form-group>
                            Data inicial
                            <b-form-input id="data-inicial" type="date"
                                v-model="dataInicial"
                                :readonly="mode === 'remove'" />
                        </b-form-group>
                    </b-col>
                    <b-col md="3" sm="12">
                        <b-form-group>
                            Data final
                            <b-form-input id="data-final" type="date"
                                v-model="dataFinal"
                                :readonly="mode === 'remove'" />
                        </b-form-group>
                    </b-col>
                    <b-col md="2" sm="12" class="d-flex justify-content-end">
                        <b-button variant="info" @click="novaConsulta">
                            <i class="fa fa-search button-icons"></i> Consultar
                        </b-button>
                    </b-col>
                </b-row>
            </b-card>  
            <!-- NOVA VENDA -->
            <b-card class="cadastros-card-pesquisa" v-show="!show">
                <b-form>
                    <input id="venda-id-cadastro" type="hidden" v-model="venda.id_venda" /> 
                    <b-row>
                        <b-col md="2" sm="12">
                            <b-form-group label="Data" label-for="data-venda">
                                <b-form-input id="data-venda" type="date"
                                    v-model="venda.data_venda"
                                    :readonly="mode === 'remove'" required/>
                            </b-form-group>
                        </b-col> 
                        <b-col md="4" sm="12">
                            <b-form-group label="Cliente" label-for="cliente-nome">
                                <Autocomplete
                                    id="cliente-nome"
                                    :options="clientes"
                                    label-key="razao_social"
                                    value-key="idpessoa"
                                    v-model="venda.id_pessoa"
                                    placeholder=""
                                    @shouldSearch="loadPessoas"
                                    @select="onSelect"
                                />
                            </b-form-group>                        
                        </b-col>                    
                        <b-col md="3" sm="12">
                            <b-form-group label="Forma" label-for="forma-venda">
                                <b-form-select id="forma-venda" v-model="venda.id_forma_pagar_receber" :options="formas" />                            
                            </b-form-group>
                        </b-col> 
                        <b-col md="3" sm="12">
                            <b-form-group label="Condição" label-for="condicao-venda">
                                <b-form-select id="condicao-venda" v-model="venda.id_condicao_pagar_receber" :options="condicoes" />
                            </b-form-group>
                        </b-col> 
                    </b-row> 

                    <b-row>
                        <b-col md="3" sm="12">
                            <b-form-group label="Lançamento" label-for="lancamento-venda">
                                <b-form-select id="lancamento-venda" v-model="venda.id_lancamento" :options="lancamentos" />                            
                            </b-form-group>
                        </b-col>     
                        <b-col md="5" sm="12" v-if="venda.email">
                            <b-form-group label="E-mail (Marketplace)" label-for="e-mail-venda">
                                <b-form-input id="e-mail-venda" type="text" v-model="venda.email" :readonly="mode === 'remove'" />                           
                            </b-form-group>
                        </b-col>     
                    </b-row>            

                    <b-row>
                        <b-col md="3" sm="12">
                            <b-card bg-variant="info" class="totais-venda">
                                <b-form-group label="Descontos" label-for="desconto-total-venda">
                                    <money id="desconto-total-venda" v-model="venda.desconto" v-bind="money" class="form-control-lg" readonly style="width: 100%" />                             
                                </b-form-group>
                            </b-card>
                        </b-col>
                        <b-col md="3" sm="12">
                            <b-card bg-variant="info" class="totais-venda">
                                <b-form-group label="Acréscimos" label-for="acrescimo-total-venda">
                                    <money id="acrescimo-total-venda" v-model="venda.acrescimo" v-bind="money" class="form-control-lg" readonly style="width: 100%"/>
                                </b-form-group>
                            </b-card>
                        </b-col> 
                        <b-col md="3" sm="12">
                            <b-card bg-variant="info" class="totais-venda">
                                <b-form-group label="Total Produtos" label-for="produtos-total-venda">
                                    <money id="produtos-total-venda" v-model="venda.total_itens" v-bind="money" class="form-control-lg" readonly style="width: 100%"/>
                                </b-form-group>
                            </b-card>
                        </b-col> 
                        <b-col md="3" sm="12">
                            <b-card bg-variant="danger" class="totais-venda">
                                <b-form-group label="Total da Venda" label-for="liquido-total-venda">
                                    <money id="liquido-total-venda" v-model="venda.total_liquido" v-bind="money" class="form-control-lg" readonly style="width: 100%"/>
                                </b-form-group>
                            </b-card>
                        </b-col>  
                    </b-row>
                </b-form>


                <!-- ITENS DA VENDA / PARCELAMENTO -->
                <b-tabs  content-class="mt-3" card pills align="center">

                    <!-- ITENS DA VENDA -->
                    <b-tab title="Produtos" class=""> 
                        <b-form> 
                            <b-row>                  
                                <b-col md="11" sm="12" style="padding-right: 30px">
                                    <b-row style="margin-bottom: -40px">  
                                        <b-col md="6" sm="12">
                                            <b-form-group label="Produto" label-for="produto-name-input">
                                                    <Autocomplete
                                                        id="produto-name-input"
                                                        :options="produtos"
                                                        label-key="descricao"
                                                        value-key="id_produto"
                                                        :readyOnlyX="modeItens === 'remove' ? 'true' : 'false'"
                                                        v-model="vendaItem.idproduto"
                                                        placeholder=""
                                                        @shouldSearch="loadProdutos"
                                                        @select="onSelectProduto"
                                                        :readonly="modeItens === 'remove'" 
                                                    />
                                            </b-form-group>                        
                                        </b-col>
                                        <b-col md="2" sm="12">
                                            <b-form-group label="Qtde" label-for="qtde-item-venda">
                                                <b-form-input id="qtde-item-venda" type="number"                                
                                                    v-model="vendaItem.qtde" required
                                                    :readonly="modeItens === 'remove'" trim @input="calculaItem()"/>
                                            </b-form-group>
                                        </b-col> 
                                        <b-col md="4" sm="12">
                                            <b-form-group label="Unitário" label-for="unitario-item-venda">
                                                <money v-model="vendaItem.unitario" v-bind="moneyItens" class="form-control" :readonly="modeItens === 'remove' " @input="calculaItem()"/>                                    
                                            </b-form-group>
                                        </b-col> 
                                    </b-row> 

                                    <b-row style="margin-bottom: 0px">
                                        <b-col md="4" sm="12">
                                            <b-form-group label="Desc." label-for="desc-item-venda">
                                                <money v-model="vendaItem.desconto" v-bind="moneyItens" class="form-control" :readonly="modeItens === 'remove'" @input="calculaItem()"/>
                                            </b-form-group>
                                        </b-col> 
                                        <b-col md="4" sm="12">
                                            <b-form-group label="Acrésc." label-for="acresc-item-venda">
                                                <money v-model="vendaItem.acrescimo" v-bind="moneyItens" class="form-control" :readonly="modeItens === 'remove'" @input="calculaItem()"/>
                                            </b-form-group>
                                        </b-col> 
                                        <b-col md="4" sm="12">
                                            <b-form-group label="Total" label-for="total-item-venda">
                                                <money v-model="vendaItem.total_liquido" v-bind="moneyItens" class="form-control" readonly/>
                                            </b-form-group>
                                        </b-col>                                 
                                    </b-row>                                    
                                </b-col>

                                <b-col md="1" sm="12" align-self="end" style="padding-bottom: 47px">
                                    <b-row style="padding: 5px">
                                        <b-button style="width: 100%" variant="success" v-if="modeItens === 'save'"
                                            @click="saveItem"><i class="fa fa-check"></i></b-button>                                        
                                    </b-row>
                                    <b-row style="padding: 5px">
                                        <b-button  class="btn-excluir" variant="danger" v-if="modeItens === 'remove'"
                                            @click="removeItem"><i class="fa fa-trash"></i></b-button>

                                        <b-button style="width: 100%"  @click="resetItem"><i class="fa fa-times"></i></b-button>
                                    </b-row>
                                </b-col>

                            </b-row>
                        </b-form>

                        <b-table hover fixed striped :items="vendasItens" :fields="fieldsVendasItens">  
                            <template v-slot:cell(actions)="data">
                                <b-button variant="warning" @click="loadVendaItem(data.item)" class="mr-2">
                                    <i class="fa fa-pencil"></i>
                                </b-button>
                                <b-button variant="danger" @click="loadVendaItem(data.item, 'remove')">
                                    <i class="fa fa-trash"></i>
                                </b-button>
                            </template>
                            <template #cell(unitario)="data">                            
                                <money type="text" v-model="data.item.unitario" v-bind="moneyItens" readonly class="moeda-tabela valores-tabela" style="width: 80px"/>
                            </template>
                            <template #cell(desconto)="data">                            
                                <money type="text" v-model="data.item.desconto" v-bind="moneyItens" readonly class="moeda-tabela valores-tabela"  style="width: 80px"/>
                            </template>
                            <template #cell(acrescimo)="data">                            
                                <money type="text" v-model="data.item.acrescimo" v-bind="moneyItens" readonly class="moeda-tabela valores-tabela"  style="width: 80px"/>
                            </template>
                            <template #cell(total_liquido)="data">                            
                                <money type="text" v-model="data.item.total_liquido" v-bind="moneyItens" readonly class="moeda-tabela valores-tabela"  style="width: 80px"/>
                            </template>
                            <template #table-colgroup="scope">                          
                                <col :key="scope.id_produto" style="width: 85px">
                                <col :key="scope.descricao" style="width: 100%; min-width: 130px">
                                <col :key="scope.qtde" style="width: 85px">
                                <col :key="scope.unitario" style="width: 130px">
                                <col :key="scope.desconto" style="width: 110px">
                                <col :key="scope.acrescimo" style="width: 110px">
                                <col :key="scope.total_liquido" style="width: 130px">
                                <col :key="scope.actions" style="width: 125px">
                            </template>
                        </b-table>
                    </b-tab>

                    <!-- PARCELAMENTO -->
                    <b-tab title="Parcelamento">   
                        <b-row>
                            <b-col md="12" sm="12" class="d-flex justify-content-end">
                                <b-button variant="success" @click="gerarParcelas">Gerar parcelas</b-button>
                            </b-col>
                        </b-row>                 
                        <CadastroFaturaReceber 
                            :fatura="venda" 
                            :loadFaturaVenda="carregaParcelas" 
                            :gerarFatura="gerarFatura"
                            :resetFaturaVenda="resetFatura"
                            @enviaParcelas="anexarParcelas" />
                                
                    </b-tab>

                    <!-- OBSERVAÇÕES -->
                    <b-tab title="Observações">   
                        <b-row>
                            <b-col md="12" sm="12" class="d-flex justify-content-end">
                                <b-form-textarea
                                    placeholder="Informe uma observação de até 500 caracteres..."
                                    v-model="venda.obs"
                                    rows="3"
                                ></b-form-textarea>
                            </b-col>
                        </b-row> 
                                
                    </b-tab>
                </b-tabs>                
            </b-card>

            <b-card v-show="!show" style="margin-top: 10px">
                    <div class="acoesRodape">                 
                    <b-button variant="secondary " class="botoesAcaoRodape" v-if="(mode === 'save') || (mode === 'remove')"
                        @click="reset">
                            <i class="fa fa-times button-icons"></i>
                            Cancelar
                    </b-button>
                    <b-button variant="danger" class="botoesAcaoRodape" v-if="mode === 'remove'"
                        @click="remove">
                            <i class="fa fa-trash button-icons"></i>
                            Excluir
                    </b-button>
                    <b-button variant="success" class="botoesAcaoRodape" v-if="mode === 'save'"
                        @click="save">
                            <i class="fa fa-check button-icons"></i>
                            Salvar
                    </b-button>   
                </div>
            </b-card> 

            <b-card class="cadastros-card-corpo" v-if="show">
                <b-table :items="vendas" :fields="fieldsConsulta" responsive striped hover borderless fixed v-if="vendas.length > 0">
                    <template v-slot:cell(actions)="data">
                        
                        <b-button variant="warning" @click="loadVenda(data.item)" class="mr-2" >
                            <i class="fa fa-pencil"></i>
                        </b-button>
                        <b-button variant="danger" @click="loadVenda(data.item, 'remove')">
                            <i class="fa fa-trash"></i>
                        </b-button>
                    </template>
                    <template #cell(total_liquido)="data">                            
                        <money type="text" v-model="data.item.total_liquido" v-bind="moneyItens" readonly class="moeda-tabela" />
                    </template>
                    <template #table-colgroup="scope">                          
                        <col :key="scope.id_venda" style="width: 85px">
                        <col :key="scope.data_venda" style="width: 130px">
                        <col :key="scope.razao_social" style="width: 100%; min-width: 130px">
                        <col :key="scope.total_liquido" style="width: 150px">
                        <col :key="scope.actions" style="width: 125px">
                    </template>
                </b-table>
                <hr>

                <b-pagination 
                    v-if="vendas.length > 0"
                    align="center" 
                    size="md" 
                    v-model="page"  
                    :total-rows="count" 
                    :per-page="limit" 
                    first-number 
                    last-number>
                </b-pagination>  
                <span v-else style="color: rgb(114, 114, 114)">Faça uma consulta para exibir os resultados...</span>              
            </b-card>
        </div>

        <b-card class="cadastros-card-corpo" v-if="!showRelato">
            <b-row>
                <b-col b-col md="6" sm="12">
                    <b-card border-variant="dark" header-tag="header">
                        <template #header>
                            <h5 class="mb-0">Vendas por período</h5>
                        </template>

                        <b-row>
                            <b-col md="6" sm="12">
                                <b-form-group label="Data inicial" label-for="vendas-data-inicial">
                                    <b-form-input id="vendas-data-inicial" type="date" v-model="vDataInicial" required/>
                                </b-form-group>
                            </b-col>

                            <b-col md="6" sm="12">
                                <b-form-group label="Data final" label-for="vendas-data-final">
                                    <b-form-input id="vendas-data-final" type="date" v-model="vDataFinal" required/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col align="right">
                                <b-button  variant="primary" style="color: white" @click="relatorio">Gerar relatório</b-button>
                            </b-col>
                        </b-row>
                    </b-card>

                    <!-- MODAL RELATÓRIO DE VENDAS --> 
                    <b-modal size="huge" scrollable ref="relatorio-modal" hide-footer>                            
                        <template #modal-header="{ close }" >
                            <b-row align-v="center" style="padding-left: 20px">
                                <b-col md="11" sm="11">
                                    <b-button variant="primary" style="color: white" 
                                        @click="()=>{gerarPdf = gerarPdf + 1 }">
                                            <i class="fa fa-print" ></i>
                                            Imprimir
                                    </b-button>
                                </b-col>
                                <b-col md="1" sm="1">
                                    <b-button size="sm" variant="outline-default" @click="close()">
                                        <i class="fa fa-times" style="font-size: 22px"></i>
                                    </b-button>
                                </b-col>
                            </b-row>
                        </template>

                        <RelatorioVendas :itens="relatorioVendas" :gerarPdf="gerarPdf" :config="configRel"/>

                    </b-modal>

                </b-col>
            </b-row> 
            
        </b-card>
        
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import CadastroFaturaReceber from '@/components/financeiro/faturasReceber/CadastroFaturaReceber'
import Autocomplete from '@/components/autocomplete/Autocomplete.vue'
import RelatorioVendas from '@/components/vendas/report/RelatorioVendas'
import axios from 'axios'
const moment = require('moment')
import {VMoney} from 'v-money'

export default {
    name: 'CadastroVendas', 
    components: {
        CadastroFaturaReceber,
        Autocomplete,
        RelatorioVendas
    },
    data: function() {
        return {
            vDataInicial: moment().format('YYYY-MM-01'),
            vDataFinal: moment().format('YYYY-MM-DD'),
            relatorioVendas: [],
            gerarPdf: 0,
            configRel: {
                headers: {},
                param: {}
            },
            teste: {},
            index: -1,
            idVenda: 0, 
            page: 1,
            count: 0,
            limit: 0,           
            mode: 'new',
            modeItens: 'save',
            venda: {
                id_pessoa: '',
                data_venda: moment().format('YYYY-MM-DD')
            },
            gerarFatura: 1,
            resetFatura: 1,
            carregaParcelas: 1,
            vendas: [],
            vendaItem: {
                idproduto: '',
                qtde: 1,
                unitario: 0,
                desconto: 0,
                acrescimo: 0
            },
            vendasItens: [],
            formas: [],
            condicoes: [],
            clientes: [],
            produtos: [],
            lancamentos: [],
            locateVenda: '',
            show: true,
            showRelato: true,            
            dataInicial: '1900-01-01',
            dataFinal: '2400-12-31', 
            money: {
                decimal: ',',
                thousands: '.',
                //prefix: 'R$ ',
                //suffix: ' #',
                precision: 2,
                masked: false
            },
            moneyItens: {
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: false
            },
            fieldsConsulta: [
                {key: 'id_venda', label: 'Código', sortable: true},
                {key: 'data_venda', label: 'Data', sortable: true, 
                formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')
                    }
                },
                {key: 'razao_social', label: 'Cliente', sortable: true},
                {key: 'total_liquido', label: 'Total', sortable: true},
                {key: 'actions', label: 'Ações'}
            ],  
            fieldsVendasItens: [
                //{key: 'seq', label: 'Seq', sortable: true},
                //{key: 'id_venda', label: 'VENDA', sortable: true},
                {key: 'id_produto', label: 'Código', sortable: true},
                {key: 'descricao', label: 'Descrição', sortable: true},
                {key: 'qtde', label: 'Quant', sortable: true},
                {key: 'unitario', label: 'Unitário', sortable: true},
                {key: 'desconto', label: 'Desconto', sortable: true},
                {key: 'acrescimo', label: 'Acréscimo', sortable: true},
                //{key: 'total_item', label: 'Total Bruto', sortable: true},
                {key: 'total_liquido', label: 'Total Líquido', sortable: true},
                {key: 'actions', label: 'Ações'}
            ]          
        }
    },
    directives: {
        money: VMoney
    },
    methods: {
        relatorio: async function() {

            this.configRel.headers = [
                { text: 'Nº Venda', value: 'id_venda', align: 'left' },
                { text: 'Data', value: 'data_venda', align: 'left' },
                { text: 'Cliente', value: 'razao_social', align: 'left' },
                { text: 'Produtos', value: 'total_itens', align: 'left' },
                { text: 'Desconto', value: 'desconto', align: 'left' },
                { text: 'Acréscimo', value: 'acrescimo', align: 'left' },
                { text: 'Total', value: 'total_liquido', align: 'right' },
            ]
            this.configRel.param = {  
                titulo: 'Vendas por período',
                subtitulo: `Período: ${moment(this.vDataInicial).format('DD/MM/YYYY')} à ${moment(this.vDataFinal).format('DD/MM/YYYY')}`,
                arquivo: 'vendas-por-periodo',
                datainicial: this.vDataInicial,
                datafinal: this.vDataFinal,
                //agrupador: 'AGRUPADOR',
                ordenar: '',
                
                page: 1,
                limit: 10,
                //order: 'asc'
            }           

            const url = `${baseApiUrl}/vendas/relatorios/periodo/?datainicial=${this.configRel.param.datainicial}&datafinal=${this.configRel.param.datafinal}`    

            await axios.get(url).then(res => {
                this.relatorioVendas = res.data
            }).then(() => {               
                this.$refs['relatorio-modal'].show()
            }).catch(err => {
                showError(err)
            })           
            
        },
        novaVenda(){
            this.show =  !this.show
            this.mode = 'save'
        },
        carrgaUsuarioLogado(){
            const usu = JSON.parse(localStorage.getItem('__lancamentos_user'))
            this.venda.usu_cad = usu.id
            this.venda.usu_alt = usu.id
            this.venda.id_filial = usu.idfilial
        },
        loadIdUsuario(){
            return JSON.parse(localStorage.getItem('__lancamentos_user')).id
        },
        upperCase(value) {
            return value.toUpperCase()
        },
        lowerCase(value) {
            return value.toLowerCase()
        },
        loadVendasItens(value) {
            const url = `${baseApiUrl}/vendas_itens/item_venda/${value}`

            axios.get(url).then(res => {
                this.vendasItens = res.data
            })
        },
        loadFormas() {
            const url = `${baseApiUrl}/formas_pagar_receber`
            axios.get(url).then(res => {
                this.formas = res.data.map(forma => {
                    return { ...forma,  value: forma.id_forma_pagar_receber, text: forma.descricao}
                })
            })
        },
        loadCondicoes() {
            const url = `${baseApiUrl}/condicoes_pagar_receber`
            axios.get(url).then(res => {
                this.condicoes = res.data.map(condicao => {
                    return { ...condicao, value: condicao.id_condicao_pagar_receber, text: condicao.descricao }
                })
            })
        },
        loadPessoas() {  
            const url = `${baseApiUrl}/pessoas`

            axios.get(url).then(res => {
                this.clientes = res.data
            })
        },
        onSelect(pessoa) {
            this.venda.idpessoa = pessoa.idpessoa
        },
        onSelectProduto(produto) {
            this.vendaItem.id_produto = produto.id_produto
        },
        loadProdutos() {
            const url = `${baseApiUrl}/produtos`

            axios.get(url).then(res => {
                this.produtos = res.data
            })
        },  
        loadLancamentos() {
            let url = `${baseApiUrl}/lancamentos/consulta/`    
            url = url+`?data_inicio=1900-01-01`
            url = url+`&data_fim=2400-12-31`
            url = url+`&id_tipo=0`
            url = url+`&status=T`

            axios.get(url).then(res => {
                this.lancamentos = res.data.data.map(lancamento => {
                    return { ...lancamento,  value: lancamento.id_lancamento, text: lancamento.descricao}
                })
                this.count = res.data.count 
                this.limit = res.data.limit
            })  
        },    
        novaConsulta() {
            this.page = 1 
            this.locateVendas()
        },         
        locateVendas() {
            const parc = (this.locateVenda.trim().length > 0) ? this.locateVenda : 'xxxxxxxxxx' 
            const url = `${baseApiUrl}/vendas/consulta/${parc}/datainicio/${this.dataInicial}/datafim/${this.dataFinal}/?page=${this.page}`
            axios.get(url).then(res => {                
                //if( res.data.count  === 0 ) this.$toasted.global.nadaEncontrado()
                this.vendas = res.data.data                
                this.count = res.data.count 
                this.limit = res.data.limit                
            }) 
            .catch(showError)
        },
        reset() {
            this.mode = 'new'
            this.venda = {
                idpessoa: '',
                id_pessoa: '',
                data_venda: moment().format('YYYY-MM-DD'),
                usu_cad: this.loadIdUsuario(),
            }
            this.vendasItens = []
            this.locateVendas()
            this.resetItem()
            this.show = !this.show

            this.resetFatura += 1
           // setTimeout(() => {  this.resetFatura += 1 }, 100)
        },
        resetItem(){
            this.modeItens = 'save'
            
            this.vendaItem = {
                qtde: 1,
                unitario: 0,
                desconto: 0,
                acrescimo: 0,
                id_produto: '',
                idproduto: ''
            }
            this.enumeraItens()
            this.index = -1
        },
        validaParcelas() {
            const forma = this.formas.filter(obj =>{
                if ('id_forma_pagar_receber' in obj  && obj.id_forma_pagar_receber === this.venda.id_forma_pagar_receber) {
                        return obj
                    } 
            })

            const vConta = parseFloat(this.venda.total_liquido).toFixed(2)  
            const taxa = forma[0].taxa
            const coms = forma[0].comissao

            const vTaxa = parseFloat((vConta * coms /100) + taxa).toFixed(2) 
            let somaParc = 0
            let somaTaxa = 0

            this.venda.parcelas.forEach((el, i)=>{
                somaParc = somaParc + Number(parseFloat(this.venda.parcelas[i].valor).toFixed(2))
                somaTaxa = somaTaxa + Number(parseFloat(this.venda.parcelas[i].taxa).toFixed(2))
            })

            if (Number(parseFloat(somaParc).toFixed(2)  ) !== Number(parseFloat(vConta).toFixed(2)  )) throw 'A soma das parcelas é diferente do valor da conta.'
            if (Number(parseFloat(vTaxa).toFixed(2)  ) !== Number(parseFloat(somaTaxa).toFixed(2)  )) throw 'A soma das taxas das parcelas não bate com o valor calculado.'            
        },
        save() {
            if (!this.venda.parcelas) return this.$toasted.error('Gere as parcelas da venda antes de continuar.')

            this.venda.id_pessoa = this.venda.idpessoa
            
            this.validaParcelas()
            this.venda.itens = [ ...this.vendasItens ]    
            
            const method = this.venda.id_venda ? 'put' : 'post'
            const id_venda = this.venda.id_venda ? `/${this.venda.id_venda}` : ''        
            
            axios[method](`${baseApiUrl}/vendas${id_venda}`, this.venda)
                .then(() => {
                    this.reset()
                    this.$toasted.global.defaultSuccess()                    
                })
            .catch(showError)
        },
        saveItem() {  
            if (this.vendaItem.qtde < 1) {
                this.$toasted.error('Informe a quantidade do item.')   
            } else {
                if (this.index < 0) {
                    this.vendaItem.descricao = this.vendaItem.idproduto
                    this.vendaItem.idproduto = this.vendaItem.id_produto

                    this.vendasItens.push({...this.vendaItem})
                } else {
                    this.index = this.vendaItem.seq - 1
                    this.vendaItem.descricao = this.vendaItem.idproduto
                    this.vendaItem.idproduto = this.vendaItem.id_produto
                    this.vendasItens.splice(this.index, 1, {...this.vendaItem})
                }
            }    
            this.enumeraItens()
            this.resetItem()
        },
        enumeraItens(){
            this.venda.desconto = 0
            this.venda.acrescimo = 0
            this.venda.total_itens = 0
            this.venda.total_liquido = 0            

            this.vendasItens.forEach((_, i) =>{                
                this.vendasItens[i].seq = i+1 

                //const qtde = ((!this.vendasItens[i].qtde) || (this.vendasItens[i].qtde < 0)) ? 0 : this.vendasItens[i].qtde
                const desconto = ((!this.vendasItens[i].desconto) || (this.vendasItens[i].desconto < 0)) ? 0 : this.vendasItens[i].desconto 
                const acrescimo = ((!this.vendasItens[i].acrescimo) || (this.vendasItens[i].acrescimo < 0)) ? 0 : this.vendasItens[i].acrescimo 
                const totalItem = ((!this.vendasItens[i].total_item) || (this.vendasItens[i].total_item < 0)) ? 0 : this.vendasItens[i].total_item 
                const totalLiquido = ((!this.vendasItens[i].total_liquido) || (this.vendasItens[i].total_liquido < 0)) ? 0 : this.vendasItens[i].total_liquido 

                this.venda.desconto = this.venda.desconto + desconto
                this.venda.acrescimo = this.venda.acrescimo + acrescimo
                this.venda.total_itens = this.venda.total_itens + totalItem
                this.venda.total_liquido = this.venda.total_liquido + totalLiquido
            })
        },
        quantMinima(value, min){
            return ((value < min)||(!value)) ? false : true
        },
        valorMinimo(value, min){
            return ((value < min)||(!value)) ? false : true
        },
        calculaItem() {
            const qtde = ((!this.vendaItem.qtde) || (this.vendaItem.qtde < 0)) ? 0 : this.vendaItem.qtde
            const unitario = ((!this.vendaItem.unitario) || (this.vendaItem.unitario < 0)) ? 0 : this.vendaItem.unitario
            const desconto = ((!this.vendaItem.desconto) || (this.vendaItem.desconto < 0)) ? 0 : this.vendaItem.desconto 
            const acrescimo = ((!this.vendaItem.acrescimo) || (this.vendaItem.acrescimo < 0)) ? 0 : this.vendaItem.acrescimo 
            
            this.vendaItem.total_item = qtde * unitario 
            this.vendaItem.total_liquido = (qtde * unitario ) 
                - (desconto) + (acrescimo)            
        },
        remove() {
            const id_venda = this.venda.id_venda
            axios.delete(`${baseApiUrl}/vendas/${id_venda}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        removeItem() {
            this.index = this.vendaItem.seq - 1
            this.vendasItens.splice(this.index, 1)
            this.resetItem()
        },
        loadVenda(venda, mode = 'save') {
            this.mode = mode
            this.venda = { ...venda }
            this.venda.id_pessoa = venda.razao_social
            this.venda.data_venda = moment(this.venda.data_venda).format('YYYY-MM-DD')           
            this.show = !this.show
            this.loadVendasItens(this.venda.id_venda)
            this.carregaParcelas += 1
        },
        loadItensParcelas(id_venda) {
            axios.get(`${baseApiUrl}/vendas/${id_venda}/parcelas`)
                .then(res => {
                    this.venda.parcelas = res.data.parcelas
                })
                .catch(showError)
        },
        loadVendaItem(vendaItem, modeItens = 'save') {            
            this.modeItens = modeItens
            this.vendaItem = { ...vendaItem }                     
            this.index = this.vendaItem.seq - 1
            this.vendaItem.idproduto = vendaItem.descricao
        },
        gerarParcelas(){ 
            if(!this.venda.total_liquido) return this.$toasted.info('Total da venda deve ser maior que zero')
            if(!this.venda.id_forma_pagar_receber || !this.venda.id_condicao_pagar_receber) return this.$toasted.info('Selecione a forma e condição de recebimento.')

            this.gerarFatura += 1
            setTimeout(() => {  this.gerarFatura += 1 }, 100)
        },
        anexarParcelas(n){
            //alert('teste')
            this.venda.parcelas = [ ...n ]
        }
    },
    watch: {
        page() {
            this.locateVendas() 
        }
    },
    mounted() {
        this.loadFormas(),
        this.loadCondicoes(),
        this.loadLancamentos(),
        this.carrgaUsuarioLogado()
    }
}
</script>

<style>

.totais-venda{
    margin-bottom: 10px;
    padding: 0;
    height: 115px; 
    color: white;
}

.form-control-lg {
    margin: -10px 0 0 -18px;
    /* margin-left: -50px; */

    height: calc(1.5em + .75rem + 2px);
    font-size: 2rem;
    color: white;
    line-height: 1.5;
}

.button-icons {
    margin-right: 5px;
}

.btn-excluir {
    width: 100%;
    margin-bottom: 10px
}

</style>