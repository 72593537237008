<template>
    <div class="lancamentos-pages"> 
        <PageTitle icon="fa fa-rocket" main="Lançamentos"
            sub="Cadastros" /> 
        
        <CadastroLancamentos />                   
    </div>
    
</template>

<script>
import PageTitle from '@/components/template/PageTitle'
import CadastroLancamentos from '@/components/lancamentos/CadastroLancamentosV2'

export default {
    name: 'LancamentosPages',
    components: { PageTitle, CadastroLancamentos },
}
</script>

<style>

</style>
