<template>
    <div class="financeiro-pages">
        <PageTitle icon="fa fa-usd" main="Financeiro"
            sub="Caixa, pagamentos, recebimentos e cadastros" />
    
        <div class="mt-3">
            <b-card-group deck>
                <router-link to="#" class="router_link_financeiro">
                    <b-card class="financeiro-cards"
                        header="CAIXA"
                        border-variant="primary"
                        header-bg-variant="primary"
                        header-text-variant="white"
                        align="center"
                    >   
                        <div class="card-content">
                            <span class="valores-card">R$ 20.580,75</span><br>
                            <span class="valores-card-label">Saldo</span>
                            <hr class="hr-zinho">
                        </div>
                        <b-card-text>Abrir caixa, Lançamento de receitas e despesas, liquidações e relatórios</b-card-text>
                    </b-card>
                </router-link>
                <router-link to="#" class="router_link_financeiro">
                    <b-card class="financeiro-cards"
                        header="CONTAS A PAGAR"
                        border-variant="danger"
                        header-bg-variant="danger"
                        header-text-variant="white"
                        align="center"
                    >
                        <div class="card-content">
                            <span class="valores-card">R$ 15.000,00</span><br>
                            <span class="valores-card-label">Vencendo hoje</span>
                            <hr class="hr-zinho">
                        </div>
                        <b-card-text>Lançar faturas a pagar, consultas e manutenção</b-card-text>
                    </b-card>
                </router-link>

                <router-link to="/financeiro/faturasReceber" class="router_link_financeiro">
                    <b-card class="financeiro-cards"
                        header="CONTAS A RECEBER"
                        border-variant="success"
                        header-bg-variant="success"
                        header-text-variant="white"
                        align="center"
                    >
                        <div class="card-content">
                            <span class="valores-card">R$ 58.050,00</span><br>
                            <span class="valores-card-label">Vencendo hoje</span>
                            <hr class="hr-zinho">
                        </div>
                        <b-card-text>Lançar faturas a receber, consultas e manutenção</b-card-text>
                    </b-card>
                </router-link>
            </b-card-group>

        </div>
        
        <div class="mt-3">
            <b-card-group deck> 
                <router-link to="/financeiro/planoDeContas" class="router_link_financeiro">   
                    <b-card class="financeiro-cards"
                        header="PLANO DE CONTAS"
                        border-variant="warning"
                        header-bg-variant="warning"
                        header-text-variant="white"
                        align="center"
                    >
                        <b-card-text>Cadastro e manuteção de planos de contas e seus grupos</b-card-text>
                    </b-card>
                </router-link>

                <router-link to="/financeiro/bancos" class="router_link_financeiro">
                    <b-card class="financeiro-cards"
                        header="CONTAS BANCÁRIAS"
                        border-variant="info"
                        header-bg-variant="info"
                        header-text-variant="white"
                        align="center"
                    >
                        <b-card-text>Cadastros e manuteção de contas bancárias</b-card-text>
                    </b-card>
                </router-link>

                <router-link to="/financeiro/formasCondicoes" class="router_link_financeiro">
                    <b-card class="financeiro-cards"
                        header="FORMAS E CONDIÇÕES"
                        border-variant="secondary"
                        header-bg-variant="secondary"
                        header-text-variant="white"
                        align="center"
                    >
                        <b-card-text>Cadastro e manutenção de formas e condições de pagamento e recebimento</b-card-text>
                    </b-card>
                </router-link>   
            </b-card-group> 
        </div>         
    </div>
</template>

<script>
import PageTitle from '../template/PageTitle'
// import BancosPages from './bancos/BancosPages.vue'
// import FaturasReceberPages from './faturasReceber/FaturasReceberPages.vue'
// import FormasCondicoesPages from './formasCondicoes/FormasCondicoesPages.vue'
// import PlanoContasPages from './planoDeContas/PlanoContasPages.vue'

export default {
    name: 'ProdutosPages',
    components: { PageTitle }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.router_link_financeiro {  
        display: flex;
        flex-direction: column;
        color: rgb(66, 66, 66);
        padding: 5px 5px 5px 5px ;
        width: 32.99%;
        align-items: center;
        text-decoration: none;
        transition: all 0.4s ease;
        border-radius: 12px;
}
.router_link_financeiro:hover  {
        color: #11101d;
        text-decoration: none;
}
.router_link_financeiro:hover .financeiro-cards {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.card-title {
    font-weight: 600;
}

.card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 9px;    
}

.hr-zinho {
        border: 0;
        width: 100%;
        height: 1px;
        background-image: linear-gradient(to right,
            rgba(120, 120, 120, 0),
            rgba(120, 120, 120, 0.75),
            rgba(120, 120, 120, 0));
    }

.valores-card {
    display: flex;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
    font-size: 20px; 
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.valores-card-label {
    display: flex;
    flex-direction: column;
    margin-top: 0;
}

</style>
