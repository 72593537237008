<template>
    <div class="fluxo-caixas">    
        <div class="acoesTopo"> 
            <b-button variant="primary" class="botoesAcao" v-b-tooltip.hover.bottomleft="'Baixar PDF'" 
                @click="salvarPdf('FLUXO_DE_CAIXA_MENSAL','fluxo-render-pdf')">
                    <i style="margin-right: 10px" class="fa fa-cloud-download button-icons-acoes"></i> PDF
            </b-button>  
        </div>   

        <b-card class="cadastros-card-fluxo" v-if="showFluxoCaixa === 'mensal'">            
            <b-row align-v="center">
                <b-col class="d-flex justify-content-md-center">
                    <b-button-toolbar>                                                               
                        <b-button-group class="mx-1">
                            <b-input-group prepend="Filial">
                                <b-form-select style="width: 200px; margin-right: 10px;" id="conta-bancaria" v-model="fluxoFilial" :options="filiais"/>                                
                            </b-input-group>
                            <b-input-group prepend="Período">
                                <b-form-input type="date" v-model="fluxoDataInicial"></b-form-input>
                                <b-form-input type="date" v-model="fluxoDataFinal"></b-form-input>
                                <b-input-group-append>
                                <b-button @click="fluxoCaixa()" variant="primary"><i class="fa fa-search button-icons"></i></b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-button-group> 
                    </b-button-toolbar>
                </b-col>    
            </b-row>
            <hr>

            <b-row align-h="center" >
                <div style="width: 775px" id="fluxo-render-pdf">
                    <b-col 
                        md="12" 
                        sm="12" 
                        v-for="(f, id) in dadosFluxoCaixa" v-bind:key="id"
                        :v-if="dadosFluxoCaixa.length > 0"
                    >
                        <b-card title="Fluxo de Caixa (Mensal)" style="border-color: #fff" v-if="f.id == 1">
                            <b-row>
                                <b-col>
                                    <h5>Período: {{fluxoDataInicialF}} até {{fluxoDataFinalF}}</h5>
                                </b-col>
                            </b-row>
                        </b-card>
                        
                        <b-card class="p-2 mb-3" text-variant="light" bg-variant="info" no-body><h4>{{f.data}}</h4></b-card>

                        <b-row>
                            <b-col md="5" sm="5">
                                <b-card title="Receitas por Numerário">
                                    <b-table
                                        bordered
                                        borderless
                                        small
                                        stacked
                                        responsive
                                        :items="f.receitasNumerarios"
                                        :fields="fluxoNumer"
                                        head-variant="light"
                                    ></b-table>
                                </b-card>
                            </b-col>

                            <b-col md="7" sm="7">
                                <Doughnut
                                    :chart-options="chartOptions"
                                    :chart-data="f.receitasNumerarios[0].chartData"
                                    chart-id="graf-rosca"
                                    :height="300"
                                />
                            </b-col>

                            
                        </b-row> 

                        <!-- RECEITAS -->
                        <b-row> 
                            <b-col md="12" sm="12"> 
                                
                                <b-table
                                    borderless
                                    small                                        
                                    fixed
                                    sort-icon-left
                                    :items="f.receitasPlanoContas.lista"
                                    :fields="fluxoCustosVar"
                                    head-variant="light"
                                >
                                    <template #head(plano_contas)>
                                        <h5 class="text-info m-0"><strong>Receitas</strong></h5> 
                                    </template>
                                    <template #head(valor)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.receitasPlanoContas.total.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}
                                            </strong>
                                        </h5> 
                                        </div>
                                    </template>
                                    <template #head(porcentagem)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.receitasPlanoContas.porcentagem.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}%
                                            </strong>
                                        </h5>
                                        </div>
                                    </template>
                                    <template #cell(valor)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                    <template #cell(porcentagem)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>

                                </b-table>
                            </b-col>
                        </b-row>
                        <!-- CUSTOS VARIÁVEIS -->
                        <b-row>
                            <b-col md="12" sm="12">
                                
                                <b-table
                                    borderless
                                    small                                        
                                    fixed
                                    sort-icon-left
                                    :items="f.custosVariaveis.lista"
                                    :fields="fluxoCustosVar"
                                    head-variant="light"
                                >
                                    <template #head(plano_contas)>
                                        <h5 class="text-info m-0"><strong>Custos variáveis</strong></h5> 
                                    </template>
                                    <template #head(valor)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.custosVariaveis.total.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}
                                            </strong>
                                        </h5> 
                                        </div>
                                    </template>
                                    <template #head(porcentagem)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.custosVariaveis.porcentagem.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}%
                                            </strong>
                                        </h5>
                                        </div>
                                    </template>
                                    <template #cell(valor)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                    <template #cell(porcentagem)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>

                                </b-table>
                            </b-col>
                        </b-row>
                        <!-- MARGEM DE CONTRIBUIÇÃO -->
                        <b-row>
                            <b-col md="12" sm="12">
                                <!-- {{f}} -->
                                <b-table
                                    borderless
                                    small                                        
                                    fixed
                                    sort-icon-left
                                    :items="[]"
                                    :fields="fluxoMargem"
                                    head-variant="light"
                                >
                                    <template #head(plano_contas)>
                                        <h5 class="text-info m-0"><strong>Margem de Contribuição</strong></h5> 
                                    </template>
                                    <template #head(valor)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.margemContribuicao.valor.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}
                                            </strong>
                                        </h5> 
                                        </div>
                                    </template>
                                    <template #head(porcentagem)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.margemContribuicao.porcentagem.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}%
                                            </strong>
                                        </h5>
                                        </div>
                                    </template>
                                    <template #cell(valor)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                    <template #cell(porcentagem)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                        <!-- CUSTOS FIXOS -->
                        <b-row>
                            <b-col md="12" sm="12">
                                <b-table
                                    borderless
                                    small                                        
                                    fixed
                                    sort-icon-left
                                    :items="f.custosFixos.lista"
                                    :fields="fluxoCustosVar"
                                    head-variant="light"
                                >
                                    <template #head(plano_contas)>
                                        <h5 class="text-info m-0"><strong>Custos Fixos</strong></h5> 
                                    </template>
                                    <template #head(valor)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.custosFixos.total.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}
                                            </strong>
                                        </h5> 
                                        </div>
                                    </template>
                                    <template #head(porcentagem)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.custosFixos.porcentagem.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}%
                                            </strong>
                                        </h5>
                                        </div>
                                    </template>
                                    <template #cell(valor)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                    <template #cell(porcentagem)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                        <!-- LUCRO OPERACIONAL PRÉ-INVESTIMENTOS -->
                        <b-row>
                            <b-col md="12" sm="12">
                                <!-- {{f}} -->
                                <b-table
                                    borderless
                                    small                                        
                                    fixed
                                    sort-icon-left
                                    :items="[]"
                                    :fields="fluxoMargem"
                                    head-variant="light"
                                >
                                    <template #head(plano_contas)>
                                        <h5 class="text-info m-0"><strong>Lucro operacional pré-investimentos</strong></h5> 
                                    </template>
                                    <template #head(valor)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.lucroOperacionalAntesInvetimentos.valor.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}
                                            </strong>
                                        </h5> 
                                        </div>
                                    </template>
                                    <template #head(porcentagem)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.lucroOperacionalAntesInvetimentos.porcentagem.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}%
                                            </strong>
                                        </h5>
                                        </div>
                                    </template>
                                    <template #cell(valor)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                    <template #cell(porcentagem)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                        <!-- INVESTIMENTOS -->
                        <b-row>
                            <b-col md="12" sm="12">
                                <b-table
                                    borderless
                                    small                                        
                                    fixed
                                    sort-icon-left
                                    :items="f.investimentos.lista"
                                    :fields="fluxoCustosVar"
                                    head-variant="light"
                                >
                                    <template #head(plano_contas)>
                                        <h5 class="text-info m-0"><strong>Investimentos</strong></h5> 
                                    </template>
                                    <template #head(valor)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.investimentos.total.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}
                                            </strong>
                                        </h5> 
                                        </div>
                                    </template>
                                    <template #head(porcentagem)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.investimentos.porcentagem.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}%
                                            </strong>
                                        </h5>
                                        </div>
                                    </template>
                                    <template #cell(valor)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                    <template #cell(porcentagem)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                        <!-- LUCRO OPERACIONAL -->
                        <b-row>
                            <b-col md="12" sm="12">
                                <!-- {{f}} -->
                                <b-table
                                    borderless
                                    small                                        
                                    fixed
                                    sort-icon-left
                                    :items="[]"
                                    :fields="fluxoMargem"
                                    head-variant="light"
                                >
                                    <template #head(plano_contas)>
                                        <h5 class="text-info m-0"><strong>Lucro Operacional</strong></h5> 
                                    </template>
                                    <template #head(valor)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.lucroOperacional.valor.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}
                                            </strong>
                                        </h5> 
                                        </div>
                                    </template>
                                    <template #head(porcentagem)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.lucroOperacional.porcentagem.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}%
                                            </strong>
                                        </h5>
                                        </div>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                        
                        <!-- RECEITAS NÃO OPERACIONAIs -->
                        <b-row>
                            <b-col md="12" sm="12">
                                <b-table
                                    borderless
                                    small                                        
                                    fixed
                                    sort-icon-left
                                    :items="f.entradasNaoOperacionais.lista"
                                    :fields="fluxoCustosVar"
                                    head-variant="light"
                                >
                                    <template #head(plano_contas)>
                                        <h5 class="text-info m-0"><strong>Receitas Não Operacionais</strong></h5> 
                                    </template>
                                    <template #head(valor)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.entradasNaoOperacionais.total.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}
                                            </strong>
                                        </h5> 
                                        </div>
                                    </template>
                                    <template #head(porcentagem)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.entradasNaoOperacionais.porcentagem.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}%
                                            </strong>
                                        </h5>
                                        </div>
                                    </template>
                                    <template #cell(valor)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                    <template #cell(porcentagem)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                        <!-- DESPESAS NÃO OPERACIONAIS -->
                        <b-row>
                            <b-col md="12" sm="12">
                                <b-table
                                    borderless
                                    small                                        
                                    fixed
                                    sort-icon-left
                                    :items="f.saidasNaoOperacionais.lista"
                                    :fields="fluxoCustosVar"
                                    head-variant="light"
                                >
                                    <template #head(plano_contas)>
                                        <h5 class="text-info m-0"><strong>Despesas Não Operacionais</strong></h5> 
                                    </template>
                                    <template #head(valor)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.saidasNaoOperacionais.total.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}
                                            </strong>
                                        </h5> 
                                        </div>
                                    </template>
                                    <template #head(porcentagem)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.saidasNaoOperacionais.porcentagem.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}%
                                            </strong>
                                        </h5>
                                        </div>
                                    </template>
                                    <template #cell(valor)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                    <template #cell(porcentagem)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                        
                        <!-- RESULTADO LÍQUIDO -->
                        <b-row>
                            <b-col md="12" sm="12">
                                <b-table
                                    borderless
                                    small                                        
                                    fixed
                                    sort-icon-left
                                    :items="[]"
                                    :fields="fluxoMargem"
                                    head-variant="light"
                                >
                                    <template #head(plano_contas)>
                                        <h5 class="text-info m-0"><strong>Resultado Líquido</strong></h5> 
                                    </template>
                                    <template #head(valor)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.resultadoLiquido.valor.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}
                                            </strong>
                                        </h5> 
                                        </div>
                                    </template>
                                    <template #head(porcentagem)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.resultadoLiquido.porcentagem.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}%
                                            </strong>
                                        </h5>
                                        </div>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                        
                    </b-col> 
                    <b-col md="12" sm="12" v-if="dadosFluxoCaixa.length < 1">
                        <h4>Nenhuma informação encontrada...</h4>
                    </b-col>
                </div>
                                   
            </b-row>
        </b-card>        
       
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
const moment = require('moment')
import axios from 'axios'
import html2pdf from "html2pdf.js"
import { Doughnut } from 'vue-chartjs/legacy'

export default {
    name: 'FluxoCaixa',
    components: { Doughnut },
    data: function() {
        return {
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false
            }, 
            // Fluxo de caixa
            filiais: [],
            showFluxoCaixa: 'mensal',
            fluxoFilial: 0,
            fluxoDataInicial: moment().add(-1, 'M').format('YYYY-MM-01'),
            fluxoDataFinal: moment().add(-1, 'M').endOf('month').format('YYYY-MM-DD'),
            fluxoDataInicialF: '',
            fluxoDataFinalF: '',
            dadosFluxoCaixa: {},
            fluxoNumer: [
                {key: 'valor_recebido', label: 'Total', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                }, 
                {key: 'total_cheques', label: 'Cheques', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                }, 
                {key: 'total_cartoes', label: 'Cartões', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                }, 
                {key: 'total_bancos', label: 'Bancos', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                }, 
                {key: 'total_dinheiro', label: 'Dinheiro', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                }, 
            ],
            fluxoRecPlano: [
                {key: 'plano_contas', label: 'Plano de Contas', sortable: true},
                {key: 'valor', label: 'Valor', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                }, 
            ],
            fluxoCustosVar: [
                {key: 'plano_contas', label: 'Plano de Contas', sortable: false},
                {key: 'valor', label: 'Valor', sortable: false, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                }, 
                {key: 'porcentagem', label: '', sortable: false, 
                    formatter: value => { 
                        return `${value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}%`  
                    }
                }, 
            ],

            fluxoMargem: [
                {key: 'plano_contas', label: 'Plano de Contas', sortable: false},
                {key: 'valor', label: 'Valor', sortable: false}, 
                {key: 'porcentagem', label: '', sortable: false}, 
            ],
            
        }
    },
    methods: {
        fluxoCaixa() {
            this.fluxoDataInicialF = moment(this.fluxoDataInicial).format('DD/MM/YYYY')
            this.fluxoDataFinalF = moment(this.fluxoDataFinal).format('DD/MM/YYYY')
            const url = `${baseApiUrl}/fluxocaixa/?dataIni=${this.fluxoDataInicial}&dataFim=${this.fluxoDataFinal}&idFilial=${this.fluxoFilial}`  
            axios.get(url).then(res => {
                this.loadFiliais()
                this.dadosFluxoCaixa = res.data
            })
            .catch(err => {
                showError(err)
            })
        },
        salvarPdf(nome, id){
            var element = document.getElementById(id);
            var pagebreak = { mode: 'avoid-all' };

            // Gerar o PDF.
            html2pdf().from(element).set({
                margin:[0.1,0.1,0.1,0.1],
                filename: `${nome}.pdf`,
                pagebreak: pagebreak,
                jsPDF: {orientation: 'portrait', unit: 'in', format: 'a4', compressPDF: true}
            })
            .toPdf()
            .get('pdf').then(function (pdf) {
                    var totalPages = pdf.internal.getNumberOfPages();

                    for (let i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(8);
                        pdf.setTextColor(75);
                        pdf.text('Página ' + i + ' de ' + totalPages, ((pdf.internal.pageSize.getWidth()/2)-0.4) , (pdf.internal.pageSize.getHeight()-0.2));
                    }
            })
            .save();
        },
        loadFiliais() {
            const url = `${baseApiUrl}/filiais`
            axios.get(url).then(res => {
                this.filiais = res.data.map(filial => {
                    return { ...filial,  value: filial.idfilial, text: `${filial.idfilial} | ${filial.razao_social}`}
                })
                this.filiais.unshift({value: 0, text: 'TODAS' })
            })
            .catch(err => {
                showError(err)
            }) 
        },
    
    },
    mounted() {
        this.loadFiliais()
    },
    watch: {
        fluxoFilial() {
            this.fluxoCaixa()
        },
    },
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .opcoes-faturar {
        display: flex;
        align-items: center;
        width: 200px;
        padding: 25px;
        background-color: beige;
    }

    .opcoes-faturar:hover {
        background-color: rgb(197, 197, 197);
    }

    .opcoes-faturar-f {
        width: 30%;
    }

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.9rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

    
    



</style>