<template>
    <div class="cadastro-contas-bancarias">

        <div class="acoesTopo">             
            <b-button variant="secondary " class="botoesAcao" v-if="(mode === 'save') || (mode === 'remove')"
                @click="reset">
                    <i class="fa fa-times button-icons"></i>
                    Cancelar
            </b-button>
            <b-button variant="danger" class="botoesAcao" v-if="mode === 'remove'"
                @click="remove">
                    <i class="fa fa-trash button-icons"></i>
                    Excluir
            </b-button>
            <b-button variant="success" class="botoesAcao" v-if="mode === 'save'" 
                @click="save">
                    <i class="fa fa-check button-icons"></i>
                    Salvar
            </b-button>            
            <b-button variant="primary" class="botoesAcao" v-if="mode === 'new'"
                @click="novaConta">
                    <i class="fa fa-plus button-icons"></i>
                    Novo
            </b-button>   
        </div>
        
        <b-card class="cadastros-card-pesquisa" v-if="show">
            <b-row align-v="center">
                <b-col md="5" sm="12" class="cadastros-pesquisa-coluna"> 
                    <b-form-input id="localizar" type="search"
                        :formatter="upperCase"
                        v-model="parcial"
                        :readonly="mode === 'remove'"
                        placeholder="Informe parte do nome do serviço..." />                     
                </b-col> 
                <b-col md="4" sm="12" class="radio-consulta">
                    <b-form-group  v-slot="{ ariaDescribedby }">
                        <b-form-radio-group 
                            id="radio-slots"
                            v-model="ativos"
                            :aria-describedby="ariaDescribedby"
                            name="radio-options-slots"
                        >
                            <b-form-radio value="S">Ativos</b-form-radio>
                            <b-form-radio value="N">Inativos</b-form-radio>
                            <b-form-radio value="T">Todos</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
                <b-col md="3" sm="12" class="d-flex justify-content-end">
                    <b-button variant="info" @click="novaConsulta">
                        <i class="fa fa-search button-icons"></i> Consultar
                    </b-button>
                </b-col>
            </b-row>
            
        </b-card>

        <b-card class="cadastros-card-corpo" v-if="!show">
            <b-form>
                <input id="contas-bancarias-id" type="hidden" v-model="contaBancaria.id_conta_bancaria" />

                <b-row>
                    <b-col md="8" sm="12">
                        <b-row>
                            <b-col md="6" sm="6">
                                <b-form-group label="Banco" label-for="banco-nome">
                                    <b-form-select id="banco-nome" v-model="contaBancaria.id_banco" :options="bancos" />
                                </b-form-group>

                                <b-form-group label="Nome da conta" label-for="conta-nome-conta">
                                    <b-form-input id="conta-nome-conta" type="text"
                                        :formatter="upperCase"
                                        v-model="contaBancaria.nome_conta" required
                                        :readonly="mode === 'remove'"
                                        placeholder="Informe o nome da conta..." />
                                </b-form-group> 

                                <b-form-group label="Agência / DV" label-for="conta-agencia">
                                    <b-input-group>
                                        <b-form-input id="conta-agencia" type="text"
                                        :formatter="upperCase"
                                        v-model="contaBancaria.agencia" required
                                        :readonly="mode === 'remove'"
                                        placeholder="Informe a agência" />

                                        <div style="width: 65px;">
                                            <b-form-input id="conta-agencia-dv" type="text" 
                                            :formatter="upperCase"
                                            v-model="contaBancaria.agencia_dv" required
                                            :readonly="mode === 'remove'" />
                                        </div>                                
                                    </b-input-group>
                                </b-form-group>

                                <b-form-group label="Conta / DV" label-for="conta-conta">
                                    <b-input-group>
                                        <b-form-input id="conta-conta" type="text"
                                        :formatter="upperCase"
                                        v-model="contaBancaria.conta" required
                                        :readonly="mode === 'remove'"
                                        placeholder="Informe a conta" />

                                        <div style="width: 65px;">
                                            <b-form-input id="conta-conta-dv" type="text" 
                                            :formatter="upperCase"
                                            v-model="contaBancaria.conta_dv" required
                                            :readonly="mode === 'remove'" />
                                        </div>                                
                                    </b-input-group>
                                </b-form-group>
                            </b-col>

                            <b-col md="6" sm="6">
                                <b-form-group label="Tipo de Conta" label-for="tipo-conta">
                                    <b-form-select id="tipo-conta" v-model="contaBancaria.tipo_conta" :options="['CORRENTE','POUPANCA']" />
                                </b-form-group>
                                <b-form-group label="Cód. do Beneficiário" label-for="conta-cod-benef">
                                    <b-form-input id="conta-cod-benef" type="text"
                                        :formatter="upperCase"
                                        v-model="contaBancaria.codigo_beneficiario"
                                        :readonly="mode === 'remove'"/>
                                </b-form-group>
                                <b-form-group label="Cód. banco Correspondente" label-for="conta-banco-corres">
                                    <b-form-input id="conta-banco-corres" type="text"
                                        :formatter="upperCase"
                                        v-model="contaBancaria.codigo_banco_correspondente" required
                                        :readonly="mode === 'remove'" />
                                </b-form-group>
                                <b-form-group label="Cód. Bancário da Conta" label-for="conta-cod-bancario">
                                    <b-form-input id="conta-cod-bancario" type="text"
                                        :formatter="upperCase"
                                        v-model="contaBancaria.conta_cod_empresa" required
                                        :readonly="mode === 'remove'" />
                                </b-form-group>

                            </b-col>
                        </b-row>
                        <hr>
                        <b-row>
                            <b-col md="7" sm="6">
                                <b-form-group label="Local de Pagamento" label-for="conta-local-pagto">
                                    <b-form-input id="conta-local-pagto" type="text"
                                        v-model="contaBancaria.local_pagamento" required
                                        :readonly="mode === 'remove'"
                                        placeholder="Ex.: Pagável em qualquer banco até o vencimento." />
                                </b-form-group> 
                            </b-col>

                            <b-col md="5" sm="6">
                                <b-form-group label="Filial" label-for="filial-name-inputt">
                                    <vue-single-select
                                        id="filial-name-input"                            
                                        placeholder="Procurar"
                                        v-model="filialSelecionada"
                                        :options="filiais"
                                        option-label="text"
                                        option-key="value"
                                        :get-option-description="label"
                                        :get-option-value="value"
                                    ></vue-single-select>
                                </b-form-group> 
                            </b-col>
                        </b-row> 

                    </b-col>                    

                    <b-col md="4" sm="12" style="border-left: 1px solid; border-color: #D3D3D3;">
                        <b-form-group label="Ambiente Boleto"  v-slot="{ ariaDescribedby }">
                            <b-form-radio-group 
                                id="radio-slots-ambientes"
                                v-model="contaBancaria.ambiente"
                                :aria-describedby="ariaDescribedby"
                                name="radio-options-slots-ambientes"
                            >
                                <b-form-radio value="P">Produção</b-form-radio>
                                <b-form-radio value="H">Homologação</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <hr>

                        <div >
                            <b-row class="cadastros-opcoes-row" align-h="around">                        
                                <b-col md="12" sm="12">
                                    <b-form-checkbox value="S" unchecked-value="N" id="conta-bancaria-ativo" v-show="mode === 'save'" v-model="contaBancaria.ativo" class="mt-3 mb-3" switch>Ativo</b-form-checkbox>
                                    <b-form-checkbox value="S" unchecked-value="N" id="conta-impressao-alt" v-show="mode === 'save'" v-model="contaBancaria.e_impressao_atualizada_alteracao" class="mt-3 mb-3" switch>Impressão atualizada na alteracão</b-form-checkbox>
                                    <b-form-checkbox value="S" unchecked-value="N" id="conta-validacao_ativa" v-show="mode === 'save'" v-model="contaBancaria.e_validacao_ativa" class="mt-3 mb-3" switch>Validacão ativa</b-form-checkbox>
                                    <b-form-checkbox value="S" unchecked-value="N" id="conta-impressao-liq" v-show="mode === 'save'" v-model="contaBancaria.e_impressao_atualizada_liquidado" class="mt-3 mb-3" switch>Impressão atualizada quando liquidado</b-form-checkbox>
                                    <b-form-checkbox value="S" unchecked-value="N" id="conta-impressao-atualizada" v-show="mode === 'save'" v-model="contaBancaria.e_impressao_atualizada" class="mt-3 mb-3" switch>Impressão atualizada</b-form-checkbox>
                                 </b-col> 
                            </b-row>
                        </div>

                    </b-col>
                    
                </b-row> 
                
                <br>

                <b-card header="Convênios" header-bg-variant="light">  
                    <b-row> 
                        <div>
                            <b-button size="sm" variant="primary" @click="novoConvenio()">
                                <i class="fa fa-plus"></i> Novo Convênio
                            </b-button>                            
                        </div>
                    </b-row>

                    <b-modal 
                        hideBvModalEvent
                        v-model="modalConvenio"                        
                        id="modal-tipos" 
                        centered size="xl" 
                        ok-only
                        ok-disabled
                        title="Adicionar/Editar Convênios"
                    >                          
                        <b-row>
                            <b-col md="3" sm="12">
                                <b-form-group label-for="convenio-descricao">
                                    Descrição
                                    <b-form-input id="convenio-descricao" type="text"
                                        v-model="convenio.descricao" required/>
                                </b-form-group>

                                <b-form-group label-for="convenio-numero">
                                    Número do convênio
                                    <b-form-input id="convenio-numero" type="text"
                                        v-model="convenio.numero" required/>
                                </b-form-group>

                                <b-form-group label-for="convenio-numero-contrato">
                                    Número do Contrato
                                    <b-form-input id="convenio-numero-contrato" type="text"
                                        v-model="convenio.numero_contrato" required/>
                                </b-form-group>
                            </b-col>

                            <b-col md="3" sm="12">
                                <b-form-group label-for="convenio-carteira">
                                    Carteira
                                    <b-form-input id="convenio-carteira" type="text"
                                        v-model="convenio.carteira" required/>
                                </b-form-group>

                                <b-form-group label-for="convenio-especie">
                                    Espécie
                                    <b-form-input id="convenio-especie" type="text"
                                        v-model="convenio.especie" required/>
                                </b-form-group> 

                                <b-form-group label-for="convenio-categoria">
                                    Categoria
                                    <b-form-select id="convenio-categoria" 
                                    v-model="convenio.categoria" :options="[{value: '1', text: 'Sem registro'},{value: '2', text: 'Com registro'},{value: '3', text: 'Impressão pelo banco'}]" />
                                </b-form-group>
                            </b-col>

                            <b-col md="3" sm="12">
                                <b-form-group label-for="convenio-padrao-cnab">
                                    Padrão CNAB
                                    <b-form-select id="convenio-padrao-cnab" v-model="convenio.padrao_cnab" :options="[{value: '240', text: 'CNAB240'},{value: '400', text: 'CNAB400'}]" />
                                </b-form-group>
                                <b-form-group label-for="convenio-carteira-cod" v-if="convenio.padrao_cnab === '240'">
                                    Código da Carteira
                                    <b-form-select id="convenio-carteira-cod" v-model="convenio.carteira_codigo" :options="optionsCarteira" />
                                </b-form-group>  

                                <b-form-group label-for="convenio-var-cart">
                                    Variação Carteira
                                    <b-form-input id="convenio-var-cart" type="text"
                                        v-model="convenio.variacao_carteira" 
                                    />
                                </b-form-group>
                            </b-col>

                            <b-col md="3" sm="12">
                                <b-form-group label-for="convenio-numero-nosso">
                                    Nosso Número
                                    <b-form-input id="convenio-numero-nosso" type="text"
                                        v-model="convenio.nosso_numero" :disabled="convenio.e_nosso_numero_banco === 'S'"/>
                                </b-form-group>
                                <b-form-group label-for="convenio-numero-remessa">
                                    Nº Remessa
                                    <b-form-input id="convenio-numero-remessa" type="text"
                                        v-model="convenio.numero_remessa" :disabled="convenio.e_reiniciar_diariamente === 'S'"/>
                                </b-form-group>
                                <b-form-group abel-for="convenio-densida-remessa">
                                    Densidade Remessa
                                    <b-form-select id="convenio-densida-remessa" v-model="convenio.densida_remessa" :options="['1600','6250']" />                                    
                                </b-form-group> 

                                <b-form-group label-for="convenio-impress" v-if="contaBancaria.id_banco === 748">
                                    Impressão
                                    <b-form-select id="convenio-impress" 
                                    v-model="convenio.emissao_boleto" :options="[{value: 'A', text: 'Impressão será realizado pelo banco'},{value: 'B', text: 'Impressão será realizado pelo beneficiário'}]" />
                                </b-form-group>                               
                            </b-col>
                        </b-row>
                        <hr style="margin-top: -10px">

                        <b-row>
                            <b-col md="3" sm="12">
                                <b-form-group label-for="convenio-tipo-juros">
                                    Tipo de Juros
                                    <b-form-select id="convenio-tipo-juros" 
                                    v-model="convenio.codigo_juros" :options="[{value: '1', text: 'Valor por dia'},{value: '2', text: 'Taxa mensal'},{value: '3', text: 'Isento'}]" />
                                </b-form-group>

                                <b-form-group label-for="con-taxa-juros">
                                    Taxa Juros
                                    <money id="con-taxa-juros" v-model="convenio.valor_taxa_juros" v-bind="money" required class="form-control"/>                                        
                                </b-form-group>

                                <b-form-group label-for="convenio-dias-carencia-juros"> 
                                    Dias de Carência Juros
                                    <b-form-input id="convenio-dias-carencia-juros" type="number"
                                        v-model="convenio.dias_carencia_juros"
                                    />
                                </b-form-group>                                
                            </b-col>

                            <b-col md="3" sm="12">
                                <b-form-group label-for="convenio-tipo-multa">
                                    Tipo de Multa
                                    <b-form-select id="convenio-tipo-multa" 
                                    v-model="convenio.codigo_multa" :options="[{value: '0', text: 'Não registra a multa'},{value: '1', text: 'Valor em Reais (Fixo)'},{value: '2', text: 'Valor em Percentual'}]" />
                                </b-form-group>

                                <b-form-group label-for="con-taxa-multa" v-if="convenio.codigo_multa === '2'">
                                    Taxa Multa
                                    <money id="con-taxa-multa" v-model="convenio.valor_taxa_multa" v-bind="money" required class="form-control"/>                                        
                                </b-form-group>

                                <b-form-group label-for="con-val-multa" v-else>
                                    Valor Multa
                                    <money id="con-val-multa" v-model="convenio.valor_multa" v-bind="money" required class="form-control"/>                                        
                                </b-form-group>

                                <b-form-group label-for="convenio-dias-carencia-multa"> 
                                    Dias de Carência Multa
                                    <b-form-input id="convenio-dias-carencia-multa" type="number"
                                        v-model="convenio.dias_carencia_multa"
                                    />
                                </b-form-group>
                            </b-col>

                            <b-col md="3" sm="12">
                                <b-form-group label-for="convenio-tipo-prot">
                                    Tipo Protesto
                                    <b-form-select id="convenio-tipo-prot" 
                                    v-model="convenio.cod_protesto" 
                                    :options="optionsProtesto" />
                                </b-form-group>

                                <b-form-group label-for="convenio-dias-carencia-prot"> 
                                    Dias de Carência Protesto
                                    <b-form-input id="convenio-dias-carencia-prot" type="number"
                                        v-model="convenio.dias_prazo_protesto"
                                    />
                                </b-form-group>
                            </b-col>

                            <b-col md="3" sm="12">
                                <b-form-group label-for="con-acr">
                                    Outros Acréscimos
                                    <money id="con-acr" v-model="convenio.outros_acrescimos" v-bind="money" required class="form-control"/>                                        
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <hr style="margin-top: -10px">

                        <b-row>
                            <b-col md="4" sm="12">
                                <b-form-group label-for="convenio-inst">
                                    Instruções
                                    <b-form-input id="convenio-inst" type="text"
                                        v-model="convenio.instrucoes" 
                                        placeholder="Ex.: Não receber após 30 dias do vencimento."
                                    />
                                </b-form-group>
                            </b-col>

                            <b-col md="4" sm="12">
                                <b-form-group label-for="convenio-msg1">
                                    Mensagem 1
                                    <b-form-input id="convenio-msg1" type="text"
                                        v-model="convenio.mensagem_01"
                                        placeholder="Ex.: Conceder desconto de R$2,00 até 27/12/2018."
                                    />
                                </b-form-group>                                
                            </b-col>                           

                            <b-col md="4" sm="12">
                                <b-form-group label-for="convenio-msg1">
                                    Mensagem 2
                                    <b-form-input id="convenio-msg1" type="text"
                                        v-model="convenio.mensagem_02" 
                                        placeholder="Ex.: Cobrar R$2,00 ao dia após o vencimento."
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <hr style="margin-top: -10px" v-if="convenio.e_registro_instantaneo === 'S'">
                        <br v-if="convenio.e_registro_instantaneo === 'N'">

                        <b-row v-if="convenio.e_registro_instantaneo === 'S'">
                            <b-col md="4" sm="12">
                                <b-form-group label-for="convenio-tipo-webservice">
                                    Tipo Webservice
                                    <b-form-select id="convenio-tipo-webservice" v-model="convenio.tipo_webservice" :options="optionsTipoWS" />
                                </b-form-group> 
                                <b-form-group label-for="convenio-versao-layout-arquivo">
                                    Versâo layout Arquivo
                                    <b-form-input id="convenio-versao-layout-arquivo" type="text"
                                        v-model="convenio.versao_layout_arquivo" />
                                </b-form-group>
                            </b-col>

                            <b-col md="4" sm="12">
                                <b-form-group label-for="convenio-estacao">
                                    Código da Estação
                                    <b-form-input id="convenio-estacao" type="text"
                                        v-model="convenio.estacao" />
                                </b-form-group>
                                <b-form-group label-for="convenio-api-id">
                                    Api ID
                                    <b-form-input id="convenio-api-id" type="text"
                                        v-model="convenio.api_id"/>
                                </b-form-group>
                            </b-col>

                            <b-col md="4" sm="12">
                                <b-form-group label-for="convenio-api-key">
                                    Api key
                                    <b-form-input id="convenio-api-key" type="text"
                                        v-model="convenio.api_key"/>
                                </b-form-group>
                                <b-form-group label-for="convenio-api-secret">
                                    Api Secret
                                    <b-form-input id="convenio-api-secret" type="text"
                                        v-model="convenio.api_secret"/>
                                </b-form-group> 
                            </b-col>                           
                        </b-row>                        

                        <b-card class="cadastros-opcoes-row-card">
                            <b-row class="cadastros-opcoes-row" align-h="around">                        
                                <b-col md="2" sm="12">
                                    <b-form-checkbox value="S" unchecked-value="N" id="convenio-ativo" v-show="mode === 'save'" v-model="convenio.ativo" class="mt-3 mb-3" switch>Ativo</b-form-checkbox>
                                </b-col>  
                                <b-col md="3" sm="12">
                                    <b-form-checkbox value="S" unchecked-value="N" id="convenio-reg-inst" v-show="mode === 'save'" v-model="convenio.e_registro_instantaneo" class="mt-3 mb-3" switch>Registro Instantâneo</b-form-checkbox>
                                </b-col>                                            
                                <b-col md="3" sm="12">
                                    <b-form-checkbox value="S" unchecked-value="N" id="convenio-reinic-diar" v-show="mode === 'save'" v-model="convenio.e_reiniciar_diariamente" class="mt-3 mb-3" switch>Reiniciar Nº Remessa Diariamente</b-form-checkbox>
                                </b-col> 
                                <b-col md="2" sm="12">
                                    <b-form-checkbox value="S" unchecked-value="N" id="convenio-noss-num" v-show="mode === 'save'" v-model="convenio.e_nosso_numero_banco" class="mt-3 mb-3" switch>Banco gera Nosso Número</b-form-checkbox>
                                </b-col>
                                <b-col md="2" sm="12">
                                    <b-form-checkbox value="S" unchecked-value="N" id="convenio-num-rem-banco" v-show="mode === 'save'" v-model="convenio.e_num_remessa_banco" class="mt-3 mb-3" switch>Banco gera Nº Remessa</b-form-checkbox>
                                </b-col>
                            
                                <b-col md="4" sm="12">
                                    <b-form-checkbox value="S" unchecked-value="N" id="convenio-nume-conc-banco" v-show="mode === 'save'" v-model="convenio.e_nosso_numero_conciliar_banco" class="mt-3 mb-3" switch>Conciliar Nosso Número no Banco</b-form-checkbox>
                                </b-col> 
                                <b-col md="2" sm="12">
                                    <b-form-checkbox value="S" unchecked-value="N" id="convenio-conv-padrao" v-show="mode === 'save'" v-model="convenio.e_convenio_padrao" class="mt-3 mb-3" switch>Convênio Padrão</b-form-checkbox>
                                </b-col> 
                                <b-col md="2" sm="12">
                                    <b-form-checkbox value="S" unchecked-value="N" id="convenio-cons-ws" v-show="mode === 'save'" v-model="convenio.consulta_ws" class="mt-3 mb-3" switch>Consulta WS</b-form-checkbox>
                                </b-col> 
                                <b-col md="4" sm="12">
                                    <b-form-checkbox value="S" unchecked-value="N" id="convenio-alt-ws" v-show="mode === 'save'" v-model="convenio.alteracao_webservice" class="mt-3 mb-3" switch>Alteração WS (Apenas Itaú v2)</b-form-checkbox>
                                </b-col> 
                            </b-row>
                        </b-card>
                        
                        <template #modal-footer>
                            <div class="w-100">                               

                                <b-col md="10" sm="10">
                                    <b-button variant="primary"
                                        @click="saveConvenio()">Salvar</b-button>
                                    <b-button class="ml-2" @click="resetConvenio()">Cancelar</b-button>
                                </b-col>
                            </div>
                        </template>                    
                    </b-modal>
                    

                    <b-row  v-if="contaBancaria.convenios.length > 0">
                        <b-table class="mt-2" hover striped :items="contaBancaria.convenios" :fields="fieldsConvenios" small>  
                            <template v-slot:cell(actions)="data">
                                <b-button variant="warning" @click="loadConvenio(data.item)" class="mr-2">
                                    <i class="fa fa-pencil"></i>
                                </b-button>
                                <b-button variant="danger" @click="removeConvenio(data.item)">
                                    <i class="fa fa-trash"></i>
                                </b-button>
                            </template>
                        </b-table>
                    </b-row>  

                    <b-modal id="modal-remove-convenio" title="Remover convênio" v-model="modalRemConvenio"   >
                        <p class="my-4">Deseja realmente remover este convênio?</p>
                        <template #modal-footer>
                            <div class="w-100">                    
                                <b-button
                                    variant="primary"
                                    size="md"
                                    class="float-right ml-3"
                                    @click="removeServico()"                            
                                >
                                    Sim
                                </b-button>
                                <b-button
                                    variant="danger"
                                    size="md"
                                    class="float-right"
                                    @click="$bvModal.hide('modal-remove-convenio')"
                                >
                                    Não
                                </b-button>
                            </div>
                        </template>

                    </b-modal>                
                </b-card>
                <br>
                <b-row>                  
                    <b-col md="12" sm="12" class="d-flex justify-content-end">                        

                        <i id="popover-target-conta-bancaria"  class="fa fa-question-circle info-alteracoes"></i>

                        <b-popover target="popover-target-conta-bancaria" triggers="hover" placement="top">
                            <template #title>Alterações</template>
                            <b>Usuário Cadastro:</b> {{contaBancaria.usu_cad}} <br>
                            <b>Data de Cadastro:</b> {{contaBancaria.data_cad}} <br>
                            <b>Usuário Alteração:</b> {{contaBancaria.usu_alt}} <br>
                            <b>Data de Alteração:</b> {{contaBancaria.data_alt}}
                        </b-popover>                        
                    </b-col>

                </b-row>

            </b-form>
        </b-card> 

        <b-modal id="modal-remove-conta" title="Deletar Conta Bancária" v-model="modalRemConta"   >
            <p class="my-4">Deseja realmente excluir esta conta bancária?</p>
            <template #modal-footer>
                <div class="w-100">                    
                    <b-button
                        variant="primary"
                        size="md"
                        class="float-right ml-3"
                        @click="remove()"                            
                    >
                        Sim
                    </b-button>
                    <b-button
                        variant="danger"
                        size="md"
                        class="float-right"
                        @click="$bvModal.hide('modal-remove-conta')"
                    >
                        Não
                    </b-button>
                </div>
            </template>

        </b-modal>

        <b-card v-show="!show" style="margin-top: 10px">
                <div class="acoesRodape">                 
                <b-button variant="secondary " class="botoesAcaoRodape" v-if="(mode === 'save') || (mode === 'remove')"
                    @click="reset">
                        <i class="fa fa-times button-icons"></i>
                        Cancelar
                </b-button>
                <b-button variant="danger" class="botoesAcaoRodape" v-if="mode === 'remove'"
                    @click="remove">
                        <i class="fa fa-trash button-icons"></i>
                        Excluir
                </b-button>
                <b-button variant="success" class="botoesAcaoRodape" v-if="mode === 'save'"
                    @click="save">
                        <i class="fa fa-check button-icons"></i>
                        Salvar
                </b-button>   
            </div>
        </b-card>       

        <b-card class="cadastros-card-corpo" v-show="show">
            <b-table hover striped :items="contasBancarias" :fields="fields">  
                <template v-slot:cell(actions)="data">
                    <b-button variant="warning" @click="loadContaBancaria(data.item)" class="mr-2">
                        <i class="fa fa-pencil"></i>
                    </b-button>
                    <b-button variant="danger" @click="desejaRemover(data.item)">
                        <i class="fa fa-trash"></i>
                    </b-button>                    
                </template>
            </b-table>
            <hr>

            <b-pagination 
                align="center" 
                size="md" 
                v-model="page"  
                :total-rows="count" 
                :per-page="limit" 
                first-number 
                last-number>
            </b-pagination>
        </b-card>  
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import {VMoney} from 'v-money'
import VueSingleSelect from "vue-single-select";
import axios from 'axios'

export default {
    name: 'CadastroContasBancarias',
    components: { VueSingleSelect },
    data: function() {
        return {
            mode: 'new',
            contaBancaria: {
                ativo: 'S',
                convenios: []
            },
            filiais: [],
            filialSelecionada: '',
            contasBancarias: [],
            idConta: 0,
            convenio: { 
                ativo: 'S',
                e_registro_instantaneo: 'N',
                e_reiniciar_diariamente: 'N',
                e_nosso_numero_banco: 'N',
                e_nosso_numero_conciliar_banco: 'N',
                e_convenio_padrao: 'N',
                consulta_ws: 'N'
            },
            convenios: [],
            modalConvenio: false,
            modalRemConta: false,
            modoConvenio: 'novo',
            modalRemConvenio: false,
            bancos: [],
            page: 1,
            count: 0,
            limit: 0,
            parcial: '',
            ativos: 'S',
            show: true,
            fields: [
                {key: 'id_conta_bancaria', label: 'Código', sortable: true},
                {key: 'agencia', label: 'Agência', sortable: true},
                {key: 'conta', label: 'Conta', sortable: true},
                {key: 'nome_conta', label: 'Nome da Conta', sortable: true},
                {key: 'ativo', label: 'Ativo', sortable: false, formatter: value => value ==='S' ? 'Sim' : 'Não'},
                {key: 'actions', label: 'Ações'}
            ],   
            fieldsConvenios: [
                {key: 'id_conta_bancaria_convenio', label: 'Código', sortable: true},
                {key: 'descricao', label: 'Descrição', sortable: true},
                {key: 'carteira', label: 'Carteira', sortable: true},
                {key: 'padrao_cnab', label: 'Nome da Conta', sortable: true},
                {key: 'ativo', label: 'Ativo', sortable: false, formatter: value => value ==='S' ? 'Sim' : 'Não'},
                {key: 'actions', label: ''}
            ], 
            optionsCarteira: [
                { value: 1, text: '1 | Cobrança Simples' },
                { value: 2, text: '2 | Cobrança Vinculada'},
                { value: 3, text: '3 | Cobrança Caucionada'},
                { value: 4, text: '4 | Cobrança Descontada'},
                { value: 5, text: '5 | Cobrança Vendor'}
            ], 
            optionsProtesto: [
                { value: '1', text: '1 | Protestar Dias Corridos' },
                { value: '2', text: '2 | Protestar Dias Úteis'},
                { value: '3', text: '3 | Não Protestar'},
                { value: '4', text: '4 | Protestar Fim Falimentar - Dias Úteis'},
                { value: '5', text: '5 | Protestar Fim Falimentar - Dias Corridos'},
                { value: '8', text: '8 | Negativação sem Protesto'},
                { value: '9', text: '9 | Cancelamento Protesto Automático (Apenas remessa = 31)'}
            ], 
            optionsTipoWS: [
                { value: null, text: 'Nenhum' },
                { value: 'v1', text: 'v1 (Banco do Brasil)' },
                { value: 'v2', text: 'v2 (Banco do Brasil)'},
                { value: 'NORMAL', text: 'NORMAL (Itaú)'},
                { value: 'SHOP FACIL', text: 'SHOP FACIL (Itaú)'}
            ],
            money: {
                decimal: ',',
                thousands: '.',
                //prefix: 'R$ ',
                //suffix: ' #',
                precision: 2,
                masked: false
            },
            percent: {
                decimal: ',',
                thousands: '.',
                //prefix: 'R$ ',
                suffix: ' %',
                precision: 2,
                masked: false
            }, 
        }
    },
    directives: {
        money: VMoney
    },
    methods: {  
        novaConta() {
            this.mode = 'save'
            this.show = false
            this.loadFiliais()
        },      
        upperCase(value) {
            return value.toUpperCase()
        },
        lowerCase(value) {
            return value.toLowerCase()
        },
        novaConsulta() {
            this.page = 1 
            this.loadContasBancarias()
        },
        loadFiliais() { 
            const url = `${baseApiUrl}/filiais`    

            axios.get(url).then(res => {
                this.filiais = res.data.map(f => {
                    return { ...f, value: f.idfilial, text: ` | ${f.razao_social}` }
                })
            })
            .catch(err => {
                showError(err)
            })           
        },
        loadContasBancarias() { 
            const parc = (this.parcial.trim().length > 0) ? this.parcial : 'xxxxxxxxxx' 
            const url = `${baseApiUrl}/contas_bancarias/consulta/${parc}/ativos/${this.ativos}/?page=${this.page}`    

            axios.get(url).then(res => {
                this.contasBancarias = res.data.data
                this.count = res.data.count 
                this.limit = res.data.limit
            })
            .catch(err => {
                showError(err)
            })            
        },
        loadBancos() {
            const url = `${baseApiUrl}/bancos`
            axios.get(url).then(res => {
                this.bancos = res.data.map(banco => {
                    return { ...banco, value: banco.id_banco, text: `${banco.num_banco} | ${banco.nome_banco}` }
                })
            })
            .catch(err => {
                showError(err)
            })
        },
        reset() {
            this.mode = 'new'


            this.contaBancaria = {
                ativo: 'S',
                convenios: []
            }

            this.filialSelecionada = ''

            this.loadContasBancarias()
            this.show = !this.show
        },
        save() {
            const method = this.contaBancaria.id_conta_bancaria ? 'put' : 'post'
            const idcontabancaria = this.contaBancaria.id_conta_bancaria ? `/${this.contaBancaria.id_conta_bancaria}` : '' 

            axios[method](`${baseApiUrl}/contas_bancarias${idcontabancaria}`, this.contaBancaria)
            .then(() => {
                this.$toasted.global.defaultSuccess()
                this.reset()
            })
            .catch(err => {
                showError(err)
            })
        },
        desejaRemover(c) {
            this.idConta = c.id_conta_bancaria
            this.modalRemConta = true
        },
        remove() {
            axios.delete(`${baseApiUrl}/contas_bancarias/${this.idConta}`)
            .then(() => {
                this.$toasted.global.defaultSuccess()
                this.loadContasBancarias()
                this.modalRemConta = false
            })
            .catch(err => {
                showError(err)
            })
        },
        loadContaBancaria(ct) {
            const url = `${baseApiUrl}/contas_bancarias/${ct.id_conta_bancaria}`   
            axios.get(url).then(res => {
                this.contaBancaria = { ...res.data }
                this.contaBancaria.convenios.forEach((c, i) => {
                    c.indexItem = i
                })

                this.filialSelecionada = {value: this.contaBancaria.id_filial, text: ` | ${this.contaBancaria.nome_filial}`}

                this.loadFiliais()
                
                this.mode = 'save'
                this.show = !this.show
            })
            .catch(err => {
                showError(err)
            })                
        },        

        novoConvenio() {
            this.modalConvenio = true
            this.modoConvenio = 'novo'
        },
        saveConvenio() {
            if (this.modoConvenio === 'novo') {
                this.contaBancaria.convenios.push(this.convenio)
                this.contaBancaria.convenios.forEach((c, i) => {
                    c.indexItem = i
                })
            }
            if (this.modoConvenio === 'editar') {
                this.contaBancaria.convenios.splice(this.convenio.indexItem, 1, {...this.convenio})
            }

            this.resetConvenio()

        },
        loadConvenio(c) {
            this.modalConvenio = true
            this.modoConvenio = 'editar'
            this.convenio = { ... c }
        },
        removeConvenio(item) {
            this.contaBancaria.convenios.splice(item.indexItem, 1)
            this.contaBancaria.convenios.forEach((c, i) => {
                c.indexItem = i
            })
        },
        resetConvenio() {
            this.convenio = { 
                ativo: 'S',
                e_registro_instantaneo: 'N',
                e_reiniciar_diariamente: 'N',
                e_nosso_numero_banco: 'N',
                e_nosso_numero_conciliar_banco: 'N',
                e_convenio_padrao: 'N',
                consulta_ws: 'N'
            }

            this.modalConvenio = false
        }
    },
    watch: {
        page() {
            this.loadContasBancarias()
        },

        filialSelecionada() {
            if (this.filialSelecionada) {
                this.contaBancaria.id_filial = this.filialSelecionada.value
            } else {
                this.contaBancaria.id_filial = ''
            }
        },

        modalConvenio() {
            if(!this.modalConvenio) {

                this.convenio = { 
                    ativo: 'S',
                    e_registro_instantaneo: 'N',
                    e_reiniciar_diariamente: 'N',
                    e_nosso_numero_banco: 'N',
                    e_nosso_numero_conciliar_banco: 'N',
                    e_convenio_padrao: 'N',
                    consulta_ws: 'N'
                }
            }
        }
    },
    mounted() {
        this.loadContasBancarias(),
        this.loadBancos()
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.7rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

    



</style>