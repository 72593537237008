<template>
    <div class="contas-bancarias-pages">
        <PageTitle icon="fa fa-money" main="Contas bancárias"
            sub="Cadastros & Cia" />
        
        <CadastrosContasBancarias />
    </div>
</template>

<script>
import PageTitle from '@/components/template/PageTitle'
import CadastrosContasBancarias from './CadastroContasBancarias'

export default {
    name: 'ContasBancariasPages',
    components: { PageTitle, CadastrosContasBancarias }
}
</script>

<style>

</style>