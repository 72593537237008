<template>
    <div class="bancos-pages">
        <PageTitle icon="fa fa-university" main="Bancos"
            sub="Cadastros & Cia" />
        
        <CadastrosBancos />
    </div>
</template>

<script>
import PageTitle from '@/components/template/PageTitle'
import CadastrosBancos from './CadastroBancos'

export default {
    name: 'BancosPages',
    components: { PageTitle, CadastrosBancos }
}
</script>

<style>

</style>
