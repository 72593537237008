<template>
    <div class="modelos-pages">
        <PageTitle icon="fa fa-file-text-o" main="Modelos de Relatórios"
            sub="Cadastros dos modelos de relatórios, contratos etc." />
        
        <CadastroModelos />   
    </div>
</template>

<script>
import PageTitle from '../template/PageTitle'
import CadastroModelos from './CadastroModelos.vue'

export default {
    name: 'ModelosPages',
    components: { PageTitle, CadastroModelos }
}
</script>

<style>

</style>
