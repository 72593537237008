<template>
    <div class="estados-pages"> 
        <PageTitle icon="fa fa-map-o" main="Estados"
            sub="Cadastros" />       
                         
    </div>
</template>

<script>
import PageTitle from '@/components/template/PageTitle'

export default {
    name: 'EstadosPages',
    components: { PageTitle } 
}
</script>

<style>

</style>
