<template>
    <div class="boletos-pages">
        <PageTitle icon="fa fa-barcode" main="Boletos"
            sub="Consulta e manutenção de boletos" />
        
        <CadastroBoletos />   
    </div>
</template>

<script>
import PageTitle from '@/components/template/PageTitle'
import CadastroBoletos from './CadastroBoletos.vue'

export default {
    name: 'BoletosPages',
    components: { PageTitle, CadastroBoletos }
}
</script>

<style>

</style>
