<template>
    <div class="dre-pages">
        <PageTitle icon="fa fa-file-text-o" main="DRE - Demonstrativos"
            sub="Demonstrativos de resultados" />
        
        <Dre/>   
    </div>
</template>

<script>
import PageTitle from '@/components/template/PageTitle'
import Dre from './Dre.vue'

export default {
    name: 'DrePages',
    components: { PageTitle, Dre }
}
</script>

<style>

</style>
