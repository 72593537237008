<template>
    <div class="contratos-pages">
        <PageTitle icon="fa fa-gavel" main="Contratos"
            sub="Cadastros de contratos de prestação de serviços" />
        
        <CadastroContratos />   
    </div>
</template>

<script>
import PageTitle from '@/components/template/PageTitle'
import CadastroContratos from '../contratos/CadastroContratos.vue'

export default {
    name: 'ContratosPages',
    components: { PageTitle, CadastroContratos }
}
</script>

<style>

</style>
