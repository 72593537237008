<template>
    <div class="gerar-notas-pages">
        <PageTitle icon="fa fa-files-o" main="Gerar notas fiscais de faturas"
            sub="Gera as notas fiscais das faturas criada atraves de contratos" />
        
        <!-- <CadastroContratos />    -->
    </div>
</template>

<script>
import PageTitle from '@/components/template/PageTitle'
// import CadastroContratos from '../contratos/contratos/CadastroContratos.vue'

export default {
    name: 'GerarNotasPages',
    components: { PageTitle, 
    // CadastroContratos 
    }
}
</script>

<style>

</style>
