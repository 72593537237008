<template>
    <div class="cadastro-condicoes">
        
        <b-card class="cadastros-card-pesquisa" v-if="show">
            <b-row align-v="center">
                <b-col md="2" sm="12" class="cadastros-pesquisa-coluna">
                    <b-button class="cadastros-button-novo" variant="primary" 
                        @click="show = !show">
                            <i class="fa fa-plus button-icons"></i>
                            Novo
                    </b-button>
                </b-col>
                <b-col md="5" sm="12" class="cadastros-pesquisa-coluna">                        
                    <b-input-group >
                        <b-form-input id="localizar" type="search"
                            :formatter="upperCase"
                            v-model="parcial"
                            :readonly="mode === 'remove'"
                            placeholder="Informe os dados da pesquisa..." />
                        <b-input-group-append> 
                            <b-button variant="primary" @click="novaConsulta">
                                <i class="fa fa-search button-icons"></i>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>                      
                </b-col> 
                <b-col md="3" sm="12" class="radio-consultac">
                    <b-form-group  v-slot="{ ariaDescribedby }">
                        <b-form-radio-group 
                            id="radio-slots-unidadesc"
                            v-model="ativos"
                            :aria-describedby="ariaDescribedby"
                            name="radio-options-slots-unidadesc"
                        >
                            <b-form-radio value="S">Ativos</b-form-radio>
                            <b-form-radio value="N">Inativos</b-form-radio>
                            <b-form-radio value="T">Todos</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
                <b-col md="2" sm="12" class="d-flex justify-content-end cadastros-pesquisa-coluna">                        
                    <div class="cadastro-total-card"> {{ count }} <br> cadastros </div>                     
                </b-col> 
            </b-row>
        </b-card> 

        <b-card class="cadastros-card-corpo" v-show="!show">
            <b-form>
                <input id="condicoes-id" type="hidden" v-model="condicao.id_condicao_pagar_receber" />

                <b-row>
                    <b-col md="5" sm="12">
                        <b-form-group label="Descrição" label-for="condicao-descricao">
                            <b-form-input id="condicao-descricao" type="text"
                                :formatter="upperCase"
                                v-model="condicao.descricao" required
                                :readonly="mode === 'remove'"
                                placeholder="Informe a descrição da condição..." />
                        </b-form-group>
                    </b-col>   
                    <b-col md="2" sm="12">
                        <b-form-group label="Qtde Parcelas" label-for="condicao-qtde-parcelas">
                            <b-form-input id="condicao-qtde-parcelas" type="text"
                                :formatter="upperCase"
                                v-model="condicao.qtd_parcelas" required
                                :readonly="mode === 'remove'"/>
                        </b-form-group>                        
                    </b-col>  
                    <b-col md="2" sm="12">
                        <b-form-group label="Intervalo" label-for="condicao-intervalo">
                            <b-form-input id="condicao-intervalo" type="text"
                                :formatter="upperCase"
                                v-model="condicao.intervalo" required
                                :readonly="mode === 'remove'"/>
                        </b-form-group>                        
                    </b-col>                                   
                </b-row> 

                <b-form-group label="Possui entrada?" v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                        id="radio-group-condicao"
                        v-model="condicao.possui_entrada"
                        :options="optionsCondicoes"
                        :aria-describedby="ariaDescribedby"
                        name="radio-options"
                        :disabled="mode === 'remove'"
                    ></b-form-radio-group>
                </b-form-group> 
                <br>

                <b-card class="cadastros-opcoes-row-card">
                    <b-row class="cadastros-opcoes-row" align-h="around">                        
                        <b-col md="2" sm="12">
                            <b-form-checkbox id="condicao-ativo" v-show="mode === 'save'" v-model="condicao.ativo" class="mt-3 mb-3" switch>Ativo</b-form-checkbox>
                        </b-col>                      
                    </b-row>
                </b-card>
                <hr>
                <b-row>
                    <b-col md="10" sm="10">
                        <b-button variant="primary" v-if="mode === 'save'"
                            @click="save">Salvar</b-button>
                        <b-button variant="danger" v-if="mode === 'remove'"
                            @click="remove">Excluir</b-button>
                        <b-button class="ml-2" @click="reset">Cancelar</b-button>
                    </b-col>

                    <b-col md="2" sm="2" class="d-flex justify-content-end">                        

                        <i id="popover-target-condicao"  class="fa fa-question-circle info-alteracoes"></i>

                        <b-popover target="popover-target-condicao" triggers="hover" placement="top">
                            <template #title>Alterações</template>
                            <b>Usuário Cadastro:</b> {{condicao.usu_cad}} <br>
                            <b>Data de Cadastro:</b> {{condicao.data_cad}} <br>
                            <b>Usuário Alteração:</b> {{condicao.usu_alt}} <br>
                            <b>Data de Alteração:</b> {{condicao.data_alt}}
                        </b-popover>                        
                    </b-col>

                </b-row>

            </b-form>
        </b-card>      

        <b-card class="cadastros-card-corpo" v-show="show">
            <b-table hover striped :items="condicoes" :fields="fields">  
                <template v-slot:cell(actions)="data">
                    <b-button variant="warning" @click="loadCondicao(data.item)" class="mr-2">
                        <i class="fa fa-pencil"></i>
                    </b-button>
                    <b-button variant="danger" @click="loadCondicao(data.item, 'remove')">
                        <i class="fa fa-trash"></i>
                    </b-button>
                </template>
            </b-table>
            <hr>

            <b-pagination 
                align="center" 
                size="md" 
                v-model="page"  
                :total-rows="count" 
                :per-page="limit" 
                first-number 
                last-number>
            </b-pagination>
        </b-card>  
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'

export default {
    name: 'CadastroCondicoes',
    data: function() {
        return {
            mode: 'save',
            condicao: {
                ativo: true,
                descricao: '',
                possui_entrada: 'N'
            },
            condicoes: [],
            page: 1,
            count: 0,
            limit: 0,
            parcial: '',
            ativos: 'S',
            show: true,
            fields: [
                {key: 'id_condicao_pagar_receber', label: 'Código', sortable: true},
                {key: 'descricao', label: 'Descrição', sortable: true},
                {key: 'ativo', label: 'Ativo', sortable: false, formatter: value => value ==='S' ? 'Sim' : 'Não'},
                {key: 'actions', label: 'Ações'}
            ],
            optionsCondicoes: [
                { text: 'Sim', value: 'S' },
                { text: 'Não', value: 'N' }
            ]     
        }
    },
    methods: {
        upperCase(value) {
            return value.toUpperCase()
        },
        lowerCase(value) {
            return value.toLowerCase()
        },
        novaConsulta() {
            this.page = 1 
            this.loadCondicoes()
        },
        loadCondicoes() { 
            const parc = (this.parcial.trim().length > 0) ? this.parcial : 'xxxxxxxxxx' 
            const url = `${baseApiUrl}/condicoes_pagar_receber/consulta/${parc}/ativos/${this.ativos}/?page=${this.page}`    

            axios.get(url).then(res => {
                this.condicoes = res.data.data
                this.count = res.data.count 
                this.limit = res.data.limit
            })
            .catch(err => {
                if (err.response.status === 401) {
                    showError('Sua sessão expirou, por favor, realize o login novamente.')
                } else {
                    showError('Nenhuma condição de pagto/recebto encontrada.')
                    this.condicoes = []
                }
            })           
        },
        reset() {
            this.mode = 'save'

            this.condicao = {
                ativo: true,
                descricao: '',
                possui_entrada: 'N'
            }
            this.condicao.ativo = true

            this.loadCondicoes()
            this.show = !this.show
        },
        save() {
            const method = this.condicao.id_condicao_pagar_receber ? 'put' : 'post'
            const idcondicaopagarreceber = this.condicao.id_condicao_pagar_receber ? `/${this.condicao.id_condicao_pagar_receber}` : ''
            this.condicao.ativo == true ? this.condicao.ativo='S' : this.condicao.ativo='N'  

            axios[method](`${baseApiUrl}/condicoes_pagar_receber${idcondicaopagarreceber}`, this.condicao)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)

            this.condicao.ativo = true
        },
        remove() {
            const idcondicaopagarreceber = this.condicao.id_condicao_pagar_receber
            axios.delete(`${baseApiUrl}/condicoes_pagar_receber/${idcondicaopagarreceber}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        loadCondicao(condicao, mode = 'save') {
            this.mode = mode
            this.condicao = { ...condicao }
            this.condicao.ativo==='S' ? this.condicao.ativo=true : this.condicao.ativo= false

            this.show = !this.show
        }
    },
    watch: {
        page() {
            this.loadCondicoes()
        }
    },
    mounted() {
        this.loadCondicoes()
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.7rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }
    
</style>