<template>
    <div class="cadastro-produtos">

        <b-card class="cadastros-card-pesquisa" v-if="show">
            <b-row align-v="center">
                <b-col md="2" sm="12" class="cadastros-pesquisa-coluna">
                    <b-button class="cadastros-button-novo" variant="primary" 
                        @click="show = !show">
                            <i class="fa fa-plus button-icons"></i>
                            Novo
                    </b-button>
                </b-col>
                <b-col md="5" sm="12" class="cadastros-pesquisa-coluna">                        
                    <b-input-group >
                        <b-form-input id="localizar-produto" type="search"
                            :formatter="upperCase"
                            v-model="parcial"
                            :readonly="mode === 'remove'"
                            placeholder="Informe os dados da pesquisa..." />
                        <b-input-group-append> 
                            <b-button variant="primary" @click="novaConsulta">
                                <i class="fa fa-search button-icons"></i>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>                      
                </b-col> 
                <b-col md="3" sm="12" class="radio-consulta-produtos">
                    <b-form-group  v-slot="{ ariaDescribedby }">
                        <b-form-radio-group 
                            id="radio-slots-produtos"
                            v-model="ativos"
                            :aria-describedby="ariaDescribedby"
                            name="radio-options-slots-produtos"
                        >
                            <b-form-radio  value="S">Ativos</b-form-radio>
                            <b-form-radio value="N">Inativos</b-form-radio>
                            <b-form-radio value="T">Todos</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
                <b-col md="2" sm="12" class="d-flex justify-content-end cadastros-pesquisa-coluna">                        
                    <div class="cadastro-total-card"> {{ count }} <br> cadastros </div>                     
                </b-col> 
            </b-row>
        </b-card>

        <b-card class="cadastros-card-corpo" v-show="!show">
            <b-form>
                <input id="produto-id" type="hidden" v-model="produto.id_produto" />

                <b-row>                       
                    <b-col md="6" sm="12">
                        <b-form-group label="Descrição" label-for="produto-descricao"> 
                            <b-form-input id="produto-descricao" type="text"
                                :formatter="upperCase"
                                v-model="produto.descricao" required
                                :readonly="mode === 'remove'"
                                placeholder="Informe a descrição do produto..." />
                        </b-form-group>
                    </b-col>
                    <b-col md="2" sm="12">
                        <b-form-group label="Custo" label-for="produto-custo">
                            <money id="produto-custo" v-model="produto.custo" v-bind="money" :readonly="mode === 'remove'" class="form-control"/>
                        </b-form-group>
                    </b-col> 
                    <b-col md="2" sm="12">
                        <b-form-group label="Preço" label-for="produto-preco">
                            <money id="produto-preco" v-model="produto.preco" v-bind="money" :readonly="mode === 'remove'" class="form-control"/>
                        </b-form-group>
                    </b-col>
                    <b-col md="2" sm="12">
                        <b-form-group label="NCM" label-for="produto-ncm">
                            <b-form-input id="produto-ncm" type="text"
                                :formatter="upperCase"
                                v-model="produto.ncm" required
                                :readonly="mode === 'remove'" />
                        </b-form-group>
                    </b-col>   
                </b-row>

                <b-row>                 
                    <b-col md="3" sm="12">
                        <b-form-group label="Und Medida" label-for="produto-und-medida">
                            <b-form-select id="produto-und-medida" v-model="produto.id_unidade_medida" :options="unidades" />
                        </b-form-group>
                    </b-col> 
                    <b-col md="3" sm="12">
                        <b-form-group label="Grupo" label-for="produto-grupos">
                            <b-form-select id="produto-grupos" v-model="produto.id_produto_grupo" :options="grupos" />
                        </b-form-group>
                    </b-col>  
                    <b-col md="3" sm="12" v-if="false">
                        <b-form-group label="ICMS/IPI" label-for="produto-grupo-icms-ipi">
                            <b-form-select id="produto-grupo-icms-ipi" v-model="produto.id_grupo_icms_ipi" :options="gruposIcmsIpi" />
                        </b-form-group>
                    </b-col>   
                    <b-col md="3" sm="12" v-if="false">
                        <b-form-group label="PIS/COFINS" label-for="produto-grupo-pis-cofins">
                            <b-form-select id="produto-grupo-pis-cofins" v-model="produto.id_grupo_pis_cofins" :options="gruposPisCofins" />
                        </b-form-group>
                    </b-col>                
                </b-row>

                <b-card sub-title="Integração" v-if="user.plano ==='plus'">
                    <b-row>
                        <b-col md="4" sm="12">
                            <b-form-group label="ID Hotmart" label-for="produto-id-hotmart"> 
                                <b-form-input id="produto-id-hotmart" type="text"
                                    v-model="produto.id_hotmart" 
                                    :readonly="mode === 'remove'"
                                    placeholder="Informe aqui o ID do produto na Hotmart" />
                            </b-form-group>
                        </b-col>
                        <b-col md="4" sm="12">
                            <b-form-group label="ID Eduzz" label-for="produto-id-eduzz"> 
                                <b-form-input id="produto-id-eduzz" type="text"
                                    v-model="produto.id_eduzz" 
                                    :readonly="mode === 'remove'"
                                    placeholder="Informe aqui o ID do produto na Eduzz" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card>

                <b-row>                 
                    <b-col md="12" sm="12">
                        <b-form-group label="Detalhes" label-for="produto-detalhes">
                            <VueEditor v-model="produto.detalhes" placeholder="Informe os detalhes do produto..." />
                        </b-form-group>
                    </b-col>               
                </b-row>

                <b-card class="cadastros-opcoes-row-card">
                    <b-row class="cadastros-opcoes-row" align-h="around">
                        
                        <b-col md="2" sm="12">
                            <b-form-checkbox id="produto-ativo" checked v-show="mode === 'save'" v-model="produto.ativo" class="mt-3 mb-3" switch>Ativo</b-form-checkbox>
                        </b-col>
                        
                    </b-row>
                </b-card>
                
                <br>
                <hr>
                <b-row>
                    <b-col md="10" sm="10">
                        <b-button variant="primary" v-if="mode === 'save'"
                            @click="save">Salvar</b-button>
                        <b-button variant="danger" v-if="mode === 'remove'"
                            @click="remove">Excluir</b-button>
                        <b-button class="ml-2" @click="reset">Cancelar</b-button>
                    </b-col>

                    <b-col md="2" sm="2" class="d-flex justify-content-end">                        

                        <i id="popover-target-1-produtos"  class="fa fa-question-circle info-alteracoes"></i>

                        <b-popover target="popover-target-1-produtos" triggers="hover" placement="top">
                            <template #title>Alterações</template>
                            <b>Usuário Cadastro:</b> {{produto.usu_cad}} <br>
                            <b>Data de Cadastro:</b> {{produto.data_cad}} <br>
                            <b>Usuário Alteração:</b> {{produto.usu_alt}} <br>
                            <b>Data de Alteração:</b> {{produto.data_alt}}
                        </b-popover>                        
                    </b-col>

                </b-row>

            </b-form>
        </b-card>      

        <b-card class="cadastros-card-corpo" v-show="show">
            <b-table hover striped :items="produtos" :fields="fields">  
                <template v-slot:cell(actions)="data">
                    <b-button variant="warning" @click="loadProduto(data.item)" class="mr-2">
                        <i class="fa fa-pencil"></i>
                    </b-button>
                    <b-button variant="danger" @click="loadProduto(data.item, 'remove')">
                        <i class="fa fa-trash"></i>
                    </b-button>
                </template>
            </b-table>
            <hr>
            <b-pagination align="center" size="md" v-model="page"  :total-rows="count" :per-page="limit" first-number last-number></b-pagination>
        </b-card>  
    </div>
</template>

<script>
import { VueEditor } from "vue2-editor"
import { baseApiUrl, showError } from '@/global'
import { mapState } from 'vuex'
import {VMoney} from 'v-money'
import axios from 'axios'

export default {
    name: 'CadastroProdutos',
    computed: mapState(['user']),
    components: { VueEditor },
    data: function() {
        return {
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                //suffix: ' #',
                precision: 2,
                masked: false
            },
            mode: 'save',
            produto: {
                ativo: true
            },
            produtos: [],
            page: 1,
            count: 0,
            limit: 0,
            unidades: [],
            grupos: [],
            gruposIcmsIpi: [],
            gruposPisCofins: [],
            parcial: '',
            ativos: 'S',
            show: true,
            fields: [
                {key: 'id_produto', label: 'Código', sortable: true},
                {key: 'descricao', label: 'Descrição', sortable: true},
                {key: 'ncm', label: 'NCM', sortable: true},
                {key: 'custo', label: 'Custo', sortable: true},
                {key: 'preco', label: 'Preço', sortable: true},
                {key: 'ativo', label: 'Ativo', sortable: false, formatter: value => value ==='S' ? 'Sim' : 'Não'},
                {key: 'actions', label: 'Ações'}
            ]        
        }
    },     
    directives: {
        money: VMoney
    },
    methods: {
        upperCase(value) {
            return value.toUpperCase()
        },
        lowerCase(value) {
            return value.toLowerCase()
        },
        novaConsulta() {
            this.page = 1 
            this.loadProdutos()
        },
        loadProdutos() {  
            const parc = (this.parcial.trim().length > 0) ? this.parcial : 'xxxxxxxxxx' 
            const url = `${baseApiUrl}/produtos/consulta/${parc}/ativos/${this.ativos}/?page=${this.page}`           
            

            axios.get(url).then(res => {
                this.produtos = res.data.data
                this.count = res.data.count 
                this.limit = res.data.limit
            })           
        },
        loadUnidades() {
            const url = `${baseApiUrl}/unidadesmedida`
            axios.get(url).then(res => {
                this.unidades = res.data.map(unidade => {
                    return { ...unidade, value: unidade.id_unidade_medida, text: `${unidade.und} | ${unidade.descricao}` }
                })
            })
        },
        loadGrupos() { 
            const url = `${baseApiUrl}/produtosgrupos`
            axios.get(url).then(res => {
                this.grupos = res.data.map(grupo => {
                    return { ...grupo, value: grupo.id_produto_grupo, text: grupo.path }
                })
            })
        },
        loadGruposIcmsIpi() {
            const url = `${baseApiUrl}/gruposicmsipi`
            axios.get(url).then(res => {
                this.gruposIcmsIpi = res.data.map(grupoIcmsIpi => {
                    return { ...grupoIcmsIpi, value: grupoIcmsIpi.id_grupo_icms_ipi, text: grupoIcmsIpi.descricao }
                })
            })
        },
        loadGruposPisCofins() {
            const url = `${baseApiUrl}/grupospiscofins`
            axios.get(url).then(res => {
                this.gruposPisCofins = res.data.map(grupoPisCofins => {
                    return { ...grupoPisCofins, value: grupoPisCofins.id_grupo_pis_cofins, text: grupoPisCofins.descricao }
                })
            })
        },
        reset() {
            this.mode = 'save'

            this.produto = {}
            this.produto.ativo = true

            this.loadProdutos()
            this.show = !this.show
        },
        save() {
            const method = this.produto.id_produto ? 'put' : 'post'
            const idproduto = this.produto.id_produto ? `/${this.produto.id_produto}` : ''
            this.produto.ativo == true ? this.produto.ativo='S' : this.produto.ativo='N'        
            
            axios[method](`${baseApiUrl}/produtos${idproduto}`, this.produto)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)

            this.produto.ativo == 'S' ? this.produto.ativo = true : this.produto.ativo = false
        },
        remove() {
            const idproduto = this.produto.id_produto
            axios.delete(`${baseApiUrl}/produtos/${idproduto}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        loadProduto(produto, mode = 'save') {
            this.mode = mode
            this.produto = { ...produto }
            this.produto.ativo==='S' ? this.produto.ativo=true : this.produto.ativo= false

            this.show = !this.show
        }
    },
    watch: {
        page() {
            this.loadProdutos()
        }
    },
    mounted() {
        this.loadProdutos(),
        this.loadUnidades(),
        this.loadGrupos(),
        this.loadGruposIcmsIpi(),
        this.loadGruposPisCofins()
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.7rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

    



</style>