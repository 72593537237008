<template>
    <div class="caixa-pages"> 
        <PageTitle icon="fa fa-usd" main="Caixa"
            sub="Fluxo de caixa, liquidações e relatórios" /> 
        
        <NovoCaixa />                 
    </div>
</template>

<script>
import PageTitle from '@/components/template/PageTitle'
import NovoCaixa from './NovoCaixa'

export default {
    name: 'CaixaPages',
    components: { PageTitle, NovoCaixa }
}
</script>

<style>

</style>