<template>
    <div class="grupos-fiscais-pages"> 
        <PageTitle icon="fa fa-globe" main="Grupos fiscais"
            sub="Cadastros" /> 

        <div class="fauras-receber-pages-tabs">
            <b-card no-body>
                <b-tabs card>
                    <b-tab title="IMCS & IPI">
                        <GruposIcmsIpi />   
                    </b-tab>
                    <b-tab title="PIS & COFINS">
                        <GruposPisCofins />  
                    </b-tab>
                </b-tabs>
            </b-card>
        </div>        
                         
    </div>
</template>

<script>
import PageTitle from '@/components/template/PageTitle'
import GruposIcmsIpi from './GruposIcmsIpi.vue'
import GruposPisCofins from './GruposPisCofins.vue'

export default {
    name: 'GruposFiscaisPages',
    components: { PageTitle, GruposIcmsIpi, GruposPisCofins } 
}
</script>

<style>

</style>


