<template>
    <div class="admin-pages">
        <PageTitle icon="fa fa-cogs" main="Administração do Sistema"
            sub="Cadastros & Cia" />
        <div class="admin-pages-tabs">
            <b-card no-body>
                <b-tabs card>
                    <b-tab title="Usuários">
                        <UserAdmin />
                    </b-tab>
                    <b-tab title="Filiais" v-if="user.admin === 'S'">
                        <CadastroFiliais />
                    </b-tab>
                    <b-tab title="Permissões de Usuários" v-if="user.admin === 'S'">
                        <PermissoesUsuarios />
                    </b-tab>
                    <b-tab title="Integrações" v-if="user.plano ==='plus'">
                        <Integracao />
                    </b-tab>
                </b-tabs>
            </b-card>
        </div>        
        
    </div>
</template>

<script>
import PageTitle from '../template/PageTitle'
import Integracao from './Integracao'
import { mapState } from 'vuex'
import UserAdmin from './UserAdmin'
import CadastroFiliais from './CadastroFiliais.vue'
import PermissoesUsuarios from './PermissoesUsuarios.vue'

export default {
    name: 'AdminPages',
    computed: mapState(['user']),
    components: { PageTitle, UserAdmin, CadastroFiliais, Integracao, PermissoesUsuarios },
}
</script>

<style>

</style>
