<template>
    <div class="grupos-pis-cofins-produtos">
        
        <b-card class="cadastros-card-pesquisa" v-if="show">
            <b-row align-v="center">                
                <b-col md="6" sm="12" class="cadastros-pesquisa-coluna">                        
                    <b-input-group >
                        <b-form-input id="localizar" type="search"
                            :formatter="upperCase"
                            v-model="parcial"
                            :readonly="mode === 'remove'"
                            placeholder="Informe o nome da filial..." />
                        <b-input-group-append> 
                            <b-button variant="primary" @click="novaConsulta">
                                <i class="fa fa-search button-icons"></i>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>                      
                </b-col> 

                <b-col md="4" sm="12" class="cadastros-pesquisa-coluna">
                </b-col>
                <b-col md="2" sm="12" class="cadastros-pesquisa-coluna">
                    <b-button class="cadastros-button-novo" variant="primary" 
                        @click="novaConfgNFSe">
                            <i class="fa fa-plus button-icons"></i>
                            Novo
                    </b-button>
                </b-col>
            </b-row>
        </b-card>

        <b-card class="cadastros-card-corpo" v-if="!show">
            <b-form>
                <input id="nfse-id" type="hidden" v-model="nfse.id_nfse_configuracao" />

                <b-row>
                    <b-col md="5" sm="12">
                        <b-form-group label="Filial" label-for="nfse-filial">
                            <b-form-select id="nfse-filial" v-model="nfse.id_filial" :options="filiais" />
                        </b-form-group>
                    </b-col>                     
                    <b-col md="5" sm="12">
                        <b-form-group label="Padrão do Layout" label-for="nfse-padrao-layout">
                            <b-form-select id="nfse-padrao-layout" v-model="nfse.id_nfse_padrao" :options="padroes" @change="infDadosObrigatorios(nfse.id_nfse_padrao)" />
                        </b-form-group>
                        <b-form-text style="margin: -10px 5px 0 5px">{{ dadosObrigatorios[0] }}</b-form-text>
                    </b-col> 
                    <b-col md="2" sm="12" class="radio-consulta">
                        <b-form-group label="Ambiente"  v-slot="{ ariaDescribedby }">
                            <b-form-radio-group                                 
                                id="radio-slots"
                                v-model="nfse.ambiente"
                                :aria-describedby="ariaDescribedby"
                                name="radio-options-ambiente"
                            >
                                <b-form-radio value="P">Produção</b-form-radio>
                                <b-form-radio value="H">Homologação</b-form-radio>
                                <b-form-radio value="S">SandBox</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </b-col>
                </b-row> 

                <b-row>                    
                    <b-col md="3" sm="12">
                        <b-card sub-title="Dados de Login">
                            <hr style="margin-top: 0">

                            <b-form-group label="Usuário" label-for="nfse-usuario">
                                <b-form-input id="nfse-usuario" type="text"
                                    v-model="nfse.login" />
                            </b-form-group>
                            <b-form-group label="Senha" label-for="nfse-senha">
                                <b-form-input id="nfse-senha" type="password"
                                    v-model="nfse.senha" />                                    
                            </b-form-group> 

                        </b-card>
                    </b-col> 

                    <b-col md="5" sm="12">
                        <b-card sub-title="Regime da Empresa">
                            <hr style="margin-top: 0">
                            <b-form-group label="Tributar serviço para:" label-for="nfse-trib-para">
                                <b-form-select id="nfse-trib-para" v-model="nfse.tributar_servico_para" :options="tributarPara" />
                            </b-form-group>
                            <b-form-group label="Regime Tributário" label-for="nfse-trib-regime">
                                <b-form-select id="nfse-trib-regime" v-model="nfse.regime_tributacao" :options="regimeTrib" />
                            </b-form-group>
                            <b-form-group label="Regime Tributário (Especial)" label-for="nfse-trib-regime">
                                <b-form-select id="nfse-trib-regime" v-model="nfse.regime_tributacao_especial" :options="regimeTribEspecial" />
                            </b-form-group>
                            
                        </b-card>
                    </b-col> 
                    <b-col md="4" sm="12">
                        <b-card sub-title="Tributação">
                            <hr style="margin-top: 0">
                            <b-form-group label="Tributação Padrão" label-for="nfse-tributacao">
                                <b-form-select id="nfse-tributacao" v-model="nfse.id_tributacao" :options="tribIcmsIpiIss" />
                            </b-form-group>
                            <b-form-group label="Cidade padrão pestação de serviço:" label-for="nfse-cidade-prestacao">
                                <b-form-select id="nfse-cidade-prestacao" v-model="nfse.cidade_prestacao_servico" :options="cidadePrestacao" />
                            </b-form-group>
                            <b-form-group label="Série" label-for="nfse-serie">
                                <b-form-input id="nfse-serie" type="text"
                                    v-model="nfse.serie" />
                            </b-form-group>
                            <b-form-group label="Nº Nota" label-for="nfse-num-nota">
                                <b-form-input id="nfse-num-nota" type="number"
                                    v-model="nfse.num_nota" />
                            </b-form-group>
                        </b-card>
                    </b-col> 
                </b-row>
                <br>
                <b-card no-body class="p-3">
                    <b-row>
                        <b-col md="4" sm="12">
                            <b-form-checkbox 
                                switch
                                v-model="nfse.e_enviar_email"
                                value="S"
                                unchecked-value="N"
                                >
                                Enviar e-mail para o Tomador
                            </b-form-checkbox>
                        </b-col> 
                        <b-col md="4" sm="12">
                            <b-form-checkbox 
                                switch
                                v-model="nfse.e_envio_proprio"
                                value="S"
                                unchecked-value="N"
                                disabled
                                >
                                E-mail com envio próprio
                            </b-form-checkbox>
                        </b-col>   
                    </b-row>
                </b-card>
                <br>
                <hr>
                <b-row>
                    <b-col md="10" sm="10">
                        <b-button variant="primary" v-if="mode === 'save'"
                            @click="save">Salvar</b-button>
                        <b-button variant="danger" v-if="mode === 'remove'"
                            @click="remove">Excluir</b-button>
                        <b-button class="ml-2" @click="reset">Cancelar</b-button>
                    </b-col>

                    <b-col md="2" sm="2" class="d-flex justify-content-end">                        

                        <i id="popover-target-nfse"  class="fa fa-question-circle info-alteracoes"></i>

                        <b-popover target="popover-target-nfse" triggers="hover" placement="top">
                            <template #title>Alterações</template>
                            <b>Usuário Cadastro:</b> {{nfse.usu_cad}} <br>
                            <b>Data de Cadastro:</b> {{nfse.data_cad}} <br>
                            <b>Usuário Alteração:</b> {{nfse.usu_alt}} <br>
                            <b>Data de Alteração:</b> {{nfse.data_alt}}
                        </b-popover>                        
                    </b-col>

                </b-row>

            </b-form>
        </b-card> 

        <b-modal id="modal-exclue-nfse-conf" title="Excluir Configuração de NFSe" v-model="modalExcluirNFSeConf"   >
            <p class="my-4">Deseja realmente excluir esta configuração de NFSe?</p>
            <template #modal-footer>
                <div class="w-100">                    
                    <b-button
                        variant="primary"
                        size="md"
                        class="float-right ml-3"
                        @click="remove()"                            
                    >
                        Sim
                    </b-button>
                    <b-button
                        variant="danger"
                        size="md"
                        class="float-right"
                        @click="$bvModal.hide('modal-exclue-nfse-conf')"
                    >
                        Não
                    </b-button>
                </div>
            </template>

        </b-modal>     

        <b-card class="cadastros-card-corpo" v-show="show">
            <b-table hover striped :items="nfses" :fields="fieldsNFSe">  
                <template v-slot:cell(actions)="data">
                    <b-button variant="warning" @click="loadNFSe(data.item)" class="mr-2">
                        <i class="fa fa-pencil"></i>
                    </b-button>
                    <b-button variant="danger" @click="excluirNFSeConf(data.item)">
                        <i class="fa fa-trash"></i>
                    </b-button>
                </template>
            </b-table>
            <hr>

            <b-pagination 
                align="center" 
                size="md" 
                v-model="page"  
                :total-rows="count" 
                :per-page="limit" 
                first-number 
                last-number>
            </b-pagination>
        </b-card>  
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'

export default {
    name: 'GruposPisCofins',
    data: function() {
        return {
            mode: 'save',
            padroes: [],
            nfse: {
                e_enviar_email: 'S',
                e_envio_proprio: 'N',
                ambiente: 'P'
            },
            nfses: [],
            filiais: [],
            tribIcmsIpiIss: [],
            modalExcluirNFSeConf: false,
            dadosObrigatorios: '',
            regimeTrib: [
                { value: '0', text: '0 | Nenhum' },
                { value: '1', text: '1 | Simples Nacional' },
                { value: '2', text: '2 | Simples Nacional - Excesso' },
                { value: '3', text: '3 | Regime Normal - Lucro Presumido' },
                { value: '4', text: '4 | Normal - Lucro Real' }
            ],
            regimeTribEspecial: [
                { value: '0', text: '0 | Sem Regime Tributário Especial' },
                { value: '1', text: '1 | Microempresa municipal' },
                { value: '2', text: '2 | Estimativa' },
                { value: '3', text: '3 | Sociedade de profisionais' },
                { value: '4', text: '4 | Cooperativa' },
                { value: '5', text: '5 | Microempresário Individual (MEI)' },
                { value: '6', text: '6 | Microempresário e Empresa de Pequeno Porte (ME EPP)' },                
                { value: '7', text: '7 | Lucro Real' },
                { value: '8', text: '8 | Lucro Presumido' },
                { value: '9', text: '9 | Tributação Normal' },
                { value: '10', text: '10 | Simples nacional com excesso do sublimite' },
                { value: '11', text: '11 | Empresa de Responsabilidade Limitada' }
            ],
            tributarPara: [
                { value: 'T', text: 'Tomador' },
                { value: 'P', text: 'Prestador' },
            ],
            cidadePrestacao: [
                { value: 'T', text: 'Tomador' },
                { value: 'P', text: 'Prestador' },
            ],            
            page: 1,
            count: 0,
            limit: 0,
            parcial: '',
            show: true,
            fieldsNFSe: [
                {key: 'id_filial', label: 'Código', sortable: true},
                {key: 'razao_social', label: 'Filial', sortable: true},
                {key: 'padrao_nome', label: 'Padrão', sortable: true},
                {key: 'actions', label: 'Ações'}
            ]     
        }
    },
    methods: {
        novaConfgNFSe() {
            this.reset()
            this.loadFiliais()         
            this.loadTribIcmsIpiIss()       
            this.loadPadroesLayout()       
            this.show = false
        },
        loadFiliais() { 
            const url = `${baseApiUrl}/filiais`    

            axios.get(url).then(res => {
                this.filiais = res.data.map(f => {
                    return { ...f, value: f.idfilial, text: `${f.idfilial} | ${f.razao_social}` }
                })
            })
            .catch(err => {
                showError(err)
            })           
        },
        loadTribIcmsIpiIss() { 
            const url = `${baseApiUrl}/tributacoes`    

            axios.get(url).then(res => {
                this.tribIcmsIpiIss = res.data.map(t => {
                    return { ...t, value: t.id_tributacao, text: `${t.id_tributacao} | ${t.descricao}` }
                })
            })
            .catch(err => {
                showError(err)
            })           
        },
        async loadPadroesLayout() { 
            const url = `${baseApiUrl}/nfsepadroes`    

            await axios.get(url).then(res => {                
                this.padroes = res.data.map(p => {
                    return { ...p, value: p.id_nfse_padrao, text: `${p.padrao_nome} | ${p.nome_cidade} - ${p.uf} ` }
                })
            })
            .catch(err => {
                showError(err)
            })   
            
            this.infDadosObrigatorios(this.nfse.id_nfse_padrao) 
        }, 
        infDadosObrigatorios(id) {
            this.dadosObrigatorios = this.padroes.map(function (el) {
                return el.id_nfse_padrao == id ? `Obrigatório: ${el.inf_dados_obrigatorios}` : ''
            })
        },
        upperCase(value) {
            return value.toUpperCase()
        },
        lowerCase(value) {
            return value.toLowerCase()
        },
        novaConsulta() {
            this.page = 1 
            this.loadNFSes()
        },
        loadNFSes() { 
            const url = `${baseApiUrl}/nfseconfigs`    

            axios.get(url).then(res => {
                this.nfses = res.data
            })  
            .catch(err => {
                showError(err)
            })            
        },
        reset() {
            this.mode = 'save'
            this.nfse = {
                e_enviar_email: 'S',
                e_envio_proprio: 'N',
                ambiente: 'P'
            }

            this.dadosObrigatorios = ''

            this.loadNFSes()
            this.show = true
        },
        save() {
            const method = this.nfse.id_nfse_configuracao ? 'put' : 'post'
            const idNFSe = this.nfse.id_nfse_configuracao ? `/${this.nfse.id_nfse_configuracao}` : ''

            axios[method](`${baseApiUrl}/nfseconfigs${idNFSe}`, this.nfse)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        excluirNFSeConf(t) {
            this.nfse.id_nfse_configuracao= t.id_nfse_configuracao
            this.modalExcluirNFSeConf = true
        },
        remove() {
            const idNFSe = this.nfse.id_nfse_configuracao
            axios.delete(`${baseApiUrl}/nfseconfigs/${idNFSe}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                    this.modalExcluirNFSeConf = false
                })
            .catch(err => {
                showError(err)
            })
        },
        loadNFSe(nfse) {  
            const url = `${baseApiUrl}/nfseconfigs/${nfse.id_nfse_configuracao}`    

            axios.get(url).then(res => {
                this.nfse = { ...res.data }  
                this.loadFiliais()         
                this.loadTribIcmsIpiIss()       
                this.loadPadroesLayout() 
                
                this.show = false             
            }) 
            .catch(err => {
                showError(err)
                this.show = true
            })
        },
    },
    watch: {
        page() {
            this.loadNFSes()
        }
    },
    mounted() {
        this.loadNFSes()
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.7rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

</style>