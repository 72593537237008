<template>
    <div class="cadastro-filiais">

        <b-row>
            <b-col class="d-flex justify-content-end">
                <b-button variant="success" class="botoesAcao" :disabled="mode === 'new'" 
                    @click="save">
                        <i class="fa fa-check button-icons"></i>
                        Salvar
                </b-button>
                <b-button variant="danger " class="botoesAcao" :disabled="mode === 'new'"
                    @click="reset">
                        <i class="fa fa-times button-icons"></i>
                        Cancelar
                </b-button>                
            </b-col>
        </b-row>

        <b-row>
            <b-col md="7" sm="12"> 
                <b-card>
                    <div class="funil-etapa-list">
                        <b-list-group style="margin: 5px 0 5px 0">
                            <b-list-group-item 
                                v-for="(p, id) in permissoes" v-bind:key="id"
                                :id="`it-list-${p.id_permissao}`"
                                href="#" 
                                :active="selecionado == p.id_permissao"
                                :disabled="selecionado != p.id_permissao && mode === 'save'"
                                @click="loadUsuariosPermissoes(p.id_permissao)"
                            >
                                <h6>{{p.id_permissao}} - {{p.descricao}}</h6>
                            </b-list-group-item>
                        </b-list-group>
                    </div>
                </b-card> 
            </b-col>

            <b-col md="5" sm="12"> 
                <b-card>
                    <b-table hover small borderless responsive :items="usuariosPermissoes" :fields="fieldsUsuariosPerm">  
                        <template v-slot:cell(usuario)="data">
                            <b-form-checkbox 
                                value="S" 
                                unchecked-value="N" 
                                :id="`contrato-boleto-${data.item.id_usuario}`" 
                                checked 
                                @change="mode = 'save'"
                                v-model="data.item.e_permitido" 
                                class="mr-3" 
                                switch
                            >
                                {{data.item.id_usuario}} | {{data.item.usuario}}
                            </b-form-checkbox>
                        </template>
                    </b-table>
                </b-card> 
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { baseApiUrl, showError, showSuccess } from '@/global'
import axios from 'axios'

export default {
    name: 'PermissoesUsuarios',
    data: function() {
        return {
            selecionado: 0,
            mode: 'new',
            usuariosPermissoes: [],
            permissoes: [],
            fieldsUsuariosPerm: [
                {key: 'usuario', label: 'Nome', sortable: true},
            ]     
        }
    },
    methods: {
       
        loadPermissoes() { 
            axios.get(`${baseApiUrl}/permissoes`).then(res => {
                this.permissoes = res.data
            })
            .catch(err => {
                showError(err)
            })          
        }, 

        loadUsuariosPermissoes(id) { 
            axios.get(`${baseApiUrl}/permissoes/config/${id}`).then(res => {
                this.selecionado = id
                // this.mode = 'save'
                this.usuariosPermissoes = res.data
            })
            .catch(err => {
                showError(err)
            })          
        }, 

        reset() {
            this.mode = 'new'
            this.selecionado = 0
            this.usuariosPermissoes = []
        },

        save() {           
            axios['post'](`${baseApiUrl}/permissoes/config`, this.usuariosPermissoes)
                .then(() => {
                    showSuccess('Permissões salvas com sucesso.')
                    this.reset()
                })
            .catch(err => {
                showError(err)
            })
        }
    },
    mounted() {
        this.loadPermissoes()
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.9rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

    .funil-etapa-list {
        padding-right: 5px;
        height: 55vh;
        overflow-x: hidden;
        overflow-y: scroll;
    }
    .funil-etapa-list::-webkit-scrollbar {      
        width: 3px;
        height: 3px; /* A altura só é vista quando a rolagem é horizontal */
    }
    .funil-etapa-list::-webkit-scrollbar-track {
        background: transparent;
        padding: 2px;
    }
    .funil-etapa-list::-webkit-scrollbar-thumb {
        background-color: #6ca7ff;
    }

    



</style>