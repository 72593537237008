<template>
    <div class="cadastro-integracao-cartao">

        <b-card class="cadastros-card-corpo">
            <b-form>
                <input id="integracao-id" type="hidden" v-model="integracao.id_integracao" />

                <b-row>
                    <b-col sm="12" md="5">
                        <b-form-group label="Tipo de Integração" label-for="tipo-integracao">
                            <b-form-select id="tipo-integracao" v-model="integracao.tipo_integracao" :options="tipoIntegracao" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <hr>

                <div v-if="integracao.tipo_integracao==='HOTMART'">
                    <b-row>  
                        <b-col md="4" sm="12">
                            <b-form-group label="Pessoa" label-for="integracao-pessoa">
                                <b-form-select id="integracao-pessoa" v-model="integracao.id_pessoa" :options="pessoas" />
                            </b-form-group>
                        </b-col>
                        
                        <b-col md="4" sm="12">
                            <b-form-group label="Usuário" label-for="integracao-usuario">
                                <b-form-select id="integracao-usuario" v-model="integracao.hotmart_usuario" :options="usuarios" />
                            </b-form-group>
                        </b-col>
                        
                        <b-col md="4" sm="12">
                            <b-form-group label="Hotmart Token" label-for="integracao-token">
                                <b-form-input id="integracao-token" type="text" v-model="integracao.hotmart_token" required :readonly="mode === 'remove'"/>
                            </b-form-group>
                        </b-col>                                  
                    </b-row> 

                    <b-row>   
                        <b-col md="4" sm="12">
                            <b-form-group label="Plano de conta" label-for="integracao-plano-conta">
                                <b-form-select id="integracao-plano-conta" v-model="integracao.id_plano_conta" :options="planoContas" />
                            </b-form-group>
                        </b-col>

                        <b-col md="4" sm="12">
                            <b-form-group label="Forma" label-for="integracao-forma">
                                <b-form-select id="integracao-forma" v-model="integracao.hotmart_forma" :options="formas" />
                            </b-form-group>
                        </b-col>
                        
                        <b-col md="4" sm="12">
                            <b-form-group label="Condição" label-for="integracao-condicao">
                                <b-form-select id="integracao-condicao" v-model="integracao.hotmart_condicao" :options="condicoes" />
                            </b-form-group>
                        </b-col>                                 
                    </b-row>
                </div>

                <div v-if="integracao.tipo_integracao==='EDUZZ'">
                    <b-row>  
                        <b-col md="4" sm="12">
                            <b-form-group label="Pessoa" label-for="integracao-eduzz-pessoa">
                                <b-form-select id="integracao-eduzz-pessoa" v-model="integracao.id_pessoa" :options="pessoas" />
                            </b-form-group>
                        </b-col>
                        
                        <b-col md="4" sm="12">
                            <b-form-group label="Usuário" label-for="integracao-eduzz-usuario">
                                <b-form-select id="integracao-eduzz-usuario" v-model="integracao.eduzz_usuario" :options="usuarios" />
                            </b-form-group>
                        </b-col>
                        
                        <b-col md="4" sm="12">
                            <b-form-group label="Eduzz Token" label-for="integracao-eduzz-token">
                                <b-form-input id="integracao-eduzz-token" type="text" v-model="integracao.eduzz_token" required :readonly="mode === 'remove'"/>
                            </b-form-group>
                        </b-col>                                  
                    </b-row> 

                    <b-row>   
                        <b-col md="4" sm="12">
                            <b-form-group label="Plano de conta" label-for="integracao-eduzz-plano-conta">
                                <b-form-select id="integracao-eduzz-plano-conta" v-model="integracao.id_plano_conta" :options="planoContas" />
                            </b-form-group>
                        </b-col>

                        <b-col md="4" sm="12">
                            <b-form-group label="Forma" label-for="integracao-forma">
                                <b-form-select id="integracao-forma" v-model="integracao.eduzz_forma" :options="formas" />
                            </b-form-group>
                        </b-col>
                        
                        <b-col md="4" sm="12">
                            <b-form-group label="Condição" label-for="integracao-condicao">
                                <b-form-select id="integracao-condicao" v-model="integracao.eduzz_condicao" :options="condicoes" />
                            </b-form-group>
                        </b-col>                                 
                    </b-row>
                </div>
                                
                <br>
                <hr>
                <b-row>
                    <b-col md="10" sm="10">
                        <b-button variant="primary" v-if="mode === 'save'"
                            @click="save">Salvar</b-button>
                        <b-button variant="danger" v-if="mode === 'remove'"
                            @click="remove">Excluir</b-button>
                        <b-button class="ml-2" @click="reset">Cancelar</b-button>
                    </b-col>

                    <b-col md="2" sm="2" class="d-flex justify-content-end">                        

                        <i id="popover-target-integracao"  class="fa fa-question-circle info-alteracoes"></i>

                        <b-popover target="popover-target-integracao" triggers="hover" placement="top">
                            <template #title>Alterações</template>
                            <b>Usuário Cadastro:</b> {{integracao.usu_cad}} <br>
                            <b>Data de Cadastro:</b> {{integracao.data_cad}} <br>
                            <b>Usuário Alteração:</b> {{integracao.usu_alt}} <br>
                            <b>Data de Alteração:</b> {{integracao.data_alt}}
                        </b-popover>                        
                    </b-col>

                </b-row>

            </b-form>
        </b-card>      

        <b-card class="cadastros-card-corpo">
            <b-table hover striped :items="integracoes" :fields="fields">  
                <template v-slot:cell(actions)="data">
                    <b-button variant="warning" @click="loadintegracao(data.item)" class="mr-2">
                        <i class="fa fa-pencil"></i>
                    </b-button>
                    <b-button variant="danger" @click="loadintegracao(data.item, 'remove')">
                        <i class="fa fa-trash"></i>
                    </b-button>
                </template>
            </b-table>
        </b-card>  
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'

export default {
    name: 'Integracao',
    data: function() {
        return {
            mode: 'save',
            integracao: {},
            integracoes: [],
            formas: [],
            condicoes: [],
            usuarios: [],
            pessoas: [],
            planoContas: [],
            parcial: '',
            fields: [
                {key: 'id_integracao', label: 'Código', sortable: true},
                {key: 'tipo_integracao', label: 'Plataforma', sortable: true},
                {key: 'id_filial', label: 'Filial', sortable: true},
                {key: 'actions', label: 'Ações'}
            ],
            tipoIntegracao: [
                { value: 'HOTMART', text: 'Hotmart' },
                { value: 'EDUZZ', text: 'Eduzz' }
            ]     
        }
    },
    methods: {
        upperCase(value) {
            return value.toUpperCase()
        },
        lowerCase(value) {
            return value.toLowerCase()
        },
        novaConsulta() {
            this.page = 1 
            this.loadintegracoes()
        },
        loadintegracoes() { 
            const url = `${baseApiUrl}/integracoes`
            axios.get(url).then(res => {
                this.integracoes = res.data
                this.count = res.data.count 
                this.limit = res.data.limit
            })           
        },
        loadFormas() {
            const url = `${baseApiUrl}/formas_pagar_receber`
            axios.get(url).then(res => {
                this.formas = res.data.map(forma => {
                    return { ...forma, value: forma.id_forma_pagar_receber, text: `${forma.id_forma_pagar_receber} | ${forma.descricao}` }
                })
            })
        },          
        loadCondicoes() {
            const url = `${baseApiUrl}/condicoes_pagar_receber` 
            axios.get(url).then(res => {
                this.condicoes = res.data.map(condicao => {
                    return { ...condicao, value: condicao.id_condicao_pagar_receber, text: `${condicao.id_condicao_pagar_receber} | ${condicao.descricao}` }
                })
            })
        },
        loadUsuarios() {
            const url = `${baseApiUrl}/usuarios`
            axios.get(url).then(res => {
                this.usuarios = res.data.map(usuario => {
                    return { ...usuario, value: usuario.idusuario, text: `${usuario.idusuario} | ${usuario.nome}` }
                })
            })
        },  
        loadPessoas() {
            const url = `${baseApiUrl}/pessoas`
            axios.get(url).then(res => {
                this.pessoas = res.data.map(pessoa => {
                    return { ...pessoa, value: pessoa.idpessoa, text: pessoa.razao_social }
                })
            })
        },
        loadPlanoContas() {
            const url = `${baseApiUrl}/plano_contas/?tipo=R`
            axios.get(url).then(res => {
                this.planoContas = res.data.map(planoConta => {
                    return { ...planoConta, value: planoConta.id_plano_conta, text: `${planoConta.id_plano_conta} | ${planoConta.descricao}` }
                })
            })
        },
        reset() {
            this.mode = 'save'

            this.integracao = {
                idintegracao: null
            }

            this.integracoes = []
            this.loadintegracoes()
        },
        save() {
            const method = this.integracao.id_integracao ? 'put' : 'post'
            const idintegracao = this.integracao.id_integracao ? `/${this.integracao.id_integracao}` : ''

            axios[method](`${baseApiUrl}/integracoes${idintegracao}`, this.integracao)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        remove() {
            const idintegracao = this.integracao.id_integracao
            axios.delete(`${baseApiUrl}/integracoes/${idintegracao}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        loadintegracao(integracao, mode = 'save') {
            this.mode = mode
            this.integracao = { ...integracao }
        }
    },
    mounted() {
        this.loadintegracoes(),
        this.loadFormas(),
        this.loadCondicoes(),
        this.loadUsuarios(),
        this.loadPessoas(),
        this.loadPlanoContas()
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.7rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

</style>