<template>
    <div class="notas-fiscais-pages"> 
        <PageTitle icon="fa fa-file-code-o" main="Notas fiscais"
            sub="Configurações de ambiente e tributações para NFS-e e NFS-e Nacional" /> 

        <div class="notas-fiscais-pages-tabs">
            <b-card no-body>
                <b-tabs card>
                    <b-tab title="NFS-e">
                        <NFSe />   
                    </b-tab>
                    <b-tab title="NFS-e Nacional" v-if="false">
                        <NFSeNacional />  
                    </b-tab>
                    <!-- <b-tab title="Certificado Digital">
                        
                    </b-tab> -->
                </b-tabs>
            </b-card>
        </div>        
                         
    </div>
</template>

<script>
import PageTitle from '@/components/template/PageTitle'
import NFSe from './NFSe.vue'
import NFSeNacional from './NFSeNacional.vue'

export default {
    name: 'NotasFiscaisPages',
    components: { PageTitle, NFSe, NFSeNacional } 
}
</script>

<style>

</style>


