<template>
    <div class="produtos-pages">
        <PageTitle icon="fa fa-cubes" main="Produtos"
            sub="Cadastros" />
        
        <CadastroProdutos />   
    </div>
</template>

<script>
import PageTitle from '../template/PageTitle'
import CadastroProdutos from './CadastroProdutos.vue'

export default {
    name: 'ProdutosPages',
    components: { PageTitle, CadastroProdutos }
}
</script>

<style>

</style>
