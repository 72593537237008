<template>
    <div class="formas-condicoes-pages">
        <PageTitle icon="fa fa-handshake-o" main="Formas e Condições"
            sub="Cadastros de Formas e Condições de pagamento e recebimento" />
        <div class="formas-condicoes-pages-tabs">
            <b-card no-body>
                <b-tabs card>
                    <b-tab title="Formas">
                        <CadastroFormas />
                    </b-tab>
                    <b-tab title="Condições">
                        <CadastroCondicoes />
                    </b-tab>
                </b-tabs>
            </b-card>
        </div>        
        
    </div>
</template>

<script>
import PageTitle from '@/components/template/PageTitle'
import CadastroFormas from './CadastroFormas'
import CadastroCondicoes from './CadastroCondicoes'

export default {
    name: 'BancosPages',
    components: { PageTitle, CadastroFormas, CadastroCondicoes }
}
</script>

<style>

</style>
