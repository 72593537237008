<template>
    <div class="orcamentos-pages">
        <PageTitle icon="fa fa-file-text-o" main="Orcamentos"
            sub="Cadastros e manutenção de orçamentos" />
        
        <CadastroOrcamentos/>   
    </div>
</template>

<script>
import PageTitle from '@/components/template/PageTitle'
import CadastroOrcamentos from './CadastroOrcamentos.vue'

export default {
    name: 'OrcamentosPages',
    components: { PageTitle, CadastroOrcamentos }
}
</script>

<style>

</style>
