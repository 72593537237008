<template>
    <div class="locate-area">
        <div class="locate-button">
            <b-input-group
                class="mb-3"
            >
                
                <b-form-input v-model="filtro" placeholder="procurar pessoas, documentos, e mais"></b-form-input>
                <b-input-group-append>
                    
                    <router-link to="/consultas">
                        <b-button variant="secondary" @click="loadConsulta"><i class="fa fa-search button-icons"></i></b-button>
                    </router-link>
                </b-input-group-append>               

            </b-input-group>            
        </div>
    </div>
</template>

<script>
// import { userKey } from '@/global'
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'
import { mapState } from 'vuex'

export default {
    name: 'Locate',
    computed: mapState(['user', 'consulta']),
    //emits: ['consultaGeral'],
    data: function() {
        return {
            filtro: '',
            resultado: {}
        }
    },
    methods: {
        async loadConsulta() {
            axios['get'](`${baseApiUrl}/consulta/?filtro=${this.filtro}`)
                .then(res => {
                    this.$toasted.global.defaultSuccess() 
                    res.data ? this.$store.state.consulta = res.data : this.$store.state.consulta = null
                    this.resultado = res.data                    
                })
            .catch(err => {
                showError(err)
                this.$store.state.consulta = null
            }) 
            
        }
    },
    created() {
		()=>{
            this.consulta.resultado = {}
        }
	}
}
</script>

<style>
    .locate-area {
        align-self: initial;
        height: 100%;
        width: 30rem;
    }

    .locate-button {
        display: flex;
        align-items: center;
        padding: 17px 20px;
    }  
    
</style>
