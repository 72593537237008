<template>
    <div class="p-projecao-financeira-pages">
        <PageTitle icon="fa fa-file-text-o" main="Projeção financeira"
            sub="Projeção financeira paseada em período anterior" />
        
        <ProjecaoFinanceira/>   
    </div>
</template>

<script>
import PageTitle from '@/components/template/PageTitle'
import ProjecaoFinanceira from './ProjecaoFinanceira.vue'

export default {
    name: 'ProjecaoFinanceiraPages',
    components: { PageTitle, ProjecaoFinanceira }
}
</script>

<style>

</style>
