<template>
    <div class="tributacoes-pages"> 
        <PageTitle icon="fa fa-percent" main="Tributações"
            sub="Cadastros de tributações" /> 

        <div class="tributacoes-pages-tabs">
            <b-card no-body>
                <b-tabs card>
                    <b-tab title="ICMS, IPI e ISS">
                        <CadTribIcmsIpiIss />   
                    </b-tab>
                </b-tabs>
            </b-card>
        </div>        
                         
    </div>
</template>

<script>
import PageTitle from '@/components/template/PageTitle'
import CadTribIcmsIpiIss from './CadTribIcmsIpiIss.vue'

export default {
    name: 'TributacoesPages',
    components: { PageTitle, CadTribIcmsIpiIss } 
}
</script>

<style>

</style>


