<template>
    <div class="cadastro-usuarios">
        <div class="acoesTopo">             
            <b-button variant="secondary " class="botoesAcao" v-if="mode === 'save'"
                @click="reset">
                    <i class="fa fa-times button-icons"></i>
                    Cancelar
            </b-button>
            <b-button variant="success" class="botoesAcao" v-if="mode === 'save'" 
                @click="save">
                    <i class="fa fa-check button-icons"></i>
                    Salvar
            </b-button>            
            <b-button variant="primary" class="botoesAcao" v-if="mode === 'new'"
                @click="novoUsuario">
                    <i class="fa fa-plus button-icons"></i>
                    Novo
            </b-button>   
        </div>

        <b-card class="cadastros-card-pesquisa" v-if="show">
            <b-row align-v="center">
                <b-col md="5" sm="12" class="cadastros-pesquisa-coluna"> 
                    <b-form-input id="localizar" type="search"
                        :formatter="upperCase"
                        v-model="parcial"
                        placeholder="Informe os dados da pesquisa..."
                        />                     
                </b-col> 
                <b-col md="4" sm="12" class="radio-consulta">
                    <b-form-group  v-slot="{ ariaDescribedby }">
                        <b-form-radio-group 
                            id="radio-slots-user"
                            v-model="ativos"
                            :aria-describedby="ariaDescribedby"
                            name="radio-options-slots-user"
                        >
                            <b-form-radio value="S">Ativos</b-form-radio>
                            <b-form-radio value="N">Inativos</b-form-radio>
                            <b-form-radio value="T">Todos</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
                <b-col md="3" sm="12" class="d-flex justify-content-end">
                    <b-button variant="info" @click="novaConsulta">
                        <i class="fa fa-search button-icons"></i> Consultar
                    </b-button>
                </b-col>
            </b-row>
        </b-card>  

        <b-card class="cadastros-card-corpo" v-show="!show">
            <b-form>
                <input id="usuario-id" type="hidden" v-model="usuario.idusuario" />

                <b-row>
                    <b-col md="6" sm="12">
                        <b-form-group label="Nome" label-for="usuario-nome">
                            <b-form-input id="usuario-nome" type="text"
                                :formatter="upperCase"
                                v-model="usuario.nome" required />
                        </b-form-group>
                    </b-col>          
                    <b-col md="3" sm="12">
                        <b-form-group label="CPF" label-for="usuario-cpf">
                            <b-form-input id="usuario-cpf" type="text"
                                v-model="usuario.cpf" required />
                        </b-form-group>
                    </b-col>
                    <b-col md="3" sm="12">
                        <b-form-group label="E-mail" label-for="usuario-email">
                            <b-form-input id="usuario-email" type="email" 
                                :formatter="lowerCase"
                                v-model="usuario.email" required />
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col md="3" sm="12">
                        <b-form-group label="Ramal" label-for="usuario-ramal">
                            <b-form-input id="usuario-ramal" type="text"
                                v-model="usuario.ramal" />
                        </b-form-group>
                    </b-col>          
                    <b-col md="3" sm="12">
                        <b-form-group label="Login" label-for="usuario-login">
                            <b-form-input id="usuario-login" type="text"
                                :formatter="upperCase"
                                v-model="usuario.login" required />
                        </b-form-group>
                    </b-col>
                    <b-col md="3" sm="12">
                        <b-form-group label="Senha" label-for="usuario-senha">
                            <b-form-input id="usuario-senha" type="password"
                                v-model="usuario.senha" required />
                        </b-form-group>
                    </b-col>
                    <b-col md="3" sm="12">
                        <b-form-group label="Confirmação de senha" label-for="usuario-conf-senha">
                            <b-form-input id="usuario-conf-senha" type="password"
                                v-model="usuario.confirmar_senha" required />
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col md="12" sm="12">
                        <b-form-checkbox 
                            value="S" 
                            unchecked-value="N" 
                            id="config-email-ativa"  
                            v-model="usuario.email_ativar_envio" 
                            switch
                        >
                            Ativar e-mail pessoal
                        </b-form-checkbox>
                    </b-col>

                    <b-col md="12" sm="12" class="cadastros-pesquisa-coluna" v-if="usuario.email_ativar_envio === 'S'">
                        <b-card title="E-mail de Envio" sub-title="Configura uma conta individual para envio de e-mails">
                            <b-row>  
                                <b-col md="4" sm="12">
                                    <b-form-group label="Usuário" label-for="config-email-user"> 
                                        <b-form-input size="sm" id="config-email-user" type="email" v-model="usuario.email_user" />
                                    </b-form-group>

                                    <b-form-group label="Senha" label-for="config-email-pass"> 
                                        <b-form-input size="sm" id="config-email-pass" type="password" v-model="usuario.email_pass" />
                                    </b-form-group>

                                    <b-form-group label="Responder para" label-for="config-email-reply"> 
                                        <b-form-input size="sm" id="config-email-reply" type="email" v-model="usuario.email_user_reply" />
                                    </b-form-group>
                                </b-col>

                                <b-col md="4" sm="12">
                                    <b-form-group label="Servidor" label-for="config-email-host"> 
                                        <b-form-input size="sm" id="config-email-host" type="text" v-model="usuario.email_host" />
                                    </b-form-group>
                                    
                                    <b-form-group label="Porta" label-for="config-email-port"> 
                                        <b-form-input size="sm" id="config-email-port" type="number" v-model="usuario.email_port" />
                                    </b-form-group>
                                </b-col>

                                <b-col md="4" sm="12">                                    
                                    <b-form-group label="Protocolo" label-for="radio-slots-protocol"> 
                                        <b-form-radio-group 
                                            id="radio-slots-protocol"
                                            v-model="usuario.email_protocol"
                                            name="config-email-user"
                                        >
                                            <b-form-radio value="SSL">SSL</b-form-radio>
                                            <b-form-radio value="TLS">TLS</b-form-radio>
                                            <b-form-radio value="AMBOS">Ambos</b-form-radio>
                                        </b-form-radio-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>

                <!-- <b-row>
                    <b-col md="12" sm="12">
                        <b-form-group label="Imagem de perfil" label-for="imagem-perfil" >
                            <b-input-group>
                                <b-input-group-prepend is-text>
                                    <b-icon class="fa fa-picture-o"></b-icon>
                                </b-input-group-prepend>
                                <b-form-file placeholder="Nenhum arquivo selecionado" id="imagem-perfil" :disabled="busy" accept="image/*" browse-text="Selecionar"></b-form-file>                                
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row> -->
                <br>

                <b-card class="cadastros-opcoes-row-card">
                    <b-row class="cadastros-opcoes-row" align-h="around">                        
                        <b-col md="2" sm="12">
                            <b-form-checkbox id="usuario-ativo" v-show="mode === 'save'" v-model="usuario.ativo" class="mt-3 mb-3" switch>Ativo</b-form-checkbox>
                        </b-col> 
                        <b-col md="3" sm="12" v-if="user.admin === 'S'">
                            <b-form-checkbox id="usuario-admin" v-show="mode === 'save'" v-model="usuario.e_admin" class="mt-3 mb-3" switch>Administrador</b-form-checkbox>
                        </b-col>
                        <b-col md="3" sm="12" v-if="user.superv === 'S'">
                            <b-form-checkbox id="usuario-superv" v-show="mode === 'save'" v-model="usuario.e_supervisor" class="mt-3 mb-3" switch>Supervisor</b-form-checkbox>
                        </b-col>
                        <b-col md="3" sm="12">
                            <b-form-checkbox id="usuario-multifilial" v-show="mode === 'save'" v-model="usuario.multifilial" class="mt-3 mb-3" switch>Multi filial</b-form-checkbox>
                        </b-col>                       
                    </b-row>
                </b-card>
                <br>

                <b-card header="Filias de trabalho">
                    <b-row>
                        <b-col md="4" sm="12">
                            <b-form-group label="Filial" label-for="usuario-filial">
                                <b-form-select id="usuario-filial" v-model="usuarioFilial.idfilial" :options="filiais" />
                            </b-form-group>
                        </b-col> 

                        <b-col md="3" sm="12">
                            <b-form-group label="Apenas visualizar?" v-slot="{ radioFiliais }" > 
                                <b-form-radio-group
                                    class="mt-2"
                                    id="radio-group-1"
                                    v-model="usuarioFilial.apenas_visualizar"
                                    :options="[{text: 'Sim', value: 'S'}, {text: 'Não', value: 'N'}]"
                                    :aria-describedby="radioFiliais"
                                    name="radio-options"
                                ></b-form-radio-group>
                            </b-form-group>
                        </b-col> 
                        <b-col md="4" sm="12">
                            <b-button variant="success" class="mt-4" @click="adicUsuFil()">+ Adicionar</b-button>
                        </b-col> 
                    </b-row>

                    <b-table hover striped :items="usuarioFiliais" :fields="fieldsUsuFiliais">  
                        <template v-slot:cell(actions)="data">                            
                            <b-button variant="danger" @click="removeUsuFil(data.item.id_usuario_filial)">
                                <i class="fa fa-trash"></i>
                            </b-button>
                        </template>
                    </b-table>
                </b-card>

                <br>
                <b-row>
                    <b-col md="12" sm="12" class="d-flex justify-content-end">                        

                        <i id="popover-target-1"  class="fa fa-exclamation-circle info-alteracoes"></i>

                        <b-popover target="popover-target-1" triggers="hover" placement="top">
                            <template #title>Alterações</template>
                            <div v-if="usuario.usu_cad">
                                <b>Usuário Cadastro:</b> {{usuario.usu_cad}} <br>
                                <b>Data de Cadastro:</b> {{usuario.data_cad}} <br>
                                <b>Usuário Alteração:</b> {{usuario.usu_alt}} <br>
                                <b>Data de Alteração:</b> {{usuario.data_alt}}
                            </div>
                            <div v-else>
                                <p>Informações não disponíveis!</p>
                            </div>
                        </b-popover>                        
                    </b-col>
                </b-row>

            </b-form>
        </b-card> 

        <b-card v-show="!show" style="margin-top: 10px">
                <div class="acoesRodape">                 
                <b-button variant="secondary " class="botoesAcaoRodape" v-if="mode === 'save'"
                    @click="reset">
                        <i class="fa fa-times button-icons"></i>
                        Cancelar
                </b-button>
                <b-button variant="success" class="botoesAcaoRodape" v-if="mode === 'save'"
                    @click="save">
                        <i class="fa fa-check button-icons"></i>
                        Salvar
                </b-button>            
                <b-button variant="primary" class="botoesAcaoRodape" v-if="mode === 'new'"
                    @click="novoUsuario">
                        <i class="fa fa-plus button-icons"></i>
                        Novo
                </b-button>   
            </div>
        </b-card>      

        <b-card class="cadastros-card-corpo" v-show="show">
            <b-table hover striped :items="usuarios" :fields="fields">  
                <template v-slot:cell(actions)="data">
                    <b-button variant="warning" @click="loadUser(data.item)" class="mr-2">
                        <i class="fa fa-pencil"></i>
                    </b-button>
                </template>
            </b-table>
            <hr>

            <b-pagination 
                align="center" 
                size="md" 
                v-model="page"  
                :total-rows="count" 
                :per-page="limit" 
                first-number 
                last-number>
            </b-pagination>
        </b-card>  
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import { mapState } from 'vuex'
const moment = require('moment')
import axios from 'axios'

export default {
    name: 'UserAdmin',
    computed: mapState(['user']),
    data: function() {
        return {
            mode: 'new',
            usuario: {
                ativo: true,
                email_ativar_envio: 'N',
                email_user: '',
                email_pass: '',
                email_user_reply: '',
                email_host: '',
                email_port: '',
                email_protocol: 'SSL',
                usuarioFiliais: []
            },
            usuarios: [],
            usuarioFiliais: [],
            usuarioFilial: {
                idusuario: 0,
                idfilial: 0,
                apenas_visualizar: 'N',                
            },
            filiais: [],
            page: 1,
            count: 0,
            limit: 0,
            parcial: '',
            ativos: 'S',
            show: true,
            fields: [
                {key: 'idusuario', label: 'Código', sortable: true},
                {key: 'nome', label: 'Nome', sortable: true},
                {key: 'login', label: 'Login', sortable: true},
                {key: 'email', label: 'E-mail', sortable: true},
                // {key: 'ultimo_acesso', label: 'Último Acesso'},
                {key: 'ativo', label: 'Ativo', sortable: true, formatter: value => value ==='S' ? 'Sim' : 'Não'},
                {key: 'actions', label: 'Ações'}
            ],       
            fieldsUsuFiliais: [
                {key: 'idfilial', label: 'Código', sortable: true},
                {key: 'fantasia', label: 'Filial', sortable: true},
                {key: 'apenas_visualizar', label: 'Apenas Visulizar?', sortable: true, formatter: value => value ==='S' ? 'Sim' : 'Não'},                
                {key: 'actions', label: 'Ações'}
            ]       
        }
    },
    methods: {
        novoUsuario() {
            this.reset()
            this.loadFiliais()
            this.mode = 'save'
        },
        upperCase(value) {
            return value.toUpperCase()
        },
        lowerCase(value) {
            return value.toLowerCase()
        },
        novaConsulta() {
            this.page = 1 
            this.loadUsers()
        },
        loadUsers() { 
            const parc = (this.parcial.trim().length > 0) ? this.parcial : 'xxxxxxxxxx' 
            const url = `${baseApiUrl}/usuarios/consulta/${parc}/ativos/${this.ativos}/?page=${this.page}`    

            axios.get(url).then(res => {
                this.usuarios = res.data.data
                this.count = res.data.count 
                this.limit = res.data.limit
            })           
        },
        loadFiliais() {
            const url = `${baseApiUrl}/filiais`
            axios.get(url).then(res => {
                this.filiais = res.data.map(filial => {
                    return { ...filial, value: filial.idfilial, text: `${filial.idfilial} | ${filial.razao_social}` }
                })
            })
        },
        reset() {
            this.mode = 'new'

            this.usuario = {
                ativo: true,
                e_admin: false,
                e_supervisor: false,
                multifilial: false,
                email_ativar_envio: 'N',
                email_user: '',
                email_pass: '',
                email_user_reply: '',
                email_host: '',
                email_port: '',
                email_protocol: 'SSL',
                usuarioFiliais: []
            }           

            this.usuarioFiliais = []
            
            this.usuarioFilial = {
                idusuario: 0,
                idfilial: 0,
                apenas_visualizar: 'N'
            }

            this.loadUsers()
            this.show = !this.show
        },
        save() {
            const method = this.usuario.idusuario ? 'put' : 'post'
            const idusuario = this.usuario.idusuario ? `/${this.usuario.idusuario}` : ''
            this.usuario.ativo == true ? this.usuario.ativo='S' : this.usuario.ativo='N'           
            this.usuario.e_admin == true ? this.usuario.e_admin='S' : this.usuario.e_admin='N'           
            this.usuario.e_supervisor == true ? this.usuario.e_supervisor='S' : this.usuario.e_supervisor='N'           
            this.usuario.multifilial == true ? this.usuario.multifilial='S' : this.usuario.multifilial='N' 
            this.usuario.usuarioFiliais = [ ...this.usuarioFiliais ]       
            
            axios[method](`${baseApiUrl}/usuarios${idusuario}`, this.usuario)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset() 
                })
                .catch(err => {
                    showError(err)
            })
            
            this.usuario.ativo == 'S' ? this.usuario.ativo = true : this.usuario.ativo =  false  
            this.usuario.e_admin == 'S' ? this.usuario.e_admin = true : this.usuario.e_admin =  false
            this.usuario.e_supervisor == 'S' ? this.usuario.e_supervisor = true : this.usuario.e_supervisor =  false
            this.usuario.multifilial == 'S' ? this.usuario.multifilial = true : this.usuario.multifilial =  false
            
        },
        remove() {
            const idusuario = this.usuario.idusuario
            axios.delete(`${baseApiUrl}/usuarios/${idusuario}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(err => {
                    showError(err)
            })
        },
        loadUser(usuario, mode = 'save') {
            const url = `${baseApiUrl}/usuarios/${usuario.idusuario}` 

            axios.get(url).then(res => { 
                this.loadFiliais()               
                this.mode = mode
                this.usuario = { ...res.data.usuario }
                this.usuario.ativo==='S' ? this.usuario.ativo=true : this.usuario.ativo= false
                this.usuario.e_admin==='S' ? this.usuario.e_admin=true : this.usuario.e_admin= false
                this.usuario.e_supervisor==='S' ? this.usuario.e_supervisor=true : this.usuario.e_supervisor= false
                this.usuario.multifilial==='S' ? this.usuario.multifilial=true : this.usuario.multifilial= false
                this.usuario.data_cad = moment(usuario.data_cad).format('DD/MM/YYYY HH:mm:ss')
                this.usuario.data_alt = moment(usuario.data_alt).format('DD/MM/YYYY HH:mm:ss')
                this.show = !this.show
                this.usuarioFiliais =  [ ...res.data.usuario_filiais ]
            }) 
            .catch(showError) 
        },

        adicUsuFil() {
            this.usuarioFiliais.push({ ...this.usuarioFilial })
        },
        removeUsuFil(idUsuFil) {
            const idx = this.usuarioFiliais.map((e)=>{ return e.id_usuario_filial; }).indexOf(idUsuFil)
            this.usuarioFiliais.splice(idx, 1)
            
            // axios.delete(`${baseApiUrl}/usuarios_filiais/${idUsuFil}`)
            //     .then(() => {
            //         this.$toasted.global.defaultSuccess()
            //         this.usuarioFiliais.splice(idx, 1)
            //         this.loadUsuFiliais(this.usuarioFilial.idusuario)
            //     })
            //     .catch(showError)
        },
        loadUsuFiliais(idUsuario) {
            const url = `${baseApiUrl}/usuarios_filiais/${idUsuario}`

            axios.get(url).then(res => {
                this.usuarioFiliais = res.data
            })
            .catch(showError)
        },
    },
    watch: {
        page() {
            this.loadUsers()
        }
    },
    mounted() {
        this.loadUsers()
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.9rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

    



</style>