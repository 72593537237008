<template>
    <div class="notas-emitidas-pages">
        <PageTitle icon="fa fa-file-code-o" main="Notas emitidas"
            sub="Consulta e manutenção de notas fiscais emitidas" 
            @set-title="addTitle" />
        <ConsultaNotas />         
    </div>
</template>

<script>
import PageTitle from '../template/PageTitle'
import ConsultaNotas from './ConsultaNotas'

export default {
    name: 'VendasPages',
    main: 'sdfgsdfgf',
    components: { PageTitle , ConsultaNotas },    
    methods: {        
        addTitle(payload){
           return payload.main
        }
    }

}
</script>

<style>

</style>
