<template> 

    <header class="header">  
        <div class="logo-top">  
            <router-link to="/" class="logo">
                <img src="../../../public/rex_logo_60.svg" alt="Logo" >
            </router-link> 
        </div>

        <Locate v-if="user" />
        <Notifications v-if="user" />
        <UserDropdown v-if="user" />
    </header>

</template>

<script>
import UserDropdown from './UserDropdown'
import Locate from './Locate.vue'
import Notifications from './Notifications.vue'

import { mapState } from 'vuex'
export default {
    name: 'Header',
    components: { UserDropdown, Locate, Notifications },
    computed: mapState(['user']),
    data: function() {
        return {
            plano: ''
        }
    },
}
</script>

<style>
    .header {
        grid-area: header;
        background: #FAFFFE;
        display: flex;
        /* justify-content: center; */
        align-items: center; 
        width: 100%;
        height: 70px;

        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;

        box-shadow: 0px 0px 5px 0px rgba(95, 95, 95, 0.39);
    }

    .logo-top {
        flex-grow: 1;
        text-align: left;
        display: flex;
        align-items: center;
        height: 100%;
    }

    .logo-top img {
      height: 55px;
    } 

    .logo {
        padding-left: 30px;        
        max-width: 250px;
    }    

</style>
