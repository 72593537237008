<template>
    <div class="funil-vendas-pages">
        <PageTitle icon="fa fa-filter" main="Funil de vendas"
            sub="Controle o progresso de suas negociações" />
        
        <FunilVendas />   
    </div>
</template>

<script>
import PageTitle from '../template/PageTitle'
import FunilVendas from './FunilDeVendas.vue'

export default {
    name: 'FunilDeVendasPages',
    components: { PageTitle, FunilVendas }
}
</script>

<style>

</style>
