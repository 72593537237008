import Vue from 'vue'
import $ from "jquery";

export const userKey = '__lancamentos_user'
export const jquery = $

export const baseApiUrl = 'https://api.rexskale.com/api/v1'
export const baseApiUrlV2 = 'https://api.rexskale.com/api/v2'

// export const baseApiUrl = 'http://localhost:3000/api/v1'  
// export const baseApiUrlV2 = 'http://localhost:3000/api/v2'  

export const baseStaticUrl = 'https://localhost:8080/'  

export function showError(e) {
    if(e && e.response && e.response.data) {
        Vue.toasted.global.defaultError({ msg : e.response.data })
    } else if(typeof e === 'string') {
        Vue.toasted.global.defaultError({ msg : e })
    } else {
        Vue.toasted.global.defaultError()
    } 
}
export function showSuccess(e) {
    if(e && e.response && e.response.data) {
        Vue.toasted.global.defaultSuccess({ msg : e.response.data })
    } else if(typeof e === 'string') {
        Vue.toasted.global.defaultSuccess({ msg : e })
    } else {
        Vue.toasted.global.defaultSuccess()
    } 
}
export function showAlert(e) {
    if(e && e.response && e.response.data) {
        Vue.toasted.global.defaultAlert({ msg : e.response.data })
    } else if(typeof e === 'string') {
        Vue.toasted.global.defaultAlert({ msg : e })
    } else {
        Vue.toasted.global.defaultAlert()
    } 
}

export default { baseApiUrl, showError, showSuccess, showAlert, userKey, baseStaticUrl, jquery }