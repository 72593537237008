<template>
    <div class="cadastro-modelos">
        
        <div class="acoesTopo">             
            <b-button variant="secondary " class="botoesAcao" v-if="(mode === 'save') || (mode === 'remove')"
                @click="reset">
                    <i class="fa fa-times button-icons"></i>
                    Cancelar
            </b-button>
            <b-button variant="danger" class="botoesAcao" v-if="mode === 'remove'"
                @click="remove">
                    <i class="fa fa-trash button-icons"></i>
                    Excluir
            </b-button>
            <b-button variant="success" class="botoesAcao" v-if="mode === 'save'" 
                @click="save">
                    <i class="fa fa-check button-icons"></i>
                    Salvar
            </b-button>            
            <b-button variant="primary" class="botoesAcao" v-if="mode === 'new'"
                @click="novoModelo">
                    <i class="fa fa-plus button-icons"></i>
                    Novo
            </b-button>   
        </div>

        <b-card class="cadastros-card-pesquisa" v-if="show">
            
            <b-row align-v="center">
                <b-col md="6" sm="12" class="cadastros-pesquisa-coluna"> 
                    <b-form-input id="localizar" type="search"
                        :formatter="upperCase"
                        v-model="parcial"
                        :readonly="mode === 'remove'"
                        placeholder="Informe parte da descrição do modelo..." />                     
                </b-col> 
                
                <b-col md="3" sm="12" class="d-flex justify-content-end">
                    <b-button variant="info" @click="novaConsulta">
                        <i class="fa fa-search button-icons"></i> Consultar
                    </b-button>
                </b-col>
            </b-row>
        </b-card>

        <b-overlay :show="showOver" rounded="sm">
            <b-card class="cadastros-card-corpo" v-if="!show">
                <b-form>
                    <input id="modelos-id" type="hidden" v-model="modelo.id_modelo_relatorio" />

                    <b-row>                       
                        <b-col md="8" sm="12">
                            <b-form-group label="Descrição" label-for="modelos-descricao"> 
                                <b-form-input id="modelos-descricao" type="text"
                                    :formatter="upperCase"
                                    v-model="modelo.descricao" required
                                    :readonly="mode === 'remove'"
                                    placeholder="Informe a descrição do modelo..." />
                            </b-form-group>
                        </b-col> 
                        <b-col md="4" sm="12">
                            <b-form-group label="Tela" label-for="modelo-tela">
                                <b-form-select id="modelo-tela" v-model="telaSelecionada" :options="opcoesTelas" />
                            </b-form-group>
                        </b-col> 
                    </b-row>

                    <b-row>                 
                        <b-col md="3" sm="12">
                            <b-form-group label="Campos disponíveis" label-for="lista-botoes-ul">
                                <b-card class="scroll" style="height: 582px">
                                    <ul style="list-style-type: none;" id="lista-botoes-ul">
                                        <li v-for="item in botoes" :key="item" class="m-1" > 
                                            <b-button v-if="item.value !== 'hr'"  
                                                style="padding: 2px 10px 2px 10px;"
                                                size="sm" 
                                                :id="`botao-mod-camp-${botoes.indexOf(item)}`"
                                                :value="item.value">
                                                    {{ item.descr }} 
                                            </b-button>

                                            <div v-if="item.value === 'hr'" style="margin-top: 30px;">
                                                <p style="margin-bottom: -18px; font-size: 13px">{{ item.descr }}</p>
                                                <hr>
                                            </div>
                                        </li>
                                    </ul>
                                    <br>
                                </b-card>
                            </b-form-group>            
                        </b-col>               
                        <b-col md="9" sm="12">
                            <b-form-group label="Corpo do Modelo de Relatório" label-for="modelo-corpo">
                                <VueEditor id="editor-este"
                                    v-model="modelo.corpo_modelo" 
                                    placeholder="Crie aqui seu modelo..." 
                                    style="width: 826px; height: 500px;"
                                />
                            </b-form-group>
                        </b-col>               
                    </b-row>
                    
                    <br>
                    <b-row>
                        <b-col md="12" sm="12" class="d-flex justify-content-end">                        

                            <i id="popover-target-1"  class="fa fa-exclamation-circle info-alteracoes"></i>

                            <b-popover target="popover-target-1" triggers="hover" placement="top">
                                <template #title>Alterações</template>
                                <b>Usuário Cadastro:</b> {{modelo.usu_cad}} <br>
                                <b>Data de Cadastro:</b> {{modelo.data_cad}} <br>
                                <b>Usuário Alteração:</b> {{modelo.usu_alt}} <br>
                                <b>Data de Alteração:</b> {{modelo.data_alt}}
                            </b-popover>                        
                        </b-col>

                    </b-row>

                </b-form>
            </b-card>
        </b-overlay>   

        <b-card v-show="!show" style="margin-top: 10px">
                <div class="acoesRodape">                 
                <b-button variant="secondary " class="botoesAcaoRodape" v-if="(mode === 'save') || (mode === 'remove')"
                    @click="reset">
                        <i class="fa fa-times button-icons"></i>
                        Cancelar
                </b-button>
                <b-button variant="danger" class="botoesAcaoRodape" v-if="mode === 'remove'"
                    @click="remove">
                        <i class="fa fa-trash button-icons"></i>
                        Excluir
                </b-button>
                <b-button variant="success" class="botoesAcaoRodape" v-if="mode === 'save'"
                    @click="save">
                        <i class="fa fa-check button-icons"></i>
                        Salvar
                </b-button>            
                <b-button variant="primary" class="botoesAcaoRodape" v-if="mode === 'new'"
                    @click="novoModelo">
                        <i class="fa fa-plus button-icons"></i>
                        Novo
                </b-button>   
            </div>
        </b-card>   

        <b-card class="cadastros-card-corpo" v-show="show">
            <b-table hover striped :items="modelos" :fields="fields">  
                <template v-slot:cell(actions)="data">
                    <b-button variant="warning" @click="loadModelo(data.item)" class="mr-2">
                        <i class="fa fa-pencil"></i>
                    </b-button>
                    <b-button variant="danger" @click="loadModelo(data.item, 'remove')">
                        <i class="fa fa-trash"></i>
                    </b-button>
                </template>
            </b-table>
            <hr>
            <b-pagination align="center" size="md" v-model="page"  :total-rows="count" :per-page="limit" first-number last-number></b-pagination>
        </b-card>  
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import {VMoney} from 'v-money'
import { VueEditor } from "vue2-editor"
// const moment = require('moment')
import axios from 'axios'

export default {
    name: 'CadastroModelos',
    components: { VueEditor },
    data: function() {
        return {
            teste: {},
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                //suffix: ' #',
                precision: 2,
                masked: false
            },
            mode: 'new',
            telaSelecionada: '',
            modelo: {
                id_modelo_relatorio: ''
            },
            modelos: [],
            page: 1,
            count: 0,
            limit: 0,           
            parcial: '',
            show: true,
            showOver: false,
            opcoesTelas: [
                { value: 'cadcontrato', text: 'Cadastro de Contratos' },
                { value: 'cadorcamento', text: 'Cadastro de Orçamentos' },
            ],
            
            fields: [
                {key: 'descricao', label: 'Descricao', sortable: true},
                {key: 'tela', label: 'Tela', sortable: true},                
                {key: 'actions', label: 'Ações'}
            ], 

            botoes: [],
            
            btsCadContrato: [
                { value: 'hr', descr: 'Cliente'},

                { value: '@nome-cliente@', descr: 'Nome (cliente)'},
                { value: '@id-pessoa@', descr: 'Id (cliente)'},
                { value: '@tipo-pessoa@', descr: 'Tipo Pessoa (cliente)'},
                { value: '@cpf@', descr: 'CPF (cliente)'},
                { value: '@cnpj@', descr: 'CNPJ (cliente)'},
                { value: '@rg@', descr: 'RG (cliente)'},
                { value: '@ie@', descr: 'IE (cliente)'}, 
                { value: '@endereco@', descr: 'Logradouro (cliente)'}, 
                { value: '@numero@', descr: 'Número (cliente)'},
                { value: '@bairro@', descr: 'Bairro (cliente)'},
                { value: '@complemento@', descr: 'Complemento (cliente)'},
                { value: '@uf@', descr: 'UF (cliente)'},
                { value: '@nome-cidade@', descr: 'Cidade (cliente)'},
                { value: '@cep@', descr: 'CEP (cliente)'},
                { value: '@telefone@', descr: 'Telefone (cliente)'},
                { value: '@celular@', descr: 'Celular (cliente)'},

                { value: 'hr', descr: 'Contrato'},

                { value: '@num-contrato@', descr: 'Numero do Contrato'},
                { value: '@data@', descr: 'Data do Contrato'},
                { value: '@data-extenso@', descr: 'Data do Contrato (por extenso)'},
                { value: '@primeiro-vencimento@', descr: 'Primeiro Vencimento'},
                { value: '@primeiro-vencimento-extenso@', descr: 'Primeiro Vencimento (por extenso)'},
                { value: '@encerramento@', descr: 'Data Encerramento'},
                { value: '@encerramento-extenso@', descr: 'Data Encerramento (por extenso)'},
                { value: '@periodicidade@', descr: 'Periodicidade'},
                { value: '@valor-total@', descr: 'Valor Total'},
                { value: '@valor-total-extenso@', descr: 'Valor Total (por extenso)'},
                { value: '@qtde-parcelas@', descr: 'Qtde Parcelas'},
                { value: '@qtde-parcelas-extenso@', descr: 'Qtde Parcelas (por extenso)'},
                { value: '@valor-parcela@', descr: 'Valor da Parcela'},
                { value: '@valor-parcela-extenso@', descr: 'Valor da Parcela (por extenso)'},
                { value: '@desc-ate-vencto@', descr: 'Desc. até Vencimento'},

                { value: '@obs@', descr: 'Observação do Contrato'},
                { value: '@instrucoes@', descr: 'Instruções do Contrato'},
                { value: '@obs-fiscais@', descr: 'Observações fiscais'},

                { value: 'hr', descr: 'Filial'},

                { value: '@razao-social-filial@', descr: 'Razão Social (filial)'},
                { value: '@fantasia-filial@', descr: 'Fantasia (filial)'},
                { value: '@tipo-pessoa-filial@', descr: 'Tipo Pessoa (filial)'},
                { value: '@cnpj-cpf-filial@', descr: 'CPF/CNPJ (filial)'},
                { value: '@ie-rg-filial@', descr: 'RG/IE (filial)'},
                { value: '@im-filial@', descr: 'IM (filial)'},
                { value: '@telefone-filial@', descr: 'Telefone (filial)'},
                { value: '@fax-filial@', descr: 'Fax (filial)'},
                { value: '@celular-filial@ ', descr: 'Celular (filial)'},              
                { value: '@email-filial@', descr: 'E-mail (filial)'},
                { value: '@endereco-filial@', descr: 'Logradouro (filial)'},
                { value: '@numero-filial@', descr: 'Número (filial)'},
                { value: '@bairro-filial@', descr: 'Bairro (filial)'},
                { value: '@nome-cidade-filial@', descr: 'Cidade (filial)'},
                { value: '@uf-filial@', descr: 'UF (filial)'},
                { value: '@complemento-filial@', descr: 'Complemento (filial)'},
                { value: '@cep-filial@', descr: 'CEP (filial)'},

                { value: 'hr', descr: 'Sistema'},

                { value: '@data-hoje@', descr: 'Data de hoje'},
                { value: '@data-hoje-extenso@', descr: 'Data de hoje (por extenso)'}
            ],

            btsCadOrcamento: [
                { value: 'hr', descr: 'Cliente'},
                { value: '@nome-cliente@', descr: 'Nome (cliente)'},
                { value: '@id-pessoa@', descr: 'Id (cliente)'},
                { value: '@nome-vendedor@', descr: 'Nome do Vendedor'},
                { value: '@id-vendedor@', descr: 'Id (vendedor)'},
                { value: '@tipo-pessoa@', descr: 'Tipo Pessoa (cliente)'},
                { value: '@cpf@', descr: 'CPF (cliente)'},
                { value: '@cnpj@', descr: 'CNPJ (cliente)'},
                { value: '@rg@', descr: 'RG (cliente)'},
                { value: '@ie@', descr: 'IE (cliente)'}, 
                { value: '@endereco@', descr: 'Logradouro (cliente)'}, 
                { value: '@numero@', descr: 'Número (cliente)'},
                { value: '@bairro@', descr: 'Bairro (cliente)'},
                { value: '@complemento@', descr: 'Complemento (cliente)'},
                { value: '@uf@', descr: 'UF (cliente)'},
                { value: '@nome-cidade@', descr: 'Cidade (cliente)'},
                { value: '@cep@', descr: 'CEP (cliente)'},
                { value: '@telefone@', descr: 'Telefone (cliente)'},
                { value: '@celular@', descr: 'Celular (cliente)'},

                { value: 'hr', descr: 'Formas e Condições'},
                
                { value: '@id-forma-recebimento@', descr: 'ID Forma de Recebimento'},
                { value: '@desc-forma-recebimento@', descr: 'Desc. Forma de Recebimento'},
                { value: '@id-cond-recebimento@', descr: 'ID Condição de Recebimento'},
                { value: '@desc-cond-recebimento@', descr: 'Desc. Condição de Recebimento'},
                { value: '@valor-total@', descr: 'Valor Total'},
                { value: '@valor-total-extenso@', descr: 'Valor Total (por extenso)'},
                { value: '@valor-total-liquido@', descr: 'Valor Total Líquido'},
                { value: '@valor-total-liquido-extenso@', descr: 'Valor Total Líquido (por extenso)'},

                { value: 'hr', descr: 'Produtos'},

                { value: '@produtos-desconto@', descr: 'Desconto Produtos'},
                { value: '@produtos-acrescimo@', descr: 'Acréscimo Produtos'},
                { value: '@produtos-total@', descr: 'Total Bruto Produtos'},
                { value: '@produtos-total-liquido@', descr: 'Total Líquido Produtos'},

                { value: 'hr', descr: 'Serviços'},

                { value: '@servicos-desconto@', descr: 'Desconto Servicos'},
                { value: '@servicos-acrescimo@', descr: 'Acréscimo Servicos'},
                { value: '@servicos-total@', descr: 'Total Bruto Servicos'},
                { value: '@servicos-total-liquido@', descr: 'Total Líquido Servicos'},

                { value: 'hr', descr: 'Orçamento'},

                { value: '@num-orcamento@', descr: 'Numero do Orçamento'},
                { value: '@data@', descr: 'Data do Orçamento'},
                { value: '@data-extenso@', descr: 'Data do Contrato (por extenso)'},
                { value: '@qtde-parcelas@', descr: 'Qtde Parcelas'},
                { value: '@qtde-parcelas-extenso@', descr: 'Qtde Parcelas (por extenso)'},
                { value: '@valor-parcela@', descr: 'Valor da Parcela'},
                { value: '@valor-parcela-extenso@', descr: 'Valor da Parcela (por extenso)'},
                { value: '@desc-ate-vencto@', descr: 'Desc. até Vencimento'},
                { value: '@obs@', descr: 'Observação do Orçamento'},
                { value: '@status@', descr: 'Status'},  

                { value: 'hr', descr: 'Filial'},

                { value: '@razao-social-filial@', descr: 'Razão Social (filial)'},
                { value: '@fantasia-filial@', descr: 'Fantasia (filial)'},
                { value: '@tipo-pessoa-filial@', descr: 'Tipo Pessoa (filial)'},
                { value: '@cnpj-cpf-filial@', descr: 'CPF/CNPJ (filial)'},
                { value: '@ie-rg-filial@', descr: 'RG/IE (filial)'},
                { value: '@im-filial@', descr: 'IM (filial)'},
                { value: '@telefone-filial@', descr: 'Telefone (filial)'},
                { value: '@fax-filial@', descr: 'Fax (filial)'},
                { value: '@celular-filial@ ', descr: 'Celular (filial)'},              
                { value: '@email-filial@', descr: 'E-mail (filial)'},
                { value: '@endereco-filial@', descr: 'Logradouro (filial)'},
                { value: '@numero-filial@', descr: 'Número (filial)'},
                { value: '@bairro-filial@', descr: 'Bairro (filial)'},
                { value: '@nome-cidade-filial@', descr: 'Cidade (filial)'},
                { value: '@uf-filial@', descr: 'UF (filial)'},
                { value: '@complemento-filial@', descr: 'Complemento (filial)'},
                { value: '@cep-filial@', descr: 'CEP (filial)'},

                { value: 'hr', descr: 'Sistema'},

                { value: '@data-hoje@', descr: 'Data de hoje'},
                { value: '@data-hoje-extenso@', descr: 'Data de hoje (por extenso)'}
            ]
        }
    },
    directives: {
        money: VMoney
    },
    methods: {
        addEvento() {
            let el = document.getElementById('lista-botoes-ul');

            el.addEventListener('click', async (e) => {
                e.preventDefault();
                navigator.clipboard.writeText(e.target.value);
            });
        },
        novoModelo() {
            this.reset()
            this.mode = 'save'
        },
        upperCase(value) {
            return value.toUpperCase()
        },
        lowerCase(value) {
            return value.toLowerCase()
        },
        novaConsulta() {
            this.page = 1 
            this.loadModelos()
        },        
        loadModelos() {  
            const url = `${baseApiUrl}/consulta/cadastros/?page=${this.page}&parcial=${this.parcial}&tipo=cadmodelo` 

            axios.get(url).then(res => {
                this.modelos = res.data.data
                this.count = res.data.count 
                this.limit = res.data.limit
            }) 
            .catch(showError)           
        },
        reset() {
            this.mode = 'new'
            this.botoes = []
            this.telaSelecionada = ''
            
            this.modelo = {
                id_modelo_relatorio: '',
                descricao: '',
                tela: '',
                corpo_modelo: ''
            }

            this.loadModelos()
            this.show = !this.show
        },
        save() {
            const method = this.modelo.id_modelo_relatorio ? 'put' : 'post'
            const idmodelo = this.modelo.id_modelo_relatorio ? `/${this.modelo.id_modelo_relatorio}` : ''   
            this.modelo.tela = this.telaSelecionada       

            axios[method](`${baseApiUrl}/modelos/relatorios${idmodelo}`, this.modelo)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
            .catch(showError)
            
        },
        remove() {
            const idmodelo = this.modelo.id_modelo_relatorio
            axios.delete(`${baseApiUrl}/modelos/relatorios/${idmodelo}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        loadModelo(modelo, mode = 'save') {
            this.show = !this.show 
            this.showOver = !this.showOver 

            const url = `${baseApiUrl}/modelos/relatorios/${modelo.id_modelo_relatorio}`    

            axios.get(url).then(res => {
                
                this.showOver = !this.showOver 
                
                this.mode = mode
                this.modelo = { ...res.data }
                this.telaSelecionada = res.data.tela
            }) 
            .catch(err => {
                showError(err)
                this.show = !this.show 
                this.showOver = !this.showOver 
            })
        },
    },
    watch: {
        page() {
            this.loadModelos()
        },
        telaSelecionada() {
            switch (this.telaSelecionada) {
                case 'cadcontrato':
                    this.botoes = this.btsCadContrato
                    break;
                case 'cadorcamento':
                    this.botoes = this.btsCadOrcamento
                    break;            
            }            
        }
    },
    mounted() {
        this.addEvento()
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.9rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

    
    .scroll {
        overflow-y: scroll
    }



</style>