<template>    
    <div>
        <section class="pdf-item">
            <div class="myStyle">
                <div id="renderPDF" class="containerPdf">
                    <h3>{{ config.param.titulo }}</h3>
                    <h6>{{ config.param.subtitulo }}</h6>
                    <hr>
                    
                    <v-data-table 
                        :headers="headers"
                        :items="relacao"
                        :sort-by="ordenar"
                        hide-default-footer
                        dense
                        :items-per-page="1000"
                        style="padding: 0px"
                    >
                        <template v-slot:[`body.append`]="{ items }" style="color: red">
                            <tr class="rel-totais">
                                <td></td>
                                <td></td>
                                <td><span>Totais</span></td>
                                <td>{{ items[0].tot_produtos }}</td>
                                <td>{{ items[0].tot_desconto }}</td>
                                <td>{{ items[0].tot_acrescimo }}</td>
                                <td>{{ items[0].tot_total }}</td>
                            </tr>
                        </template>
                        <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen, items }">
                            <td :colspan="headers.length">
                                <div class="cabecalho-grupo">
                                    <v-btn @click="toggle" x-small icon :ref="group">
                                        <v-icon v-if="isOpen">mdi-minus</v-icon>
                                        <v-icon v-else>mdi-plus</v-icon>
                                    </v-btn>

                                    <span class="mx-1 font-weight-bold">{{ group }}</span>
                                    
                                    <span class="font-weight-bold total-grupo">
                                        {{ (items[0].total || 0).toLocaleString('pt-br', {minimumFractionDigits: 2}) }} </span>
                                    <span class="font-weight-bold porc-grupo">
                                        {{ items[0].porcGrupo }} </span>
                                </div>
                                
                                
                                
                                <!-- <span class="mx-5 font-weight-bold total-grupo" v-if="(items.map(item => item.valor).reduce((prev, curr) => prev + curr, 0)) > 0">
                                    {{ somarGrupo(items) }} </span>
                                <span class="mx-5 font-weight-bold total-grupo" v-else>
                                    {{ (itens[0].total) }} </span> -->
                            </td>
                        </template> 
                        <template v-slot:[`item.valor`]="{ item }">   
                            <span v-if="item.DESCRICAO != ''"> {{ moedaBr(item.valor) }} </span>   
                            
                        </template>  
                        <template v-slot:[`item.CONTA`]="{ item }">   
                            <span v-if="item.DESCRICAO != ''">{{ item.CONTA }}</span>
                        </template>                   
                                            
                    </v-data-table>  
                </div>
            </div>
        </section>
    </div>        
</template>

<script>
import jsPDF from "jspdf"
import html2canvas from "html2canvas"
import html2pdf from "html2pdf.js"
//import $ from 'jquery'

export default {
    name: "RelatorioNotas",
    props: ['itens', 'gerarPdf', 'config'],
    data() {
        return {       
            headers: [ ...this.config.headers ],
            relacao: this.itens,
            agrupador: this.config.param.agrupador,
            ordenar: this.config.param.ordenar,
            totais: {
                produtos: 0,
                desconto: 0,
                acrescimo: 0,
                total: 0
            }
        };
    },
    methods:{
        somarGrupo(items) {
            if ((items.map(item => item.valor).reduce((prev, curr) => prev + curr, 0)) > 0) {
                return (items.map(item => item.valor).reduce((prev, curr) => prev + curr, 0).toLocaleString('pt-br', {minimumFractionDigits: 2}))
            } else {
                return (items.map(item => item.valor).reduce((prev, curr) => prev + curr, 0).toLocaleString('pt-br', {minimumFractionDigits: 2}))
            }
            
            
            //(items.map(item => item.total_receita).reduce((prev, curr) => prev + curr, 0)).toLocaleString('pt-br', {minimumFractionDigits: 2}) 
        },
        moedaBr(valor) {
            return valor.toLocaleString('pt-br', {minimumFractionDigits: 2})
        },
        salvarPdf(nomeArquivo){
            var element = document.getElementById('renderPDF');
            var pagebreak = { mode: 'avoid-all' };

            // Gerar o PDF.
            html2pdf().from(element).set({
                margin:[0.25,0,0.25,0],
                filename: nomeArquivo + '.pdf',
                pagebreak: pagebreak,
                jsPDF: {orientation: 'portrait', unit: 'in', format: 'a4', compressPDF: true}
            })
            .toPdf()
            .get('pdf').then(function (pdf) {
                    var totalPages = pdf.internal.getNumberOfPages();

                    for (let i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(8);
                        pdf.setTextColor(75);
                        pdf.text('Página ' + i + ' de ' + totalPages, ((pdf.internal.pageSize.getWidth()/2)-0.4) , (pdf.internal.pageSize.getHeight()-0.2));
                    }
            })
            .save();
        },

        salvar(nomeArquivo){
            const printContent = document.getElementById("renderPDF");
            printContent.style.fontSize = '14px';
            
            const opt = {
                margin:[10,10,16,10], // topo, esquerda, baixo, direita
                //margin: [0.1, 10, 0.1, 10],
                pagebreak: {mode: ['avoid-all', 'tr'] },
                image:{ type: 'jpeg',quality: 0.6},
                //pagebreak: {avoid: 'tr'},
                mode: ['legacy'],
                html2canvas:{
                    useCORS: true,
                    dpi:192
                },

                jsPDF: {
                    orientation: 'p',
                    unit: 'mm',
                    format: 'a4'
                }
            }

            html2pdf()
                .from(printContent)
                .set(opt)
                .toPdf()
                .get('pdf').then(function (pdf) {
                    var totalPages = pdf.internal.getNumberOfPages();

                    for (let i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(8);
                        pdf.setTextColor(75);
                        pdf.text('Pagina ' + i + ' de ' + totalPages, 175, (pdf.internal.pageSize.getHeight()-8));
                    }
                })

            .save(nomeArquivo+".pdf")

        },
        getPDF(){
            //var a4  =[ 595.28,  841.89]; // Widht e Height de uma folha a4
            // Setar o width da div no formato a4
            //$("#renderPDF").width((a4[0]*1.33333) -80).css('max-width','none');


            html2canvas(document.querySelector(".containerPdf")).then(async function(canvas) {
                //document.body.appendChild(canvas);
                var imgData = await canvas.toDataURL('image/png');

                var doc = new jsPDF('p', 'mm', 'a4');

                doc.addImage(imgData, 'png', 10, 10);
                //doc.addImage(imgData, 'png', 20, 20);
                doc.save('relatorio-cidades.pdf');
            });

               
            // // Aqui ele cria a imagem e cria o pdf
            // html2canvas($('#renderPDF'), {
            //     onrendered: function(canvas) {
            //         canvas = 'teste'
            //         alert(canvas)
            //         var img = document.getElementById('renderPDF');
            //         var doc = new jsPDF({unit:'px', format:'a4'});
            //        // doc.addImage(img, 'JPEG', 20, 20);
            //         doc.addImage(img.toDataURL(), 'PNG', 10, 10);
            //         doc.save('NOME-DO-PDF.pdf');
            //         //Retorna ao CSS normal
            //         $('#renderPDF').width(cache_width);
            //     }
            // });


            // var canvas_status_atividades = document.getElementById('canvas_status_atividades');
            // var pdf = new jsPDF('p', 'mm');
            // pdf.addImage(canvas_status_atividades.toDataURL(), 'PNG', 10, 10);
            // pdf.save('test.pdf');


        }
    },
    watch: {
        gerarPdf() {
            this.salvarPdf(this.config.param.arquivo)
        }
    }
};
</script>
<style>
.moeda-tabela{
    text-align: right;
    border-block: 1px solid #000;
}

.cabecalho-grupo{
    display: flex;
}

.total-grupo{
    margin-left: auto;
}

.porc-grupo{
    margin-left: 15px;
    width: 90px;
    text-align: right;
}

.containerPdf{
    /* width: 713.70px; */
    width: 794px;
    /* border: 1px solid; */
    /* border-color:red; */
    padding-left: 20px;
    padding-right: 20px;
}

.myStyle {
   overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: keep-all;
    word-break: keep-all;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.rel-totais{
    background: rgb(192, 192, 192);
    color: #000 !important;
    font-weight: bold;
}
</style>