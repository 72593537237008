import axios from 'axios'

const success = res => res
const error = async err => {
    if (err.response.status) {
        if (err.response.status === 401) {
            localStorage.removeItem('__lancamentos_user')
            window.location.href = '/'
            return Promise.reject(err)
        } else {
            return Promise.reject(err)
        }
    } 
}

axios.interceptors.response.use(success, error)