import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted, {
    iconPack: 'fontawesome',
    duration: 6000,
    position: "top-right",
})

Vue.toasted.register(
    'defaultSuccess',
    payload => !payload.msg ? 'Operação realidada com sucesso!' : payload.msg,
    { type: 'success', icon: 'check' }
)

Vue.toasted.register(
    'defaultError',
    payload => !payload.msg ? 'Oops.. Erro inesperado.' : payload.msg,
    { type : 'error', icon : 'times' }
)

Vue.toasted.register(
    'defaultAlert',
    payload => !payload.msg ? 'Antenção...' : payload.msg,
    { type : 'info', icon : 'fa-exclamation-triangle '  }
)


// outros

Vue.toasted.register(
    'nadaEncontrado',
    payload => !payload.msg ? 'Nada encontrado.' : payload.msg,
    { type : 'error', icon : 'times' }
)

Vue.toasted.register(
    'Internal Server Error',
    payload => !payload.msg ? 'Oops.. Erro inesperado.' : payload.msg,
    { type : 'error', icon : 'times' }
)

Vue.toasted.register(
    'valorFatura',
    payload => !payload.msg ? 'Informe o valor da fatura e condição de recebimento.' : payload.msg,
    { type : 'error', icon : 'times' }
)

Vue.toasted.register(
    'nenhumaFatura',
    payload => !payload.msg ? 'Selecione ao menos uma fatura.' : payload.msg,
    { type : 'error', icon : 'times' }
)