<template>
    <!-- <div id="app" :class="{'hide-menu': !isMenuVisible || !user}"> -->
	<div id="app" 
        :class="{
            'hide-menu': !isMenuVisible || !user || user.alterSenha,
            'compact-menu': isMenuVisible && user && isMenuCompact
        }">

		<Header v-if="user && !user.alterSenha"/>

        <Menu 
            v-if="user && !user.alterSenha" 
            :hideToggle="!user"
            :hideUserDropdown="!user"
        />
        <Loading v-if="validatingToken" />
        <Content v-else />
        <Footer v-if="user && !user.alterSenha"/>
        
	</div>
</template>

<script>
import axios from "axios"
import { baseApiUrl, userKey } from "@/global"
import { mapState } from 'vuex'
import Header from "@/components/template/Header.vue"
import Menu from "@/components/template/Menu.vue"
import Content from "@/components/template/Content.vue"
import Footer from "@/components/template/Footer.vue"
import Loading from "@/components/template/Loading.vue"

export default {
	name: "App",
    hiddenConsulta: true,
    components: { Header, Menu, Content, Footer, Loading },
    computed: mapState(['isMenuVisible', 'isMenuCompact', 'user', 'main', 'sub', 'consulta']),
    data: function() {
        return {
            validatingToken: true
        }
    },
    methods: {
		async validateToken() {
			this.validatingToken = true
			const json = localStorage.getItem(userKey)
			const userData = JSON.parse(json)
			this.$store.commit('setUser', null)
			if(!userData) {
				this.validatingToken = false
                if (this.$route.path != '/auth') {
                    this.$router.push({ name: 'auth' })
                }
				return
			}
			const res = await axios.post(`${baseApiUrl}/validateToken`, userData)
			if (res.data) {
				this.$store.commit('setUser', userData)
				
				if(this.$mq === 'xs' || this.$mq === 'sm') {
					this.$store.commit('toggleMenu', false)
				}
			} else {
				localStorage.removeItem(userKey)
				if (this.$route.path != '/auth') {
                    this.$router.push({ name: 'auth' })
                }
			}
			this.validatingToken = false
		}          
	},
	created() {
		this.validateToken()
	}
}
</script>

<style>
    * {
        font-family: "Lato", sans-serif;
    }

    .button-icons-acoes {        
        font-size: 1.4rem;
        margin: -5px -10px 0 -10px;
    }

    .botao-consulta {
        margin: 0 !important;     
        margin-top: 24px !important;
        max-height: 100px !important;        
        border-color: #8d8dfd !important;
        color: #8d8dfd !important;
        font-size: 2.2rem !important;
        padding: 15px 30px 15px 35px !important;      
    }

    .botao-consulta:hover {   
        border-color: #ffffff !important;
        color: #ffffff !important;     
    }

    body {
        margin: 0;         
    }
    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        height: 100vh;
        display: grid;
        grid-template-rows: 70px 1fr 40px;
        grid-template-columns: 250px 1fr;
        grid-template-areas: 
            "header header"
            "menu content"
            "menu footer";        
    }
    #app.hide-menu {
        grid-template-areas: 
            "content content"
            "content content"
            "content content";
    }
    #app.compact-menu {
       grid-template-columns: 78px 1fr; 
    }
    
    .acoesTopo {
        display: flex !important;
        flex-direction: row-reverse !important;
        align-content: flex-end !important;
        padding: 0 20px 0 10px !important;
    }

    .cadastros-card-pesquisa {
        display: flex !important;
        flex-direction: row !important;
        align-items: baseline !important;
        padding: 0 10px 0 10px !important;
        margin-bottom: 10px;
    }

    .botoesAcao {
        color: #ffffff !important;       
        font-family: 'Poppins', sans-serif !important; 
        line-height: 0.9rem !important;
        font-size: 0.9rem !important; 
        padding: 15px 30px 15px 30px !important;        
        height: 45px !important;
        margin-top: -70px;
        margin-right: 10px !important;
    }

    .acoesRodape {
        display: flex !important;
        flex-direction: row-reverse !important;
        align-content: flex-end !important;
    }

    .botoesAcaoRodape {
        color: #ffffff !important;       
        font-family: 'Poppins', sans-serif  !important; 
        line-height: 0.9rem !important;
        font-size: 0.9rem !important; 
        padding: 15px 30px 15px 30px !important;
        height: 45px !important;
        margin-right: 10px !important;        
    }
    
    /* CORES DOS BOTOES */
    .btn-primary {
        background-color: #5d5cfc !important;
        border-color: #5d5cfc!important;
        color: #ffffff !important;
        box-shadow: none !important;
    }
    .btn-primary:hover {
        background-color: #4747f1 !important;
        border-color: #4747f1 !important;
        color: #ffffff
    }

    .btn-info {
        background-color: #8d8dfd !important;
        border-color: #8d8dfd !important;
        color: #ffffff !important;
        box-shadow: none !important;
    }
    .btn-info:hover {
        background-color: #7b7be4 !important;
        border-color: #7b7be4 !important;
        color: #ffffff !important;
    }
    .btn-danger {
        background-color: #f93b69 !important;
        border-color: #f93b69 !important;
        color: #ffffff !important;
        box-shadow: none !important;
    }
    .btn-danger:hover {
        background-color: #d6345d !important;
        border-color: #d6345d !important;
        color: #ffffff !important;
    }
    .btn-success {
        background-color: #5dc289 !important;
        border-color: #5dc289 !important;
        color: #ffffff !important;
        box-shadow: none !important;
    }
    .btn-success:hover {
        background-color: #48b376!important;
        border-color: #48b376 !important;
        color: #ffffff !important;
    }
    .btn-warning {
        background-color: #ffcc30 !important; 
        border-color: #ffcc30 !important;
        color: #ffffff !important;
        box-shadow: none !important;
    }
    .btn-warning:hover {
        background-color: #cfa31c !important;
        border-color: #cfa31c !important;
        color: #ffffff !important;
    }
    .btn-secondary {
        background-color: #6c757d !important;
        border-color: #6c757d !important;
        color: #ffffff !important;
        box-shadow: none !important;
    }
    .btn-secondary:hover {
        background-color: #5a6268 !important;
        border-color: #5a6268 !important;
        color: #ffffff !important;
    }

    .botoes-de-impressao-download {
        margin: 0 0 0 5px;
    }

    .botoes-de-impressao-download i {
        margin: 5px;
        font-size: 1.5rem;
    }

    
</style>