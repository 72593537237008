<template>
    <div class="cidades-pages"> 
        <PageTitle icon="fa fa-map-signs" main="Cidades"
            sub="Cadastros" />        
        <CadastroCidades />                    
    </div>
</template>

<script>

import PageTitle from '@/components/template/PageTitle'
import CadastroCidades from './CadastroCidades.vue'

export default {
    name: 'CidadesRelatorio',
    components: { PageTitle, CadastroCidades }  
}
</script>

<style>

</style>