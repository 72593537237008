<template>
    <div class="gerar-faturas-pages">
        <PageTitle icon="fa fa-files-o" main="Gerar faturas de contratos"
            sub="Gera as faturas a receber conforme condições informadas nos contratos" />
        
        <GerarFaturas />   
    </div>
</template>

<script>
import PageTitle from '@/components/template/PageTitle'
import GerarFaturas from '@/components/contratos/gerarFaturas/GerarFaturas.vue'

export default {
    name: 'GerarFaturasPages',
    components: { PageTitle, GerarFaturas }
}
</script>

<style>

</style>
