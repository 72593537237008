<template>
    <div class="user-dropdown" style="z-index: 100">
        <div class="user-button">
            <div class="user-name-top">                
                <div>
                    <!-- <span style="margin: 0 10px -5px 0" class="d-none d-sm-block">{{ nomeUser }}</span>  -->
                    <b-button 
                        v-b-toggle.sidebar-right-usuario 
                        size="sm" 
                        style="margin: 0 10px -5px 0" 
                        class="d-none d-sm-block" 
                        variant="link" 
                        @click="loadUsuario"
                    >
                       <h5 style="margin: 0">{{ nomeUser }}</h5> 
                    </b-button>

                </div>

                <b-dropdown size="sm" variant="link" id="dropdown-form" :text="nomeFilial" ref="dropdown">
                    <b-dropdown-item-button :id="`botao-user-filial-${f.value}`" @click="selectFilial(f.value)" v-for="(f, value) in filiais" v-bind:key="value">
                        {{f.text}}
                    </b-dropdown-item-button>
                </b-dropdown>                
            </div>

            <div class="user-buttom-top">
                <b-button variant="light" style="height: 100%" id="nome-user-bt"><i class="fa fa-chevron-down"></i></b-button>                    

                <div class="user-dropdown-content">
                    <router-link to="/admin" v-if="user.superv === 'S'">
                        <i class="fa fa-cogs"></i> Administração
                    </router-link>

                    <a href v-b-modal.modal-logout @click.prevent ><i class="fa fa-sign-out"></i> Sair</a>
                    <b-modal id="modal-logout" title="Logout">
                        <p class="my-4">Deseja realmente sair do sistema?</p>
                        <template #modal-footer>
                            <div class="w-100">                    
                                <b-button
                                    variant="primary"
                                    size="md"
                                    class="float-right ml-3"
                                    @click="logout"                            
                                >
                                    Sim
                                </b-button>
                                <b-button
                                    variant="danger"
                                    size="md"
                                    class="float-right"
                                    @click="$bvModal.hide('modal-logout')"
                                >
                                    Não
                                </b-button>
                            </div>
                        </template>

                    </b-modal>
                </div>
            </div>

        </div>

        <b-sidebar class="menu-user-lateral" id="sidebar-right-usuario" title="" width=500px right shadow backdrop z-index="100">
           
            <div class="px-3 py-2">               
                
                <b-button-toolbar>  
                    <b-button-group class="mx-1"> 
                        <b-button v-if="!editUsuario && !alterarSenha" @click="editUsuario = true" variant="link" size="sm">Editar</b-button>
                        <b-button v-if="!editUsuario && !alterarSenha" @click="alterarSenha = true" variant="link" size="sm">Alterar Senha</b-button>
                        <b-button v-if="editUsuario || alterarSenha" @click="salvarUsuario()" variant="success" size="sm">Salvar</b-button>
                        <b-button v-if="editUsuario || alterarSenha" @click="resetUsuario" variant="danger" size="sm">Cancelar</b-button>
                    </b-button-group>
                </b-button-toolbar>
                <hr>

                <div v-if="!editUsuario && !alterarSenha">
                    <h5><strong>{{usuario.nome}}</strong></h5>
                    <p>({{usuario.email}})</p>
                    <hr>
                    <h5>E-mail de envio: <strong>{{usuario.email_user}}</strong></h5>
                                
                </div>

                <div v-if="editUsuario">
                    <b-row>                        
                        <b-col md="12" sm="12">
                            <h4>Editar Usuário</h4>
                            <b-form-group label="Nome" label-for="usuario-nome">
                                <b-form-input id="usuario-nome" type="text"
                                    :formatter="upperCase"
                                    v-model="usuario.nome" required />
                            </b-form-group>
                            <b-form-group label="E-mail" label-for="usuario-email">
                                <b-form-input id="usuario-email" type="email" 
                                    :formatter="lowerCase"
                                    v-model="usuario.email" required />
                            </b-form-group>
                            
                        </b-col>            
                    </b-row>

                    <b-row>  
                        <b-col md="12" sm="12">
                            <b-form-checkbox 
                                value="S" 
                                unchecked-value="N" 
                                id="config-email-ativa"  
                                v-model="usuario.email_ativar_envio" 
                                switch
                            >
                                Ativar e-mail pessoal
                            </b-form-checkbox>
                        </b-col>

                        <b-col md="12" sm="12" v-if="usuario.email_ativar_envio === 'S'">
                            <hr>
                            <h4>E-mail de Envio</h4>
                            <b-form-group label="Usuário" label-for="config-email-user"> 
                                <b-form-input size="sm" id="config-email-user" type="email" v-model="usuario.email_user" />
                            </b-form-group>

                            <b-form-group label="Senha" label-for="config-email-pass"> 
                                <b-form-input size="sm" id="config-email-pass" type="password" v-model="usuario.email_pass" />
                            </b-form-group>

                            <b-form-group label="Responder para" label-for="config-email-reply"> 
                                <b-form-input size="sm" id="config-email-reply" type="email" v-model="usuario.email_user_reply" />
                            </b-form-group>

                            <hr>

                            <b-form-group label="Servidor" label-for="config-email-host"> 
                                <b-form-input size="sm" id="config-email-host" type="text" v-model="usuario.email_host" />
                            </b-form-group>
                            
                            <b-form-group label="Porta" label-for="config-email-port"> 
                                <b-form-input size="sm" id="config-email-port" type="number" v-model="usuario.email_port" />
                            </b-form-group>

                            <b-form-group label="Protocolo" label-for="radio-slots-protocol"> 
                                <b-form-radio-group 
                                    id="radio-slots-protocol"
                                    v-model="usuario.email_protocol"
                                    name="config-email-user"
                                >
                                    <b-form-radio value="SSL">SSL</b-form-radio>
                                    <b-form-radio value="TLS">TLS</b-form-radio>
                                    <b-form-radio value="AMBOS">Ambos</b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                        </b-col>
                    </b-row>

                </div>

                <div v-if="alterarSenha">
                    <b-col md="12" sm="12">
                        <h4>Alterar Senha</h4>
                        <b-form-group label="Senha atual" label-for="usuario-senha-atual">
                            <b-form-input id="usuario-senha-atual" type="password"
                                v-model="usuario.senha" required />
                        </b-form-group>

                        <hr>

                        <b-form-group label="Nova senha" label-for="usuario-senha">
                            <b-form-input id="usuario-senha" type="password"
                                v-model="usuario.nova_senha" required />
                        </b-form-group>
                        
                        <b-form-group label="Confirmação de senha" label-for="usuario-conf-senha">
                            <b-form-input id="usuario-conf-senha" type="password"
                                v-model="usuario.confirmar_senha" required />
                        </b-form-group>
                    </b-col>          
                </div>

            </div>
            
        </b-sidebar>
        
    </div>
</template>

<script>
import { baseApiUrl, userKey, showError } from '@/global'
import { mapState } from 'vuex'
import axios from 'axios'
import { showSuccess } from '../../global'

export default {
    name: 'UserDropdown',
    computed: mapState(['user']),
    data: function() {
        return {
            showMenuFiliais: false,
            filiais: [] ,
            filial: 0,
            nomeFilial: '',
            nomeUser: '',
            idUser: 0,
            usuario: {},
            editUsuario: false,
            alterarSenha: false                
        }
    },
    methods: {
        loadUsuario() {
            this.editUsuario = false
            this.alterarSenha = false  

            const url = `${baseApiUrl}/usuarios/pessoal` 

            axios.get(url).then(res => { 
                this.usuario = { ...res.data }
            }) 
            .catch(showError) 
        },

        salvarUsuario() {
            if (this.alterarSenha) {
                this.usuario.alterarSenha = true
            } else {
                delete this.usuario.alterarSenha
                delete this.usuario.senha
                delete this.usuario.nova_senha
                delete this.usuario.confirmar_senha
            }

            axios['put'](`${baseApiUrl}/usuarios/pessoal`, this.usuario)
            .then(() => {
                showSuccess('Usuário alterado com sucesso.')
                
                this.editUsuario = false
                this.alterarSenha = false    
            })
            .catch(err => {
                showError(err)
            })
            
        },

        resetUsuario() {
            this.editUsuario= false
            this.alterarSenha= false   
        },

        logout() {
            localStorage.removeItem(userKey)
            this.$store.commit('setUser', null)
            this.$router.push({ name: 'auth' })
        },

        loadFiliais() {
            const s =  JSON.parse(localStorage.getItem('__lancamentos_user')) 
            const nm = s.nome.split(' ') 
            this.nomeUser = `${nm[0]}${nm.length > 1 ? ' '+nm[nm.length - 1] : '' }`
            this.idUser = 
            this.filial = s.idfilial

            const url = `${baseApiUrl}/filiais`
            axios.get(url).then(res => {
                this.filiais = res.data.map(filial => {
                    return { ...filial,  value: filial.idfilial, text: `${filial.idfilial} | ${filial.razao_social}`}
                })

                const m = this.filiais.find(obj => {
                    return obj.value == this.filial;
                });

                this.nomeFilial = m.text
            })
            .catch(err => {
                showError(err)
            }) 
        },

        showFiliais() {
            this.showMenuFiliais = true
        },
        
        selectFilial(id) {
            this.filial = id
            let s =  JSON.parse(localStorage.getItem('__lancamentos_user')) 
            s.idfilial = id

            localStorage.setItem('__lancamentos_user', JSON.stringify(s))

            const m = this.filiais.find(obj => {
                return obj.value == id
            });

            this.nomeFilial = m.text

            document.getElementById(`botao-user-filial-${id}`).addEventListener("click", () => {
                document.getElementById("nome-user-bt").focus();
            })

        }
    },

    mounted() {
        this.loadFiliais()
    }
}
</script>

<style>
    .user-dropdown {
        position: relative;
        height: 100%;
    }

    .user-button {
        display: flex;
        align-items: center;
        color: rgb(102, 101, 101);
        font-weight: 100;
        height: 100%;
        padding: 0px 10px;
    }

    .user-dropdown:hover {
        background-color: rgba(0, 0, 0, 0.2);
    }

    .user-buttom-top {
        margin-left: 10px;
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 25px; 
        font-size: 1.3rem;
    }

    .user-buttom-top:hover {
        color: rgba(0, 0, 0, 0.575)
    } 

    .user-buttom-top:hover .user-dropdown-content {
        visibility: visible;
        opacity: 1;
    }

    .user-dropdown-content {
        margin-top: 45px;
        font-size: 1rem;
        position: absolute;
        right: 0px;
        background-color: #f9f9f9;
        min-width: 170px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        padding: 10px;
        z-index: 1;

        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear;
    }  

    .user-dropdown-content a {
        text-decoration: none;
        color: #000;
        padding: 10px;
    }

    .user-dropdown-content a:hover {
        text-decoration: none;
        color: #000;
        background-color: #EDEDED;
    }

    .user-name-top {
        margin-right: 10px;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        font-size: 1.1rem;        
    }

    .menu-user-lateral {
        z-index: 99 !important;
        display: flex !important;
        position:  absolute !important;
    }

</style>
