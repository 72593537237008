<template>
    <div class="cadastro-produtos">
        <b-card class="cadastros-card-pesquisa" style="min-height: 500px" v-show="show">
            <b-row>
                <b-col md="4" sm="12" class="cadastros-pesquisa-coluna">
                    <b-card title="E-mail de Envio" sub-title="Configura as contas responsáveis por envio de e-mails">
                        <b-form-group>
                            
                            <b-form-group label="Filial" label-for="config-email-filiais"> 
                                <b-form-select size="sm" id="config-email-filiais" v-model="email.id_filial" :options="filiais" />
                            </b-form-group>
                            
                            <b-form-group label="Usuário" label-for="config-email-user"> 
                                <b-form-input size="sm" id="config-email-user" type="email" v-model="email.email_user" />
                            </b-form-group>
                            
                            <b-form-group label="Senha" label-for="config-email-pass"> 
                                <b-form-input size="sm" id="config-email-pass" type="password" v-model="email.email_pass" />
                            </b-form-group>
                            
                            <b-form-group label="Responder para" label-for="config-email-reply"> 
                                <b-form-input size="sm" id="config-email-reply" type="email" v-model="email.email_user_reply" />
                            </b-form-group>
                            <hr>
                            
                            <b-form-group label="Servidor" label-for="config-email-host"> 
                                <b-form-input size="sm" id="config-email-host" type="text" v-model="email.email_host" />
                            </b-form-group>
                            
                            <b-form-group label="Porta" label-for="config-email-port"> 
                                <b-form-input size="sm" id="config-email-port" type="number" v-model="email.email_port" />
                            </b-form-group>
                            
                            <b-form-group label="Protocolo" label-for="radio-slots-protocol"> 
                                <b-form-radio-group 
                                    id="radio-slots-protocol"
                                    v-model="email.email_protocol"
                                    name="config-email-user"
                                >
                                    <b-form-radio value="SSL">SSL</b-form-radio>
                                    <b-form-radio value="TLS">TLS</b-form-radio>
                                    <b-form-radio value="AMBOS">Ambos</b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                            <br>

                            <b-button  class="mb-3" variant="success" @click="saveEmail">Salvar</b-button>
                            <br>

                            <b-table hover small borderless striped :items="emails" :fields="fieldsEmails">  
                                <template v-slot:cell(actions)="data">
                                    <b-button size="sm" variant="warning" @click="loadEmail(data.item)" class="mr-2">
                                        <i class="fa fa-pencil"></i>
                                    </b-button>
                                    <b-button size="sm"  variant="danger" @click="removeEmail(data.item, 'remove')">
                                        <i class="fa fa-trash"></i>
                                    </b-button>
                                </template>
                            </b-table>

                        </b-form-group>
                    </b-card>
                </b-col>

                <b-col md="4" sm="12" class="cadastros-pesquisa-coluna">
                    <b-card title="Plano de Contas (Venda)" sub-title="Define qual será o plano de contas padrão para vendas">
                        <b-form-group>
                            <b-form-select id="conta-receber-plano-conta" v-model="configuracao.id_plano_conta_venda" :options="planoContas" />
                        </b-form-group>
                        
                        <router-link to="/financeiro/planoDeContas">Plano de Contas</router-link>
                        <br>

                        <b-button class="mt-3" variant="success" @click="save">Salvar</b-button>
                    </b-card>

                </b-col> 

            </b-row>
            
        </b-card>   
    </div>
</template>

<script>
import { baseApiUrl, showError, showSuccess} from '@/global'
import axios from 'axios'

export default {
    name: 'CadastroConfig',
    data: function() {
        return {
            mode: 'save',
            email: {
                id_filial: 0,
                email_user: '',
                email_pass: '',
                email_user_reply: '',
                email_host: '',
                email_port: '',
                email_protocol: 'SSL'
            },
            emails: [],
            filiais: [],
            configuracao: {
                id_configuracao: 0,
            },           
            show: true, 
            planoContas: [],  
            fieldsEmails: [
                {key: 'id_filial', label: 'Filial', sortable: true},
                {key: 'email_user', label: 'E-mail', sortable: true},
                {key: 'actions', label: 'Ações'}
            ],  
        }
    },  
    methods: {        
        saveEmail() {  
            const method = this.email.id_email_config ? 'put' : 'post'
            const idEmail = this.email.id_email_config ? `/${this.email.id_email_config}` : ''

            axios[method](`${baseApiUrl}/email/config${idEmail}`, this.email)
            .then(() => {
                showSuccess('Configuração de e-mail salva com sucesso.')
                this.resetEmail()
                this.loadEmails()
            })
            .catch(err => {
                showError(err)
            })  
        },

        resetEmail() {  
            this.email = {
                id_filial: 0,
                email_user: '',
                email_pass: '',
                email_user_reply: '',
                email_host: '',
                email_port: '',
                email_protocol: 'SSL'
            }
        },

        loadEmail(e) {
            const url = `${baseApiUrl}/email/config/${e.id_email_config}`
            axios.get(url).then(res => {
                this.email = res.data
            })
            .catch(err => {
                showError(err)
            }) 
        },

        removeEmail(e) {
            const url = `${baseApiUrl}/email/config/${e.id_email_config}`
            axios.delete(url).then((res) => {
                showSuccess(res.message)
                this.loadEmails()
            })
            .catch(err => {
                showError(err)
            }) 
        },

        loadFiliais() {
            const url = `${baseApiUrl}/filiais`
            axios.get(url).then(res => {
                this.filiais = res.data.map(filial => {
                    return { ...filial,  value: filial.idfilial, text: `${filial.idfilial} | ${filial.razao_social}`}
                })
            })
            .catch(err => {
                showError(err)
            }) 
        },

        loadEmails() {
            const url = `${baseApiUrl}/email/config`
            axios.get(url).then(res => {
                this.emails = res.data
            })
            .catch(err => {
                showError(err)
            }) 
        },

        loadPlanoContas() {
            const url = `${baseApiUrl}/plano_contas/?tipo=R`
            axios.get(url).then(res => {
                this.planoContas = res.data.map(planoConta => {
                    return { ...planoConta, value: planoConta.id_plano_conta, text: `${planoConta.id_plano_conta} | ${planoConta.descricao}` }
                })
            })
        },

        loadConfiguracao() { 
            const url = `${baseApiUrl}/empresas/config`  

            axios.get(url).then(res => {
                this.configuracao = res.data
            })
            .catch(err => {
                showError(err)
            })        
        },

        save() {  
            axios['post'](`${baseApiUrl}/empresas/config`, this.configuracao)
            .then(() => {
                this.$toasted.global.defaultSuccess()
            })
            .catch(err => {
                showError(err)
            }) 
        }
    },
    mounted() {
        this.loadConfiguracao(),
        this.loadPlanoContas(),
        this.loadFiliais(),
        this.loadEmails()
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.7rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

    



</style>