<template>
    <div class="produtos-grupos-pages">
        <PageTitle icon="fa fa-shopping-bag" main="Grupos de Produtos"
            sub="Cadastros" />
        
        <GruposProdutos />   
    </div>
</template>

<script>
import PageTitle from '@/components/template/PageTitle'
import GruposProdutos from '../grupos/GruposProdutos.vue'

export default {
    name: 'GruposPages',
    components: { PageTitle, GruposProdutos }
}
</script>

<style>

</style>