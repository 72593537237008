<template>
    <div class="cadastro-plano-contas">
        
        <div v-if="show">
            <b-row align-v="center">
                <b-col class="d-flex justify-content-md-center">
                    <b-button-toolbar>                                                               
                        <b-button-group class="mx-1">
                            <b-input-group>                                                               
                                <b-form-radio-group
                                    class="mr-2"
                                    id="btn-radios-tipo-dre"
                                    v-model="ativos"
                                    :options="optionsStatus"
                                    button-variant="outline-secondary"
                                    name="radios-btn-tipo-dre"
                                    buttons
                                    @change="novaConsulta"
                                ></b-form-radio-group>
                            </b-input-group>

                            <b-input-group>
                                <b-form-input 

                                    id="localizar" 
                                    type="search"
                                    :formatter="upperCase"
                                    v-model="parcial"
                                    :readonly="mode === 'remove'"
                                    placeholder="Pesquisar..." 
                                />
                                <b-input-group-append>
                                    <b-button @click="novaConsulta" variant="primary"><i class="fa fa-search button-icons"></i></b-button>
                                </b-input-group-append>
                            </b-input-group>

                        </b-button-group>

                        <b-button-group class="mx-1">    
                            <b-button style="width: 100px" class="cadastros-button-novo" variant="primary" 
                                @click="novoPlano()">
                                    <i class="fa fa-plus button-icons"></i>
                                    Novo
                            </b-button>
                        </b-button-group> 
                    </b-button-toolbar>
                </b-col>    
            </b-row>  
            <br> 

            <b-table hover striped small sticky-header="40vh" :items="planoContas" :fields="fields">  
                <template v-slot:cell(actions)="data">
                    <b-button size="sm" variant="warning" @click="loadPlanoConta(data.item)" class="mr-2">
                        <i class="fa fa-pencil"></i>
                    </b-button>
                    <b-button size="sm" variant="danger" @click="loadPlanoConta(data.item, 'remove')">
                        <i class="fa fa-trash"></i>
                    </b-button>
                </template>
            </b-table>
            <hr>

            <b-pagination 
                align="center" 
                size="sm" 
                v-model="page"  
                :total-rows="count" 
                :per-page="limit" 
                first-number 
                last-number>
            </b-pagination>     
        </div>

        <b-card class="cadastros-card-corpo" v-show="!show">
            <b-form>
                <input id="plano-conta-id" type="hidden" v-model="planoConta.id_plano_conta" />

                <b-row>
                    <b-col md="4" sm="12">
                        <b-form-group label="Grupo de conta" label-for="grupo-plano-conta">
                            <b-input-group>                                        
                                <vue-single-select
                                    class="btn-condicao"
                                    id="grupo-plano-conta"
                                    placeholder="Procurar"
                                    v-model="grupoSelecionado"
                                    :options="planoContasGrupos"
                                    option-key="value"
                                    option-label="text"
                                    :get-option-description="label"
                                    :get-option-value="value"
                                    :disabled="mode === 'remove'"
                                ></vue-single-select>

                                <b-input-group-append>
                                    <b-button style="height: 38px" @click="loadPlanoContasGrupos()" variant="info" v-b-tooltip.hover.bottomleft="'Atualizar grupos'"><i class="fa fa-refresh"></i></b-button>
                                </b-input-group-append>
                            </b-input-group>                                    
                        </b-form-group>
                    </b-col>

                    <b-col md="5" sm="12">
                        <b-form-group label="Descrição" label-for="plano-conta-descricao">
                            <b-form-input id="plano-conta-descricao" type="text"
                                v-model="planoConta.descricao" required
                                :readonly="mode === 'remove'"
                                placeholder="Informe a descrição do plano..." />
                        </b-form-group>
                    </b-col> 
                    <b-col md="2" sm="12">
                        <b-form-group label="Tipo DRE" label-for="tipo-dre">
                            <b-form-select :disabled="mode === 'remove'" id="tipo-dre" v-model="planoConta.tipo_dre" :options="optionsTipo" />
                        </b-form-group>
                    </b-col>  
                    <b-col md="2" sm="12">
                        <b-form-group :disabled="mode === 'remove'" label="Tipo"  id="plano-tipo">
                            <b-form-radio @change="setaTipo()" v-model="planoConta.receita_despesa" name="radio-tipo" value="R">Receita</b-form-radio>
                            <b-form-radio @change="setaTipo()" v-model="planoConta.receita_despesa"  name="radio-tipo" value="D">Despesa</b-form-radio>
                        </b-form-group>
                    </b-col>                                    
                </b-row>             

                <b-card class="cadastros-opcoes-row-card">
                    <b-row class="cadastros-opcoes-row" align-h="around">                        
                        <b-col md="2" sm="12">
                            <b-form-checkbox id="plano-conta-ativo" v-show="mode === 'save'" v-model="planoConta.ativo" class="mt-3 mb-3" switch>Ativo</b-form-checkbox>
                        </b-col>                      
                    </b-row>
                </b-card>
                
                <br>
                <hr>
                <b-row>
                    <b-col md="10" sm="10">
                        <b-button variant="primary" v-if="mode === 'save'"
                            @click="save">Salvar</b-button>
                        <b-button variant="danger" v-if="mode === 'remove'"
                            @click="remove">Excluir</b-button>
                        <b-button class="ml-2" @click="reset">Cancelar</b-button>
                    </b-col>

                    <b-col md="2" sm="2" class="d-flex justify-content-end">                        

                        <i id="popover-target-plano-conta"  class="fa fa-question-circle info-alteracoes"></i>

                        <b-popover target="popover-target-plano-conta" triggers="hover" placement="top">
                            <template #title>Alterações</template>
                            <b>Usuário Cadastro:</b> {{planoConta.usu_cad}} <br>
                            <b>Data de Cadastro:</b> {{planoConta.data_cad}} <br>
                            <b>Usuário Alteração:</b> {{planoConta.usu_alt}} <br>
                            <b>Data de Alteração:</b> {{planoConta.data_alt}}
                        </b-popover>                        
                    </b-col>

                </b-row>

            </b-form>
        </b-card> 
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'
import VueSingleSelect from "vue-single-select"

export default {
    name: 'CadPlanoContas',
    components: { VueSingleSelect },
    data: function() {
        return {
            teste: 'teste',
            mode: 'save',
            planoConta: {
                receita_despesa: 'D',
                tipo_dre: 'V',
                ativo: true
            },
            grupoSelecionado: '',
            planoContas: [],
            planoContasGrupos: [],
            page: 1,
            count: 0,
            limit: 0,
            parcial: '',
            ativos: 'S',
            show: true,
            fields: [
                {key: 'id_plano_conta', label: 'Código', sortable: true},
                {key: 'descricao', label: 'Descrição', sortable: true},
                {key: 'receita_despesa', label: 'Rec / Desp', sortable: true, formatter: value => value ==='R' ? 'Receita' : 'Despesa'},
                {key: 'ativo', label: 'Ativo', sortable: false, formatter: value => value ==='S' ? 'Sim' : 'Não'},
                {key: 'actions', label: 'Ações'}
            ],
            optionsTipo: [
                { value: 'F', text: 'Fixa' },
                { value: 'V', text: 'Variável', disabled: false },
                { value: 'E', text: 'Extra Operacional' },
                { value: 'I', text: 'Investimento' },
                { value: 'N', text: 'Nenhum' }
            ],
            
            optionsStatus: [
                { text: 'Ativos', value: 'S' },
                { text: 'Inativos', value: 'N' },
                { text: 'Todos', value: 'T' }
            ]
        }
    },
    methods: {
        novoPlano() {
            this.loadPlanoContasGrupos()
            this.show = !this.show
        },
        label(option){
            return option.text
        },
        value(option){
            return option.value
        },
        setaTipo() {
            if (this.planoConta.receita_despesa === 'R') {
                this.optionsTipo[1].disabled = true
                this.planoConta.tipo_dre = 'F'
            } else {
                this.optionsTipo[1].disabled = false
            }

            this.teste = this.variavelDisabled
        },
        upperCase(value) {
            return value.toUpperCase()
        },
        lowerCase(value) {
            return value.toLowerCase()
        },
        novaConsulta() {
            this.page = 1 
            this.loadPlanoContas()
        },
        loadPlanoContas() { 
            const parc = (this.parcial.trim().length > 0) ? this.parcial : 'xxxxxxxxxx' 
            const url = `${baseApiUrl}/plano_contas/consulta/${parc}/ativos/${this.ativos}/?page=${this.page}` 
            
            axios.get(url).then(res => {
                this.loadPlanoContasGrupos()
                this.planoContas = res.data.data
                this.count = res.data.count 
                this.limit = res.data.limit
            })
            .catch(err => {
                showError(err)
            })
        },
        loadPlanoContasGrupos() {
            const url = `${baseApiUrl}/plano_contas_grupos`
            axios.get(url).then(res => {
                this.planoContasGrupos = res.data.map(planoContaGrupo => {
                    return { ...planoContaGrupo, value: planoContaGrupo.id_plano_conta_grupo, text: `${planoContaGrupo.id_plano_conta_grupo} | ${planoContaGrupo.descricao}` }
                })
            })
        },
        reset() {
            this.mode = 'save'

            this.planoConta = {
                receita_despesa: 'D',
                tipo_dre: 'V',
                ativo: true
            }

            this.loadPlanoContas()
            this.show = !this.show
        },
        save() {
            const method = this.planoConta.id_plano_conta ? 'put' : 'post'
            const idplanoconta = this.planoConta.id_plano_conta ? `/${this.planoConta.id_plano_conta}` : ''
            this.planoConta.ativo == true ? this.planoConta.ativo='S' : this.planoConta.ativo='N'           
            
            //TEMPORÁRIO!
            this.planoConta.usu_cad = 1
            this.planoConta.id_filial = 2

            axios[method](`${baseApiUrl}/plano_contas${idplanoconta}`, this.planoConta)
            .then(() => {
                this.$toasted.global.defaultSuccess()
                this.reset()
            })
            .catch(showError)
        },
        remove() {
            const idplanoconta = this.planoConta.id_plano_conta
            axios.delete(`${baseApiUrl}/plano_contas/${idplanoconta}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        loadPlanoConta(planoConta, mode = 'save') {
            this.mode = mode
            this.planoConta = { ...planoConta }
            this.planoConta.ativo==='S' ? this.planoConta.ativo=true : this.planoConta.ativo= false

            this.grupoSelecionado= {value: this.planoConta.id_plano_conta_grupo, text: `${this.planoConta.id_plano_conta_grupo} | ${this.planoConta.desc_grupo}`}

            this.show = !this.show
        }
    },
    watch: {
        page() {
            this.loadPlanoContas()
        },
        grupoSelecionado() {
            if (this.grupoSelecionado) {
                this.planoConta.id_plano_conta_grupo = this.grupoSelecionado.value
            } else {
                this.planoConta.id_plano_conta_grupo = ''
            }
        }
    },
    mounted() {
        this.loadPlanoContas(),
        this.loadPlanoContasGrupos() 
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    @keyframes rodar {
        0% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .atualizar:hover {
        animation: rodar 1s;
    }

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.7rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

</style>