<template>
    <div class="produtos-pages">
        <PageTitle icon="fa fa-search" main="Consulta Geral"
            sub="Resultados diversos" />

        <div>
            <b-tabs content-class="mt-3" v-model="tabIndex" @changed="tabIndex = 0">

                <b-tab title="Contas a Receber" active v-if="resultado ? ( resultado.contasReceber ? true : false ) : false">
                    <p>Faturas que possuem saldo a receber</p>
                    <b-table hover striped :items="resultado.contasReceber" :fields="fieldsContasReceber" sticky-header>  
                        <template v-slot:cell(actions)="data">
                            <b-button variant="success" @click="editarLancamento(data.item)" class="mr-2">
                                <i class="fa fa-pencil-square-o"></i>
                            </b-button>
                        </template>
                    </b-table>
                </b-tab>

                <b-tab title="Contas a Pagar" v-if="resultado ? ( resultado.contasPagar ? true : false ) : false">
                    <p>Faturas que possuem saldo a pagar</p>
                    <b-table hover striped :items="resultado.contasPagar" :fields="fieldsContasPagar" sticky-header>  
                        <template v-slot:cell(actions)="data">
                            <b-button variant="success" @click="editarLancamento(data.item)" class="mr-2">
                                <i class="fa fa-pencil-square-o"></i>
                            </b-button>
                        </template>
                    </b-table>
                </b-tab>


            </b-tabs>
        </div>
        
        
    </div>
</template>

<script>
import PageTitle from '../template/PageTitle'
import { mapState } from 'vuex'

export default {
    name: 'ConsultasPages',
    components: { PageTitle },
    computed: mapState(['user', 'consulta']),
    data: function() {
        return {
            resultado: {},
            tabIndex: 0,
            teste: 0,
            fieldsContasReceber: [
                {key: 'id_conta_receber', label: 'Fatura', sortable: false},
                {key: 'razao_social', label: 'Nome', sortable: false},
                {key: 'num_docto', label: 'Docto', sortable: false},
                {key: 'num_parc', label: 'Parcela', sortable: false},
                {key: 'valor', label: 'Valor', sortable: false, formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                        
                    }
                },
                {key: 'actions', label: 'Ações'}
            ],
            fieldsContasPagar: [
                {key: 'id_conta_pagar', label: 'Fatura', sortable: false},
                {key: 'razao_social', label: 'Nome', sortable: false},
                {key: 'num_docto', label: 'Docto', sortable: false},
                {key: 'num_parc', label: 'Parcela', sortable: false},
                {key: 'valor', label: 'Valor', sortable: false, formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                        
                    }
                },
                {key: 'actions', label: 'Ações'}
            ],
        }
    },
    methods: {
        async consultaGeral() {
            this.resultado = this.consulta 
        }
    } ,
    watch: {
        'consulta'() {
            this.consultaGeral()
        }
    },
}
</script>

<style>

</style>
