<template>
    <div class="faturas-receber-pages">
        <PageTitle icon="fa fa-usd" main="Faturas a receber"
            sub="Cadastro, Manutenção de Faturas e Geração de Boletos" />
        
        <CadastroFaturaReceber />  
    </div>
</template>

<script>
import PageTitle from '@/components/template/PageTitle'
import CadastroFaturaReceber from './CadastroFaturaReceber'

export default {
    name: 'FaturasReceberPages',
    components: { PageTitle , CadastroFaturaReceber },  
}
</script>

<style>

</style>
