<template>
    <div class="lancamentos">
        {{teste}}
        <div class="acoesTopo">             
            <b-button variant="secondary " class="botoesAcao" v-if="(mode === 'save') || (mode === 'remove')"
                @click="resetLancamento">
                    <i class="fa fa-times button-icons"></i>
                    Cancelar
            </b-button>
            <b-button variant="success" class="botoesAcao" v-if="mode === 'save'" 
                @click="salvarLancamento">
                    <i class="fa fa-check button-icons"></i>
                    Salvar
            </b-button>   
            <b-button variant="primary" class="botoesAcao" v-if="mode === 'new'"
                @click.stop="novoLancamento">
                    <i class="fa fa-plus button-icons"></i>
                    Novo
            </b-button>             
        </div>
        
        <b-card class="cadastros-card-pesquisa" v-if="show">
            
            <b-row align-v="center">
                <b-col md="3" sm="12"> 
                    <b-form-group>
                        Tipos 
                        <b-form-select id="pes-grupo-plano-conta" v-model="pesquisa.id_tipo" :options="tipos" />  
                    </b-form-group>                   
                </b-col>
                <b-col md="2" sm="12">
                    <b-form-group>
                        Data inicial 
                        <b-form-input id="pes-data-inicial" type="date"
                            v-model="pesquisa.dataInicial"
                            :readonly="mode === 'remove'" />
                    </b-form-group>
                </b-col>
                <b-col md="2" sm="12">
                    <b-form-group >
                        Data final 
                        <b-form-input id="pes-data-final" type="date"
                            v-model="pesquisa.dataFinal"
                            :readonly="mode === 'remove'" />
                    </b-form-group>
                </b-col>

                <b-col md="3" sm="12" class="radio-consulta">
                    <b-form-group  v-slot="{ ariaDescribedby }">
                        <b-form-radio-group 
                            id="pes-radio-slots-plano-contas"
                            v-model="pesquisa.status"
                            :aria-describedby="ariaDescribedby"
                            name="radio-options-slots-plano-contas"
                        >
                            <b-form-radio value="A">Ativos</b-form-radio>
                            <b-form-radio value="F">Encerrados</b-form-radio>
                            <b-form-radio value="T" selected>Todos</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>

                <b-col md="2" sm="12" class="d-flex justify-content-end">
                    <b-button variant="info" @click="novaConsulta">
                        <i class="fa fa-search button-icons"></i> Consultar
                    </b-button>
                </b-col>

                
            </b-row>
        </b-card>
        
        <b-card no-body v-show="!show">
            Lançamento V2
        </b-card>

        <!-- MODAL FATURA A RECEBER -->
        <b-modal 
            v-model="showReceita"
            id="modal-lg-receita" 
            centered size="lg" 
            ok-only 
            title="Contas a receber"
            header-bg-variant="success"
            header-text-variant="light"
            hide-footer
        >
            <CadastroFaturaReceber @salvar-receita="salvarReceita" :id_lanc="lancamento.id_lancamento"/>         
        </b-modal>

        <!-- MODAL FATURA PAGAR -->
        <b-modal 
            v-model="showDespesa"
            id="modal-lg-despesa" 
            centered size="lg" 
            ok-only 
            title="Contas a pagar"
            header-bg-variant="danger"
            header-text-variant="light"
            hide-footer
        >
            <CadastroFaturaPagar @salvar-despesa="salvarDespesa" :id_lanc="lancamento.id_lancamento"/>         
        </b-modal>

        <!-- MODAL NOVO LANÇAMENTO -->
        <b-modal 
            v-model="novoLancModal"
            id="modal-lg-despesa" 
            centered 
            size="xl" 
            ok-only             
            title="Novo Lançamento"
            body-class="p-0"
            hide-footer
        >
            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step
                        :complete="e1 > 1"
                        color="#5d5cfc"
                        step="1"
                        
                    >
                        <span class="ml-2">Informações Gerais</span>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        :complete="e1 > 2"
                        color="#5d5cfc"
                        step="2"
                    >                        
                        <span class="ml-2">Distribuição de Verba</span>
                    </v-stepper-step>                    <v-divider></v-divider>

                    <v-stepper-step
                        :complete="e1 > 3"
                        color="#5d5cfc"
                        step="3"
                    >                        
                        <span class="ml-2">Métricas de Companha</span>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step 
                        color="#5d5cfc"
                        step="4"
                    >
                        <span class="ml-2">Visão geral</span>
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <div class="stepper-content" style="padding: 5px">
                            <b-row>
                                <b-col md="4" sm="12">
                                    <span><strong>Nome do Lançamento</strong></span>
                                    <b-form-input id="1-lanc-descricao" type="text"
                                        v-model="lancamento.descricao"
                                        :readonly="mode === 'remove'" 
                                        :formatter="upperCase"
                                    />
                                </b-col>  
                                <b-col md="3" sm="12">
                                    <span><strong>Tipo</strong></span>
                                    <b-input-group id="1-tipo-lancamento" class="mt-0">
                                        <b-form-select v-model="lancamento.id_lancamento_tipo" :options="tipos" />
                                        <b-input-group-append>
                                            <b-button variant="success" v-b-modal.1-modal-tipos @click="loadCadTipos"><i class="fa fa-plus"></i></b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                    <!-- Cadastro de tipos -->
                                    <b-modal 
                                        hideBvModalEvent
                                        v-model="showModal"                        
                                        centered 
                                        ok-only
                                        ok-disabled
                                        title="Cadastrar tipo de Lançamento"
                                        header-bg-variant="success"
                                        header-text-variant="light"                                        
                                        id="1-modal-tipos" 
                                    >                       
                                        <input id="1-cartao-admin-id" type="hidden" v-model="cadTipo.id_lancamento_tipo" />

                                        <b-row> 
                                            <b-col md="7" sm="12"> 
                                                <b-form-group label="Descrição" label-for="descricao-tipo">
                                                    <b-input id="1-descricao-tipo" type="text"
                                                        :formatter="upperCase"
                                                        v-model="cadTipo.descricao" required
                                                        :readonly="modeTipo === 'remove'" 
                                                        />                                            
                                                </b-form-group>
                                            </b-col>  

                                            <b-col md="5" sm="12" align-self="center" style="padding-top: 10px">
                                                <b-checkbox id="1-cad-tipo-ativo" v-show="modeTipo === 'save'" v-model="cadTipo.ativo" class="mt-3 mb-3" switch>Ativo</b-checkbox>
                                            </b-col>   
                                        </b-row> 

                                        <b-table hover striped :items="cadTipos" :fields="fieldsTipos" small>  
                                            <template v-slot:cell(actions)="data">
                                                <b-button variant="warning" @click="loadTipo(data.item)" class="mr-2">
                                                    <i class="fa fa-pencil"></i>
                                                </b-button>
                                                <b-button variant="danger" @click="loadTipo(data.item, 'remove')">
                                                    <i class="fa fa-trash"></i>
                                                </b-button>
                                            </template>
                                        </b-table>
                                        <hr>
                                        <b-pagination 
                                            align="center" 
                                            size="md" 
                                            v-model="page"  
                                            :total-rows="count" 
                                            :per-page="limit" 
                                            first-number 
                                            last-number>
                                        </b-pagination>

                                        <template #modal-footer>
                                            <div class="w-100">                               

                                                <b-col md="10" sm="10">
                                                    <b-button variant="primary" v-if="modeTipo === 'save'"
                                                        @click="salvarTipo">Salvar</b-button>
                                                    <b-button variant="danger" v-if="modeTipo === 'remove'"
                                                        @click="removeTipo">Excluir</b-button>
                                                    <b-button class="ml-2" @click="resetTipo">Cancelar</b-button>
                                                </b-col>
                                            </div>
                                        </template>                        
                                    </b-modal>
                                </b-col>
                                <b-col md="3" sm="12">
                                    <span><strong>Produto</strong></span> 
                                    <Autocomplete
                                        style="margin-top: -8px"
                                        id="produto-name-input"
                                        :options="produtos"
                                        label-key="descricao"
                                        value-key="id_produto"
                                        :readyOnlyX="modeItens === 'remove' ? 'true' : 'false'"
                                        v-model="lancamento.idproduto"
                                        placeholder=""
                                        @shouldSearch="loadProdutos"
                                        @select="onSelectProduto"
                                        :readonly="modeItens === 'remove'" 
                                    />  

                                                        
                                </b-col>
                                <b-col md="2" sm="12">
                                    <span><strong>Verba total</strong></span>                                     
                                    <money id="1-valor-item-lancamento" v-model="lancamento.verba_total" v-bind="money" class="form-control"/>                                  
                                </b-col> 

                                <div class="w-100"></div>
                                
                                <b-col md="3" sm="12">
                                    <span><strong>Abertura Carrinho</strong></span>
                                    <b-input-group id="1-abertura-carrinho" class="mt-0">
                                        <b-form-input id="ac-data-inicial" type="date"
                                            v-model="lancamento.abertura_carrinho"
                                            :readonly="mode === 'remove'" 
                                        />
                                        <b-input-group-append>
                                            <b-button variant="success" @click="carregaDatas"><i class="fa fa-check"></i></b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-col>
                                <b-col md="3" sm="12">
                                    <span><strong>Fechamento Carrinho</strong></span>
                                    <b-form-input id="fc-data-final" type="date"
                                        v-model="lancamento.fechamento_carrinho"
                                        :readonly="mode === 'remove'" 
                                    />
                                </b-col>
                                <b-col md="3" sm="12">
                                    <span><strong>Início Captação</strong></span>
                                    <b-form-input id="ic-data-inicial" type="date"
                                        v-model="lancamento.data_inicio"
                                        :readonly="mode === 'remove'" 
                                    />
                                </b-col>
                                <b-col md="3" sm="12">
                                    <span><strong>Fim Captação</strong></span>
                                    <b-form-input id="fc-data-final" type="date"
                                        v-model="lancamento.data_final"
                                        :readonly="mode === 'remove'" 
                                    />
                                </b-col>  

                                <div class="w-100"></div>

                                <b-col md="4" sm="12">
                                    <span><strong>CPL 1</strong></span>
                                    <b-form-input id="d-cpl-1" type="date"
                                        v-model="lancamento.data_inicio_cpl1"
                                        :readonly="mode === 'remove'" 
                                    />
                                </b-col>
                                <b-col md="4" sm="12">
                                    <span><strong>CPL 2</strong></span>
                                    <b-form-input id="d-cpl-2" type="date"
                                        v-model="lancamento.data_inicio_cpl2"
                                        :readonly="mode === 'remove'" 
                                    />
                                </b-col>
                                <b-col md="4" sm="12">
                                    <span><strong>CPL 3</strong></span>
                                    <b-form-input id="d-cpl-3" type="date"
                                        v-model="lancamento.data_inicio_cpl3"
                                        :readonly="mode === 'remove'" 
                                    />
                                </b-col>

                                <div class="w-100"></div>

                                <b-col md="6" sm="12">
                                    <b-card no-body class="p-3">
                                        <b-form-checkbox
                                            style="margin: 10px 0 10px 10px"
                                            id="1-checkbox-testes"
                                            v-model="lancamento.teste"
                                            name="checkbox-testes"
                                        >
                                            <span><strong>Fazer período de testes de campanha</strong></span>                                    
                                        </b-form-checkbox>
                                        <div v-if="lancamento.teste" style="margin: -10px 0 0 10px; ">
                                            <span>Verba Teste</span>                                     
                                            <money id="1-valor-item-lancamento" v-model="lancamento.verba_total_teste" v-bind="money" class="form-control"/> 
                                            <b-row>                                        
                                                <b-col md="6" sm="12">
                                                    <span>Início</span>
                                                    <b-form-input type="date"
                                                        v-model="lancamento.data_inicio_teste"
                                                        :readonly="mode === 'remove'" 
                                                    />
                                                </b-col>
                                                <b-col md="6" sm="12">
                                                    <span>Fim</span>
                                                    <b-form-input type="date"
                                                        v-model="lancamento.data_fim_teste"
                                                        :readonly="mode === 'remove'" 
                                                    />
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </b-card>
                                    
                                </b-col>

                                <b-col md="6" sm="12">
                                    <b-card no-body class="p-2">
                                        <div>
                                            <b-dropdown id="1-dropdown-buttons" text="Adicionar lembretes" class="m-2" variant="info">
                                                <b-dropdown-item-button 
                                                    :disabled="!it.ativo" 
                                                    v-for="(it, id) in lembretes" 
                                                    @click="()=> {it.ativo = false; lembretesSelecionados.push(it)}" 
                                                    v-bind:key="id"
                                                >
                                                    {{ it.nome }}
                                                </b-dropdown-item-button>
                                            </b-dropdown>
                                        </div>

                                        <div style="margin-left: 10px">
                                            <b-badge class="mr-2 pl-2 pr-2" 
                                                :variant="item.cor" v-for="item in lembretesSelecionados" 
                                                v-bind:key="item.id"
                                            >
                                                {{ item.nome }} <button class="ml-1" @click="removerLembrete(item)"><i class="fa fa-times"></i></button>
                                            </b-badge>
                                        </div>
                                    </b-card>
                                </b-col>
                                
                            </b-row>

                            <b-row>  
                                <b-col md="12" sm="12">                                    
                                    <span><strong>Detalhes do projeto</strong></span>
                                    <b-form-textarea class="mb-3"
                                        id="1-lanc-detalhes"
                                        placeholder="Informe uma descrição de até 1000 caracteres..."
                                        v-model="lancamento.detalhes"
                                        rows="3"
                                    ></b-form-textarea>

                                    <span><strong>Observações</strong></span>
                                    <b-form-textarea
                                        id="1-lanc-obsr"
                                        placeholder="Informe uma observação de até 200 caracteres..."
                                        v-model="lancamento.obs"
                                        rows="3"
                                    ></b-form-textarea>
                                </b-col>
                                
                            </b-row>
                        </div>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <div class="stepper-content">
                            <b-form-group label="Escolha as mídias sociais" v-slot="{ ariaDescribedby }">
                                <b-form-checkbox-group
                                    class="ml-2"
                                    id="checkbox-group-midias"
                                    v-model="metricasSelecionadas"
                                    :options="metricasOptions"
                                    :aria-describedby="ariaDescribedby"
                                    name="flavour-1"
                                ></b-form-checkbox-group>
                            </b-form-group>
                            <hr>
                            <b-row>
                                <b-col md="7" sm="12" class="p-3">
                                    
                                    <b-card no-body class="p-2 mb-2" v-if="ativaMidia('facebookADS')">
                                        <b-row>                                        
                                            <b-col md="4" sm="12">
                                                <span><strong>Facebook (%)</strong></span>
                                                <b-form-input id="2-face-lanc-descricao" type="number"
                                                    v-model="facebookADS.plan_porc_capital"
                                                    :readonly="mode === 'remove'" 
                                                    @input="calculaCapital()"
                                                    min="0"
                                                    max="100"
                                                />
                                            </b-col>  

                                            <b-col md="4" sm="12" v-if="lancamento.teste">
                                                <span><strong>Teste (R$)</strong></span>                                   
                                                <money id="2-face-valor-item-lancamento" v-model="facebookADS.valor_teste" v-bind="moneyNoPrefix" class="form-control"/>
                                            </b-col>  

                                            <b-col md="4" sm="12">
                                                <money type="text" v-model="facebookADS.valor_capital" v-bind="moneyNoPrefix" readonly class="moeda-totais-midias"/>
                                            </b-col>
                                        
                                        </b-row>
                                    </b-card>

                                    <b-card no-body class="p-2 mb-2" v-if="ativaMidia('googleADS')">
                                        <b-row>                                        
                                            <b-col md="4" sm="12">
                                                <span><strong>Google (%)</strong></span>
                                                <b-form-input id="2-goo-lanc-descricao" type="number"
                                                    v-model="googleADS.plan_porc_capital"
                                                    :readonly="mode === 'remove'" 
                                                    @input="calculaCapital()"
                                                    min="0"
                                                    max="100"
                                                />
                                            </b-col>  

                                            <b-col md="4" sm="12" v-if="lancamento.teste">
                                                <span><strong>Teste (R$)</strong></span>                                   
                                                <money id="2-goo-valor-item-lancamento" v-model="googleADS.valor_teste" v-bind="moneyNoPrefix" class="form-control"/>
                                            </b-col>  

                                            <b-col md="4" sm="12">
                                                <money type="text" v-model="googleADS.valor_capital" v-bind="moneyNoPrefix" readonly class="moeda-totais-midias"/>
                                            </b-col>
                                        
                                        </b-row>
                                    </b-card>

                                    <b-card no-body class="p-2 mb-2" v-if="ativaMidia('tiktokADS')">
                                        <b-row>                                        
                                            <b-col md="4" sm="12">
                                                <span><strong>Tiktok (%)</strong></span>
                                                <b-form-input id="2-tik-lanc-descricao" type="number"
                                                    v-model="tiktokADS.plan_porc_capital"
                                                    :readonly="mode === 'remove'" 
                                                    @input="calculaCapital()"
                                                    min="0"
                                                    max="100"
                                                />
                                            </b-col>  

                                            <b-col md="4" sm="12" v-if="lancamento.teste">
                                                <span><strong>Teste (R$)</strong></span>                                   
                                                <money id="2-tik-valor-item-lancamento" v-model="tiktokADS.valor_teste" v-bind="moneyNoPrefix" class="form-control"/>
                                            </b-col>  

                                            <b-col md="4" sm="12">
                                                <money type="text" v-model="tiktokADS.valor_capital" v-bind="moneyNoPrefix" readonly class="moeda-totais-midias"/>
                                            </b-col>
                                        
                                        </b-row>
                                    </b-card>
                          
                                </b-col>
                                
                                <b-col md="5" sm="12">
                                    <b-card bg-variant="success" text-variant="white"  class="mb-3 p-3" no-body>
                                        <span>Verba total disponível</span>
                                        <money type="text" v-model="lancamento.verba_total" v-bind="money" readonly class="moeda-totais-verba-total"/>
                                        <span>Reservado testes</span>
                                        <money type="text" v-model="lancamento.verba_total_teste" v-bind="money" readonly class="moeda-totais-verba-total"/>
                                    </b-card>
                                    <b-card no-body class="p-3">
                                        <b-card-text>
                                            <h5>Distribuída</h5>
                                            <div class="totais">
                                                <money 
                                                    type="text" 
                                                    v-model="lancamento.perc_distribuido" 
                                                    v-bind="moneyDet" 
                                                    readonly 
                                                    :class="checaDistri('P', lancamento.perc_distribuido)"
                                                />
                                                <money 
                                                    type="text" 
                                                    v-model="lancamento.vlr_distribuido" 
                                                    v-bind="money" 
                                                    readonly 
                                                    :class="checaDistri('V', lancamento.perc_distribuido)"
                                                /> 
                                            </div>
                                        </b-card-text>
                                        <b-card-text>
                                            <h5>Restante</h5> 
                                            <div class="totais">
                                                <money 
                                                    type="text" 
                                                    v-model="lancamento.perc_restante" 
                                                    v-bind="moneyDet" 
                                                    readonly 
                                                    :class="checaDistri('P', lancamento.perc_distribuido)"
                                                />
                                                <money 
                                                    type="text" 
                                                    v-model="lancamento.vlr_restante" 
                                                    v-bind="money" 
                                                    readonly 
                                                    :class="checaDistri('V', lancamento.perc_distribuido)"
                                                /> 
                                            </div>
                                        </b-card-text>
                                    </b-card>
                                    
                                </b-col>
                            </b-row>
                        </div>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <div class="stepper-content">                          

                            <b-card
                                v-if="ativaMidia('facebookADS')"
                                :title="`Facebook ADS - ${calculaTitulomidia('facebookADS')}`"
                                border-variant="primary"      
                                align="left"
                                class="mt-3"
                            >                                
                                <b-row> 
                                    <b-col md="3" sm="12">
                                        <b-form-group
                                            label="Captação (%)"
                                            label-for="input-cap-facebookADS"
                                            :description="((facebookADS.valor_capital * facebookADS.plan_porc_captacao) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})"
                                            class="mb-0"
                                        >
                                            <b-form-input
                                                size="sm"
                                                type="number"
                                                id="input-cap-facebookADS"
                                                v-model="facebookADS.plan_porc_captacao"
                                                :state="valida0a100(facebookADS.plan_porc_captacao)"
                                                aria-describedby="feedback-cap-facebook"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="feedback-cap-facebook">
                                                Valor deve ser de 0 a 100%!
                                            </b-form-invalid-feedback>
                                        </b-form-group>                                        
                                    </b-col> 

                                    <b-col md="3" sm="12" v-if="lembretesSelecionados.length > 0">
                                        <b-form-group
                                            label="Antecipação (%)"
                                            label-for="input-ant-facebook"
                                            :description="((facebookADS.valor_capital * facebookADS.plan_porc_antecipacao) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})"
                                            class="mb-0"
                                        >
                                            <b-form-input
                                                size="sm"
                                                type="number"
                                                id="input-ant-facebook"
                                                v-model="facebookADS.plan_porc_antecipacao"
                                                :state="valida0a100(facebookADS.plan_porc_antecipacao)"
                                                aria-describedby="feedback-ant-facebook"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="feedback-ant-facebook">
                                                Valor deve ser de 0 a 100%!
                                            </b-form-invalid-feedback>
                                        </b-form-group>                                        
                                    </b-col> 
                                     
                                    <b-col md="3" sm="12">
                                        <b-form-group
                                            label="CPL (%)"
                                            label-for="input-cpl-facebook"
                                            :description="((facebookADS.valor_capital * facebookADS.plan_porc_camp_pre_lanc) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})"
                                            class="mb-0"
                                        >
                                            <b-form-input
                                                size="sm"
                                                type="number"
                                                id="input-cpl-facebook"
                                                v-model="facebookADS.plan_porc_camp_pre_lanc"
                                                :state="valida0a100(facebookADS.plan_porc_camp_pre_lanc)"
                                                aria-describedby="feedback-cpl-facebookADS"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="feedback-cpl-facebookADS">
                                                Valor deve ser de 0 a 100%!
                                            </b-form-invalid-feedback>
                                        </b-form-group>                                        
                                    </b-col> 

                                    <b-col md="3" sm="12">
                                        <b-form-group                                        
                                            label="Vendas (%)"
                                            label-for="input-vendas-facebook"
                                            :description="((facebookADS.valor_capital * facebookADS.plan_porc_vendas) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})"
                                            class="mb-0"
                                        >
                                            <b-form-input
                                                size="sm"
                                                type="number"
                                                id="input-vendas-facebook"
                                                v-model="facebookADS.plan_porc_vendas"
                                                :state="valida0a100(facebookADS.plan_porc_vendas)"
                                                aria-describedby="feedback-vendas-facebookADS"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="feedback-vendas-facebookADS">
                                                Valor deve ser de 0 a 100%!
                                            </b-form-invalid-feedback>
                                        </b-form-group>                                        
                                    </b-col>                                                                         
                                </b-row>                                
                            </b-card>

                            <b-card
                                v-if="ativaMidia('googleADS')"
                                :title="`Google ADS - ${calculaTitulomidia('googleADS')}`"
                                border-variant="primary"      
                                align="left"
                                class="mt-3"
                            >                                
                                <b-row> 
                                    <b-col md="3" sm="12">
                                        <b-form-group
                                            label="Captação (%)"
                                            label-for="input-cap-google"
                                            :description="((googleADS.valor_capital * googleADS.plan_porc_captacao) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})"
                                            class="mb-0"
                                        >
                                            <b-form-input
                                                size="sm"
                                                type="number"
                                                id="input-cap-google"
                                                v-model="googleADS.plan_porc_captacao"
                                                :state="valida0a100(googleADS.plan_porc_captacao)"
                                                aria-describedby="feedback-cap-google"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="feedback-cap-google">
                                                Valor deve ser de 0 a 100%!
                                            </b-form-invalid-feedback>
                                        </b-form-group>                                        
                                    </b-col> 

                                    <b-col md="3" sm="12" v-if="lembretesSelecionados.length > 0">
                                        <b-form-group
                                            label="Antecipação (%)"
                                            label-for="input-ant-google"
                                            :description="((googleADS.valor_capital * googleADS.plan_porc_antecipacao) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})"
                                            class="mb-0"
                                        >
                                            <b-form-input
                                                size="sm"
                                                type="number"
                                                id="input-ant-google"
                                                v-model="googleADS.plan_porc_antecipacao"
                                                :state="valida0a100(googleADS.plan_porc_antecipacao)"
                                                aria-describedby="feedback-ant-google"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="feedback-ant-google">
                                                Valor deve ser de 0 a 100%!
                                            </b-form-invalid-feedback>
                                        </b-form-group>                                        
                                    </b-col> 
                                     
                                    <b-col md="3" sm="12">
                                        <b-form-group
                                            label="CPL (%)"
                                            label-for="input-cpl-google"
                                            :description="((googleADS.valor_capital * googleADS.plan_porc_camp_pre_lanc) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})"
                                            class="mb-0"
                                        >
                                            <b-form-input
                                                size="sm"
                                                type="number"
                                                id="input-cpl-google"
                                                v-model="googleADS.plan_porc_camp_pre_lanc"
                                                :state="valida0a100(googleADS.plan_porc_camp_pre_lanc)"
                                                aria-describedby="feedback-cpl-google"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="feedback-cpl-google">
                                                Valor deve ser de 0 a 100%!
                                            </b-form-invalid-feedback>
                                        </b-form-group>                                        
                                    </b-col> 

                                    <b-col md="3" sm="12">
                                        <b-form-group                                        
                                            label="Vendas (%)"
                                            label-for="input-vendas-google"
                                            :description="((googleADS.valor_capital * googleADS.plan_porc_vendas) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})"
                                            class="mb-0"
                                        >
                                            <b-form-input
                                                size="sm"
                                                type="number"
                                                id="input-vendas-google"
                                                v-model="googleADS.plan_porc_vendas"
                                                :state="valida0a100(googleADS.plan_porc_vendas)"
                                                aria-describedby="feedback-vendas-google"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="feedback-vendas-google">
                                                Valor deve ser de 0 a 100%!
                                            </b-form-invalid-feedback>
                                        </b-form-group>                                        
                                    </b-col>                                     
                                </b-row>
                            </b-card>

                            <b-card
                                v-if="ativaMidia('tiktokADS')"
                                :title="`Tiktok ADS - ${calculaTitulomidia('tiktokADS')}`"
                                border-variant="primary"      
                                align="left"
                                class="mt-3"
                            >                                
                                <b-row> 
                                    <b-col md="3" sm="12">
                                        <b-form-group
                                            label="Captação (%)"
                                            label-for="input-cap-tiktok"
                                            :description="((tiktokADS.valor_capital * tiktokADS.plan_porc_captacao) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})"
                                            class="mb-0"
                                        >
                                            <b-form-input
                                                size="sm"
                                                type="number"
                                                id="input-cap-tiktok"
                                                v-model="tiktokADS.plan_porc_captacao"
                                                :state="valida0a100(tiktokADS.plan_porc_captacao)"
                                                aria-describedby="feedback-cap-tiktok"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="feedback-cap-tiktok">
                                                Valor deve ser de 0 a 100%!
                                            </b-form-invalid-feedback>
                                        </b-form-group>                                        
                                    </b-col> 

                                    <b-col md="3" sm="12" v-if="lembretesSelecionados.length > 0">
                                        <b-form-group
                                            label="Antecipação (%)"
                                            label-for="input-ant-tiktok"
                                            :description="((tiktokADS.valor_capital * tiktokADS.plan_porc_antecipacao) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})"
                                            class="mb-0"
                                        >
                                            <b-form-input
                                                size="sm"
                                                type="number"
                                                id="input-ant-tiktok"
                                                v-model="tiktokADS.plan_porc_antecipacao"
                                                :state="valida0a100(tiktokADS.plan_porc_antecipacao)"
                                                aria-describedby="feedback-ant-tiktok"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="feedback-ant-tiktok">
                                                Valor deve ser de 0 a 100%!
                                            </b-form-invalid-feedback>
                                        </b-form-group>                                        
                                    </b-col> 
                                     
                                    <b-col md="3" sm="12">
                                        <b-form-group
                                            label="CPL (%)"
                                            label-for="input-cpl-tiktok"
                                            :description="((tiktokADS.valor_capital * tiktokADS.plan_porc_camp_pre_lanc) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})"
                                            class="mb-0"
                                        >
                                            <b-form-input
                                                size="sm"
                                                type="number"
                                                id="input-cpl-tiktok"
                                                v-model="tiktokADS.plan_porc_camp_pre_lanc"
                                                :state="valida0a100(tiktokADS.plan_porc_camp_pre_lanc)"
                                                aria-describedby="feedback-cpl-tiktok"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="feedback-cpl-tiktok">
                                                Valor deve ser de 0 a 100%!
                                            </b-form-invalid-feedback>
                                        </b-form-group>                                        
                                    </b-col> 

                                    <b-col md="3" sm="12">
                                        <b-form-group                                        
                                            label="Vendas (%)"
                                            label-for="input-vendas-tiktok"
                                            :description="((tiktokADS.valor_capital * tiktokADS.plan_porc_vendas) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})"
                                            class="mb-0"
                                        >
                                            <b-form-input
                                                size="sm"
                                                type="number"
                                                id="input-vendas-tiktok"
                                                v-model="tiktokADS.plan_porc_vendas"
                                                :state="valida0a100(tiktokADS.plan_porc_vendas)"
                                                aria-describedby="feedback-vendas-tiktok"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="feedback-vendas-tiktok">
                                                Valor deve ser de 0 a 100%!
                                            </b-form-invalid-feedback>
                                        </b-form-group>                                        
                                    </b-col>                                     
                                </b-row>
                            </b-card>
                        </div>
                    </v-stepper-content>

                    <v-stepper-content step="4">
                        <div class="stepper-content">                            
                            <b-row>
                                <b-col md="12" sm="12">
                                    <h3>{{ `${lancamento.descricao} (${lancamento.descricaoTipo})` }}</h3>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col md="4" sm="12">
                                    <b-card no-body border-variant="success" class="p-2">
                                        <span>Verba total</span> 
                                        <h5>{{ lancamento.verba_total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}</h5>
                                    </b-card>
                                </b-col>
                                <b-col md="4" sm="12" v-if="true">
                                    <b-card no-body border-variant="primary" class="p-2">
                                        <span>Verba Testes</span> 
                                        <h5>{{ lancamento.verba_total_teste.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}</h5>
                                    </b-card>
                                </b-col>
                                <b-col md="4" sm="12" v-if="true">
                                    <span>Lembretes</span> 
                                    <div v-if="lembretesSelecionados.length > 0 ? true : false">
                                        <b-badge class="mr-2 pl-2 pr-2" 
                                            :variant="item.cor" v-for="item in lembretesSelecionados" 
                                            v-bind:key="item.id"
                                        >
                                            {{ item.nome }}
                                        </b-badge>
                                    </div>
                                    <div v-else>
                                        <span>Nenhum lembrete selecionado.</span>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col md="3" sm="12" v-if="true">
                                    <span>Início Captação</span> 
                                    <h5>{{ lancamento.dt_in }}</h5>
                                </b-col>
                                <b-col md="3" sm="12" v-if="true">
                                    <span>Fim Captação</span> 
                                    <h5>{{ lancamento.dt_fm }}</h5>
                                </b-col>
                                <b-col md="3" sm="12" v-if="true">
                                    <span>Abertura Carrinho</span> 
                                    <h5>{{ lancamento.dt_ab }}</h5>
                                </b-col>
                                <b-col md="3" sm="12" v-if="true">
                                    <span>Fechamento Carrinho</span> 
                                    <h5>{{ lancamento.dt_fc }}</h5>
                                </b-col>
                            </b-row>
                            <b-row>                                
                                <b-col md="12" sm="12">
                                    <b-card no-body style="padding: 10px; margin-bottom: 10px">
                                        <h5>Detalhes</h5>
                                        <span v-if="lancamento.detalhes != '' ? true : false">{{ lancamento.detalhes }}</span>
                                        <span v-else>Nenhum detalhe informado.</span>
                                    </b-card>
                                    <b-card no-body style="padding: 10px; margin-bottom: 10px">
                                        <h5>Observações</h5>
                                        <span v-if="lancamento.obs != '' ? true : false">{{ lancamento.obs }}</span>
                                        <span v-else>Nenhuma observação informada.</span>
                                    </b-card>
                                </b-col>                                
                            </b-row>
                            <b-row>
                                <b-col md="12" sm="12">
                                    <h4>Distribuição de verbas</h4>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col md="12" sm="12">                                    
                                    <!-- ABA 04 FACEBOOK -->
                                    <b-card
                                        v-if="ativaMidia('facebookADS')"
                                        :title="`Facebook ADS - ${calculaTitulomidia('facebookADS')}`"
                                        border-variant="primary"      
                                        align="left"
                                        class="mt-3"
                                    >   
                                        <b-row class="mt-3"> 
                                            <b-col md="3" sm="12">
                                                <span style="font-size: 1.2rem"><strong>Captação</strong></span> 
                                                <h5>
                                                    {{ facebookADS.plan_porc_captacao.toLocaleString('pt-br',{currency: 'BRL'}) }}% 
                                                    - {{ ((facebookADS.valor_capital * facebookADS.plan_porc_captacao) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                                                </h5>                                      
                                            </b-col>                                                                                         
                                        </b-row> 
                                        <b-row style="margin-top: -25px">
                                            <b-col md="12" sm="12">
                                                <b-table  hover :items="itensFacebookADSCaptacao" :fields="fieldsResumoMidias" small head-variant="dark">
                                                    <template #cell(etapa)="{ item }">
                                                        <span style="font-size: 1.2rem">{{ item.etapa }}</span>
                                                    </template>
                                                </b-table>
                                            </b-col>        
                                        </b-row> 

                                        <div v-if="lembretesSelecionados.length > 0">
                                            <b-row class="mt-3"> 
                                                <b-col md="3" sm="12">
                                                    <span style="font-size: 1.2rem"><strong>Antecipação</strong></span> 
                                                    <h5>
                                                        {{ facebookADS.plan_porc_antecipacao.toLocaleString('pt-br',{currency: 'BRL'}) }}% 
                                                        - {{ ((facebookADS.valor_capital * facebookADS.plan_porc_antecipacao) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                                                    </h5>                                      
                                                </b-col>                                            
                                            </b-row> 
                                            <b-row style="margin-top: -25px">
                                                <b-col md="12" sm="12">
                                                    <b-table  hover :items="itensFacebookADSAntecipacao" :fields="fieldsResumoMidias" small head-variant="dark">
                                                        <template #cell(etapa)="{ item }">
                                                            <span style="font-size: 1.2rem">{{ item.etapa }}</span>
                                                        </template>
                                                    </b-table>
                                                </b-col>        
                                            </b-row> 
                                        </div>

                                        <!--###################### CPL -->
                                        <b-row class="mt-3"> 
                                            <b-col md="3" sm="12">
                                                <span style="font-size: 1.2rem"><strong>CPL (remarketing)</strong></span> 
                                                <h5>
                                                    {{ facebookADS.plan_porc_camp_pre_lanc.toLocaleString('pt-br',{currency: 'BRL'}) }}% 
                                                    - {{ ((facebookADS.valor_capital * facebookADS.plan_porc_camp_pre_lanc) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                                                </h5>                                      
                                            </b-col>                                            
                                        </b-row> 

                                        <b-row style="margin-top: -25px">
                                            <b-col md="12" sm="12">
                                                <b-table  hover :items="itensFacebookADSCpl" :fields="fieldsResumoMidias" small head-variant="dark">
                                                    <template #cell(etapa)="{ item }">
                                                        <span style="font-size: 1.2rem">{{ item.etapa }}</span>
                                                    </template>
                                                </b-table>

                                                <b-table  hover :items="itensFacebookADSCplDetalhes" small head-variant="light">
                                                    <template #head(d1)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 1</span><br>
                                                            <span>{{ dataMaisX(lancamento.data_inicio_cpl1, 0) }}</span>
                                                        </div>
                                                    </template>
                                                    <template #head(d2)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 2</span><br>
                                                            <span>{{ dataMaisX(lancamento.data_inicio_cpl1, 1) }}</span>
                                                        </div>
                                                    </template>
                                                    <template #head(d3)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 3</span><br>
                                                            <span>{{ dataMaisX(lancamento.data_inicio_cpl1, 2) }}</span>
                                                        </div>
                                                    </template>
                                                    <template #head(d4)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 4</span><br>
                                                            <span>{{ dataMaisX(lancamento.data_inicio_cpl1, 3) }}</span>
                                                        </div>
                                                    </template>
                                                    <template #head(d5)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 5</span><br>
                                                            <span>{{ dataMaisX(lancamento.data_inicio_cpl1, 4) }}</span>
                                                        </div>
                                                    </template>
                                                    <template #head(d6)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 6</span><br>
                                                            <span>{{ dataMaisX(lancamento.data_inicio_cpl1, 5) }}</span>
                                                        </div>
                                                    </template>
                                                    <template #head(d7)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 7</span><br>
                                                            <span>{{ dataMaisX(lancamento.data_inicio_cpl1, 6) }}</span>
                                                        </div>
                                                    </template>
                                                    <template #cell(etapa)="{ item }">
                                                        <span style="font-size: 1.2rem">{{ item.etapa }}</span>
                                                    </template>
                                                </b-table>
                                            </b-col>        
                                        </b-row>

                                        <!--###################### VENDAS --> 
                                        <b-row class="mt-3"> 
                                            <b-col md="3" sm="12">
                                                <span style="font-size: 1.2rem"><strong>Vendas</strong></span> 
                                                <h5>
                                                    {{ facebookADS.plan_porc_vendas.toLocaleString('pt-br',{currency: 'BRL'}) }}% 
                                                    - {{ ((facebookADS.valor_capital * facebookADS.plan_porc_vendas) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                                                </h5>                                      
                                            </b-col>                                            
                                        </b-row> 

                                        <b-row style="margin-top: -25px">
                                            <b-col md="12" sm="12">
                                                <b-table  hover :items="itensFacebookADSVendas" :fields="fieldsResumoMidias" small head-variant="dark">
                                                    <template #cell(etapa)>
                                                        <span style="font-size: 1.2rem">VV (CPL4) +</span><br>
                                                        <span style="font-size: 1.2rem">Abertura Carrinho</span>
                                                    </template>
                                                </b-table>

                                                <b-table  hover :items="itensFacebookADSVendasDetalhes" :fields="fieldsVendasResumo" small head-variant="light">
                                                    <template #head(d1)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 1</span><br>
                                                            <span>{{ dataMaisX(lancamento.abertura_carrinho, 0) }}</span>
                                                        </div>
                                                    </template>
                                                    <template #head(d2)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 2</span><br>
                                                            <span>{{ dataMaisX(lancamento.abertura_carrinho, 1) }}</span>
                                                        </div>
                                                    </template>
                                                    <template #head(d3)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 3</span><br>
                                                            <span>{{ dataMaisX(lancamento.abertura_carrinho, 2) }}</span>
                                                        </div>
                                                    </template>
                                                   
                                                    <template #cell(etapa)="{ item }">
                                                        <span style="font-size: 1.2rem">{{ item.etapa }}</span>
                                                    </template>
                                                </b-table>
                                            </b-col>        
                                        </b-row> 
                                    </b-card>

                                    <!-- ABA 04 GOOGLE -->
                                    <b-card
                                        v-if="ativaMidia('googleADS')"
                                        :title="`Google ADS - ${calculaTitulomidia('googleADS')}`"
                                        border-variant="primary"      
                                        align="left"
                                        class="mt-3"
                                    >  
                                        <b-row class="mt-3"> 
                                            <b-col md="3" sm="12">
                                                <span style="font-size: 1.2rem"><strong>Captação</strong></span> 
                                                <h5>
                                                    {{ googleADS.plan_porc_captacao.toLocaleString('pt-br',{currency: 'BRL'}) }}% 
                                                    - {{ ((googleADS.valor_capital * googleADS.plan_porc_captacao) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                                                </h5>                                      
                                            </b-col>                                                                                         
                                        </b-row> 
                                        <b-row style="margin-top: -25px">
                                            <b-col md="12" sm="12">
                                                <b-table  hover :items="itensGoogleADSCaptacao" :fields="fieldsResumoMidias" small head-variant="dark">
                                                    <template #cell(etapa)="{ item }">
                                                        <span style="font-size: 1.2rem">{{ item.etapa }}</span>
                                                    </template>
                                                </b-table>
                                            </b-col>        
                                        </b-row> 

                                        <div v-if="lembretesSelecionados.length > 0">
                                            <b-row class="mt-3"> 
                                                <b-col md="3" sm="12">
                                                    <span style="font-size: 1.2rem"><strong>Antecipação</strong></span> 
                                                    <h5>
                                                        {{ googleADS.plan_porc_antecipacao.toLocaleString('pt-br',{currency: 'BRL'}) }}% 
                                                        - {{ ((googleADS.valor_capital * googleADS.plan_porc_antecipacao) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                                                    </h5>                                      
                                                </b-col>                                            
                                            </b-row> 
                                            <b-row style="margin-top: -25px">
                                                <b-col md="12" sm="12">
                                                    <b-table  hover :items="itensGoogleADSAntecipacao" :fields="fieldsResumoMidias" small head-variant="dark">
                                                        <template #cell(etapa)="{ item }">
                                                            <span style="font-size: 1.2rem">{{ item.etapa }}</span>
                                                        </template>
                                                    </b-table>
                                                </b-col>        
                                            </b-row> 
                                        </div>

                                        <!--###################### CPL -->
                                        <b-row class="mt-3"> 
                                            <b-col md="3" sm="12">
                                                <span style="font-size: 1.2rem"><strong>CPL (remarketing)</strong></span> 
                                                <h5>
                                                    {{ googleADS.plan_porc_camp_pre_lanc.toLocaleString('pt-br',{currency: 'BRL'}) }}% 
                                                    - {{ ((googleADS.valor_capital * googleADS.plan_porc_camp_pre_lanc) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                                                </h5>                                      
                                            </b-col>                                            
                                        </b-row> 

                                        <b-row style="margin-top: -25px">
                                            <b-col md="12" sm="12">
                                                <b-table  hover :items="itensGoogleADSCpl" :fields="fieldsResumoMidias" small head-variant="dark">
                                                    <template #cell(etapa)="{ item }">
                                                        <span style="font-size: 1.2rem">{{ item.etapa }}</span>
                                                    </template>
                                                </b-table>

                                                <b-table  hover :items="itensGoogleADSCplDetalhes" small head-variant="light">
                                                    <template #head(d1)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 1</span><br>
                                                            <span>{{ dataMaisX(lancamento.data_inicio_cpl1, 0) }}</span>
                                                        </div>
                                                    </template>
                                                    <template #head(d2)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 2</span><br>
                                                            <span>{{ dataMaisX(lancamento.data_inicio_cpl1, 1) }}</span>
                                                        </div>
                                                    </template>
                                                    <template #head(d3)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 3</span><br>
                                                            <span>{{ dataMaisX(lancamento.data_inicio_cpl1, 2) }}</span>
                                                        </div>
                                                    </template>
                                                    <template #head(d4)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 4</span><br>
                                                            <span>{{ dataMaisX(lancamento.data_inicio_cpl1, 3) }}</span>
                                                        </div>
                                                    </template>
                                                    <template #head(d5)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 5</span><br>
                                                            <span>{{ dataMaisX(lancamento.data_inicio_cpl1, 4) }}</span>
                                                        </div>
                                                    </template>
                                                    <template #head(d6)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 6</span><br>
                                                            <span>{{ dataMaisX(lancamento.data_inicio_cpl1, 5) }}</span>
                                                        </div>
                                                    </template>
                                                    <template #head(d7)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 7</span><br>
                                                            <span>{{ dataMaisX(lancamento.data_inicio_cpl1, 6) }}</span>
                                                        </div>
                                                    </template>
                                                    <template #cell(etapa)="{ item }">
                                                        <span style="font-size: 1.2rem">{{ item.etapa }}</span>
                                                    </template>
                                                </b-table>
                                            </b-col>        
                                        </b-row>

                                        <!--###################### VENDAS --> 
                                        <b-row class="mt-3"> 
                                            <b-col md="3" sm="12">
                                                <span style="font-size: 1.2rem"><strong>Vendas</strong></span> 
                                                <h5>
                                                    {{ googleADS.plan_porc_vendas.toLocaleString('pt-br',{currency: 'BRL'}) }}% 
                                                    - {{ ((googleADS.valor_capital * googleADS.plan_porc_vendas) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                                                </h5>                                      
                                            </b-col>                                            
                                        </b-row> 

                                        <b-row style="margin-top: -25px">
                                            <b-col md="12" sm="12">
                                                <b-table  hover :items="itensGoogleADSVendas" :fields="fieldsResumoMidias" small head-variant="dark">
                                                    <template #cell(etapa)>
                                                        <span style="font-size: 1.2rem">VV (CPL4) +</span><br>
                                                        <span style="font-size: 1.2rem">Abertura Carrinho</span>
                                                    </template>
                                                </b-table>

                                                <b-table  hover :items="itensGoogleADSVendasDetalhes" :fields="fieldsVendasResumo" small head-variant="light">
                                                    <template #head(d1)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 1</span><br>
                                                            <span>{{ dataMaisX(lancamento.abertura_carrinho, 0) }}</span>
                                                        </div>
                                                    </template>
                                                    <template #head(d2)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 2</span><br>
                                                            <span>{{ dataMaisX(lancamento.abertura_carrinho, 1) }}</span>
                                                        </div>
                                                    </template>
                                                    <template #head(d3)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 3</span><br>
                                                            <span>{{ dataMaisX(lancamento.abertura_carrinho, 2) }}</span>
                                                        </div>
                                                    </template>
                                                   
                                                    <template #cell(etapa)="{ item }">
                                                        <span style="font-size: 1.2rem">{{ item.etapa }}</span>
                                                    </template>
                                                </b-table>
                                            </b-col>        
                                        </b-row> 
                                    </b-card>

                                    <!-- ABA 04 TIKITOK -->
                                    <b-card
                                        v-if="ativaMidia('tiktokADS')"
                                        :title="`Tiktok ADS - ${calculaTitulomidia('tiktokADS')}`"
                                        border-variant="primary"      
                                        align="left"
                                        class="mt-3"
                                    >   
                                        <b-row class="mt-3"> 
                                            <b-col md="3" sm="12">
                                                <span style="font-size: 1.2rem"><strong>Captação</strong></span> 
                                                <h5>
                                                    {{ tiktokADS.plan_porc_captacao.toLocaleString('pt-br',{currency: 'BRL'}) }}% 
                                                    - {{ ((tiktokADS.valor_capital * tiktokADS.plan_porc_captacao) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                                                </h5>                                      
                                            </b-col>                                                                                         
                                        </b-row> 
                                        <b-row style="margin-top: -25px">
                                            <b-col md="12" sm="12">
                                                <b-table  hover :items="itensTiktokADSCaptacao" :fields="fieldsResumoMidias" small head-variant="dark">
                                                    <template #cell(etapa)="{ item }">
                                                        <span style="font-size: 1.2rem">{{ item.etapa }}</span>
                                                    </template>
                                                </b-table>
                                            </b-col>        
                                        </b-row> 

                                        <div v-if="lembretesSelecionados.length > 0">
                                            <b-row class="mt-3"> 
                                                <b-col md="3" sm="12">
                                                    <span style="font-size: 1.2rem"><strong>Antecipação</strong></span> 
                                                    <h5>
                                                        {{ tiktokADS.plan_porc_antecipacao.toLocaleString('pt-br',{currency: 'BRL'}) }}% 
                                                        - {{ ((tiktokADS.valor_capital * tiktokADS.plan_porc_antecipacao) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                                                    </h5>                                      
                                                </b-col>                                            
                                            </b-row> 
                                            <b-row style="margin-top: -25px">
                                                <b-col md="12" sm="12">
                                                    <b-table  hover :items="itensTiktokADSAntecipacao" :fields="fieldsResumoMidias" small head-variant="dark">
                                                        <template #cell(etapa)="{ item }">
                                                            <span style="font-size: 1.2rem">{{ item.etapa }}</span>
                                                        </template>
                                                    </b-table>
                                                </b-col>        
                                            </b-row> 
                                        </div>

                                        <!--###################### CPL -->
                                        <b-row class="mt-3"> 
                                            <b-col md="3" sm="12">
                                                <span style="font-size: 1.2rem"><strong>CPL (remarketing)</strong></span> 
                                                <h5>
                                                    {{ tiktokADS.plan_porc_camp_pre_lanc.toLocaleString('pt-br',{currency: 'BRL'}) }}% 
                                                    - {{ ((tiktokADS.valor_capital * tiktokADS.plan_porc_camp_pre_lanc) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                                                </h5>                                      
                                            </b-col>                                            
                                        </b-row> 

                                        <b-row style="margin-top: -25px">
                                            <b-col md="12" sm="12">
                                                <b-table  hover :items="itensTiktokADSCpl" :fields="fieldsResumoMidias" small head-variant="dark">
                                                    <template #cell(etapa)="{ item }">
                                                        <span style="font-size: 1.2rem">{{ item.etapa }}</span>
                                                    </template>
                                                </b-table>

                                                <b-table  hover :items="itensTiktokADSCplDetalhes" small head-variant="light">
                                                    <template #head(d1)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 1</span><br>
                                                            <span>{{ dataMaisX(lancamento.data_inicio_cpl1, 0) }}</span>
                                                        </div>
                                                    </template>
                                                    <template #head(d2)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 2</span><br>
                                                            <span>{{ dataMaisX(lancamento.data_inicio_cpl1, 1) }}</span>
                                                        </div>
                                                    </template>
                                                    <template #head(d3)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 3</span><br>
                                                            <span>{{ dataMaisX(lancamento.data_inicio_cpl1, 2) }}</span>
                                                        </div>
                                                    </template>
                                                    <template #head(d4)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 4</span><br>
                                                            <span>{{ dataMaisX(lancamento.data_inicio_cpl1, 3) }}</span>
                                                        </div>
                                                    </template>
                                                    <template #head(d5)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 5</span><br>
                                                            <span>{{ dataMaisX(lancamento.data_inicio_cpl1, 4) }}</span>
                                                        </div>
                                                    </template>
                                                    <template #head(d6)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 6</span><br>
                                                            <span>{{ dataMaisX(lancamento.data_inicio_cpl1, 5) }}</span>
                                                        </div>
                                                    </template>
                                                    <template #head(d7)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 7</span><br>
                                                            <span>{{ dataMaisX(lancamento.data_inicio_cpl1, 6) }}</span>
                                                        </div>
                                                    </template>
                                                    <template #cell(etapa)="{ item }">
                                                        <span style="font-size: 1.2rem">{{ item.etapa }}</span>
                                                    </template>
                                                </b-table>
                                            </b-col>        
                                        </b-row>

                                        <!--###################### VENDAS --> 
                                        <b-row class="mt-3"> 
                                            <b-col md="3" sm="12">
                                                <span style="font-size: 1.2rem"><strong>Vendas</strong></span> 
                                                <h5>
                                                    {{ tiktokADS.plan_porc_vendas.toLocaleString('pt-br',{currency: 'BRL'}) }}% 
                                                    - {{ ((tiktokADS.valor_capital * tiktokADS.plan_porc_vendas) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                                                </h5>                                      
                                            </b-col>                                            
                                        </b-row> 

                                        <b-row style="margin-top: -25px">
                                            <b-col md="12" sm="12">
                                                <b-table  hover :items="itensTiktokADSVendas" :fields="fieldsResumoMidias" small head-variant="dark">
                                                    <template #cell(etapa)>
                                                        <span style="font-size: 1.2rem">VV (CPL4) +</span><br>
                                                        <span style="font-size: 1.2rem">Abertura Carrinho</span>
                                                    </template>
                                                </b-table>

                                                <b-table  hover :items="itensTiktokADSVendasDetalhes" :fields="fieldsVendasResumo" small head-variant="light">
                                                    <template #head(d1)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 1</span><br>
                                                            <span>{{ dataMaisX(lancamento.abertura_carrinho, 0) }}</span>
                                                        </div>
                                                    </template>
                                                    <template #head(d2)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 2</span><br>
                                                            <span>{{ dataMaisX(lancamento.abertura_carrinho, 1) }}</span>
                                                        </div>
                                                    </template>
                                                    <template #head(d3)>
                                                        <div style="text-align: center; font-size: 0.8rem">
                                                            <span>Dia 3</span><br>
                                                            <span>{{ dataMaisX(lancamento.abertura_carrinho, 2) }}</span>
                                                        </div>
                                                    </template>
                                                   
                                                    <template #cell(etapa)="{ item }">
                                                        <span style="font-size: 1.2rem">{{ item.etapa }}</span>
                                                    </template>
                                                </b-table>
                                            </b-col>        
                                        </b-row> 
                                    </b-card>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col md="12" sm="12">
                                    <h3>Resumo</h3>                                    
                                </b-col>
                            </b-row>
                            <b-row> 
                                <b-col md="12" sm="12">
                                    <b-table striped hover :items="resumoMidias" :fields="fieldsMidias">
                                        <template #cell(midia)="{ item }">
                                            <span style="font-size: 1.2rem">{{ item.midia }}</span>
                                        </template>
                                        <template #cell(verba)="{ item }">
                                            <span style="font-size: 1.2rem">{{ item.verba.toLocaleString('pt-br',{minimumFractionDigits: 2, currency: 'BRL'}) }}</span>
                                        </template>
                                        <template #cell(teste)="{ item }">
                                            <span style="font-size: 1.2rem">{{ item.teste.toLocaleString('pt-br',{minimumFractionDigits: 2, currency: 'BRL'}) }}</span>
                                        </template>
                                        <template #cell(captacao)="{ item }">
                                            <span style="font-size: 1.2rem">{{ item.captacao.toLocaleString('pt-br',{ currency: 'BRL'}) }}%</span><br>
                                            <span>{{ ((item.verba * item.captacao) /100).toLocaleString('pt-br',{minimumFractionDigits: 2, currency: 'BRL'}) }}</span>
                                        </template>
                                        <template #cell(antecipacao)="{ item }">
                                            <span style="font-size: 1.2rem">{{ item.antecipacao.toLocaleString('pt-br',{ currency: 'BRL'}) }}%</span><br>
                                            <span>{{ ((item.verba * item.antecipacao) /100).toLocaleString('pt-br',{minimumFractionDigits: 2, currency: 'BRL'}) }}</span>
                                        </template>
                                        <template #cell(cpl)="{ item }">
                                            <span style="font-size: 1.2rem">{{ item.cpl.toLocaleString('pt-br',{ currency: 'BRL'}) }}%</span><br>
                                            <span>{{ ((item.verba * item.cpl) /100).toLocaleString('pt-br',{minimumFractionDigits: 2, currency: 'BRL'}) }}</span>
                                        </template>
                                        <template #cell(vendas)="{ item }">
                                            <span style="font-size: 1.2rem">{{ item.vendas.toLocaleString('pt-br',{ currency: 'BRL'}) }}%</span><br>
                                            <span>{{ ((item.verba * item.vendas) /100).toLocaleString('pt-br',{minimumFractionDigits: 2, currency: 'BRL'}) }}</span>
                                        </template>
                                    </b-table>
                                </b-col>
                            </b-row>
                        </div>
                    </v-stepper-content>
                </v-stepper-items>

                <v-divider></v-divider>
                <v-btn
                    class="stepper-buttons"
                    @click="navegarAbas(-1)"
                >
                    Voltar
                </v-btn>
                <v-btn
                    v-if="e1 < 4"
                    class="stepper-buttons"
                    @click="navegarAbas(1)"
                >
                    Continuar
                </v-btn>
                

                <v-btn
                    v-if="e1 == 4"
                    class="stepper-buttons-salvar"
                    @click="salvarLancamento"
                >
                    Salvar
                </v-btn> 

            </v-stepper>      
        </b-modal>

        <!-- MODAL CONFIRMAR EXCLUSÃO -->
        <b-modal 
            v-model="showModalExcluir"
            id="modal-lg-liquidar" 
            centered  
            title="CUIDADO!"
            header-bg-variant="danger"
            header-text-variant="light"
        >
            <span>O procedimento de exclusão não pode ser desfeito!</span> <br>
            <span><strong>Deseja excluir a liquidação?</strong></span>

            <template #modal-footer>
                <div class="w-100">
                    <b-button 
                        variant="danger" 
                        size="lg"
                        class="float-right"
                        @click="closeModalExc"
                    >
                        Não
                    </b-button>

                    <b-button
                        style="margin-right: 10px"
                        variant="success"
                        size="lg"
                        class="float-right"
                        @click="removeItem"
                    >
                        Sim
                    </b-button>
                </div>
            </template>
        </b-modal> 

        <b-modal id="lancamento-dashboard-modal" size="xl" title="Dashboard" v-model="dashLancModal">            
            <template #modal-header>
                <div>
                    <div class="w-100">
                        <h4 class="float-left">{{ lancamento.descricao }} ({{ lancamento.descricaoTipo }})</h4>                                    
                    </div>
                    <div class="w-100">
                        <h4 class="float-left">Infoproduto: {{ lancamento.descricaoProduto }}</h4>                                        
                    </div>
                </div>
            </template>

            <div class="modal-dash-lanc-indicadores"> 
                <Indicador 
                    title="Verba Total" 
                    :value="lancamento.verba_total" 
                    :fracao1="2"
                    :fracao2="2"
                    color="#398bf7"
                />      
                <!-- :value2="`ROI: ${lancamento.roi_geral}x`"            -->
                <Indicador 
                    title="Faturamento" 
                    :value="lancamento.faturamento"                     
                    :value2="`ROI: ${lancamento.roi_geral}x`" 
                    :fracao1="2"
                    :fracao2="2"                   
                    color="#7460ee"
                />
                <Indicador 
                    title="Vendas" 
                    :value="lancamento.qtde_vendas" 
                    :fracao1="0"
                    :fracao2="2"
                    color="#06d79c"
                />
                <Indicador 
                    title="Leads" 
                    :value="lancamento.qtde_leeds" 
                    :fracao1="0"
                    :fracao2="2"                    
                    color="#ef5350"
                /> 
            </div>

            <div class="modal-dash-lanc-indicadores"> 
                <div class="top-info">
                    <span class="top-info-title"><i class="fa fa-usd"></i> CPL (geral)</span>
                    <span class="top-info-value">{{ lancamento.cpl }}</span>
                </div>
                <div class="top-info-divisor"></div>

                <div class="top-info">
                    <span class="top-info-title"><i class="fa fa-usd"></i> CPA</span>
                    <span class="top-info-value">{{ lancamento.cpa }}</span>
                </div>
                <div class="top-info-divisor"></div>

                <div class="top-info">
                    <span class="top-info-title"><i class="fa fa-percent"></i> Conversão</span>
                    <span class="top-info-value">{{ lancamento.conversao }}</span>
                </div>
                <div class="top-info-divisor"></div>

                <div class="top-info">
                    <span class="top-info-title"><i class="fa fa-usd"></i> Ticket</span>
                    <span class="top-info-value">{{ lancamento.ticket }}</span>
                </div>

            </div>            

            <div class="modal-dash-lanc-indicadores">              
                <b-row>
                    <b-col md="6" sm="12">
                        <b-card>
                            <ChartRosca 
                                :titulo="'Verba investida na captação'"
                                :dados="lancamento.investimentoPorMidea"
                            />
                            <b-table
                            :striped="false"
                            :small="true"
                            :hover="false"
                            :items="lancamento.investimentoPorMideaItens"
                            :fields="lancamento.investimentoPorMideaFields"
                            ></b-table>
                        </b-card>
                    </b-col>
                    <b-col md="6" sm="12">
                        <b-card>
                            <ChartRosca 
                                :titulo="'Distribuição por etapas'"
                                :dados="lancamento.distribuicaoPorEtapa"
                            />
                            <b-table
                            :striped="false"
                            :small="true"
                            :hover="false"
                            :items="lancamento.distribuicaoPorEtapaItens"
                            :fields="lancamento.distribuicaoPorEtapaFields"
                            ></b-table>
                        </b-card>
                    </b-col>
                </b-row>

            </div>   
            <br>         
            
            <div class="modal-dash-lanc-indicadores">
                <b-row>
                    <b-col md="12" sm="12">  
                        <b-card title="Dados Gerais" class="wt-100">
                            <b-table
                            caption-top
                            :striped="false"
                            :outlined="true"
                            :small="true"
                            :hover="false"
                            :items="lancamento.cpl_itens"
                            :fields="lancamento.cpl_fields"
                            :head-variant="'light'"
                            >
                                <template #table-caption>
                                    <h5 style="margin-bottom: -5px">Informações das CPL's</h5>
                                </template>
                            
                            </b-table>

                            <b-table
                            caption-top
                            :striped="false"
                            :outlined="true"
                            :small="true"
                            :hover="false"
                            :items="lancamento.outros_dados_itens"
                            :fields="lancamento.outros_dados_fields"
                            :head-variant="'light'"
                            >
                                <template #table-caption>
                                    <h5 style="margin-bottom: -5px">Leads de outras fontes / Outros Dados</h5>
                                </template>
                            </b-table>
                        </b-card>
                    </b-col>


                    <b-col md="12" sm="12"> 
                        <b-card title="Campanha de Remarketing" class="wt-100">
                            <b-table
                            caption-top
                            :striped="false"
                            :outlined="true"
                            :small="true"
                            :hover="false"
                            :items="lancamento.campanha_lembrete_itens"
                            :fields="lancamento.campanha_lembrete_fields"
                            :head-variant="'light'"
                            >
                                <template #table-caption>
                                    <h5 style="margin-bottom: -5px">Lembretes</h5>
                                </template>
                            
                            </b-table>

                            <b-table
                            caption-top
                            :striped="false"
                            :outlined="true"
                            :small="true"
                            :hover="false"
                            :items="lancamento.campanha_cpl_itens"
                            :fields="lancamento.campanha_cpl_fields"
                            :head-variant="'light'"
                            >
                                <template #table-caption>
                                    <h5 style="margin-bottom: -5px">CPL</h5>
                                </template>
                            
                            </b-table>

                            

                            <b-table
                            caption-top
                            :striped="false"
                            :outlined="true"
                            :small="true"
                            :hover="false"
                            :items="lancamento.campanha_carrinho_itens"
                            :fields="lancamento.campanha_carrinho_fields"
                            :head-variant="'light'"
                            >
                                <template #table-caption>
                                    <h5 style="margin-bottom: -5px">Carrinho Aberto</h5>
                                </template>
                            
                            </b-table>                  

                        </b-card>
                    </b-col>
                </b-row>
            </div>
            
            <div class="modal-dash-lanc-indicadores"> 
                <b-row>
                    <b-col :md="12 / metricasSelecionadas.length" sm="12" v-if="ativaMidia('facebookADS')">
                        <h5 class="dash-col-title">Facebook ADS</h5>
                        
                        <b-card>
                            <b-table
                            :stacked="metricasSelecionadas.length > 1"
                            :striped="false"
                            :small="true"
                            :items="facebookADS.dash_itens1"
                            :fields="facebookADS.dash_fields1"
                            :head-variant="null"
                            ></b-table>                        
                        </b-card>
                        <br>

                        <b-card>
                            <ChartRosca 
                                :titulo="'Público Captado'"
                                :dados="facebookADS.publico_captado"
                            />
                            <b-table
                            :striped="false"
                            :small="true"
                            :hover="false"
                            :items="facebookADS.dash_itens4"
                            :fields="facebookADS.dash_fields4"                        
                            ></b-table>
                        </b-card>
                        <br>
                        
                        <b-card>
                            <ChartRosca 
                                :titulo="'Verba Direcionada'"
                                :dados="facebookADS.verba_direcionada"
                            />
                            <b-table
                            :striped="false"
                            :small="true"
                            :hover="false"
                            :items="facebookADS.dash_itens5"
                            :fields="facebookADS.dash_fields5"
                            ></b-table>
                        </b-card>
                        
                    </b-col>

                    <b-col :md="12 / metricasSelecionadas.length" sm="12" v-if="ativaMidia('googleADS')">
                        <h5 class="dash-col-title">Google ADS</h5>
                        
                        <b-card>
                            <b-table
                            :stacked="metricasSelecionadas.length > 1"
                            :striped="false"
                            :small="true"
                            :items="googleADS.dash_itens1"
                            :fields="googleADS.dash_fields1"
                            :head-variant="null"
                            ></b-table>                        
                        </b-card>
                        <br>

                        <b-card>
                            <ChartRosca 
                                :titulo="'Público Captado'"
                                :dados="googleADS.publico_captado"
                            />
                            <b-table
                            :striped="false"
                            :small="true"
                            :hover="false"
                            :items="googleADS.dash_itens4"
                            :fields="googleADS.dash_fields4"                        
                            ></b-table>
                        </b-card>
                        <br>
                        
                        <b-card>
                            <ChartRosca 
                                :titulo="'Verba Direcionada'"
                                :dados="googleADS.verba_direcionada"
                            />
                            <b-table
                            :striped="false"
                            :small="true"
                            :hover="false"
                            :items="googleADS.dash_itens5"
                            :fields="googleADS.dash_fields5"
                            ></b-table>
                        </b-card>
                        
                    </b-col>

                    <b-col :md="12 / metricasSelecionadas.length" sm="12" v-if="ativaMidia('tiktokADS')">
                        <h5 class="dash-col-title">TikTok ADS</h5>
                        
                        <b-card>
                            <b-table
                            :stacked="metricasSelecionadas.length > 1"
                            :striped="false"
                            :small="true"
                            :items="tiktokADS.dash_itens1"
                            :fields="tiktokADS.dash_fields1"
                            :head-variant="null"
                            ></b-table>                        
                        </b-card>
                        <br>

                        <b-card>
                            <ChartRosca 
                                :titulo="'Público Captado'"
                                :dados="tiktokADS.publico_captado"
                            />
                            <b-table
                            :striped="false"
                            :small="true"
                            :hover="false"
                            :items="tiktokADS.dash_itens4"
                            :fields="tiktokADS.dash_fields4"                        
                            ></b-table>
                        </b-card>
                        <br>
                        
                        <b-card>
                            <ChartRosca 
                                :titulo="'Verba Direcionada'"
                                :dados="tiktokADS.verba_direcionada"
                            />
                            <b-table
                            :striped="false"
                            :small="true"
                            :hover="false"
                            :items="tiktokADS.dash_itens5"
                            :fields="tiktokADS.dash_fields5"
                            ></b-table>
                        </b-card>                        
                    </b-col>                    
                </b-row>
            </div>                

            <template #modal-footer>
                <div class="w-100">
                    
                </div>
            </template>
        </b-modal> 

        <b-modal id="lancamento-edicao-modal" size="xl" title="Dashboard Edição" v-model="edicaoLancModal">            
            <template #modal-header>
                <div>
                    <div class="w-100">
                        <h4 class="float-left">EDIÇÃO: {{ lancamento.descricao }} ({{ lancamento.descricaoTipo }})</h4>                                    
                    </div>
                </div>
            </template>

            <b-card no-body class="p-3" >
                <div class="stepper-content" style="padding: 5px">
                    <b-row>
                        <b-col md="4" sm="12">
                            <span><strong>Nome do Lançamento</strong></span>
                            <b-form-input id="1-lanc-descricao" type="text"
                                v-model="lancamento.descricao"
                                :readonly="mode === 'remove'" 
                                :formatter="upperCase"
                            />
                        </b-col>  
                        <b-col md="3" sm="12">
                            <span><strong>Tipo</strong></span>
                            <b-input-group id="1-tipo-lancamento" class="mt-0">
                                <b-form-select v-model="lancamento.id_lancamento_tipo" :options="tipos" />
                                <b-input-group-append>
                                    <b-button variant="success" v-b-modal.1-modal-tipos @click="loadCadTipos"><i class="fa fa-plus"></i></b-button>
                                </b-input-group-append>
                            </b-input-group>

                            <!-- Cadastro de tipos -->
                            <b-modal 
                                hideBvModalEvent
                                v-model="showModal"                        
                                centered 
                                ok-only
                                ok-disabled
                                title="Cadastrar tipo de Lançamento"
                                header-bg-variant="success"
                                header-text-variant="light"                                        
                                id="1-modal-tipos" 
                            >                       
                                <input id="1-cartao-admin-id" type="hidden" v-model="cadTipo.id_lancamento_tipo" />

                                <b-row> 
                                    <b-col md="7" sm="12"> 
                                        <b-form-group label="Descrição" label-for="descricao-tipo">
                                            <b-input id="1-descricao-tipo" type="text"
                                                :formatter="upperCase"
                                                v-model="cadTipo.descricao" required
                                                :readonly="modeTipo === 'remove'" 
                                                />                                            
                                        </b-form-group>
                                    </b-col>  

                                    <b-col md="5" sm="12" align-self="center" style="padding-top: 10px">
                                        <b-checkbox id="1-cad-tipo-ativo" v-show="modeTipo === 'save'" v-model="cadTipo.ativo" class="mt-3 mb-3" switch>Ativo</b-checkbox>
                                    </b-col>   
                                </b-row> 

                                <b-table hover striped :items="cadTipos" :fields="fieldsTipos" small>  
                                    <template v-slot:cell(actions)="data">
                                        <b-button variant="warning" @click="loadTipo(data.item)" class="mr-2">
                                            <i class="fa fa-pencil"></i>
                                        </b-button>
                                        <b-button variant="danger" @click="loadTipo(data.item, 'remove')">
                                            <i class="fa fa-trash"></i>
                                        </b-button>
                                    </template>
                                </b-table>
                                <hr>
                                <b-pagination 
                                    align="center" 
                                    size="md" 
                                    v-model="page"  
                                    :total-rows="count" 
                                    :per-page="limit" 
                                    first-number 
                                    last-number>
                                </b-pagination>

                                <template #modal-footer>
                                    <div class="w-100">                               

                                        <b-col md="10" sm="10">
                                            <b-button variant="primary" v-if="modeTipo === 'save'"
                                                @click="salvarTipo">Salvar</b-button>
                                            <b-button variant="danger" v-if="modeTipo === 'remove'"
                                                @click="removeTipo">Excluir</b-button>
                                            <b-button class="ml-2" @click="resetTipo">Cancelar</b-button>
                                        </b-col>
                                    </div>
                                </template>                        
                            </b-modal>
                        </b-col>
                        <b-col md="3" sm="12">
                            <span><strong>Produto</strong></span> 
                            <Autocomplete
                                style="margin-top: -8px"
                                id="produto-name-input"
                                :options="produtos"
                                label-key="descricao"
                                value-key="id_produto"
                                :readyOnlyX="modeItens === 'remove' ? 'true' : 'false'"
                                v-model="lancamento.idproduto"
                                placeholder=""
                                @shouldSearch="loadProdutos"
                                @select="onSelectProduto"
                                :readonly="modeItens === 'remove'" 
                            />  

                                                
                        </b-col>
                        <b-col md="2" sm="12">
                            <span><strong>Verba total</strong></span>                                     
                            <money id="1-valor-item-lancamento" v-model="lancamento.verba_total" v-bind="money" class="form-control"/>                                  
                        </b-col> 

                        <div class="w-100"></div>
                        
                        <b-col md="3" sm="12">
                            <span><strong>Abertura Carrinho</strong></span>
                            <b-input-group id="1-abertura-carrinho" class="mt-0">
                                <b-form-input id="ac-data-inicial" type="date"
                                    v-model="lancamento.abertura_carrinho"
                                    :readonly="mode === 'remove'" 
                                />
                                <b-input-group-append>
                                    <b-button variant="success" @click="carregaDatas"><i class="fa fa-check"></i></b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-col>
                        <b-col md="3" sm="12">
                            <span><strong>Fechamento Carrinho</strong></span>
                            <b-form-input id="fc-data-final" type="date"
                                v-model="lancamento.fechamento_carrinho"
                                :readonly="mode === 'remove'" 
                            />
                        </b-col>
                        <b-col md="3" sm="12">
                            <span><strong>Início Captação</strong></span>
                            <b-form-input id="ic-data-inicial" type="date"
                                v-model="lancamento.data_inicio"
                                :readonly="mode === 'remove'" 
                            />
                        </b-col>
                        <b-col md="3" sm="12">
                            <span><strong>Fim Captação</strong></span>
                            <b-form-input id="fc-data-final" type="date"
                                v-model="lancamento.data_final"
                                :readonly="mode === 'remove'" 
                            />
                        </b-col>  

                        <div class="w-100"></div>

                        <b-col md="4" sm="12">
                            <span><strong>CPL 1</strong></span>
                            <b-form-input id="d-cpl-1" type="date"
                                v-model="lancamento.data_inicio_cpl1"
                                :readonly="mode === 'remove'" 
                            />
                        </b-col>
                        <b-col md="4" sm="12">
                            <span><strong>CPL 2</strong></span>
                            <b-form-input id="d-cpl-2" type="date"
                                v-model="lancamento.data_inicio_cpl2"
                                :readonly="mode === 'remove'" 
                            />
                        </b-col>
                        <b-col md="4" sm="12">
                            <span><strong>CPL 3</strong></span>
                            <b-form-input id="d-cpl-3" type="date"
                                v-model="lancamento.data_inicio_cpl3"
                                :readonly="mode === 'remove'" 
                            />
                        </b-col>

                        <div class="w-100"></div>

                        <b-col md="6" sm="12">
                            <b-card no-body class="p-3">
                                <b-form-checkbox
                                    style="margin: 10px 0 10px 10px"
                                    id="1-checkbox-testes"
                                    v-model="lancamento.teste"
                                    name="checkbox-testes"
                                >
                                    <span><strong>Fazer período de testes de campanha</strong></span>                                    
                                </b-form-checkbox>
                                <div v-if="lancamento.teste" style="margin: -10px 0 0 10px; ">
                                    <span>Verba Teste</span>                                     
                                    <money id="1-valor-item-lancamento" v-model="lancamento.verba_total_teste" v-bind="money" class="form-control"/> 
                                    <b-row>                                        
                                        <b-col md="6" sm="12">
                                            <span>Início</span>
                                            <b-form-input type="date"
                                                v-model="lancamento.data_inicio_teste"
                                                :readonly="mode === 'remove'" 
                                            />
                                        </b-col>
                                        <b-col md="6" sm="12">
                                            <span>Fim</span>
                                            <b-form-input type="date"
                                                v-model="lancamento.data_fim_teste"
                                                :readonly="mode === 'remove'" 
                                            />
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-card>
                            
                        </b-col>

                        <b-col md="6" sm="12">
                            <b-card no-body class="p-2">
                                <div>
                                    <b-dropdown id="1-dropdown-buttons" text="Adicionar lembretes" class="m-2" variant="info">
                                        <b-dropdown-item-button 
                                            :disabled="!it.ativo" 
                                            v-for="(it, id) in lembretes" 
                                            @click="()=> {it.ativo = false; lembretesSelecionados.push(it)}" 
                                            v-bind:key="id"
                                        >
                                            {{ it.nome }}
                                        </b-dropdown-item-button>
                                    </b-dropdown>
                                </div>

                                <div style="margin-left: 10px">
                                    <b-badge class="mr-2 pl-2 pr-2" 
                                        :variant="item.cor" v-for="item in lembretesSelecionados" 
                                        v-bind:key="item.id"
                                    >
                                        {{ item.nome }} <button class="ml-1" @click="removerLembrete(item)"><i class="fa fa-times"></i></button>
                                    </b-badge>
                                </div>
                            </b-card>
                        </b-col>
                        
                    </b-row>

                    <b-row>  
                        <b-col md="12" sm="12">                                    
                            <span><strong>Detalhes do projeto</strong></span>
                            <b-form-textarea class="mb-3"
                                id="1-lanc-detalhes"
                                placeholder="Informe uma descrição de até 1000 caracteres..."
                                v-model="lancamento.detalhes"
                                rows="3"
                            ></b-form-textarea>

                            <span><strong>Observações</strong></span>
                            <b-form-textarea
                                id="1-lanc-obsr"
                                placeholder="Informe uma observação de até 200 caracteres..."
                                v-model="lancamento.obs"
                                rows="3"
                            ></b-form-textarea>
                        </b-col>
                        
                    </b-row>
                </div> 
            </b-card>
            <br>

            <b-card no-body class="p-3" style="background: rgb(233, 233, 233)">
                <div class="stepper-content">
                    <b-form-group label="Escolha as mídias sociais" v-slot="{ ariaDescribedby }">
                        <b-form-checkbox-group
                            class="ml-2"
                            id="checkbox-group-midias"
                            v-model="metricasSelecionadas"
                            :options="metricasOptions"
                            :aria-describedby="ariaDescribedby"
                            name="flavour-1"
                        ></b-form-checkbox-group>
                    </b-form-group>
                    <hr>
                    <b-row>
                        <b-col md="7" sm="12" class="p-3">
                            
                            <b-card no-body class="p-2 mb-2" v-if="ativaMidia('facebookADS')">
                                <b-row>                                        
                                    <b-col md="4" sm="12">
                                        <span><strong>Facebook (%)</strong></span>
                                        <b-form-input id="2-face-lanc-descricao" type="number"
                                            v-model="facebookADS.plan_porc_capital"
                                            :readonly="mode === 'remove'" 
                                            @input="calculaCapital()"
                                            min="0"
                                            max="100"
                                        />
                                    </b-col>  

                                    <b-col md="4" sm="12" v-if="lancamento.teste">
                                        <span><strong>Teste (R$)</strong></span>                                   
                                        <money id="2-face-valor-item-lancamento" v-model="facebookADS.valor_teste" v-bind="moneyNoPrefix" class="form-control"/>
                                    </b-col>  

                                    <b-col md="4" sm="12">
                                        <money type="text" v-model="facebookADS.valor_capital" v-bind="moneyNoPrefix" readonly class="moeda-totais-midias"/>
                                    </b-col>
                                
                                </b-row>
                            </b-card>

                            <b-card no-body class="p-2 mb-2" v-if="ativaMidia('googleADS')">
                                <b-row>                                        
                                    <b-col md="4" sm="12">
                                        <span><strong>Google (%)</strong></span>
                                        <b-form-input id="2-goo-lanc-descricao" type="number"
                                            v-model="googleADS.plan_porc_capital"
                                            :readonly="mode === 'remove'" 
                                            @input="calculaCapital()"
                                            min="0"
                                            max="100"
                                        />
                                    </b-col>  

                                    <b-col md="4" sm="12" v-if="lancamento.teste">
                                        <span><strong>Teste (R$)</strong></span>                                   
                                        <money id="2-goo-valor-item-lancamento" v-model="googleADS.valor_teste" v-bind="moneyNoPrefix" class="form-control"/>
                                    </b-col>  

                                    <b-col md="4" sm="12">
                                        <money type="text" v-model="googleADS.valor_capital" v-bind="moneyNoPrefix" readonly class="moeda-totais-midias"/>
                                    </b-col>
                                
                                </b-row>
                            </b-card>

                            <b-card no-body class="p-2 mb-2" v-if="ativaMidia('tiktokADS')">
                                <b-row>                                        
                                    <b-col md="4" sm="12">
                                        <span><strong>Tiktok (%)</strong></span>
                                        <b-form-input id="2-tik-lanc-descricao" type="number"
                                            v-model="tiktokADS.plan_porc_capital"
                                            :readonly="mode === 'remove'" 
                                            @input="calculaCapital()"
                                            min="0"
                                            max="100"
                                        />
                                    </b-col>  

                                    <b-col md="4" sm="12" v-if="lancamento.teste">
                                        <span><strong>Teste (R$)</strong></span>                                   
                                        <money id="2-tik-valor-item-lancamento" v-model="tiktokADS.valor_teste" v-bind="moneyNoPrefix" class="form-control"/>
                                    </b-col>  

                                    <b-col md="4" sm="12">
                                        <money type="text" v-model="tiktokADS.valor_capital" v-bind="moneyNoPrefix" readonly class="moeda-totais-midias"/>
                                    </b-col>
                                
                                </b-row>
                            </b-card>
                    
                        </b-col>
                        
                        <b-col md="5" sm="12">
                            <b-card bg-variant="success" text-variant="white"  class="mb-3 p-3" no-body>
                                <span>Verba total disponível</span>
                                <money type="text" v-model="lancamento.verba_total" v-bind="money" readonly class="moeda-totais-verba-total"/>
                                <span>Reservado testes</span>
                                <money type="text" v-model="lancamento.verba_total_teste" v-bind="money" readonly class="moeda-totais-verba-total"/>
                            </b-card>
                            <b-card no-body class="p-3">
                                <b-card-text>
                                    <h5>Distribuída</h5>
                                    <div class="totais">
                                        <money 
                                            type="text" 
                                            v-model="lancamento.perc_distribuido" 
                                            v-bind="moneyDet" 
                                            readonly 
                                            :class="checaDistri('P', lancamento.perc_distribuido)"
                                        />
                                        <money 
                                            type="text" 
                                            v-model="lancamento.vlr_distribuido" 
                                            v-bind="money" 
                                            readonly 
                                            :class="checaDistri('V', lancamento.perc_distribuido)"
                                        /> 
                                    </div>
                                </b-card-text>
                                <b-card-text>
                                    <h5>Restante</h5> 
                                    <div class="totais">
                                        <money 
                                            type="text" 
                                            v-model="lancamento.perc_restante" 
                                            v-bind="moneyDet" 
                                            readonly 
                                            :class="checaDistri('P', lancamento.perc_distribuido)"
                                        />
                                        <money 
                                            type="text" 
                                            v-model="lancamento.vlr_restante" 
                                            v-bind="money" 
                                            readonly 
                                            :class="checaDistri('V', lancamento.perc_distribuido)"
                                        /> 
                                    </div>
                                </b-card-text>
                            </b-card>
                            
                        </b-col>
                    </b-row>
                </div>               
            </b-card>
            <br>

            <b-card no-body class="p-3" >
                <div class="stepper-content"> 
                    <b-card
                        v-if="ativaMidia('facebookADS')"
                        :title="`Facebook ADS - ${calculaTitulomidia('facebookADS')}`"
                        border-variant="primary"      
                        align="left"
                        class="mt-3"
                    >                                
                        <b-row> 
                            <b-col md="3" sm="12">
                                <b-form-group
                                    label="Captação (%)"
                                    label-for="input-cap-facebookADS"
                                    :description="((facebookADS.valor_capital * facebookADS.plan_porc_captacao) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})"
                                    class="mb-0"
                                >
                                    <b-form-input
                                        size="sm"
                                        type="number"
                                        id="input-cap-facebookADS"
                                        v-model="facebookADS.plan_porc_captacao"
                                        :state="valida0a100(facebookADS.plan_porc_captacao)"
                                        aria-describedby="feedback-cap-facebook"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="feedback-cap-facebook">
                                        Valor deve ser de 0 a 100%!
                                    </b-form-invalid-feedback>
                                </b-form-group>                                        
                            </b-col> 

                            <b-col md="3" sm="12" v-if="lembretesSelecionados.length > 0">
                                <b-form-group
                                    label="Antecipação (%)"
                                    label-for="input-ant-facebook"
                                    :description="((facebookADS.valor_capital * facebookADS.plan_porc_antecipacao) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})"
                                    class="mb-0"
                                >
                                    <b-form-input
                                        size="sm"
                                        type="number"
                                        id="input-ant-facebook"
                                        v-model="facebookADS.plan_porc_antecipacao"
                                        :state="valida0a100(facebookADS.plan_porc_antecipacao)"
                                        aria-describedby="feedback-ant-facebook"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="feedback-ant-facebook">
                                        Valor deve ser de 0 a 100%!
                                    </b-form-invalid-feedback>
                                </b-form-group>                                        
                            </b-col> 
                                
                            <b-col md="3" sm="12">
                                <b-form-group
                                    label="CPL (%)"
                                    label-for="input-cpl-facebook"
                                    :description="((facebookADS.valor_capital * facebookADS.plan_porc_camp_pre_lanc) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})"
                                    class="mb-0"
                                >
                                    <b-form-input
                                        size="sm"
                                        type="number"
                                        id="input-cpl-facebook"
                                        v-model="facebookADS.plan_porc_camp_pre_lanc"
                                        :state="valida0a100(facebookADS.plan_porc_camp_pre_lanc)"
                                        aria-describedby="feedback-cpl-facebookADS"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="feedback-cpl-facebookADS">
                                        Valor deve ser de 0 a 100%!
                                    </b-form-invalid-feedback>
                                </b-form-group>                                        
                            </b-col> 

                            <b-col md="3" sm="12">
                                <b-form-group                                        
                                    label="Vendas (%)"
                                    label-for="input-vendas-facebook"
                                    :description="((facebookADS.valor_capital * facebookADS.plan_porc_vendas) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})"
                                    class="mb-0"
                                >
                                    <b-form-input
                                        size="sm"
                                        type="number"
                                        id="input-vendas-facebook"
                                        v-model="facebookADS.plan_porc_vendas"
                                        :state="valida0a100(facebookADS.plan_porc_vendas)"
                                        aria-describedby="feedback-vendas-facebookADS"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="feedback-vendas-facebookADS">
                                        Valor deve ser de 0 a 100%!
                                    </b-form-invalid-feedback>
                                </b-form-group>                                        
                            </b-col>                                                                         
                        </b-row>                                
                    </b-card>

                    <b-card
                        v-if="ativaMidia('googleADS')"
                        :title="`Google ADS - ${calculaTitulomidia('googleADS')}`"
                        border-variant="primary"      
                        align="left"
                        class="mt-3"
                    >                                
                        <b-row> 
                            <b-col md="3" sm="12">
                                <b-form-group
                                    label="Captação (%)"
                                    label-for="input-cap-google"
                                    :description="((googleADS.valor_capital * googleADS.plan_porc_captacao) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})"
                                    class="mb-0"
                                >
                                    <b-form-input
                                        size="sm"
                                        type="number"
                                        id="input-cap-google"
                                        v-model="googleADS.plan_porc_captacao"
                                        :state="valida0a100(googleADS.plan_porc_captacao)"
                                        aria-describedby="feedback-cap-google"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="feedback-cap-google">
                                        Valor deve ser de 0 a 100%!
                                    </b-form-invalid-feedback>
                                </b-form-group>                                        
                            </b-col> 

                            <b-col md="3" sm="12" v-if="lembretesSelecionados.length > 0">
                                <b-form-group
                                    label="Antecipação (%)"
                                    label-for="input-ant-google"
                                    :description="((googleADS.valor_capital * googleADS.plan_porc_antecipacao) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})"
                                    class="mb-0"
                                >
                                    <b-form-input
                                        size="sm"
                                        type="number"
                                        id="input-ant-google"
                                        v-model="googleADS.plan_porc_antecipacao"
                                        :state="valida0a100(googleADS.plan_porc_antecipacao)"
                                        aria-describedby="feedback-ant-google"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="feedback-ant-google">
                                        Valor deve ser de 0 a 100%!
                                    </b-form-invalid-feedback>
                                </b-form-group>                                        
                            </b-col> 
                                
                            <b-col md="3" sm="12">
                                <b-form-group
                                    label="CPL (%)"
                                    label-for="input-cpl-google"
                                    :description="((googleADS.valor_capital * googleADS.plan_porc_camp_pre_lanc) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})"
                                    class="mb-0"
                                >
                                    <b-form-input
                                        size="sm"
                                        type="number"
                                        id="input-cpl-google"
                                        v-model="googleADS.plan_porc_camp_pre_lanc"
                                        :state="valida0a100(googleADS.plan_porc_camp_pre_lanc)"
                                        aria-describedby="feedback-cpl-google"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="feedback-cpl-google">
                                        Valor deve ser de 0 a 100%!
                                    </b-form-invalid-feedback>
                                </b-form-group>                                        
                            </b-col> 

                            <b-col md="3" sm="12">
                                <b-form-group                                        
                                    label="Vendas (%)"
                                    label-for="input-vendas-google"
                                    :description="((googleADS.valor_capital * googleADS.plan_porc_vendas) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})"
                                    class="mb-0"
                                >
                                    <b-form-input
                                        size="sm"
                                        type="number"
                                        id="input-vendas-google"
                                        v-model="googleADS.plan_porc_vendas"
                                        :state="valida0a100(googleADS.plan_porc_vendas)"
                                        aria-describedby="feedback-vendas-google"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="feedback-vendas-google">
                                        Valor deve ser de 0 a 100%!
                                    </b-form-invalid-feedback>
                                </b-form-group>                                        
                            </b-col>                                     
                        </b-row>
                    </b-card>

                    <b-card
                        v-if="ativaMidia('tiktokADS')"
                        :title="`Tiktok ADS - ${calculaTitulomidia('tiktokADS')}`"
                        border-variant="primary"      
                        align="left"
                        class="mt-3"
                    >                                
                        <b-row> 
                            <b-col md="3" sm="12">
                                <b-form-group
                                    label="Captação (%)"
                                    label-for="input-cap-tiktok"
                                    :description="((tiktokADS.valor_capital * tiktokADS.plan_porc_captacao) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})"
                                    class="mb-0"
                                >
                                    <b-form-input
                                        size="sm"
                                        type="number"
                                        id="input-cap-tiktok"
                                        v-model="tiktokADS.plan_porc_captacao"
                                        :state="valida0a100(tiktokADS.plan_porc_captacao)"
                                        aria-describedby="feedback-cap-tiktok"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="feedback-cap-tiktok">
                                        Valor deve ser de 0 a 100%!
                                    </b-form-invalid-feedback>
                                </b-form-group>                                        
                            </b-col> 

                            <b-col md="3" sm="12" v-if="lembretesSelecionados.length > 0">
                                <b-form-group
                                    label="Antecipação (%)"
                                    label-for="input-ant-tiktok"
                                    :description="((tiktokADS.valor_capital * tiktokADS.plan_porc_antecipacao) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})"
                                    class="mb-0"
                                >
                                    <b-form-input
                                        size="sm"
                                        type="number"
                                        id="input-ant-tiktok"
                                        v-model="tiktokADS.plan_porc_antecipacao"
                                        :state="valida0a100(tiktokADS.plan_porc_antecipacao)"
                                        aria-describedby="feedback-ant-tiktok"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="feedback-ant-tiktok">
                                        Valor deve ser de 0 a 100%!
                                    </b-form-invalid-feedback>
                                </b-form-group>                                        
                            </b-col> 
                                
                            <b-col md="3" sm="12">
                                <b-form-group
                                    label="CPL (%)"
                                    label-for="input-cpl-tiktok"
                                    :description="((tiktokADS.valor_capital * tiktokADS.plan_porc_camp_pre_lanc) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})"
                                    class="mb-0"
                                >
                                    <b-form-input
                                        size="sm"
                                        type="number"
                                        id="input-cpl-tiktok"
                                        v-model="tiktokADS.plan_porc_camp_pre_lanc"
                                        :state="valida0a100(tiktokADS.plan_porc_camp_pre_lanc)"
                                        aria-describedby="feedback-cpl-tiktok"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="feedback-cpl-tiktok">
                                        Valor deve ser de 0 a 100%!
                                    </b-form-invalid-feedback>
                                </b-form-group>                                        
                            </b-col> 

                            <b-col md="3" sm="12">
                                <b-form-group                                        
                                    label="Vendas (%)"
                                    label-for="input-vendas-tiktok"
                                    :description="((tiktokADS.valor_capital * tiktokADS.plan_porc_vendas) /100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})"
                                    class="mb-0"
                                >
                                    <b-form-input
                                        size="sm"
                                        type="number"
                                        id="input-vendas-tiktok"
                                        v-model="tiktokADS.plan_porc_vendas"
                                        :state="valida0a100(tiktokADS.plan_porc_vendas)"
                                        aria-describedby="feedback-vendas-tiktok"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="feedback-vendas-tiktok">
                                        Valor deve ser de 0 a 100%!
                                    </b-form-invalid-feedback>
                                </b-form-group>                                        
                            </b-col>                                     
                        </b-row>
                    </b-card>
                </div>
            </b-card>


            <template #modal-footer>
                <div class="w-100">
                    <b-button
                        variant="danger"
                        size="md"
                        class="float-right"
                        @click="resetLancamento"
                    >
                        Cancelar
                    </b-button>
                    <b-button
                        variant="success"
                        size="md"
                        class="float-right mr-2"
                        @click="salvarLancamento"
                    >
                        Salvar
                    </b-button>
                </div>
            </template>
        </b-modal> 

        <b-modal size="lg" title="Inserir dados" v-model="showModalInserirDados">            
                            
            <h5>Financeiro</h5>
            <b-row>
                <b-col md="3" sm="12">
                    Verba Total Utilizada
                    <money v-model="lancamento.verba_total_utilizada" v-bind="money" class="form-control"/> 
                </b-col>
                <b-col md="3" sm="12">
                    Verba Teste Utilizada
                    <money v-model="lancamento.verba_total_teste_utilizada" v-bind="money" class="form-control"/> 
                </b-col>
                <b-col md="3" sm="12">
                    Faturamento
                    <money v-model="lancamento.faturamento" v-bind="money" class="form-control"/>
                </b-col>
                <b-col md="3" sm="12"> 
                    Nº de Vendas 
                    <b-form-input type="number" v-model="lancamento.qtde_vendas"/> 
                </b-col>
            </b-row>
            
            <hr>   
            <h5>Leeds de outras fontes / Outros dados</h5>
            <b-row>
                <b-col md="4" sm="12">
                    Leads Facebook
                    <b-form-input id="pes-data-inicial" type="number" v-model="lancamento.leads_facebook"/>
                </b-col>
                <b-col md="4" sm="12">
                    Leads Google
                    <b-form-input id="pes-data-inicial" type="number" v-model="lancamento.leads_google"/>
                </b-col>
                <b-col md="4" sm="12">
                    Leads TikTok
                    <b-form-input id="pes-data-inicial" type="number" v-model="lancamento.leads_tiktok"/>
                </b-col>
            </b-row>  
            <b-row>
                <b-col md="3" sm="12">
                    Total de Leads 
                    <b-form-input id="pes-data-inicial" type="number" v-model="lancamento.qtde_leeds"/>
                </b-col>
                <b-col md="3" sm="12">
                    Leads Orgânicos
                    <b-form-input id="pes-data-inicial" type="number" v-model="lancamento.leads_organicos"/> 
                </b-col>
                <b-col md="3" sm="12">
                    Nº Grupos
                    <b-form-input id="pes-data-inicial" type="number" v-model="lancamento.num_grupos"/>  
                </b-col>
                <b-col md="3" sm="12">
                    Grupos super Int
                    <b-form-input id="pes-data-inicial" type="number" v-model="lancamento.grupos_super_int"/>  
                </b-col>
            </b-row>  

            <template #modal-footer>
                <div class="w-100">
                    <b-button
                        variant="success"
                        size="md"
                        class="float-right"
                        @click="salvarMetricas"
                    >
                        Inserir
                    </b-button>
                </div>
            </template>
        </b-modal>    

        <!-- TABELA LANCAMENTOS -->
        <b-card class="cadastros-card-corpo" v-show="show" >
            
            <b-table hover striped :items="lancamentos" :fields="fields" sticky-header v-if="lancamentos.length> 0">  
                <template v-slot:cell(actions)="data">
                    <b-button @click="getMetricas(data.item, 1)" class="mr-2">
                        <i class="fa fa-facebook"></i>
                    </b-button>
                    
                    <b-button @click="getMetricas(data.item, 2)" class="mr-2">
                        <i class="fa fa-google"></i>
                    </b-button>
                    
                    <b-button @click="getMetricas(data.item, 3)" class="mr-2">
                        <i class="fa fa-star"></i>
                    </b-button>
                    
                    <b-button @click="getMetricas(data.item, false)" class="mr-2">
                        <i class="fa fa-inbox"></i>
                    </b-button>

                    <b-button variant="primary" @click="dashLancExibir(data.item)" class="mr-2">
                        <i class="fa fa-tachometer"></i>
                    </b-button>

                    <b-button variant="success" @click="editarLancamento(data.item)" class="mr-2">
                        <i class="fa fa-pencil-square-o"></i>
                    </b-button>
                    
                    <b-button v-if="false" variant="danger" @click="dashLancExibir()">
                        <i class="fa fa-trash"></i>
                    </b-button>
                </template>
            </b-table>
            <hr>

            <b-pagination 
                v-if="lancamentos.length > 0"
                align="center" 
                size="md" 
                v-model="page"  
                :total-rows="count" 
                :per-page="limit" 
                first-number 
                last-number>
            </b-pagination>
            <span v-else style="color: rgb(114, 114, 114)">Faça uma consulta para exibir os resultados...</span>

            
        </b-card> 

        <b-modal id="dados-modal-facebook" size="lg" :title="`Metricas: ${metricaAtiva}`" v-model="showModalMetricas"> 
            <div v-if="metricaAtiva==='facebook'"> <!-- facebook -->
                
                <h5>Verba utilizada</h5>                                   
                <b-row> 
                    <b-col md="3" sm="12">
                        Captação
                        <money id="1-valor-item-lancamento" v-model="facebookADS.verba_total_utilizada" v-bind="money" class="form-control"/> 
                    </b-col>
                    <b-col md="2" sm="12">
                        Teste
                        <money id="1-valor-item-lancamento" v-model="facebookADS.verba_teste_utilizada" v-bind="money" class="form-control"/> 
                    </b-col>
                    <b-col md="2" sm="12">
                        Lembrete
                        <money id="1-valor-item-lancamento" v-model="facebookADS.verba_lembrete_utilizada" v-bind="money" class="form-control"/> 
                    </b-col>
                    <b-col md="2" sm="12">
                        CPL
                        <money id="1-valor-item-lancamento" v-model="facebookADS.verba_cpl_utilizada" v-bind="money" class="form-control"/> 
                    </b-col>
                    <b-col md="3" sm="12">
                        Carrinho Aberto
                        <money id="1-valor-item-lancamento" v-model="facebookADS.verba_carrinho_utilizada" v-bind="money" class="form-control"/> 
                    </b-col>
                </b-row> 
                <br>
                <h5>Captação</h5>   
                <b-row> 
                    <b-col md="2" sm="12">
                        Alcance
                        <b-form-input id="pes-data-inicial" type="number" v-model="facebookADS.captacao_alcance"/>  
                        Views Página
                        <b-form-input id="pes-data-inicial" type="number" v-model="facebookADS.captacao_views_pagina"/> 
                    </b-col>
                    <b-col md="2" sm="12">
                        Impressões
                        <b-form-input id="pes-data-inicial" type="number" v-model="facebookADS.captacao_impressoes"/>  
                        CPL
                        <money id="1-valor-item-lancamento" v-model="facebookADS.captacao_cpl" v-bind="money" class="form-control"/> 
                    </b-col>
                    <b-col md="2" sm="12">
                        Leads
                        <b-form-input id="pes-data-inicial" type="number" v-model="facebookADS.captacao_leads"/> 
                        CPM
                        <b-form-input id="pes-data-inicial" type="number" v-model="facebookADS.captacao_cpm"/> 
                    </b-col>
                    <b-col md="2" sm="12">
                        Frequência
                        <b-form-input id="pes-data-inicial" type="number" v-model="facebookADS.captacao_frequencia"/>  
                        CTR (link)
                        <b-form-input id="pes-data-inicial" type="number" v-model="facebookADS.captacao_ctr"/> 
                    </b-col>
                    <b-col md="2" sm="12">
                        Cliques Link
                        <b-form-input id="pes-data-inicial" type="number" v-model="facebookADS.captacao_cliques"/>  
                        Visualizações
                        <b-form-input id="pes-data-inicial" type="number" v-model="facebookADS.captacao_visualizacoes"/>  
                    </b-col>
                     <b-col md="2" sm="12">
                        Pub. Quente
                        <b-form-input id="pes-data-inicial" type="number" v-model="facebookADS.publico_quente"/>  
                        Verba Quente
                        <money v-model="facebookADS.publico_quente_verba" v-bind="money" class="form-control"/> 
                    </b-col>
                </b-row>
                <br>
                <h5>Antecedência (Lembretes)</h5> 
                <b-row>
                    <b-col md="4" sm="12">
                        Alcance
                        <b-form-input id="pes-data-inicial" type="number" v-model="facebookADS.lembrete_alcance"/>  
                    </b-col>
                    <b-col md="4" sm="12">
                        Frequência
                        <b-form-input id="pes-data-inicial" type="number" v-model="facebookADS.lembrete_frequencia"/>  
                    </b-col>
                </b-row>

                <br>
                <h5>CPL's</h5> 
                <b-row>
                    <b-col md="4" sm="12">
                        Leads CPL 1
                        <b-form-input id="pes-data-inicial" type="number" v-model="facebookADS.leads_cpl1"/>  
                    </b-col>
                    <b-col md="4" sm="12">
                        Leads CPL 2
                        <b-form-input id="pes-data-inicial" type="number" v-model="facebookADS.leads_cpl2"/>  
                    </b-col>
                    <b-col md="4" sm="12">
                        Leads CPL 3
                        <b-form-input id="pes-data-inicial" type="number" v-model="facebookADS.leads_cpl3"/>  
                    </b-col>
                </b-row> 
                <b-row>
                    <b-col md="2" sm="12">
                        Alcance
                        <b-form-input id="pes-data-inicial" type="number" v-model="facebookADS.cpl_alcance"/> 
                    </b-col>
                    <b-col md="2" sm="12">
                        Frequência
                        <b-form-input id="pes-data-inicial" type="number" v-model="facebookADS.cpl_frequencia"/> 
                    </b-col>
                    <b-col md="2" sm="12">
                        Cliques
                        <b-form-input id="pes-data-inicial" type="number" v-model="facebookADS.cpl_cliques"/> 
                    </b-col>
                    <b-col md="3" sm="12">
                        CPC  
                        <money v-model="facebookADS.cpl_cpc" v-bind="money" class="form-control"/>
                    </b-col>
                    <b-col md="3" sm="12">
                        CTR
                        <money v-model="facebookADS.cpl_ctr" v-bind="money" class="form-control"/> 
                    </b-col>
                </b-row>

                <br>
                <h5>Carrinho Aberto</h5> 
                <b-row>
                    <b-col md="3" sm="12">
                        Alcance
                        <b-form-input id="pes-data-inicial" type="number" v-model="facebookADS.carrinho_alcance"/>  
                    </b-col>
                    <b-col md="2" sm="12">
                        Frequência
                        <b-form-input id="pes-data-inicial" type="number" v-model="facebookADS.carrinho_frequencia"/>  
                         
                    </b-col>
                    <b-col md="2" sm="12">
                        Cliques
                        <b-form-input id="pes-data-inicial" type="number" v-model="facebookADS.carrinho_cliques"/> 
                    </b-col>
                    <b-col md="3" sm="12">
                        CPC
                        <money v-model="facebookADS.carrinho_cpc" v-bind="money" class="form-control"/> 
                        
                    </b-col>
                    <b-col md="2" sm="12">
                        Vendas
                        <b-form-input id="pes-data-inicial" type="number" v-model="facebookADS.carrinho_vendas"/>  
                    </b-col>

                </b-row>                 

            </div>
           
            <div v-if="metricaAtiva==='google'"> <!-- google -->
                <h5>Verba utilizada</h5>                                   
                <b-row> 
                    <b-col md="3" sm="12">
                        Captação
                        <money id="1-valor-item-lancamento" v-model="googleADS.verba_total_utilizada" v-bind="money" class="form-control"/> 
                    </b-col>
                    <b-col md="2" sm="12">
                        Teste
                        <money id="1-valor-item-lancamento" v-model="googleADS.verba_teste_utilizada" v-bind="money" class="form-control"/> 
                    </b-col>
                    <b-col md="2" sm="12">
                        Lembrete
                        <money id="1-valor-item-lancamento" v-model="googleADS.verba_lembrete_utilizada" v-bind="money" class="form-control"/> 
                    </b-col>
                    <b-col md="2" sm="12">
                        CPL
                        <money id="1-valor-item-lancamento" v-model="googleADS.verba_cpl_utilizada" v-bind="money" class="form-control"/> 
                    </b-col>
                    <b-col md="3" sm="12">
                        Carrinho Aberto
                        <money id="1-valor-item-lancamento" v-model="googleADS.verba_carrinho_utilizada" v-bind="money" class="form-control"/> 
                    </b-col>
                </b-row> 
                <br>
                <h5>Captação</h5>   
                <b-row> 
                    <b-col md="2" sm="12">
                        Alcance
                        <b-form-input id="pes-data-inicial" type="number" v-model="googleADS.captacao_alcance"/>  
                        Views Página
                        <b-form-input id="pes-data-inicial" type="number" v-model="googleADS.captacao_views_pagina"/> 
                    </b-col>
                    <b-col md="2" sm="12">
                        Impressões
                        <b-form-input id="pes-data-inicial" type="number" v-model="googleADS.captacao_impressoes"/>  
                        CPL
                        <money id="1-valor-item-lancamento" v-model="googleADS.captacao_cpl" v-bind="money" class="form-control"/> 
                    </b-col>
                    <b-col md="2" sm="12">
                        Leads
                        <b-form-input id="pes-data-inicial" type="number" v-model="googleADS.captacao_leads"/> 
                        CPM
                        <b-form-input id="pes-data-inicial" type="number" v-model="googleADS.captacao_cpm"/> 
                    </b-col>
                    <b-col md="2" sm="12">
                        Frequência
                        <b-form-input id="pes-data-inicial" type="number" v-model="googleADS.captacao_frequencia"/>  
                        CTR (link)
                        <b-form-input id="pes-data-inicial" type="number" v-model="googleADS.captacao_ctr"/> 
                    </b-col>
                    <b-col md="2" sm="12">
                        Cliques Link
                        <b-form-input id="pes-data-inicial" type="number" v-model="googleADS.captacao_cliques"/>  
                        Visualizações
                        <b-form-input id="pes-data-inicial" type="number" v-model="googleADS.captacao_visualizacoes"/>  
                    </b-col>
                    <b-col md="2" sm="12">
                        Pub. Quente
                        <b-form-input id="pes-data-inicial" type="number" v-model="googleADS.publico_quente"/>  
                        Verba Quente
                        <money v-model="googleADS.publico_quente_verba" v-bind="money" class="form-control"/>  
                    </b-col>
                </b-row>
                <br>
                <h5>Antecedência (Lembretes)</h5> 
                <b-row>
                    <b-col md="4" sm="12">
                        Alcance
                        <b-form-input id="pes-data-inicial" type="number" v-model="googleADS.lembrete_alcance"/>  
                    </b-col>
                    <b-col md="4" sm="12">
                        Frequência
                        <b-form-input id="pes-data-inicial" type="number" v-model="googleADS.lembrete_frequencia"/>  
                    </b-col>
                </b-row>

                <br>
                <h5>CPL's</h5> 
                <b-row>
                    <b-col md="4" sm="12">
                        Leads CPL 1
                        <b-form-input id="pes-data-inicial" type="number" v-model="googleADS.leads_cpl1"/>  
                    </b-col>
                    <b-col md="4" sm="12">
                        Leads CPL 2
                        <b-form-input id="pes-data-inicial" type="number" v-model="googleADS.leads_cpl2"/>  
                    </b-col>
                    <b-col md="4" sm="12">
                        Leads CPL 3
                        <b-form-input id="pes-data-inicial" type="number" v-model="googleADS.leads_cpl3"/>  
                    </b-col>
                </b-row> 
                <b-row>
                    <b-col md="2" sm="12">
                        Alcance
                        <b-form-input id="pes-data-inicial" type="number" v-model="googleADS.cpl_alcance"/> 
                    </b-col>
                    <b-col md="2" sm="12">
                        Frequência
                        <b-form-input id="pes-data-inicial" type="number" v-model="googleADS.cpl_frequencia"/> 
                    </b-col>
                    <b-col md="2" sm="12">
                        Cliques
                        <b-form-input id="pes-data-inicial" type="number" v-model="googleADS.cpl_cliques"/> 
                    </b-col>
                    <b-col md="3" sm="12">
                        CPC  
                        <money v-model="googleADS.cpl_cpc" v-bind="money" class="form-control"/>
                    </b-col>
                    <b-col md="3" sm="12">
                        CTR
                        <money v-model="googleADS.cpl_ctr" v-bind="money" class="form-control"/> 
                    </b-col>
                </b-row>

                <br>
                <h5>Carrinho Aberto</h5> 
                <b-row>
                    <b-col md="3" sm="12">
                        Alcance
                        <b-form-input id="pes-data-inicial" type="number" v-model="googleADS.carrinho_alcance"/>  
                    </b-col>
                    <b-col md="2" sm="12">
                        Frequência
                        <b-form-input id="pes-data-inicial" type="number" v-model="googleADS.carrinho_frequencia"/>  
                         
                    </b-col>
                    <b-col md="2" sm="12">
                        Cliques
                        <b-form-input id="pes-data-inicial" type="number" v-model="googleADS.carrinho_cliques"/> 
                    </b-col>
                    <b-col md="3" sm="12">
                        CPC
                        <money v-model="googleADS.carrinho_cpc" v-bind="money" class="form-control"/> 
                        
                    </b-col>
                    <b-col md="2" sm="12">
                        Vendas
                        <b-form-input id="pes-data-inicial" type="number" v-model="googleADS.carrinho_vendas"/>  
                    </b-col>

                </b-row>
            </div>

            <div v-if="metricaAtiva==='tiktok'"> <!-- tiktok -->
                <h5>Verba utilizada</h5>                                   
                <b-row> 
                    <b-col md="3" sm="12">
                        Captação
                        <money id="1-valor-item-lancamento" v-model="tiktokADS.verba_total_utilizada" v-bind="money" class="form-control"/> 
                    </b-col>
                    <b-col md="2" sm="12">
                        Teste
                        <money id="1-valor-item-lancamento" v-model="tiktokADS.verba_teste_utilizada" v-bind="money" class="form-control"/> 
                    </b-col>
                    <b-col md="2" sm="12">
                        Lembrete
                        <money id="1-valor-item-lancamento" v-model="tiktokADS.verba_lembrete_utilizada" v-bind="money" class="form-control"/> 
                    </b-col>
                    <b-col md="2" sm="12">
                        CPL
                        <money id="1-valor-item-lancamento" v-model="tiktokADS.verba_cpl_utilizada" v-bind="money" class="form-control"/> 
                    </b-col>
                    <b-col md="3" sm="12">
                        Carrinho Aberto
                        <money id="1-valor-item-lancamento" v-model="tiktokADS.verba_carrinho_utilizada" v-bind="money" class="form-control"/> 
                    </b-col>
                </b-row> 
                <br>
                <h5>Captação</h5>   
                <b-row> 
                    <b-col md="2" sm="12">
                        Alcance
                        <b-form-input id="pes-data-inicial" type="number" v-model="tiktokADS.captacao_alcance"/>  
                        Views Página
                        <b-form-input id="pes-data-inicial" type="number" v-model="tiktokADS.captacao_views_pagina"/> 
                    </b-col>
                    <b-col md="2" sm="12">
                        Impressões
                        <b-form-input id="pes-data-inicial" type="number" v-model="tiktokADS.captacao_impressoes"/>  
                        CPL
                        <money id="1-valor-item-lancamento" v-model="tiktokADS.captacao_cpl" v-bind="money" class="form-control"/> 
                    </b-col>
                    <b-col md="2" sm="12">
                        Leads
                        <b-form-input id="pes-data-inicial" type="number" v-model="tiktokADS.captacao_leads"/> 
                        CPM
                        <b-form-input id="pes-data-inicial" type="number" v-model="tiktokADS.captacao_cpm"/> 
                    </b-col>
                    <b-col md="2" sm="12">
                        Frequência
                        <b-form-input id="pes-data-inicial" type="number" v-model="tiktokADS.captacao_frequencia"/>  
                        CTR (link)
                        <b-form-input id="pes-data-inicial" type="number" v-model="tiktokADS.captacao_ctr"/> 
                    </b-col>
                    <b-col md="2" sm="12">
                        Cliques Link
                        <b-form-input id="pes-data-inicial" type="number" v-model="tiktokADS.captacao_cliques"/>  
                        Visualizações
                        <b-form-input id="pes-data-inicial" type="number" v-model="tiktokADS.captacao_visualizacoes"/>  
                    </b-col>
                     <b-col md="2" sm="12">
                        Pub. Quente
                        <b-form-input id="pes-data-inicial" type="number" v-model="tiktokADS.publico_quente"/>  
                        Verba Quente
                        <money v-model="tiktokADS.publico_quente_verba" v-bind="money" class="form-control"/>   
                    </b-col>
                </b-row>
                <br>
                <h5>Antecedência (Lembretes)</h5> 
                <b-row>
                    <b-col md="4" sm="12">
                        Alcance
                        <b-form-input id="pes-data-inicial" type="number" v-model="tiktokADS.lembrete_alcance"/>  
                    </b-col>
                    <b-col md="4" sm="12">
                        Frequência
                        <b-form-input id="pes-data-inicial" type="number" v-model="tiktokADS.lembrete_frequencia"/>  
                    </b-col>
                </b-row>

                <br>
                <h5>CPL's</h5> 
                <b-row>
                    <b-col md="4" sm="12">
                        Leads CPL 1
                        <b-form-input id="pes-data-inicial" type="number" v-model="tiktokADS.leads_cpl1"/>  
                    </b-col>
                    <b-col md="4" sm="12">
                        Leads CPL 2
                        <b-form-input id="pes-data-inicial" type="number" v-model="tiktokADS.leads_cpl2"/>  
                    </b-col>
                    <b-col md="4" sm="12">
                        Leads CPL 3
                        <b-form-input id="pes-data-inicial" type="number" v-model="tiktokADS.leads_cpl3"/>  
                    </b-col>
                </b-row> 
                <b-row>
                    <b-col md="2" sm="12">
                        Alcance
                        <b-form-input id="pes-data-inicial" type="number" v-model="tiktokADS.cpl_alcance"/> 
                    </b-col>
                    <b-col md="2" sm="12">
                        Frequência
                        <b-form-input id="pes-data-inicial" type="number" v-model="tiktokADS.cpl_frequencia"/> 
                    </b-col>
                    <b-col md="2" sm="12">
                        Cliques
                        <b-form-input id="pes-data-inicial" type="number" v-model="tiktokADS.cpl_cliques"/> 
                    </b-col>
                    <b-col md="3" sm="12">
                        CPC  
                        <money v-model="tiktokADS.cpl_cpc" v-bind="money" class="form-control"/>
                    </b-col>
                    <b-col md="3" sm="12">
                        CTR
                        <money v-model="tiktokADS.cpl_ctr" v-bind="money" class="form-control"/> 
                    </b-col>
                </b-row>

                <br>
                <h5>Carrinho Aberto</h5> 
                <b-row>
                    <b-col md="3" sm="12">
                        Alcance
                        <b-form-input id="pes-data-inicial" type="number" v-model="tiktokADS.carrinho_alcance"/>  
                    </b-col>
                    <b-col md="2" sm="12">
                        Frequência
                        <b-form-input id="pes-data-inicial" type="number" v-model="tiktokADS.carrinho_frequencia"/>  
                         
                    </b-col>
                    <b-col md="2" sm="12">
                        Cliques
                        <b-form-input id="pes-data-inicial" type="number" v-model="tiktokADS.carrinho_cliques"/> 
                    </b-col>
                    <b-col md="3" sm="12">
                        CPC
                        <money v-model="tiktokADS.carrinho_cpc" v-bind="money" class="form-control"/> 
                        
                    </b-col>
                    <b-col md="2" sm="12">
                        Vendas
                        <b-form-input id="pes-data-inicial" type="number" v-model="tiktokADS.carrinho_vendas"/>  
                    </b-col>

                </b-row>
            </div>

            <template #modal-footer>
                <div class="w-100">
                    <b-button
                        variant="success"
                        size="md"
                        class="float-right"
                        @click="salvarMetricas"
                    >
                        Salvar
                    </b-button>
                </div>
            </template>
        </b-modal> 


    </div>
</template>

<script>
import { baseApiUrl, baseApiUrlV2, showError } from '@/global'
// import CadastroFaturaReceber from '@/components/financeiro/faturasReceber/CadastroFaturaReceber'
// import CadastroFaturaPagar from '@/components/financeiro/faturasPagar/CadastroFaturaPagar'
import Autocomplete from '@/components/autocomplete/Autocomplete.vue' 
import ChartRosca from '../charts/ChartRosca.vue'
import Indicador from '../template/Indicador.vue'
import {VMoney} from 'v-money'
const moment = require('moment')
import axios from 'axios'

export default {
    name: 'CadastroLancamentos',
    components: {
        // CadastroFaturaReceber,
        // CadastroFaturaPagar,
        Autocomplete,
        ChartRosca,
        Indicador
    },
    data: function() {
        return {
            distVerba: {
                labels: [],
                datasets: []
            },
            teste: 'teste',
            index: -1,
            novoLancModal: false,
            dashLancModal: false,
            edicaoLancModal: false,
            e1: 1,      
            metricasAtivas: {
                facebookADS: false,
                googleADS: false,
                tiktokADS: false
            },
            metricasOptions: [
                { text: 'Facebook ADS', value: 'facebookADS' },
                { text: 'Google ADS', value: 'googleADS' },
                { text: 'Tiktok ADS', value: 'tiktokADS' }
            ],
            metricasSelecionadas:[],
            aplicaData: true,            
            facebookADS: {
                plan_porc_capital: 0,
                valor_capital: 0,
                valor_teste: 0,

                plan_porc_captacao: 85,
                plan_porc_antecipacao: 2,
                plan_porc_camp_pre_lanc: 9,
                plan_porc_vendas: 4,
                data_inicio_captacao: moment().format('YYYY-MM-DD'),
                data_fim_captacao: moment().format('YYYY-MM-DD'),
                verba_utilizada_quente: 0,
                leads: 0,
                alcance: 0,
                impressoes: 0,
                cpm: 0,
                cpl: 0,
                ctr: 0,
                frequencia: 0,
                views_pagina: 0,
                cliques: 0,
                visualizacoes: 0,

                //metricas
                verba_total_utilizada: 0,
                verba_lembrete_utilizada: 0,
                verba_cpl_utilizada: 0,
                verba_carrinho_utilizada: 0,
                dash_itens1: [],
                dash_fields1: [
                    {key: 'leads', label: 'Leads', sortable: false},
                    {key: 'cpl', label: 'CPL', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                       
                        }
                    },
                    {key: 'investimento', label: 'Investimento', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                       
                        }
                    },
                    {key: 'alcance', label: 'Alcance', sortable: false},
                    {key: 'impressoes', label: 'Impressões', sortable: false},
                    {key: 'cpm', label: 'CPM', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                       
                        }
                    },
                    {key: 'ctr', label: 'CTR (link)', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                       
                        }
                    },
                    {key: 'frequencia', label: 'Frequência', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                       
                        }
                    },
                    {key: 'click_link', label: 'Cliques no Link', sortable: false},
                    {key: 'views_pagina', label: 'Views da Página', sortable: false},
                    {key: 'carr_pagina', label: 'Carregou a Página', sortable: false, formatter: value => { 
                            return `${value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})}%`                       
                        }
                    },
                    {key: 'conv_pagina', label: 'Conversão da Página', sortable: false, formatter: value => { 
                            return `${value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})}%`                       
                        }
                    },
                    {key: 'cpc', label: 'CPC (link)', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                       
                        }
                    }
                ],
                dash_itens4: [
                    { temp_publico: 'Frio', publico: 4606, cpl: 4.58, _rowVariant: 'primary' },
                    { temp_publico: 'Quente', publico: 3134, cpl: 5.15, _rowVariant: 'danger'  }
                ],
                dash_fields4: [
                    {key: 'temp_publico', label: 'Temperatura', sortable: false},
                    {key: 'publico', label: 'Público', sortable: false},
                    {key: 'cpl', label: 'CPL', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                        
                        }
                    }
                ],

                dash_itens5: [
                    { temp_publico: 'Frio', valor: 21078.57, cpl: 4.58, _rowVariant: 'primary' },
                    { temp_publico: 'Quente', valor: 16127.7, cpl: 4.58, _rowVariant: 'danger' }
                ],
                dash_fields5: [
                    {key: 'temp_publico', label: 'Temperatura', sortable: false},
                    {key: 'valor', label: 'Valor', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                        
                        }
                    }
                ],

                //gráficos
                publico_captado: {
                    labels: [],
                    datasets: []
                },
                verba_direcionada: {
                    labels: [],
                    datasets: []
                },
            },
            googleADS: {
                plan_porc_capital: 0,
                valor_capital: 0,
                valor_teste: 0,

                plan_porc_captacao: 85,
                plan_porc_antecipacao: 2,
                plan_porc_camp_pre_lanc: 9,
                plan_porc_vendas: 4,
                data_inicio_captacao: moment().format('YYYY-MM-DD'),
                data_fim_captacao: moment().format('YYYY-MM-DD'),
                verba_utilizada_quente: 0,
                leads: 0,
                alcance: 0,
                impressoes: 0,
                cpm: 0,
                cpl: 0,
                ctr: 0,
                frequencia: 0,
                views_pagina: 0,
                cliques: 0,
                visualizacoes: 0,

                //metricas
                verba_total_utilizada: 0,
                verba_lembrete_utilizada: 0,
                verba_cpl_utilizada: 0,
                verba_carrinho_utilizada: 0,
                dash_itens1: [],
                dash_fields1: [
                    {key: 'leads', label: 'Leads', sortable: false},
                    {key: 'cpl', label: 'CPL', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                       
                        }
                    },
                    {key: 'investimento', label: 'Investimento', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                       
                        }
                    },
                    {key: 'visualizacoes', label: 'Visualizacões', sortable: false},
                    {key: 'cliques', label: 'Cliques', sortable: false},                    
                    {key: 'ctr', label: 'CTR (link)', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                       
                        }
                    }
                ],

                dash_itens4: [
                    { temp_publico: 'Frio', publico: 4606, cpl: 4.58, _rowVariant: 'primary' },
                    { temp_publico: 'Quente', publico: 3134, cpl: 5.15, _rowVariant: 'danger'  }
                ],
                dash_fields4: [
                    {key: 'temp_publico', label: 'Temperatura', sortable: false},
                    {key: 'publico', label: 'Público', sortable: false},
                    {key: 'cpl', label: 'CPL', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                        
                        }
                    }
                ],

                dash_itens5: [
                    { temp_publico: 'Frio', valor: 21078.57, cpl: 4.58, _rowVariant: 'primary' },
                    { temp_publico: 'Quente', valor: 16127.7, cpl: 4.58, _rowVariant: 'danger' }
                ],
                dash_fields5: [
                    {key: 'temp_publico', label: 'Temperatura', sortable: false},
                    {key: 'valor', label: 'Valor', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                        
                        }
                    }
                ],

                //gráficos
                publico_captado: {
                    labels: [],
                    datasets: []
                },
                verba_direcionada: {
                    labels: [],
                    datasets: []
                },
            },
            tiktokADS: {
                plan_porc_capital: 0,
                valor_capital: 0,
                valor_teste: 0,

                plan_porc_captacao: 85,
                plan_porc_antecipacao: 2,
                plan_porc_camp_pre_lanc: 9,
                plan_porc_vendas: 4,
                data_inicio_captacao: moment().format('YYYY-MM-DD'),
                data_fim_captacao: moment().format('YYYY-MM-DD'),
                verba_utilizada_quente: 0,
                leads: 0,
                alcance: 0,
                impressoes: 0,
                cpm: 0,
                cpl: 0,
                ctr: 0,
                frequencia: 0,
                views_pagina: 0,
                cliques: 0,
                visualizacoes: 0,

                //metricas
                verba_total_utilizada: 0,
                verba_lembrete_utilizada: 0,
                verba_cpl_utilizada: 0,
                verba_carrinho_utilizada: 0,
                dash_itens1: [],
                dash_fields1: [
                    {key: 'leads', label: 'Leads', sortable: false},
                    {key: 'cpl', label: 'CPL', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                       
                        }
                    },
                    {key: 'investimento', label: 'Investimento', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                       
                        }
                    },
                    {key: 'alcance', label: 'Alcance', sortable: false},
                    {key: 'impressoes', label: 'Impressões', sortable: false},
                    {key: 'cpm', label: 'CPM', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                       
                        }
                    },
                    {key: 'ctr', label: 'CTR (link)', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                       
                        }
                    },
                    {key: 'frequencia', label: 'Frequência', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                       
                        }
                    },
                    {key: 'click_link', label: 'Cliques no Link', sortable: false},
                    {key: 'views_pagina', label: 'Views da Página', sortable: false},
                    {key: 'carr_pagina', label: 'Carregou a Página', sortable: false, formatter: value => { 
                            return `${value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})}%`                       
                        }
                    },
                    {key: 'conv_pagina', label: 'Conversão da Página', sortable: false, formatter: value => { 
                            return `${value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})}%`                       
                        }
                    },
                    {key: 'cpc', label: 'CPC (link)', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                       
                        }
                    }
                ],

                dash_itens4: [],
                dash_fields4: [
                    {key: 'temp_publico', label: 'Temperatura', sortable: false},
                    {key: 'publico', label: 'Público', sortable: false},
                    {key: 'cpl', label: 'CPL', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                        
                        }
                    }
                ],

                dash_itens5: [],
                dash_fields5: [
                    {key: 'temp_publico', label: 'Temperatura', sortable: false},
                    {key: 'valor', label: 'Valor', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                        
                        }
                    }
                ],

                //gráficos
                publico_captado: {
                    labels: [],
                    datasets: []
                },
                verba_direcionada: {
                    labels: [],
                    datasets: []
                },
            },      
            tipos: [],      
            cadTipo: {                
                id_lancamento_tipo: '',
                descricao: '',
                ativo: true
            },
            cadTipos: [],
            showModal: false,
            showReceita: false,
            showDespesa: false,
            mode: 'new',
            modeTipo: 'save',
            modeItens: 'save',
            show: true,
            showModalExcluir: false,
            showModalInserirDados: false,
            showModalMetricas: false,
            metricaAtiva: null,
            page: 1,
            count: 0,
            limit: 0,
            lancamento: {
                //banco de dados
                descricao: '',
                detalhes: '',
                obs: '',
                verba_total: 0,
                id_lancamento_tipo: 0,
                id_produto: 0,
                idproduto: '',
                status: 'A',
                ativo: 'S',
                verba_total_teste: 0,
                lembretes: '',
                teste: false,
                abertura_carrinho: moment().add(15, 'days').format('YYYY-MM-DD'),
                fechamento_carrinho: moment().format('YYYY-MM-DD'),
                data_inicio: moment().format('YYYY-MM-DD'),
                data_final: moment().format('YYYY-MM-DD'),                
                data_inicio_teste: moment().format('YYYY-MM-DD'), 
                data_fim_teste: moment().format('YYYY-MM-DD'), 
                data_inicio_cpl1: moment().format('YYYY-MM-DD'),
                data_inicio_cpl2: moment().format('YYYY-MM-DD'),
                data_inicio_cpl3: moment().format('YYYY-MM-DD'),
                financeiro: 'S',
                
                faturamento: 0,
                verba_total_utilizada: 0,
                verba_total_teste_utilizada: 0,
                qtde_vendas: 0,
                qtde_leeds: 0, 
                leads_cpl1: 0,
                leads_cpl2: 0,
                leads_cpl3: 0,

                //usado apenas no front ***
                dt_ab: '',
                dt_fc: '',
                dt_in: '',
                dt_fm: '',
                desc_produto: '',
                descricaoTipo: '',
                total_receitas: 0,
                total_despesas: 0,
                total_vendas: 0,

                perc_distribuido: 0,
                vlr_distribuido: 0,
                perc_restante: 0,
                vlr_restante: 0, 

                //metricas                 
                distribuicaoPorEtapa: {
                    labels: [],
                    datasets: []
                },
                distribuicaoPorEtapaItens: [],
                distribuicaoPorEtapaFields: [
                    {key: 'captacao', label: 'Captação', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                       
                        }
                    },
                    {key: 'lembrete', label: 'Lembrete', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                       
                        }
                    },
                    {key: 'cpl', label: 'CPL', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                       
                        }
                    },
                    {key: 'carrinho', label: 'Carrinho', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                       
                        }
                    },
                ],
                
                per_verba_teste_utilizada: 0,
                per_verba_total_utilizada: 0,
                roi_geral: 0,                
                cpl: 0,
                cpa: 0,
                conversao: 0,
                ticket: 0,
                
                investimentoPorMidea: {
                    labels: [],
                    datasets: []
                },
                investimentoPorMideaItens:[],
                investimentoPorMideaFields:[
                    {key: 'midia', label: 'Mídia', sortable: false},
                    {key: 'planejado', label: 'Planejado', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                       
                        }
                    },
                    {key: 'valor', label: 'Investido', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                       
                        }
                    },
                    {key: 'perc', label: '% Utilizada', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', { maximumFractionDigits: 2, currency: 'BRL'})                       
                        }
                    },
                ],

                cpl_itens: [],
                cpl_fields: [
                    {key: 'cpl1', label: 'CPL 1', sortable: false},
                    {key: 'cpl2', label: 'CPL 2', sortable: false},
                    {key: 'cpl3', label: 'CPL 3', sortable: false},
                    {key: 'vv', label: 'VV', sortable: false},
                    {key: 'pres_cpl1', label: 'Presença CPL1', sortable: false, formatter: value => { 
                            return `${ value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}) }%`                        
                        }
                    },
                    {key: 'pres_cpl2', label: 'Foi do CPL1 pro 2', sortable: false, formatter: value => { 
                            return `${ value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}) }%`                        
                        }
                    },
                    {key: 'pres_cpl3', label: 'Foi do CPL2 pro 3', sortable: false, formatter: value => { 
                            return `${ value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}) }%`                        
                        }
                    },
                ],

                outros_dados_itens: [],
                outros_dados_fields: [],

                campanha_lembrete_itens: [],
                campanha_lembrete_fields: [
                    {key: 'investimento', label: 'Investimento', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                        
                        }
                    },
                    {key: 'alcance', label: 'Alcance', sortable: false},
                    {key: 'frequencia', label: 'Frequência', sortable: false}
                ],

                campanha_cpl_itens: [],
                campanha_cpl_fields: [
                    {key: 'investimento', label: 'Investimento', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                        
                        }
                    },
                    {key: 'alcance', label: 'Alcance', sortable: false},
                    {key: 'frequencia', label: 'Frequência', sortable: false},
                    {key: 'cliques', label: 'Cliques', sortable: false},
                    {key: 'cpc', label: 'CPC (link)', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                        
                        }
                    },
                    {key: 'ctr', label: 'CTR (link)', sortable: false, formatter: value => { 
                            return `${ value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}) }%`                        
                        }
                    },
                ],

                campanha_carrinho_itens: [],
                campanha_carrinho_fields: [
                    {key: 'investimento', label: 'Investimento', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                        
                        }
                    },
                    {key: 'alcance', label: 'Alcance', sortable: false},
                    {key: 'impressoes', label: 'Impressões', sortable: false},
                    {key: 'cliques', label: 'Cliques', sortable: false},
                    {key: 'cpc', label: 'CPC (link)', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                        
                        }
                    },
                    {key: 'vendas', label: 'Vendas', sortable: false},
                ],

            },
            lembretes: [
                {id: 1, nome: 'Faltam 10 dias!', dias: 10, cor: 'secondary', ativo: true},
                {id: 2, nome: 'Falta pouco!', dias: 5, cor: 'info', ativo: true},
                {id: 3, nome: 'É nessa segunda!', dias: 2, cor: 'warning', ativo: true},
                {id: 4, nome: 'É amanhã!', dias: 1, cor: 'danger', ativo: true},
            ],
            lembretesSelecionados: [],
            lancamentos: [],
            lancamentoItens: [],
            lancamentoReceitas: [],
            lancamentoDespesas: [],
            lancamentoItem: {
                descricao: '',
                valor: 0,
                id_produto: '',
                idproduto: ''
            },            
            produtos: [],
            pesquisa: {
                id_tipo: 0,
                dataInicial: '1900-01-01',
                dataFinal: '2400-12-31',
                status: 'T'
            },
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                //suffix: ' #',
                precision: 2,
                masked: false
            },
            moneyNoPrefix: {
                decimal: ',',
                thousands: '.',                
                precision: 2,
                masked: false
            },
            moneyDet: {
                decimal: ',',
                thousands: '.',
                suffix: '%',
                precision: 2,
                masked: false
            },
            fields: [
                {key: 'id_lancamento', label: 'Código', sortable: true},
                {key: 'descricao', label: 'Descricão', sortable: true},
                {key: 'data_inicio', label: 'Data Início', sortable: true,
                formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')
                    }
                },
                {key: 'data_final', label: 'Data Fim', sortable: true,
                formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')
                    }
                },
                {key: 'status', label: 'Status', sortable: false, formatter: value => value ==='A' ? 'Aberto' : 'Encerrado'},
                {key: 'actions', label: 'Ações'}
            ],   
            fieldsLancamentoItens: [
                {key: 'id_produto', label: 'Código', sortable: true},
                {key: 'descricao', label: 'Descricao', sortable: true},
                {key: 'valor', label: 'Valor', sortable: true},
                {key: 'actions', label: 'Ações'}
            ], 
            fieldsReceitas: [
                {key: 'id_conta_receber', label: 'Fatura', sortable: true},
                {key: 'razao_social', label: 'Pessoa', sortable: true},
                {key: 'valor', label: 'Valor', sortable: true}
            ],
            fieldsDespesas: [
                {key: 'id_conta_pagar', label: 'Fatura', sortable: true},
                {key: 'razao_social', label: 'Pessoa', sortable: true},
                {key: 'valor', label: 'Valor', sortable: true}
            ],  
            fieldsTipos: [
                {key: 'id_lancamento_tipo', label: 'Id', sortable: true},
                {key: 'descricao', label: 'Descrição', sortable: true},
                {key: 'ativo', label: 'Status', sortable: false, formatter: value => value ==='S' ? 'Ativo' : 'Inativo'},
                {key: 'actions', label: 'Ações'}
            ],
            fieldsMidias: [
                {key: 'midia', label: 'Mídia', sortable: true},
                {key: 'verba', label: 'Verba', sortable: true},
                {key: 'teste', label: 'Teste', sortable: true},
                {key: 'captacao', label: 'Captação'},
                {key: 'antecipacao', label: 'Antecipação'},
                {key: 'cpl', label: 'CPL'},
                {key: 'vendas', label: 'Vendas'}
            ],
            fieldsResumoMidias: [
                {key: 'etapa', label: ''},
                {key: 'orcamento', label: 'Orçamento'},
                {key: 'valor_por_dia', label: 'Média p/ Dia'},
                {key: 'data_inicial', label: 'Data inicial'},
                {key: 'data_final', label: 'Data final'},
                {key: 'duracao', label: 'Duração (dias)'}
            ],
            fieldsVendasResumo: ['etapa', 'd1', 'd2', 'd3', 'total'],
            
            // facebook
            itensFacebookADSCaptacao: [],
            itensFacebookADSAntecipacao: [],
            itensFacebookADSCpl: [],
            itensFacebookADSCplDetalhes: [],
            itensFacebookADSVendas: [],
            itensFacebookADSVendasDetalhes: [],
            // google
            itensGoogleADSCaptacao: [],
            itensGoogleADSAntecipacao: [],
            itensGoogleADSCpl: [],
            itensGoogleADSCplDetalhes: [],
            itensGoogleADSVendas: [],
            itensGoogleADSVendasDetalhes: [],
            // tiktok
            itensTiktokADSCaptacao: [],
            itensTiktokADSAntecipacao: [],
            itensTiktokADSCpl: [],
            itensTiktokADSCplDetalhes: [],
            itensTiktokADSVendas: [],
            itensTiktokADSVendasDetalhes: [],
            
            resumoMidias: []
        }
    },
    directives: {
        money: VMoney
    },
    methods: {
        novoLancamento() {
            this.resetLancamento()
            this.novoLancModal = true
            this.carregaDatas()
        },
        editarLancamento(item) {
            this.loadLancamento(item)
            this.edicaoLancModal = true
        },
        carregaDatas() {
            this.lancamento.fechamento_carrinho = moment(this.lancamento.abertura_carrinho).add(2, 'days').format('YYYY-MM-DD')
            this.lancamento.data_inicio = moment(this.lancamento.abertura_carrinho).add(-15, 'days').format('YYYY-MM-DD')
            this.lancamento.data_final = moment(this.lancamento.abertura_carrinho).add(-1, 'days').format('YYYY-MM-DD')              
            this.lancamento.data_inicio_teste = moment(this.lancamento.abertura_carrinho).add(-15, 'days').format('YYYY-MM-DD')
            this.lancamento.data_fim_teste = moment(this.lancamento.abertura_carrinho).add(-12, 'days').format('YYYY-MM-DD')
            this.lancamento.data_inicio_cpl1 = moment(this.lancamento.abertura_carrinho).add(-3, 'days').format('YYYY-MM-DD')
            this.lancamento.data_inicio_cpl2 = moment(this.lancamento.abertura_carrinho).add(-1, 'days').format('YYYY-MM-DD')
            this.lancamento.data_inicio_cpl3 = moment(this.lancamento.abertura_carrinho).add(1, 'days').format('YYYY-MM-DD')
        },

        async getMetricas(id, id_midia) {
            
            const url = `${baseApiUrlV2}/lancamentos/metricas/?id_lancamento=${id.id_lancamento}&id_midia=${id_midia}`
            let show = false

            switch (id_midia) {
                case 1: 
                    this.metricaAtiva = 'facebook'
                    show = true
                    break
                case 2: 
                    this.metricaAtiva = 'google'
                    show = true
                    break
                case 3: 
                    this.metricaAtiva = 'tiktok'
                    show = true
                    break
                default:
                    this.metricaAtiva = 'geral'                    
                    show = false
            }

            await axios.get(url).then(res => {
                if (id_midia === 1) {
                    this.facebookADS.id_lancamento = res.data.id_lancamento || 0
                    this.facebookADS.id_lancamento_metricas_canal_mkt = res.data.id_lancamento_metricas_canal_mkt || 0
                    this.facebookADS.verba_total_utilizada = res.data.verba_total_utilizada || 0
                    this.facebookADS.verba_teste_utilizada = res.data.verba_teste_utilizada || 0
                    this.facebookADS.verba_lembrete_utilizada = res.data.verba_lembrete_utilizada || 0
                    this.facebookADS.verba_cpl_utilizada = res.data.verba_cpl_utilizada || 0
                    this.facebookADS.verba_carrinho_utilizada = res.data.verba_carrinho_utilizada || 0                    
                    
                    this.facebookADS.captacao_alcance = res.data.captacao_alcance || 0
                    this.facebookADS.captacao_impressoes = res.data.captacao_impressoes || 0
                    this.facebookADS.captacao_leads = res.data.captacao_leads || 0
                    this.facebookADS.captacao_cpl = res.data.captacao_cpl || 0
                    this.facebookADS.captacao_cpm = res.data.captacao_cpm || 0
                    this.facebookADS.captacao_ctr = res.data.captacao_ctr || 0
                    this.facebookADS.captacao_frequencia = res.data.captacao_frequencia || 0
                    this.facebookADS.captacao_cliques = res.data.captacao_cliques || 0
                    this.facebookADS.captacao_views_pagina = res.data.captacao_views_pagina || 0 
                    this.facebookADS.captacao_visualizacoes = res.data.captacao_visualizacoes || 0 

                    this.facebookADS.lembrete_alcance = res.data.lembrete_alcance || 0 
                    this.facebookADS.lembrete_frequencia = res.data.lembrete_frequencia || 0 
                    
                    this.facebookADS.leads_cpl1 = res.data.leads_cpl1 || 0 
                    this.facebookADS.leads_cpl2 = res.data.leads_cpl2 || 0 
                    this.facebookADS.leads_cpl3 = res.data.leads_cpl3 || 0 
                    
                    this.facebookADS.cpl_alcance = res.data.cpl_alcance || 0 
                    this.facebookADS.cpl_frequencia = res.data.cpl_frequencia || 0 
                    this.facebookADS.cpl_cliques = res.data.cpl_cliques || 0 
                    this.facebookADS.cpl_cpc = res.data.cpl_cpc || 0 
                    this.facebookADS.cpl_ctr = res.data.cpl_ctr || 0 

                    this.facebookADS.carrinho_alcance = res.data.carrinho_alcance || 0 
                    this.facebookADS.carrinho_frequencia = res.data.carrinho_frequencia || 0 
                    this.facebookADS.carrinho_cliques = res.data.carrinho_cliques || 0 
                    this.facebookADS.carrinho_cpc = res.data.carrinho_cpc || 0 
                    this.facebookADS.carrinho_vendas = res.data.carrinho_vendas || 0 

                    this.facebookADS.publico_quente = res.data.publico_quente || 0 
                    this.facebookADS.publico_quente_verba = res.data.publico_quente_verba || 0 

                }

                if (id_midia === 2) {
                    this.googleADS.id_lancamento = res.data.id_lancamento || 0
                    this.googleADS.id_lancamento_metricas_canal_mkt = res.data.id_lancamento_metricas_canal_mkt || 0
                    this.googleADS.verba_total_utilizada = res.data.verba_total_utilizada || 0
                    this.googleADS.verba_teste_utilizada = res.data.verba_teste_utilizada || 0
                    this.googleADS.verba_lembrete_utilizada = res.data.verba_lembrete_utilizada || 0
                    this.googleADS.verba_cpl_utilizada = res.data.verba_cpl_utilizada || 0
                    this.googleADS.verba_carrinho_utilizada = res.data.verba_carrinho_utilizada || 0                    
                    
                    this.googleADS.captacao_alcance = res.data.captacao_alcance || 0
                    this.googleADS.captacao_impressoes = res.data.captacao_impressoes || 0
                    this.googleADS.captacao_leads = res.data.captacao_leads || 0
                    this.googleADS.captacao_cpl = res.data.captacao_cpl || 0
                    this.googleADS.captacao_cpm = res.data.captacao_cpm || 0
                    this.googleADS.captacao_ctr = res.data.captacao_ctr || 0
                    this.googleADS.captacao_frequencia = res.data.captacao_frequencia || 0
                    this.googleADS.captacao_cliques = res.data.captacao_cliques || 0
                    this.googleADS.captacao_views_pagina = res.data.captacao_views_pagina || 0 
                    this.googleADS.captacao_visualizacoes = res.data.captacao_visualizacoes || 0 

                    this.googleADS.lembrete_alcance = res.data.lembrete_alcance || 0 
                    this.googleADS.lembrete_frequencia = res.data.lembrete_frequencia || 0 
                    
                    this.googleADS.leads_cpl1 = res.data.leads_cpl1 || 0 
                    this.googleADS.leads_cpl2 = res.data.leads_cpl2 || 0 
                    this.googleADS.leads_cpl3 = res.data.leads_cpl3 || 0 
                    
                    this.googleADS.cpl_alcance = res.data.cpl_alcance || 0 
                    this.googleADS.cpl_frequencia = res.data.cpl_frequencia || 0 
                    this.googleADS.cpl_cliques = res.data.cpl_cliques || 0 
                    this.googleADS.cpl_cpc = res.data.cpl_cpc || 0 
                    this.googleADS.cpl_ctr = res.data.cpl_ctr || 0 

                    this.googleADS.carrinho_alcance = res.data.carrinho_alcance || 0 
                    this.googleADS.carrinho_frequencia = res.data.carrinho_frequencia || 0 
                    this.googleADS.carrinho_cliques = res.data.carrinho_cliques || 0 
                    this.googleADS.carrinho_cpc = res.data.carrinho_cpc || 0 
                    this.googleADS.carrinho_vendas = res.data.carrinho_vendas || 0

                    this.googleADS.publico_quente = res.data.publico_quente || 0 
                    this.googleADS.publico_quente_verba = res.data.publico_quente_verba || 0 
                }

                if (id_midia === 3) {
                    this.tiktokADS.id_lancamento = res.data.id_lancamento || 0
                    this.tiktokADS.id_lancamento_metricas_canal_mkt = res.data.id_lancamento_metricas_canal_mkt || 0
                    this.tiktokADS.verba_total_utilizada = res.data.verba_total_utilizada || 0
                    this.tiktokADS.verba_teste_utilizada = res.data.verba_teste_utilizada || 0
                    this.tiktokADS.verba_lembrete_utilizada = res.data.verba_lembrete_utilizada || 0
                    this.tiktokADS.verba_cpl_utilizada = res.data.verba_cpl_utilizada || 0
                    this.tiktokADS.verba_carrinho_utilizada = res.data.verba_carrinho_utilizada || 0                    
                    
                    this.tiktokADS.captacao_alcance = res.data.captacao_alcance || 0
                    this.tiktokADS.captacao_impressoes = res.data.captacao_impressoes || 0
                    this.tiktokADS.captacao_leads = res.data.captacao_leads || 0
                    this.tiktokADS.captacao_cpl = res.data.captacao_cpl || 0
                    this.tiktokADS.captacao_cpm = res.data.captacao_cpm || 0
                    this.tiktokADS.captacao_ctr = res.data.captacao_ctr || 0
                    this.tiktokADS.captacao_frequencia = res.data.captacao_frequencia || 0
                    this.tiktokADS.captacao_cliques = res.data.captacao_cliques || 0
                    this.tiktokADS.captacao_views_pagina = res.data.captacao_views_pagina || 0 
                    this.tiktokADS.captacao_visualizacoes = res.data.captacao_visualizacoes || 0 

                    this.tiktokADS.lembrete_alcance = res.data.lembrete_alcance || 0 
                    this.tiktokADS.lembrete_frequencia = res.data.lembrete_frequencia || 0 
                    
                    this.tiktokADS.leads_cpl1 = res.data.leads_cpl1 || 0 
                    this.tiktokADS.leads_cpl2 = res.data.leads_cpl2 || 0 
                    this.tiktokADS.leads_cpl3 = res.data.leads_cpl3 || 0 
                    
                    this.tiktokADS.cpl_alcance = res.data.cpl_alcance || 0 
                    this.tiktokADS.cpl_frequencia = res.data.cpl_frequencia || 0 
                    this.tiktokADS.cpl_cliques = res.data.cpl_cliques || 0 
                    this.tiktokADS.cpl_cpc = res.data.cpl_cpc || 0 
                    this.tiktokADS.cpl_ctr = res.data.cpl_ctr || 0 

                    this.tiktokADS.carrinho_alcance = res.data.carrinho_alcance || 0 
                    this.tiktokADS.carrinho_frequencia = res.data.carrinho_frequencia || 0 
                    this.tiktokADS.carrinho_cliques = res.data.carrinho_cliques || 0 
                    this.tiktokADS.carrinho_cpc = res.data.carrinho_cpc || 0 
                    this.tiktokADS.carrinho_vendas = res.data.carrinho_vendas || 0

                    this.tiktokADS.publico_quente = res.data.publico_quente || 0 
                    this.tiktokADS.publico_quente_verba = res.data.publico_quente_verba || 0 
                }

                if (!id_midia) {
                    this.lancamento.id_lancamento = res.data.id_lancamento,
                    this.lancamento.verba_total_utilizada = res.data.verba_total_utilizada,
                    this.lancamento.verba_total_teste_utilizada = res.data.verba_total_teste_utilizada,
                    this.lancamento.faturamento = res.data.faturamento,
                    this.lancamento.qtde_vendas = res.data.qtde_vendas,

                    this.lancamento.qtde_leeds = res.data.qtde_leeds,
                    this.lancamento.leads_organicos = res.data.leads_organicos,
                    this.lancamento.num_grupos =res.data.num_grupos,
                    this.lancamento.grupos_super_int= res.data.grupos_super_int,

                    this.lancamento.lembrete_investimento = res.data.lembrete_investimento,
                    this.lancamento.lembrete_alcance = res.data.lembrete_alcance,
                    this.lancamento.lembrete_frequencia = res.data.lembrete_frequencia,

                    this.lancamento.leads_facebook = res.data.leads_facebook,
                    this.lancamento.leads_google = res.data.leads_google,
                    this.lancamento.leads_tiktok = res.data.leads_tiktok,

                    this.lancamento.leads_cpl1 = res.data.leads_cpl1,
                    this.lancamento.leads_cpl2 = res.data.leads_cpl2,
                    this.lancamento.leads_cpl3 = res.data.leads_cpl3, 
                    this.lancamento.cpl_investimento = res.data.cpl_investimento,
                    this.lancamento.cpl_frequencia = res.data.cpl_frequencia,
                    this.lancamento.cpl_alcance = res.data.cpl_alcance,
                    this.lancamento.cpl_cliques = res.data.cpl_cliques,
                    this.lancamento.cpl_cpc = res.data.cpl_cpc,
                    this.lancamento.cpl_ctr = res.data.cpl_ctr,

                    this.lancamento.carrinho_investimento = res.data.carrinho_investimento,
                    this.lancamento.carrinho_frequencia = res.data.carrinho_frequencia,
                    this.lancamento.carrinho_cpc = res.data.carrinho_cpc,
                    this.lancamento.carrinho_alcance = res.data.carrinho_alcance,
                    this.lancamento.carrinho_cliques = res.data.carrinho_cliques,
                    this.lancamento.carrinho_vendas = res.data.carrinho_vendas
                }

                this.showModalMetricas = show
                this.showModalInserirDados = !show
            }) 
            .catch(showError)
        },

        async salvarMetricas() {
            let id = null
            let midia = {}

            switch (this.metricaAtiva) {
                case 'facebook': 
                    id = this.facebookADS.id_lancamento_metricas_canal_mkt
                    midia = {
                        verba_total_utilizada:      this.facebookADS.verba_total_utilizada || 0,
                        verba_teste_utilizada:      this.facebookADS.verba_teste_utilizada || 0,
                        verba_lembrete_utilizada:   this.facebookADS.verba_lembrete_utilizada || 0,
                        verba_cpl_utilizada:        this.facebookADS.verba_cpl_utilizada || 0,
                        verba_carrinho_utilizada:   this.facebookADS.verba_carrinho_utilizada || 0,

                        captacao_alcance:           this.facebookADS.captacao_alcance || 0,
                        captacao_impressoes:        this.facebookADS.captacao_impressoes || 0,
                        captacao_leads:             this.facebookADS.captacao_leads || 0,
                        captacao_cpl:               this.facebookADS.captacao_cpl || 0,
                        captacao_cpm:               this.facebookADS.captacao_cpm || 0,
                        captacao_ctr:               this.facebookADS.captacao_ctr || 0,
                        captacao_frequencia:        this.facebookADS.captacao_frequencia || 0,
                        captacao_cliques:           this.facebookADS.captacao_cliques || 0,
                        captacao_views_pagina:      this.facebookADS.captacao_views_pagina || 0,
                        captacao_visualizacoes:     this.facebookADS.captacao_visualizacoes || 0,

                        lembrete_alcance:           this.facebookADS.lembrete_alcance || 0,
                        lembrete_frequencia:        this.facebookADS.lembrete_frequencia || 0,

                        leads_cpl1:                 this.facebookADS.leads_cpl1 || 0,
                        leads_cpl2:                 this.facebookADS.leads_cpl2 || 0,
                        leads_cpl3:                 this.facebookADS.leads_cpl3 || 0,

                        cpl_alcance:                this.facebookADS.cpl_alcance || 0,
                        cpl_frequencia:             this.facebookADS.cpl_frequencia || 0,
                        cpl_cliques:                this.facebookADS.cpl_cliques || 0,
                        cpl_cpc:                    this.facebookADS.cpl_cpc || 0,
                        cpl_ctr:                    this.facebookADS.cpl_ctr || 0,

                        carrinho_alcance:           this.facebookADS.carrinho_alcance || 0,
                        carrinho_frequencia:        this.facebookADS.carrinho_frequencia || 0,
                        carrinho_cliques:           this.facebookADS.carrinho_cliques || 0,
                        carrinho_cpc:               this.facebookADS.carrinho_cpc || 0,
                        carrinho_vendas:            this.facebookADS.carrinho_vendas || 0,

                        publico_quente:             this.facebookADS.publico_quente || 0,
                        publico_quente_verba:       this.facebookADS.publico_quente_verba || 0
                    }
                    break
                case 'google': 
                    id = this.googleADS.id_lancamento_metricas_canal_mkt
                    midia = {
                        verba_total_utilizada:      this.googleADS.verba_total_utilizada || 0,
                        verba_teste_utilizada:      this.googleADS.verba_teste_utilizada || 0,
                        verba_lembrete_utilizada:   this.googleADS.verba_lembrete_utilizada || 0,
                        verba_cpl_utilizada:        this.googleADS.verba_cpl_utilizada || 0,
                        verba_carrinho_utilizada:   this.googleADS.verba_carrinho_utilizada || 0,

                        captacao_alcance:           this.googleADS.captacao_alcance || 0,
                        captacao_impressoes:        this.googleADS.captacao_impressoes || 0,
                        captacao_leads:             this.googleADS.captacao_leads || 0,
                        captacao_cpl:               this.googleADS.captacao_cpl || 0,
                        captacao_cpm:               this.googleADS.captacao_cpm || 0,
                        captacao_ctr:               this.googleADS.captacao_ctr || 0,
                        captacao_frequencia:        this.googleADS.captacao_frequencia || 0,
                        captacao_cliques:           this.googleADS.captacao_cliques || 0,
                        captacao_views_pagina:      this.googleADS.captacao_views_pagina || 0,
                        captacao_visualizacoes:     this.googleADS.captacao_visualizacoes || 0,

                        lembrete_alcance:           this.googleADS.lembrete_alcance || 0,
                        lembrete_frequencia:        this.googleADS.lembrete_frequencia || 0,

                        leads_cpl1:                 this.googleADS.leads_cpl1 || 0,
                        leads_cpl2:                 this.googleADS.leads_cpl2 || 0,
                        leads_cpl3:                 this.googleADS.leads_cpl3 || 0,

                        cpl_alcance:                this.googleADS.cpl_alcance || 0,
                        cpl_frequencia:             this.googleADS.cpl_frequencia || 0,
                        cpl_cliques:                this.googleADS.cpl_cliques || 0,
                        cpl_cpc:                    this.googleADS.cpl_cpc || 0,
                        cpl_ctr:                    this.googleADS.cpl_ctr || 0,

                        carrinho_alcance:           this.googleADS.carrinho_alcance || 0,
                        carrinho_frequencia:        this.googleADS.carrinho_frequencia || 0,
                        carrinho_cliques:           this.googleADS.carrinho_cliques || 0,
                        carrinho_cpc:               this.googleADS.carrinho_cpc || 0,
                        carrinho_vendas:            this.googleADS.carrinho_vendas || 0,

                        publico_quente:             this.googleADS.publico_quente || 0,
                        publico_quente_verba:       this.googleADS.publico_quente_verba || 0
                    }
                    break
                case 'tiktok': 
                    id = this.tiktokADS.id_lancamento_metricas_canal_mkt
                    midia = {
                        verba_total_utilizada:      this.tiktokADS.verba_total_utilizada || 0,
                        verba_teste_utilizada:      this.tiktokADS.verba_teste_utilizada || 0,
                        verba_lembrete_utilizada:   this.tiktokADS.verba_lembrete_utilizada || 0,
                        verba_cpl_utilizada:        this.tiktokADS.verba_cpl_utilizada || 0,
                        verba_carrinho_utilizada:   this.tiktokADS.verba_carrinho_utilizada || 0,

                        captacao_alcance:           this.tiktokADS.captacao_alcance || 0,
                        captacao_impressoes:        this.tiktokADS.captacao_impressoes || 0,
                        captacao_leads:             this.tiktokADS.captacao_leads || 0,
                        captacao_cpl:               this.tiktokADS.captacao_cpl || 0,
                        captacao_cpm:               this.tiktokADS.captacao_cpm || 0,
                        captacao_ctr:               this.tiktokADS.captacao_ctr || 0,
                        captacao_frequencia:        this.tiktokADS.captacao_frequencia || 0,
                        captacao_cliques:           this.tiktokADS.captacao_cliques || 0,
                        captacao_views_pagina:      this.tiktokADS.captacao_views_pagina || 0,
                        captacao_visualizacoes:     this.tiktokADS.captacao_visualizacoes || 0,

                        lembrete_alcance:           this.tiktokADS.lembrete_alcance || 0,
                        lembrete_frequencia:        this.tiktokADS.lembrete_frequencia || 0,

                        leads_cpl1:                 this.tiktokADS.leads_cpl1 || 0,
                        leads_cpl2:                 this.tiktokADS.leads_cpl2 || 0,
                        leads_cpl3:                 this.tiktokADS.leads_cpl3 || 0,

                        cpl_alcance:                this.tiktokADS.cpl_alcance || 0,
                        cpl_frequencia:             this.tiktokADS.cpl_frequencia || 0,
                        cpl_cliques:                this.tiktokADS.cpl_cliques || 0,
                        cpl_cpc:                    this.tiktokADS.cpl_cpc || 0,
                        cpl_ctr:                    this.tiktokADS.cpl_ctr || 0,

                        carrinho_alcance:           this.tiktokADS.carrinho_alcance || 0,
                        carrinho_frequencia:        this.tiktokADS.carrinho_frequencia || 0,
                        carrinho_cliques:           this.tiktokADS.carrinho_cliques || 0,
                        carrinho_cpc:               this.tiktokADS.carrinho_cpc || 0,
                        carrinho_vendas:            this.tiktokADS.carrinho_vendas || 0,

                        publico_quente:             this.tiktokADS.publico_quente || 0,
                        publico_quente_verba:       this.tiktokADS.publico_quente_verba || 0
                    }
                    break
                case 'geral': 
                    id = false
                    midia = {
                        verba_total_utilizada:          this.lancamento.verba_total_utilizada || 0,
                        verba_total_teste_utilizada:    this.lancamento.verba_total_teste_utilizada || 0,
                        faturamento:                    this.lancamento.faturamento || 0,
                        qtde_vendas:                    this.lancamento.qtde_vendas || 0,

                        qtde_leeds:                     this.lancamento.qtde_leeds || 0,
                        leads_organicos:                this.lancamento.leads_organicos || 0,
                        num_grupos:                     this.lancamento.num_grupos || 0,
                        grupos_super_int:               this.lancamento.grupos_super_int || 0,

                        lembrete_investimento:          this.lancamento.lembrete_investimento || 0,
                        lembrete_alcance:               this.lancamento.lembrete_alcance || 0,
                        lembrete_frequencia:            this.lancamento.lembrete_frequencia || 0,

                        leads_facebook:                 this.lancamento.leads_facebook || 0,
                        leads_google:                   this.lancamento.leads_google || 0,
                        leads_tiktok:                   this.lancamento.leads_tiktok || 0,

                        leads_cpl1:                     this.lancamento.leads_cpl1 || 0,
                        leads_cpl2:                     this.lancamento.leads_cpl2 || 0,
                        leads_cpl3:                     this.lancamento.leads_cpl3 || 0,
                        cpl_investimento:               this.lancamento.cpl_investimento || 0,
                        cpl_frequencia:                 this.lancamento.cpl_frequencia || 0,
                        cpl_alcance:                    this.lancamento.cpl_alcance || 0,
                        cpl_cliques:                    this.lancamento.cpl_cliques || 0,
                        cpl_cpc:                        this.lancamento.cpl_cpc || 0,
                        cpl_ctr:                        this.lancamento.cpl_ctr || 0,

                        carrinho_investimento:          this.lancamento.carrinho_investimento || 0,
                        carrinho_frequencia:            this.lancamento.carrinho_frequencia || 0,
                        carrinho_cpc:                   this.lancamento.carrinho_cpc || 0,
                        carrinho_alcance:               this.lancamento.carrinho_alcance || 0,
                        carrinho_cliques:               this.lancamento.carrinho_cliques || 0,
                        carrinho_vendas:                this.lancamento.carrinho_vendas || 0,
                        
                    }
                    break
            }   
            
            await axios['put'](`${baseApiUrlV2}/lancamentos/metricas/${id}/?id_lancamento=${this.lancamento.id_lancamento || false}`, midia)
                .then(() => {
                    this.showModalMetricas = false
                    this.showModalInserirDados = false
                    this.$toasted.global.defaultSuccess()                    
                    this.resetLancamento()
                })
            .catch(showError)
        },
        
        async dashLancExibir(lanc) {

            const url = `${baseApiUrlV2}/lancamentos/${lanc.id_lancamento}` 

            await axios.get(url).then(res => { 
                this.lancamento.id_lancamento = res.data.id_lancamento,
                this.lancamento.data_inicio = moment(res.data.data_inicio).format('YYYY-MM-DD')
                this.lancamento.data_final = moment(res.data.data_final).format('YYYY-MM-DD')
                this.lancamento.descricao = res.data.descricao,
                this.lancamento.descricaoTipo = res.data.descricaoTipo,
                this.lancamento.descricaoProduto = res.data.descricaoProduto,
                this.lancamento.verba_total = res.data.verba_total || 0,
                this.lancamento.verba_total_teste = res.data.verba_total_teste || 0,
                this.lancamento.verba_total_utilizada = res.data.verba_total_utilizada || 0,
                this.lancamento.verba_total_teste_utilizada = res.data.verba_total_teste_utilizada || 0,
                this.lancamento.faturamento = res.data.faturamento || 0,
                this.lancamento.qtde_vendas = res.data.qtde_vendas || 0,
                this.lancamento.qtde_leeds = res.data.qtde_leeds || 0,
                this.lancamento.ticket = (res.data.ticket || 0).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 3, currency: 'BRL'}),

                this.lancamento.leads_facebook = res.data.leads_facebook || 0,
                this.lancamento.leads_google = res.data.leads_google || 0,
                this.lancamento.leads_tiktok = res.data.leads_tiktok || 0,

                this.lancamento.leads_organicos = res.data.leads_organicos || 0,
                this.lancamento.num_grupos =res.data.num_grupos || 0,
                this.lancamento.grupos_super_int= res.data.grupos_super_int || 0,

                this.lancamento.lembrete_investimento = res.data.lembrete_investimento || 0,
                this.lancamento.lembrete_alcance = res.data.lembrete_alcance || 0,
                this.lancamento.lembrete_frequencia = res.data.lembrete_frequencia || 0,

                this.lancamento.leads_cpl1 = res.data.leads_cpl1 || 0,
                this.lancamento.leads_cpl2 = res.data.leads_cpl2 || 0,
                this.lancamento.leads_cpl3 = res.data.leads_cpl3 || 0, 
                this.lancamento.cpl_investimento = res.data.cpl_investimento || 0,
                this.lancamento.cpl_frequencia = res.data.cpl_frequencia || 0,
                this.lancamento.cpl_alcance = res.data.cpl_alcance || 0,
                this.lancamento.cpl_cliques = res.data.cpl_cliques || 0,
                this.lancamento.cpl_cpc = res.data.cpl_cpc || 0,
                this.lancamento.cpl_ctr = res.data.cpl_ctr || 0,

                this.lancamento.carrinho_investimento = res.data.carrinho_investimento || 0,
                this.lancamento.carrinho_frequencia = res.data.carrinho_frequencia || 0,
                this.lancamento.carrinho_cpc = res.data.carrinho_cpc || 0,
                this.lancamento.carrinho_alcance = res.data.carrinho_alcance || 0,
                this.lancamento.carrinho_cliques = res.data.carrinho_cliques || 0,
                this.lancamento.carrinho_vendas = res.data.carrinho_vendas || 0

                this.lancamento.mideas = res.data.mideas
                this.metricasSelecionadas = []

                this.lancamento.mideas.forEach((item) => {                    
                    
                    switch (parseInt(item.id_lancamento_canal_mkt)) {
                        case 1: 
                            this.metricasSelecionadas.push('facebookADS')

                            this.facebookADS.plan_porc_capital      = item.plan_porc_capital
                            this.facebookADS.verba_total_utilizada      = item.verba_total_utilizada

                            this.facebookADS.leads_cpl1   = item.leads_cpl1
                            this.facebookADS.leads_cpl2   = item.leads_cpl2
                            this.facebookADS.leads_cpl3   = item.leads_cpl3

                            this.facebookADS.verba_lembrete_utilizada   = item.verba_lembrete_utilizada
                            this.facebookADS.lembrete_alcance   = item.lembrete_alcance
                            this.facebookADS.lembrete_frequencia   = item.lembrete_frequencia
                            
                            this.facebookADS.verba_cpl_utilizada        = item.verba_cpl_utilizada
                            this.facebookADS.cpl_alcance = item.cpl_alcance
                            this.facebookADS.cpl_frequencia = item.cpl_frequencia
                            this.facebookADS.cpl_cliques = item.cpl_cliques
                            this.facebookADS.cpl_cpc = item.cpl_cpc
                            this.facebookADS.cpl_ctr = item.cpl_ctr
                            
                            this.facebookADS.verba_carrinho_utilizada   = item.verba_carrinho_utilizada
                            this.facebookADS.carrinho_alcance = item.carrinho_alcance
                            this.facebookADS.carrinho_frequencia = item.carrinho_frequencia
                            this.facebookADS.carrinho_cliques = item.carrinho_cliques
                            this.facebookADS.carrinho_cpc = item.carrinho_cpc
                            this.facebookADS.carrinho_vendas = item.carrinho_vendas

                            this.facebookADS.captacao_leads = item.captacao_leads 
                            this.facebookADS.captacao_cpl = item.captacao_cpl 
                            this.facebookADS.captacao_alcance = item.captacao_alcance
                            this.facebookADS.captacao_impressoes = item.captacao_impressoes
                            this.facebookADS.captacao_cpm = item.captacao_cpm
                            this.facebookADS.captacao_ctr = item.captacao_ctr  
                            this.facebookADS.captacao_frequencia = item.captacao_frequencia
                            this.facebookADS.captacao_cliques = item.captacao_cliques
                            this.facebookADS.captacao_views_pagina = item.captacao_views_pagina
                            this.facebookADS.captacao_carr_pagina = item.captacao_carr_pagina
                            this.facebookADS.captacao_carr_pagina = item.captacao_carr_pagina
                            this.facebookADS.captacao_cpc = item.captacao_cpc

                            this.facebookADS.publico_quente = item.publico_quente
                            this.facebookADS.publico_quente_verba = item.publico_quente_verba

                            break
                        case 2: 
                            this.metricasSelecionadas.push('googleADS')

                            this.googleADS.plan_porc_capital      = item.plan_porc_capital
                            this.googleADS.verba_total_utilizada      = item.verba_total_utilizada

                            this.googleADS.leads_cpl1   = item.leads_cpl1
                            this.googleADS.leads_cpl2   = item.leads_cpl2
                            this.googleADS.leads_cpl3   = item.leads_cpl3

                            this.googleADS.verba_lembrete_utilizada   = item.verba_lembrete_utilizada
                            this.googleADS.lembrete_alcance   = item.lembrete_alcance
                            this.googleADS.lembrete_frequencia   = item.lembrete_frequencia
                            
                            this.googleADS.verba_cpl_utilizada        = item.verba_cpl_utilizada
                            this.googleADS.cpl_alcance = item.cpl_alcance
                            this.googleADS.cpl_frequencia = item.cpl_frequencia
                            this.googleADS.cpl_cliques = item.cpl_cliques
                            this.googleADS.cpl_cpc = item.cpl_cpc
                            this.googleADS.cpl_ctr = item.cpl_ctr
                            
                            this.googleADS.verba_carrinho_utilizada   = item.verba_carrinho_utilizada
                            this.googleADS.carrinho_alcance = item.carrinho_alcance
                            this.googleADS.carrinho_frequencia = item.carrinho_frequencia
                            this.googleADS.carrinho_cliques = item.carrinho_cliques
                            this.googleADS.carrinho_cpc = item.carrinho_cpc
                            this.googleADS.carrinho_vendas = item.carrinho_vendas

                            this.googleADS.captacao_leads = item.captacao_leads 
                            this.googleADS.captacao_cpl = item.captacao_cpl 
                            this.googleADS.captacao_alcance = item.captacao_alcance
                            this.googleADS.captacao_impressoes = item.captacao_impressoes
                            this.googleADS.captacao_cpm = item.captacao_cpm
                            this.googleADS.captacao_ctr = item.captacao_ctr  
                            this.googleADS.captacao_frequencia = item.captacao_frequencia
                            this.googleADS.captacao_cliques = item.captacao_cliques
                            this.googleADS.captacao_views_pagina = item.captacao_views_pagina
                            this.googleADS.captacao_carr_pagina = item.captacao_carr_pagina
                            this.googleADS.captacao_carr_pagina = item.captacao_carr_pagina
                            this.googleADS.captacao_cpc = item.captacao_cpc

                            this.googleADS.publico_quente = item.publico_quente
                            this.googleADS.publico_quente_verba = item.publico_quente_verba
                            break
                        case 3: 
                            this.metricasSelecionadas.push('tiktokADS')

                            this.tiktokADS.plan_porc_capital      = item.plan_porc_capital
                            this.tiktokADS.verba_total_utilizada      = item.verba_total_utilizada

                            this.tiktokADS.leads_cpl1   = item.leads_cpl1
                            this.tiktokADS.leads_cpl2   = item.leads_cpl2
                            this.tiktokADS.leads_cpl3   = item.leads_cpl3

                            this.tiktokADS.verba_lembrete_utilizada   = item.verba_lembrete_utilizada
                            this.tiktokADS.lembrete_alcance   = item.lembrete_alcance
                            this.tiktokADS.lembrete_frequencia   = item.lembrete_frequencia
                            
                            this.tiktokADS.verba_cpl_utilizada        = item.verba_cpl_utilizada
                            this.tiktokADS.cpl_alcance = item.cpl_alcance
                            this.tiktokADS.cpl_frequencia = item.cpl_frequencia
                            this.tiktokADS.cpl_cliques = item.cpl_cliques
                            this.tiktokADS.cpl_cpc = item.cpl_cpc
                            this.tiktokADS.cpl_ctr = item.cpl_ctr
                            
                            this.tiktokADS.verba_carrinho_utilizada = item.verba_carrinho_utilizada
                            this.tiktokADS.carrinho_alcance = item.carrinho_alcance
                            this.tiktokADS.carrinho_frequencia = item.carrinho_frequencia
                            this.tiktokADS.carrinho_cliques = item.carrinho_cliques
                            this.tiktokADS.carrinho_cpc = item.carrinho_cpc
                            this.tiktokADS.carrinho_vendas = item.carrinho_vendas

                            this.tiktokADS.captacao_leads = item.captacao_leads 
                            this.tiktokADS.captacao_cpl = item.captacao_cpl 
                            this.tiktokADS.captacao_alcance = item.captacao_alcance
                            this.tiktokADS.captacao_impressoes = item.captacao_impressoes
                            this.tiktokADS.captacao_cpm = item.captacao_cpm
                            this.tiktokADS.captacao_ctr = item.captacao_ctr  
                            this.tiktokADS.captacao_frequencia = item.captacao_frequencia
                            this.tiktokADS.captacao_cliques = item.captacao_cliques
                            this.tiktokADS.captacao_views_pagina = item.captacao_views_pagina
                            this.tiktokADS.captacao_carr_pagina = item.captacao_carr_pagina
                            this.tiktokADS.captacao_carr_pagina = item.captacao_carr_pagina
                            this.tiktokADS.captacao_cpc = item.captacao_cpc

                            this.tiktokADS.publico_quente = item.publico_quente
                            this.tiktokADS.publico_quente_verba = item.publico_quente_verba
                            break
                    }
                })

                const vt = parseFloat(res.data.verba_total || 0)
                const vtt = parseFloat(res.data.verba_total_teste || 0)
                const ft = parseFloat(res.data.faturamento || 0)
                const vtu = parseFloat(res.data.verba_total_utilizada || 0)
                const vttu = parseFloat(res.data.verba_total_teste_utilizada || 0)
                const q_leeds = parseInt(res.data.qtde_leeds || 0)
                const q_vendas = parseInt(res.data.qtde_vendas || 0)   

                
                this.teste = {
                    vt: parseFloat(res.data.verba_total || 0),
                    vtt: parseFloat(res.data.verba_total_teste || 0),
                    ft: parseFloat(res.data.faturamento || 0),
                    vtu: parseFloat(res.data.verba_total_utilizada || 0),
                    vttu: parseFloat(res.data.verba_total_teste_utilizada || 0),
                    q_leeds: parseInt(res.data.qtde_leeds || 0),
                    q_vendas: parseInt(res.data.qtde_vendas || 0)  
                }
                

                this.lancamento.roi_geral = (vtu + vttu)>0 ? ( ft - ( vtu + vttu )).toLocaleString('pt-br', { maximumFractionDigits: 3, currency: 'BRL'}) / ( vtu + vttu ) : 0
                this.lancamento.per_verba_total_utilizada = vt>0 ? ( vtu * 100 ) / vt : 0
                this.lancamento.per_verba_teste_utilizada = vtt>0 ? ( vttu * 100 ) / vtt : 0
                this.lancamento.cpl = (q_leeds>0 ? (vtu / q_leeds) : 0).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 3, currency: 'BRL'})
                this.lancamento.cpa = (q_vendas>0 ? (vtu / q_vendas) : 0).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 3, currency: 'BRL'})
                this.lancamento.conversao = (q_leeds>0 ? ((q_vendas * 100) /q_leeds) : 0).toLocaleString('pt-br',{maximumFractionDigits: 3, currency: 'BRL'})

                this.graficoDistVerbas()
                this.graficoVerbaDirecionada()
                this.graficoPubCaptado()                
                this.informacoesCpls()
                this.outrosDados()
                this.campanhaRemarketing()
                this.mideasTabelas()

                this.dashLancModal = true
            }) 
            .catch(showError)            
        },

        mideasTabelas() {

            this.facebookADS.dash_itens1 = [
                { 
                    leads: this.facebookADS.captacao_leads || 0, 
                    cpl: this.facebookADS.captacao_cpl || 0, 
                    investimento: this.facebookADS.verba_total_utilizada || 0, 
                    alcance: this.facebookADS.captacao_alcance || 0, 
                    impressoes: this.facebookADS.captacao_impressoes || 0, 
                    cpm: this.facebookADS.captacao_cpm || 0,  
                    ctr: this.facebookADS.captacao_ctr || 0,  
                    frequencia: this.facebookADS.captacao_frequencia || 0,
                    click_link: this.facebookADS.captacao_cliques || 0, 
                    views_pagina: this.facebookADS.captacao_views_pagina || 0, 
                    carr_pagina: this.facebookADS.captacao_carr_pagina || 0, 
                    conv_pagina: this.facebookADS.captacao_conv_pagina || 0,
                    cpc: this.facebookADS.captacao_cpc || 0
                }
            ]
            
            this.googleADS.dash_itens1 = [
                { 
                    leads: this.googleADS.captacao_leads || 0, 
                    cpl: this.googleADS.captacao_cpl || 0, 
                    investimento: this.googleADS.verba_total_utilizada || 0,                      
                    visualizacoes: this.googleADS.captacao_impressoes || 0, 
                    cliques: this.googleADS.captacao_cliques || 0,  
                    ctr: this.googleADS.captacao_ctr || 0,                       
                }
            ]            

            this.tiktokADS.dash_itens1 = [
                { 
                    leads: this.tiktokADS.captacao_leads || 0, 
                    cpl: this.tiktokADS.captacao_cpl || 0, 
                    investimento: this.tiktokADS.verba_total_utilizada || 0, 
                    alcance: this.tiktokADS.captacao_alcance || 0, 
                    impressoes: this.tiktokADS.captacao_impressoes || 0, 
                    cpm: this.tiktokADS.captacao_cpm || 0,  
                    ctr: this.tiktokADS.captacao_ctr || 0,  
                    frequencia: this.tiktokADS.captacao_frequencia || 0,
                    click_link: this.tiktokADS.captacao_cliques || 0, 
                    views_pagina: this.tiktokADS.captacao_views_pagina || 0, 
                    carr_pagina: this.tiktokADS.captacao_carr_pagina || 0, 
                    conv_pagina: this.tiktokADS.captacao_conv_pagina || 0,
                    cpc: this.tiktokADS.captacao_cpc || 0
                }
            ]
            
        },

        campanhaRemarketing() {    
            const investimento = (this.facebookADS.verba_lembrete_utilizada || 0) + (this.googleADS.verba_lembrete_utilizada || 0) +(this.tiktokADS.verba_lembrete_utilizada || 0)     
            const alcance = (this.facebookADS.lembrete_alcance || 0) + (this.googleADS.lembrete_alcance || 0) +(this.tiktokADS.lembrete_alcance || 0)     
            const frequencia = (this.facebookADS.lembrete_frequencia || 0) + (this.googleADS.lembrete_frequencia || 0) +(this.tiktokADS.lembrete_frequencia || 0)     
            
            const cplInvestimento = (this.facebookADS.verba_cpl_utilizada || 0) + (this.googleADS.verba_cpl_utilizada || 0) +(this.tiktokADS.verba_cpl_utilizada || 0)     
            const cplAlcance = (this.facebookADS.cpl_alcance || 0) + (this.googleADS.cpl_alcance || 0) +(this.tiktokADS.cpl_alcance || 0)     
            const cplFrequencia = (this.facebookADS.cpl_frequencia || 0) + (this.googleADS.cpl_frequencia || 0) +(this.tiktokADS.cpl_frequencia || 0)     
            const cplCliques = (this.facebookADS.cpl_cliques || 0) + (this.googleADS.cpl_cliques || 0) +(this.tiktokADS.cpl_cliques || 0)     
            const cplCpc = (this.facebookADS.cpl_cpc || 0) + (this.googleADS.cpl_cpc || 0) +(this.tiktokADS.cpl_cpc || 0)     
            const cplCtr = (this.facebookADS.cpl_ctr || 0) + (this.googleADS.cpl_ctr || 0) +(this.tiktokADS.cpl_ctr || 0)     
            
            const carrInvestimento = (this.facebookADS.verba_carrinho_utilizada || 0) + (this.googleADS.verba_carrinho_utilizada || 0) +(this.tiktokADS.verba_carrinho_utilizada || 0)     
            const carrAlcance = (this.facebookADS.carrinho_alcance || 0) + (this.googleADS.carrinho_alcance || 0) +(this.tiktokADS.carrinho_alcance || 0)     
            const carrImpressoes= (this.facebookADS.carrinho_frequencia || 0) + (this.googleADS.carrinho_frequencia || 0) +(this.tiktokADS.carrinho_frequencia || 0)     
            const carrCliques = (this.facebookADS.carrinho_cliques || 0) + (this.googleADS.carrinho_cliques || 0) +(this.tiktokADS.carrinho_cliques || 0)     
            const carrCpc = (this.facebookADS.carrinho_cpc || 0) + (this.googleADS.carrinho_cpc || 0) +(this.tiktokADS.carrinho_cpc || 0)     
            const carrVendas = (this.facebookADS.carrinho_vendas || 0) + (this.googleADS.carrinho_vendas || 0) +(this.tiktokADS.carrinho_vendas || 0)     

            this.lancamento.campanha_lembrete_itens = [
                {
                    investimento: investimento, 
                    alcance: alcance, 
                    frequencia: frequencia
                }
            ]

            this.lancamento.campanha_cpl_itens = [
                {
                    investimento: cplInvestimento, 
                    alcance: cplAlcance, 
                    frequencia: cplFrequencia,
                    cliques: cplCliques,
                    cpc: cplCpc,
                    ctr: cplCtr
                }
            ]

            this.lancamento.campanha_carrinho_itens = [
                {
                    investimento: carrInvestimento, 
                    alcance: carrAlcance, 
                    impressoes: carrImpressoes,
                    cliques: carrCliques,
                    cpc: carrCpc,
                    vendas: carrVendas
                }
            ]
        },

        informacoesCpls() { //Tabela Informações das CPL's
            this.lancamento.cpl_itens = []

            const cpl1 = (this.facebookADS.leads_cpl1 || 0) + (this.googleADS.leads_cpl1 || 0) +(this.tiktokADS.leads_cpl1 || 0)
            const cpl2 = (this.facebookADS.leads_cpl2 || 0) + (this.googleADS.leads_cpl2 || 0) +(this.tiktokADS.leads_cpl2 || 0)
            const cpl3 = (this.facebookADS.leads_cpl3 || 0) + (this.googleADS.leads_cpl3 || 0) +(this.tiktokADS.leads_cpl3 || 0)
            const leads = this.lancamento.qtde_leeds || 0
            const vv = 0
            const pres_cpl1 = leads>0 ? (cpl1*100/leads/100).toLocaleString('pt-br',{maximumFractionDigits: 2, currency: 'BRL'}) : 0
            const pres_cpl2 = cpl1>0 ? (cpl2*100/cpl1/100).toLocaleString('pt-br',{maximumFractionDigits: 2, currency: 'BRL'}) : 0
            const pres_cpl3 = cpl2>0 ? (cpl3*100/cpl2/100).toLocaleString('pt-br',{maximumFractionDigits: 2, currency: 'BRL'}) : 0

            this.lancamento.cpl_itens.push({
                cpl1: cpl1, 
                cpl2: cpl2, 
                cpl3: cpl3, 
                vv: vv, 
                pres_cpl1: pres_cpl1, 
                pres_cpl2: pres_cpl2, 
                pres_cpl3: pres_cpl3
            }) 
        },

        outrosDados() { //Tabela Dados de outras fontes / Outros Dados
            this.lancamento.outros_dados_itens = []
            this.lancamento.outros_dados_fields = []

            if (this.ativaMidia('facebookADS')) {
                this.lancamento.outros_dados_fields.push({key: 'leads_facebook', label: 'Leads Facebook', sortable: false})
            }
            if (this.ativaMidia('googleADS')) {
                this.lancamento.outros_dados_fields.push({key: 'leads_google', label: 'Leads Google', sortable: false})
            }
            if (this.ativaMidia('tiktokADS')) {
                this.lancamento.outros_dados_fields.push({key: 'leads_tiktok', label: 'Leads TikTok', sortable: false})
            }

            this.lancamento.outros_dados_fields.push(
                {key: 'leads_organicos', label: 'Leads Organicos', sortable: false},
                {key: 'num_grupos', label: 'Nº de Grupos', sortable: false},
                {key: 'grupos_super_int', label: 'Grupos Super Int.', sortable: false},
            )

            this.lancamento.outros_dados_itens.push({
                leads_facebook: this.lancamento.leads_facebook, 
                leads_google: this.lancamento.leads_google, 
                leads_tiktok: this.lancamento.leads_tiktok, 
                leads_organicos: this.lancamento.leads_organicos, 
                num_grupos: this.lancamento.num_grupos, 
                grupos_super_int: this.lancamento.grupos_super_int
            })
        },

        distribuicaoEtapas() { //dash_itens5


        },

        dataMaisX(v, n) {
            return moment(v).add(n, 'days').format('DD/MM/YY')
        },

        dataFormat(v, f) {
            return moment(v).format(f)
        },

        graficoDistVerbas() {
            this.distVerba = {
                labels: [],
                datasets: [{
                    backgroundColor: [],
                    data: []
                }]
            }

            if (this.ativaMidia('facebookADS')) {
                this.distVerba.labels.push('Facebook ADS')
                this.distVerba.datasets[0].backgroundColor.push('#398bf7')
                this.distVerba.datasets[0].data.push(this.facebookADS.plan_porc_capital)
            }
            if (this.ativaMidia('googleADS')) {
                this.distVerba.labels.push('Google ADS')
                this.distVerba.datasets[0].backgroundColor.push('#E46651')
                this.distVerba.datasets[0].data.push(this.googleADS.plan_porc_capital)
            }
            if (this.ativaMidia('tiktokADS')) {
                this.distVerba.labels.push('TikTok ADS')
                this.distVerba.datasets[0].backgroundColor.push('#00D8FF')
                this.distVerba.datasets[0].data.push(this.tiktokADS.plan_porc_capital)
            }
        },

        graficoPubCaptado() {
            this.facebookADS.publico_captado = {
                labels: [],
                datasets: [{
                    backgroundColor: [],
                    data: []
                }]
            }
            this.googleADS.publico_captado = {
                labels: [],
                datasets: [{
                    backgroundColor: [],
                    data: []
                }]
            }
            this.tiktokADS.publico_captado = {
                labels: [],
                datasets: [{
                    backgroundColor: [],
                    data: []
                }]
            }            

            //facebook 
            if (this.ativaMidia('facebookADS')) {

                const fbVerbaQuente = (this.facebookADS.publico_quente_verba || 0).toLocaleString('pt-br',{maximumFractionDigits: 3, currency: 'BRL'})
                const fbPublicoQuente = this.facebookADS.publico_quente || 0
                const fbCplQuente = (fbPublicoQuente > 0 ? fbVerbaQuente /fbPublicoQuente : 0).toLocaleString('pt-br',{maximumFractionDigits: 3, currency: 'BRL'})

                const fbVerbaFrio =  ((this.facebookADS.verba_total_utilizada || 0) - fbVerbaQuente).toLocaleString('pt-br',{maximumFractionDigits: 3, currency: 'BRL'})
                const fbPublicoFrio = (this.facebookADS.captacao_leads || 0) - fbPublicoQuente
                const fbCplFrio = (fbPublicoFrio > 0 ? fbVerbaFrio /fbPublicoFrio : 0).toLocaleString('pt-br',{maximumFractionDigits: 3, currency: 'BRL'})

                this.facebookADS.dash_itens4 = [
                    { temp_publico: 'Frio', publico: fbPublicoFrio, cpl: fbCplFrio, _rowVariant: 'primary' },
                    { temp_publico: 'Quente', publico: fbPublicoQuente, cpl: fbCplQuente, _rowVariant: 'danger' }
                ]

                this.facebookADS.dash_itens5 = [
                    { temp_publico: 'Frio', valor: fbVerbaFrio, _rowVariant: 'primary' },
                    { temp_publico: 'Quente', valor: fbVerbaQuente, _rowVariant: 'danger' }
                ]

                const fbTotal = this.facebookADS.dash_itens4[0].publico + this.facebookADS.dash_itens4[1].publico
                const fbFrio = (100 - (this.facebookADS.dash_itens4[0].publico * 100 / fbTotal))
                const fbQuente= (100 - (this.facebookADS.dash_itens4[1].publico * 100 / fbTotal))

                this.facebookADS.publico_captado.labels.push('Frio', 'Quente')
                this.facebookADS.publico_captado.datasets[0].backgroundColor.push('#398bf7', '#E46651')
                this.facebookADS.publico_captado.datasets[0].data.push(fbFrio, fbQuente)                
            }

            //google  
            if (this.ativaMidia('googleADS')) {
                const fbVerbaQuente = (this.googleADS.publico_quente_verba || 0).toLocaleString('pt-br',{maximumFractionDigits: 3, currency: 'BRL'})
                const fbPublicoQuente = this.googleADS.publico_quente || 0
                const fbCplQuente = (fbPublicoQuente > 0 ? fbVerbaQuente /fbPublicoQuente : 0).toLocaleString('pt-br',{maximumFractionDigits: 3, currency: 'BRL'})

                const fbVerbaFrio =  ((this.googleADS.verba_total_utilizada || 0) - fbVerbaQuente).toLocaleString('pt-br',{maximumFractionDigits: 3, currency: 'BRL'})
                const fbPublicoFrio = (this.googleADS.captacao_leads || 0) - fbPublicoQuente
                const fbCplFrio = (fbPublicoFrio > 0 ? fbVerbaFrio /fbPublicoFrio : 0).toLocaleString('pt-br',{maximumFractionDigits: 3, currency: 'BRL'})

                this.googleADS.dash_itens4 = [
                    { temp_publico: 'Frio', publico: fbPublicoFrio, cpl: fbCplFrio, _rowVariant: 'primary' },
                    { temp_publico: 'Quente', publico: fbPublicoQuente, cpl: fbCplQuente, _rowVariant: 'danger' }
                ]

                this.googleADS.dash_itens5 = [
                    { temp_publico: 'Frio', valor: fbVerbaFrio, _rowVariant: 'primary' },
                    { temp_publico: 'Quente', valor: fbVerbaQuente, _rowVariant: 'danger' }
                ]

                const ggTotal = this.googleADS.dash_itens4[0].publico + this.googleADS.dash_itens4[1].publico
                const ggFrio = (100 - (this.googleADS.dash_itens4[0].publico * 100 / ggTotal))
                const ggQuente= (100 - (this.googleADS.dash_itens4[1].publico * 100 / ggTotal))

                this.googleADS.publico_captado.labels.push('Frio', 'Quente')
                this.googleADS.publico_captado.datasets[0].backgroundColor.push('#398bf7', '#E46651')
                this.googleADS.publico_captado.datasets[0].data.push(ggFrio, ggQuente)                
            }

            //tiktok
            if (this.ativaMidia('tiktokADS')) {
                
                const ttVerbaQuente = (this.tiktokADS.publico_quente_verba || 0)
                const ttPublicoQuente = this.tiktokADS.publico_quente || 0
                const ttCplQuente = (ttPublicoQuente > 0 ? ttVerbaQuente /ttPublicoQuente : 0)

                const ttVerbaFrio =  ((this.tiktokADS.verba_total_utilizada || 0) - ttVerbaQuente)
                const ttPublicoFrio = (this.tiktokADS.captacao_leads || 0) - ttPublicoQuente
                const ttCplFrio = (ttPublicoFrio > 0 ? ttVerbaFrio /ttPublicoFrio : 0)

                this.tiktokADS.dash_itens4 = [
                    { 
                        temp_publico: 'Frio', 
                        publico: ttPublicoFrio, 
                        cpl: ttCplFrio.toLocaleString('pt-br',{maximumFractionDigits: 3, currency: 'BRL'}), 
                        _rowVariant: 'primary' 
                    },
                    { 
                        temp_publico: 'Quente', 
                        publico: ttPublicoQuente, 
                        cpl: ttCplQuente.toLocaleString('pt-br',{maximumFractionDigits: 3, currency: 'BRL'}), 
                        _rowVariant: 'danger' 
                    }
                ]                

                const ttTotal = this.tiktokADS.dash_itens4[0].publico + this.tiktokADS.dash_itens4[1].publico
                const ttFrio = (100 - (this.tiktokADS.dash_itens4[0].publico * 100 / ttTotal))
                const ttQuente= (100 - (this.tiktokADS.dash_itens4[1].publico * 100 / ttTotal))

                this.tiktokADS.publico_captado.labels.push('Frio', 'Quente')
                this.tiktokADS.publico_captado.datasets[0].backgroundColor.push('#398bf7', '#E46651')
                this.tiktokADS.publico_captado.datasets[0].data.push(ttFrio, ttQuente)               
            }

            
            this.graficoVerbaDistribuicao()
        },

        graficoVerbaDirecionada() {
            this.facebookADS.verba_direcionada = {
                labels: [],
                datasets: [{
                    backgroundColor: [],
                    data: []
                }]
            }
            this.googleADS.verba_direcionada = {
                labels: [],
                datasets: [{
                    backgroundColor: [],
                    data: []
                }]
            }
            this.tiktokADS.verba_direcionada = {
                labels: [],
                datasets: [{
                    backgroundColor: [],
                    data: []
                }]
            }            
            
            //facebook
            if (this.ativaMidia('facebookADS')) {
                const ffTotal = this.facebookADS.verba_total_utilizada || 0
                const ffVerbaQuente = (this.facebookADS.publico_quente_verba || 0)
                const ffVerbaFrio =  ((this.facebookADS.verba_total_utilizada || 0) - ffVerbaQuente)

                const ffFrio = (100 - (ffVerbaFrio * 100 / ffTotal))
                const ffQuente= (100 - (ffVerbaQuente * 100 / ffTotal))

                this.googleADS.dash_itens5 = [
                    { 
                        temp_publico: 'Frio', 
                        valor: ffVerbaFrio.toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}), 
                        _rowVariant: 'primary' 
                    },
                    { 
                        temp_publico: 'Quente', 
                        valor: ffVerbaQuente.toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}), 
                        _rowVariant: 'danger' 
                    }
                ]

                this.facebookADS.verba_direcionada.labels.push('Frio', 'Quente')
                this.facebookADS.verba_direcionada.datasets[0].backgroundColor.push('#398bf7', '#E46651')
                this.facebookADS.verba_direcionada.datasets[0].data.push(ffFrio, ffQuente)                
            }

            //google
            if (this.ativaMidia('googleADS')) {
                const ggTotal = this.googleADS.verba_total_utilizada || 0
                const ggVerbaQuente = (this.googleADS.publico_quente_verba || 0)
                const ggVerbaFrio =  ((this.googleADS.verba_total_utilizada || 0) - ggVerbaQuente)

                const ggFrio = (100 - (ggVerbaFrio * 100 / ggTotal))
                const ggQuente= (100 - (ggVerbaQuente * 100 / ggTotal))

                this.googleADS.dash_itens5 = [
                    { 
                        temp_publico: 'Frio', 
                        valor: ggVerbaFrio.toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}), 
                        _rowVariant: 'primary' 
                    },
                    { 
                        temp_publico: 'Quente', 
                        valor: ggVerbaQuente.toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}), 
                        _rowVariant: 'danger' 
                    }
                ]

                this.googleADS.verba_direcionada.labels.push('Frio', 'Quente')
                this.googleADS.verba_direcionada.datasets[0].backgroundColor.push('#398bf7', '#E46651')
                this.googleADS.verba_direcionada.datasets[0].data.push(ggFrio, ggQuente)                
            }

            //tiktok     
            if (this.ativaMidia('tiktokADS')) {
                const ttTotal = this.tiktokADS.verba_total_utilizada || 0
                const ttVerbaQuente = (this.tiktokADS.publico_quente_verba || 0)
                const ttVerbaFrio =  ((this.tiktokADS.verba_total_utilizada || 0) - ttVerbaQuente)

                const ttFrio = (ttVerbaFrio * 100 / ttTotal)
                const ttQuente= (ttVerbaQuente * 100 / ttTotal)

                this.tiktokADS.dash_itens5 = [
                    { 
                        temp_publico: 'Frio', 
                        valor: ttVerbaFrio.toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}), 
                        _rowVariant: 'primary' 
                    },
                    { 
                        temp_publico: 'Quente', 
                        valor: ttVerbaQuente.toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}), 
                        _rowVariant: 'danger' 
                    }
                ]

                this.tiktokADS.verba_direcionada.labels.push('Frio', 'Quente')
                this.tiktokADS.verba_direcionada.datasets[0].backgroundColor.push('#398bf7', '#E46651')
                this.tiktokADS.verba_direcionada.datasets[0].data.push(ttFrio, ttQuente)                
            }
        },

        graficoVerbaDistribuicao() {
            this.lancamento.investimentoPorMidea = {
                labels: [],
                datasets: [{
                    backgroundColor: [],
                    data: []
                }]
            }
            this.lancamento.distribuicaoPorEtapa = {
                labels: [],
                datasets: [{
                    backgroundColor: [],
                    data: []
                }]
            }            
            
            const verbaTotal = parseFloat(this.lancamento.verba_total || 0)
            const fbVerbaUtilizada = parseFloat(this.facebookADS.verba_total_utilizada || 0)
            const ggVerbaUtilizada = parseFloat(this.googleADS.verba_total_utilizada || 0)
            const ttVerbaUtilizada = parseFloat(this.tiktokADS.verba_total_utilizada || 0)
            const fbVerbaPorc = parseFloat(this.facebookADS.plan_porc_capital || 0)
            const ggVerbaPorc = parseFloat(this.googleADS.plan_porc_capital || 0)
            const ttVerbaPorc = parseFloat(this.tiktokADS.plan_porc_capital || 0)
            const fbVerbaTotal = ((verbaTotal * fbVerbaPorc)/100)
            const ggVerbaTotal = ((verbaTotal * ggVerbaPorc)/100)
            const ttVerbaTotal = ((verbaTotal * ttVerbaPorc)/100)
            const fbVerbaPorcUtil = ((fbVerbaUtilizada / fbVerbaTotal) * 100)
            const ggVerbaPorcUtil = ((ggVerbaUtilizada / ggVerbaTotal) * 100)
            const ttVerbaPorcUtil = ((ttVerbaUtilizada / ttVerbaTotal) * 100)


            this.lancamento.investimentoPorMideaItens = []            

            let captacao = 0
            let lembrete = 0
            let cpl = 0
            let carrinho = 0               

            //facebook
            if (this.ativaMidia('facebookADS')) {
                this.lancamento.investimentoPorMidea.labels.push('Facebook ADS')
                this.lancamento.investimentoPorMidea.datasets[0].backgroundColor.push('#398bf7')
                this.lancamento.investimentoPorMidea.datasets[0].data.push(fbVerbaUtilizada) 

                captacao    = captacao  + this.facebookADS.verba_total_utilizada
                lembrete    = lembrete  + this.facebookADS.verba_lembrete_utilizada
                cpl         = cpl       + this.facebookADS.verba_cpl_utilizada
                carrinho    = carrinho  + this.facebookADS.verba_carrinho_utilizada

                this.lancamento.investimentoPorMideaItens.push({ midia: 'Facebook ADS', planejado: fbVerbaTotal, valor: fbVerbaUtilizada, perc: fbVerbaPorcUtil })
            }

            //google
            if (this.ativaMidia('googleADS')) {
                this.lancamento.investimentoPorMidea.labels.push('Google ADS')
                this.lancamento.investimentoPorMidea.datasets[0].backgroundColor.push('#DD1B16')
                this.lancamento.investimentoPorMidea.datasets[0].data.push(ggVerbaUtilizada) 

                captacao    = captacao  + this.googleADS.verba_total_utilizada
                lembrete    = lembrete  + this.googleADS.verba_lembrete_utilizada
                cpl         = cpl       + this.googleADS.verba_cpl_utilizada
                carrinho    = carrinho  + this.googleADS.verba_carrinho_utilizada

                this.lancamento.investimentoPorMideaItens.push({ midia: 'Google ADS', planejado: ggVerbaTotal, valor: ggVerbaUtilizada, perc: ggVerbaPorcUtil })
            }

            //tiktok
            if (this.ativaMidia('tiktokADS')) {
                this.lancamento.investimentoPorMidea.labels.push('TikTok ADS')
                this.lancamento.investimentoPorMidea.datasets[0].backgroundColor.push('#41B883')
                this.lancamento.investimentoPorMidea.datasets[0].data.push(ttVerbaUtilizada) 

                captacao    = captacao  + this.tiktokADS.verba_total_utilizada
                lembrete    = lembrete  + this.tiktokADS.verba_lembrete_utilizada
                cpl         = cpl       + this.tiktokADS.verba_cpl_utilizada
                carrinho    = carrinho  + this.tiktokADS.verba_carrinho_utilizada

                this.lancamento.investimentoPorMideaItens.push({ midia: 'TikTok ADS', planejado: ttVerbaTotal, valor: ttVerbaUtilizada, perc: ttVerbaPorcUtil })
            }

            this.lancamento.distribuicaoPorEtapa.labels.push('Captação', 'Lembrete', 'CPL', 'Carrinho')
            this.lancamento.distribuicaoPorEtapa.datasets[0].backgroundColor.push('#41B883', '#E46651', '#00D8FF', '#DD1B16')
            this.lancamento.distribuicaoPorEtapa.datasets[0].data.push(captacao, lembrete, cpl, carrinho) 

            this.lancamento.distribuicaoPorEtapaItens = [{
                captacao: captacao,
                lembrete: lembrete,
                cpl: cpl,
                carrinho: carrinho
            }]            
        },

        checaDistri(t, v) {  
            if ( t === 'P' ) {
                if (v < 100 && v >=0) { return 'moeda-totais-verbas moeda-totais-verbas-perc'}
                if (v === 100) { return 'moeda-totais-verbas moeda-totais-verbas-perc-succ'}
                if (v < 0 || v > 100) { return 'moeda-totais-verbas moeda-totais-verbas-perc-err'}
            }
            if ( t === 'V' ) {
                if (v < 100 && v >=0) { return 'moeda-totais-verbas moeda-totais-verbas-vlr'}
                if (v === 100) { return 'moeda-totais-verbas moeda-totais-verbas-vlr-succ'}
                if (v < 0 || v > 100) { return 'moeda-totais-verbas moeda-totais-verbas-vlr-err'}
            }

        },

        removerLembrete(item) {
            const idx = this.lembretesSelecionados.map((e)=>{ return e.id; }).indexOf(item.id);
            const idy = this.lembretes.map((e)=>{ return e.id; }).indexOf(item.id);

            this.lembretesSelecionados.splice(idx, 1)
            this.lembretes[idy].ativo = true
        },

        ativaMidia(valor){
            let vlr = this.metricasSelecionadas.filter(v => v === valor)  
            this.zerarDesativados()          
            return vlr.length > 0 ? true : false
        },

        calculaTitulomidia(m) {
            let t = ''

            if (m === 'facebookADS') {
                let facebookADS = (
                    parseFloat(this.facebookADS.plan_porc_captacao ? this.facebookADS.plan_porc_captacao : 0) + 
                    parseFloat(this.facebookADS.plan_porc_antecipacao ? this.facebookADS.plan_porc_antecipacao : 0) + 
                    parseFloat(this.facebookADS.plan_porc_camp_pre_lanc ? this.facebookADS.plan_porc_camp_pre_lanc : 0) + 
                    parseFloat(this.facebookADS.plan_porc_vendas ? this.facebookADS.plan_porc_vendas : 0)
                )

                t = `${this.facebookADS.valor_capital.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} - `
                t = t + `${facebookADS.toLocaleString('pt-br',{currency: 'BRL'})}%`
                if (this.lancamento.teste) {
                    t = t + ` | Verba teste: ${this.facebookADS.valor_teste.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`
                }

                return t

            }
            if (m === 'googleADS') {
                let googleADS = (
                    parseFloat(this.googleADS.plan_porc_captacao ? this.googleADS.plan_porc_captacao : 0) + 
                    parseFloat(this.googleADS.plan_porc_antecipacao ? this.googleADS.plan_porc_antecipacao : 0) + 
                    parseFloat(this.googleADS.plan_porc_camp_pre_lanc ? this.googleADS.plan_porc_camp_pre_lanc : 0) + 
                    parseFloat(this.googleADS.plan_porc_vendas ? this.googleADS.plan_porc_vendas : 0)
                )

                t = `${this.googleADS.valor_capital.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} - `
                t = t + `${googleADS.toLocaleString('pt-br',{currency: 'BRL'})}%`
                if (this.lancamento.teste) {
                    t = t + ` | Verba teste: ${this.googleADS.valor_teste.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`
                }

                return t

            }
            if (m === 'tiktokADS') {
                let tiktokADS = (
                    parseFloat(this.tiktokADS.plan_porc_captacao ? this.tiktokADS.plan_porc_captacao : 0) + 
                    parseFloat(this.tiktokADS.plan_porc_antecipacao ? this.tiktokADS.plan_porc_antecipacao : 0) + 
                    parseFloat(this.tiktokADS.plan_porc_camp_pre_lanc ? this.tiktokADS.plan_porc_camp_pre_lanc : 0) + 
                    parseFloat(this.tiktokADS.plan_porc_vendas ? this.tiktokADS.plan_porc_vendas : 0)
                )

                t = `${this.tiktokADS.valor_capital.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} - `
                t = t + `${tiktokADS.toLocaleString('pt-br',{currency: 'BRL'})}%`
                if (this.lancamento.teste) {
                    t = t + ` | Verba teste: ${this.tiktokADS.valor_teste.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`
                }

                return t

            }

            return ''
        },

        zerarDesativados() {
            const fb = this.metricasSelecionadas.filter(v => v === 'facebookADS')
            const fbZerar = fb.length > 0 ? false : true
            if (fbZerar) {
                this.facebookADS.plan_porc_capital = 0
                this.facebookADS.valor_capital = 0
                this.facebookADS.valor_teste = 0
            }

            const gg = this.metricasSelecionadas.filter(v => v === 'googleADS')
            const ggZerar = gg.length > 0 ? false : true
            if (ggZerar) {
                this.googleADS.plan_porc_capital = 0
                this.googleADS.valor_capital = 0
                this.googleADS.valor_teste = 0
            }

            const ig = this.metricasSelecionadas.filter(v => v === 'tiktokADS')
            const igZerar = ig.length > 0 ? false : true
            if (igZerar) {
                this.tiktokADS.plan_porc_capital = 0
                this.tiktokADS.valor_capital = 0
                this.tiktokADS.valor_teste = 0
            }

            this.calculaDistribuicao()
        },

        navegarAbas(n) {
            if (this.validaEtapas(n)) {
                if ((parseInt(this.e1) + n) >= 1) {
                    this.e1 = parseInt(this.e1) + n
                } else {
                    this.e1 = 1
                }

                if(parseInt(this.e1) === 4 ) { this.preencheAba04() }                 
            }
        },        

        calculaCapital() {          

            this.facebookADS.valor_capital = (parseFloat(this.lancamento.verba_total ) * parseFloat(this.facebookADS.plan_porc_capital ? this.facebookADS.plan_porc_capital : 0)) /100
            
            this.googleADS.valor_capital = (parseFloat(this.lancamento.verba_total ) * parseFloat(this.googleADS.plan_porc_capital ? this.googleADS.plan_porc_capital : 0)) /100
            
            this.tiktokADS.valor_capital = (parseFloat(this.lancamento.verba_total ) * parseFloat(this.tiktokADS.plan_porc_capital ? this.tiktokADS.plan_porc_capital : 0)) /100
                

            this.calculaDistribuicao()
        },

        calculaDistribuicao() {
            const pFacebookADS = parseFloat(this.facebookADS.plan_porc_capital ? this.facebookADS.plan_porc_capital : 0)
            const pGoogleADS = parseFloat(this.googleADS.plan_porc_capital ? this.googleADS.plan_porc_capital : 0)
            const pTiktokADS = parseFloat(this.tiktokADS.plan_porc_capital ? this.tiktokADS.plan_porc_capital : 0)
            
            const vFacebookADS = parseFloat(this.facebookADS.valor_capital ? this.facebookADS.valor_capital : 0)
            const vGoogleADS = parseFloat(this.googleADS.valor_capital ? this.googleADS.valor_capital : 0)
            const vTiktokADS = parseFloat(this.tiktokADS.valor_capital ? this.tiktokADS.valor_capital : 0)


            this.lancamento.perc_distribuido = pFacebookADS + pGoogleADS + pTiktokADS
            this.lancamento.vlr_distribuido = vFacebookADS + vGoogleADS + vTiktokADS
            
            this.lancamento.perc_restante = 100 - (pFacebookADS + pGoogleADS + pTiktokADS)
            this.lancamento.vlr_restante = parseFloat(this.lancamento.verba_total) - (vFacebookADS + vGoogleADS + vTiktokADS)
        },

        validaEtapas(n) {
            switch (this.e1) {
                case 1: {
                    if(n === 1) { return this.validaAba01() } else { return true }
                }
                case 2: {
                    if(n === 1) { return this.validaAba02() } else { return true }
                }
                case 3: {
                    if(n === 1) { return this.validaAba03() } else { return true }
                }
                case 4: {
                    return true
                }
            }
        },
        
        preencheAba03() {

            if (this.lembretesSelecionados.length > 0) {
                this.facebookADS.plan_porc_captacao = 85
                this.facebookADS.plan_porc_antecipacao = 2
                this.facebookADS.plan_porc_camp_pre_lanc = 9
                this.facebookADS.plan_porc_vendas = 4

                this.googleADS.plan_porc_captacao = 85
                this.googleADS.plan_porc_antecipacao = 2
                this.googleADS.plan_porc_camp_pre_lanc = 9
                this.googleADS.plan_porc_vendas = 4

                this.tiktokADS.plan_porc_captacao = 85
                this.tiktokADS.plan_porc_antecipacao = 2
                this.tiktokADS.plan_porc_camp_pre_lanc = 9
                this.tiktokADS.plan_porc_vendas = 4
            } else {
                this.facebookADS.plan_porc_captacao = 87
                this.facebookADS.plan_porc_antecipacao = 0
                this.facebookADS.plan_porc_camp_pre_lanc = 9
                this.facebookADS.plan_porc_vendas = 4

                this.googleADS.plan_porc_captacao = 87
                this.googleADS.plan_porc_antecipacao = 0
                this.googleADS.plan_porc_camp_pre_lanc = 9
                this.googleADS.plan_porc_vendas = 4

                this.tiktokADS.plan_porc_captacao = 87
                this.tiktokADS.plan_porc_antecipacao = 0
                this.tiktokADS.plan_porc_camp_pre_lanc = 9
                this.tiktokADS.plan_porc_vendas = 4
            }
            
            
        },

        preencheAba04() {
            this.resumoMidias.length = 0

            this.lancamento.dt_ab = moment(this.lancamento.abertura_carrinho).format('DD/MM/YYYY')
            this.lancamento.dt_fc = moment(this.lancamento.fechamento_carrinho).format('DD/MM/YYYY')
            this.lancamento.dt_in = moment(this.lancamento.data_inicio).format('DD/MM/YYYY')
            this.lancamento.dt_fm = moment(this.lancamento.data_final).format('DD/MM/YYYY')

            const tipo = this.tipos.find( tipo => tipo.id_lancamento_tipo === this.lancamento.id_lancamento_tipo )
            this.lancamento.descricaoTipo = tipo.descricao

            if (this.ativaMidia('facebookADS')) {
                this.resumoMidias.push({
                    midia: 'Facebook ADS', 
                    verba: this.facebookADS.valor_capital, 
                    teste: this.facebookADS.valor_teste, 
                    captacao: this.facebookADS.plan_porc_captacao, 
                    antecipacao: this.facebookADS.plan_porc_antecipacao, 
                    cpl: this.facebookADS.plan_porc_camp_pre_lanc, 
                    vendas: this.facebookADS.plan_porc_vendas                    
                })

                this.itensFacebookADSCaptacao = this.preencheCaptacao(this.facebookADS)
                
                this.itensFacebookADSAntecipacao = this.preencheAntecipacao(this.facebookADS)
                
                const cpls = this.preencheCpl(this.facebookADS)
                this.itensFacebookADSCpl = cpls.cpls
                this.itensFacebookADSCplDetalhes = cpls.detalhes

                const vendas = this.preencheVendas(this.facebookADS)
                this.itensFacebookADSVendas = vendas.vendas
                this.itensFacebookADSVendasDetalhes = vendas.detalhes
            }
            if (this.ativaMidia('googleADS')) {
                this.resumoMidias.push({
                    midia: 'Google ADS', 
                    verba: this.googleADS.valor_capital, 
                    teste: this.googleADS.valor_teste, 
                    captacao: this.googleADS.plan_porc_captacao, 
                    antecipacao: this.googleADS.plan_porc_antecipacao, 
                    cpl: this.googleADS.plan_porc_camp_pre_lanc, 
                    vendas: this.googleADS.plan_porc_vendas
                })

                this.itensGoogleADSCaptacao = this.preencheCaptacao(this.googleADS)
                
                this.itensGoogleADSAntecipacao = this.preencheAntecipacao(this.googleADS)
                
                const cpls = this.preencheCpl(this.googleADS)
                this.itensGoogleADSCpl = cpls.cpls
                this.itensGoogleADSCplDetalhes = cpls.detalhes

                const vendas = this.preencheVendas(this.googleADS)
                this.itensGoogleADSVendas = vendas.vendas
                this.itensGoogleADSVendasDetalhes = vendas.detalhes
            }

            if (this.ativaMidia('tiktokADS')) {
                this.resumoMidias.push({
                    midia: 'TikTok ADS', 
                    verba: this.tiktokADS.valor_capital, 
                    teste: this.tiktokADS.valor_teste, 
                    captacao: this.tiktokADS.plan_porc_captacao, 
                    antecipacao: this.tiktokADS.plan_porc_antecipacao, 
                    cpl: this.tiktokADS.plan_porc_camp_pre_lanc, 
                    vendas: this.tiktokADS.plan_porc_vendas
                })

                this.itensTiktokADSCaptacao = this.preencheCaptacao(this.tiktokADS)
                
                this.itensTiktokADSAntecipacao = this.preencheAntecipacao(this.tiktokADS)
                
                const cpls = this.preencheCpl(this.tiktokADS)
                this.itensTiktokADSCpl = cpls.cpls
                this.itensTiktokADSCplDetalhes = cpls.detalhes

                const vendas = this.preencheVendas(this.tiktokADS)
                this.itensTiktokADSVendas = vendas.vendas
                this.itensTiktokADSVendasDetalhes = vendas.detalhes
            }

        },

        preencheVendas(midia) {
            const p_vendas = parseFloat(midia.plan_porc_vendas)
            const v_capital = parseFloat((midia.valor_capital * p_vendas) /100)
            const d_inicio =  this.lancamento.abertura_carrinho
            const d_fim =  moment(this.lancamento.abertura_carrinho).add(3, 'days')

            const duracao = 3
                        
            return {                
                vendas: [
                    {                   
                        orcamento: v_capital.toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}), 
                        valor_por_dia: (v_capital / duracao).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}), 
                        data_inicial: moment(d_inicio).format('DD/MM/YY'), 
                        data_final: moment(d_fim).format('DD/MM/YY'), 
                        duracao: duracao
                    },
                ],
                detalhes: [
                    {
                        etapa: 'Inscrições Abertas', 
                        d1: (v_capital*(1/3)).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}), 
                        d2: (v_capital*(0.3/3)).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}),
                        total: ((v_capital*(1/3)) + (v_capital*(0.3/3))).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})
                    },
                    {
                        etapa: 'Garantia',                        
                        d2: (v_capital*(0.3/3)).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}),
                        d3: (v_capital*(0.3/3)).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}),
                        total: ((v_capital*(0.3/3)) + (v_capital*(0.3/3))).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})
                    },
                    {
                        etapa: 'Depoimento',                        
                        d2: (v_capital*(0.4/3)).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}),
                        d3: (v_capital*(0.3/3)).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}),
                        total: ((v_capital*(0.4/3)) + (v_capital*(0.3/3))).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})
                    },
                    {
                        etapa: 'Último dia',
                        d3: (v_capital*(0.4/3)).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}),
                        total: (v_capital*(0.4/3)).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})
                    },
                    {
                        etapa: '',
                        d1: (v_capital /3).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}),
                        d2: (v_capital /3).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}),
                        d3: (v_capital /3).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}),
                        total: (v_capital).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}),
                        _rowVariant: 'danger'
                    },
                ]
            }
        },

        preencheCpl(midia) {
            const p_cpl = parseFloat(midia.plan_porc_camp_pre_lanc)
            const v_capital = parseFloat((midia.valor_capital * p_cpl) /100)

            const d_cpl1 = this.lancamento.data_inicio_cpl1
            const d_cpl2 = this.lancamento.data_inicio_cpl2
            const d_cpl3 = this.lancamento.data_inicio_cpl3
            const fim_cpl =  moment(this.lancamento.abertura_carrinho).add(3, 'days')

            const diasCpl1 = moment(fim_cpl).diff(d_cpl1, 'days') + 1
            const diasCpl2 = moment(fim_cpl).diff(d_cpl2, 'days') + 1
            const diasCpl3 = moment(fim_cpl).diff(d_cpl3, 'days') + 1  
            
            const dCpl1 = moment(d_cpl2).diff(d_cpl1, 'days')
            const dCpl2 = moment(d_cpl3).diff(d_cpl2, 'days')
            const dCpl3 = moment(fim_cpl).diff(d_cpl3, 'days')
                        
            let c = {
                cpls: [
                    {
                        etapa: 'CPL 1', 
                        orcamento: (v_capital /2).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}), 
                        valor_por_dia: ((v_capital /2) / diasCpl1).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}), 
                        data_inicial: moment(d_cpl1).format('DD/MM/YY'), 
                        data_final: moment(fim_cpl).format('DD/MM/YY'), 
                        duracao: diasCpl1
                    },
                    {
                        etapa: 'CPL 2', 
                        orcamento: (v_capital /4).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}), 
                        valor_por_dia: ((v_capital /4) / diasCpl2).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}), 
                        data_inicial: moment(d_cpl2).format('DD/MM/YY'), 
                        data_final: moment(fim_cpl).format('DD/MM/YY'), 
                        duracao: diasCpl2
                    },
                    {
                        etapa: 'CPL 3', 
                        orcamento: (v_capital /4).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}), 
                        valor_por_dia: ((v_capital /4) / diasCpl3).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}), 
                        data_inicial: moment(d_cpl3).format('DD/MM/YY'), 
                        data_final: moment(fim_cpl).format('DD/MM/YY'), 
                        duracao: diasCpl3
                    },

                ],
                detalhes: [
                    dCpl1 === 2 ? 
                        {
                            cpl: 'CPL 1', 
                            d1: (v_capital /2) * (1.85/7),
                            d2: (v_capital /2) * (1.85/7),
                            d3: (v_capital /2) * (0.9/7),
                            d4: (v_capital /2) * (0.9/7),
                            d5: (v_capital /2) * (0.5/7),
                            d6: (v_capital /2) * (0.5/7),
                            d7: (v_capital /2) * (0.5/7),                            
                            total: (v_capital /2)
                        } :                                  
                        {
                            cpl: 'CPL 1', 
                            d1: (v_capital /2) * (3.7/7),
                            d2: (v_capital /2) * (1.8/7),
                            d3: (v_capital /2) * (1.5/7),
                            total: (v_capital /2)
                        },
                    dCpl2 === 2 ? 
                        {
                            cpl: 'CPL 2',
                            d3: (v_capital /4) * (1.375/5),
                            d4: (v_capital /4) * (1.375/5),
                            d5: (v_capital /4) * (0.75/5),
                            d6: (v_capital /4) * (0.75/5),
                            d7: (v_capital /4) * (0.75/5),                            
                            total: (v_capital /4)
                        } :                                  
                        {
                            cpl: 'CPL 2',
                            d2: (v_capital /4) * (2.75/5),
                            d3: (v_capital /4) * (2.25/5),
                            total: (v_capital /4)
                        },
                    dCpl3 === 2 ? 
                        {
                            cpl: 'CPL 3',                           
                            d5: ((v_capital /4) /3),
                            d6: ((v_capital /4) /3),
                            d7: ((v_capital /4) /3),                            
                            total: (v_capital  /4)
                        } :                                  
                        {
                            cpl: 'CPL 3',
                            d3: (v_capital /4),
                            total: (v_capital /4)
                        }
                ]
            }

            const t1 = c.detalhes.reduce(function (acc, obj) { return acc + parseFloat((obj.d1 ? obj.d1 : 0)); }, 0)
            const t2 = c.detalhes.reduce(function (acc, obj) { return acc + parseFloat((obj.d2 ? obj.d2 : 0)); }, 0)
            const t3 = c.detalhes.reduce(function (acc, obj) { return acc + parseFloat((obj.d3 ? obj.d3 : 0)); }, 0)
            const t4 = c.detalhes.reduce(function (acc, obj) { return acc + parseFloat((obj.d4 ? obj.d4 : 0)); }, 0)
            const t5 = c.detalhes.reduce(function (acc, obj) { return acc + parseFloat((obj.d5 ? obj.d5 : 0)); }, 0)
            const t6 = c.detalhes.reduce(function (acc, obj) { return acc + parseFloat((obj.d6 ? obj.d6 : 0)); }, 0)
            const t7 = c.detalhes.reduce(function (acc, obj) { return acc + parseFloat((obj.d7 ? obj.d7 : 0)); }, 0)

            c.detalhes.forEach(v => {
                v.d1 ? v.d1 = v.d1.toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}) : ''
                v.d2 ? v.d2 = v.d2.toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}) : ''
                v.d3 ? v.d3 = v.d3.toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}) : ''
                v.d4 ? v.d4 = v.d4.toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}) : ''
                v.d5 ? v.d5 = v.d5.toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}) : ''
                v.d6 ? v.d6 = v.d6.toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}) : ''
                v.d7 ? v.d7 = v.d7.toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}) : ''
                v.total ? v.total = v.total.toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}) : ''
            })

            c.detalhes.push({
                cpl: '', 
                d1: (t1).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}),
                d2: (t2).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}),
                d3: (t3).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}),
                d4: (t4).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}),
                d5: (t5).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}),
                d6: (t6).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}),
                d7: (t7).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}),                            
                total: (v_capital).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}),
                _rowVariant: 'danger'
            })

            return c
        },

        preencheAntecipacao(midia) {
            let antecipacao = []

            const p_cpl = parseFloat(midia.plan_porc_antecipacao)
            const v_capital = parseFloat((midia.valor_capital * p_cpl) /100)
            const t_lembretes = this.lembretesSelecionados.length
            const orcamento =  v_capital / t_lembretes
            let lembretes = [ ...this.lembretesSelecionados ]
            const ini_cpl1 = midia.data_inicio_cpl1
            
            
            lembretes.sort(function compare(a, b) {
                if (a.dias < b.dias) return -1;
                if (a.dias > b.dias) return 1;
                return 0;
            })

            let dataAnterior = null

            antecipacao = lembretes.map(item => {
                const dataFinal = !dataAnterior ? moment(ini_cpl1).add(-1, 'days') : moment(dataAnterior).add(-1, 'days')
                const dataInicio = moment(dataFinal).add(-item.dias + 1, 'days')
                dataAnterior = dataInicio

                const ant = {
                    etapa: item.nome, 
                    orcamento: orcamento.toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}), 
                    valor_por_dia: (orcamento / item.dias).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}), 
                    data_inicial: moment(dataInicio).format('DD/MM/YY'), 
                    data_final: moment(dataFinal).format('DD/MM/YY'), 
                    duracao: item.dias
                }

                return ant
            })

            antecipacao.sort(function compare(a, b) {
                if (a.duracao < b.duracao) return 1;
                if (a.duracao > b.duracao) return -1;
                return 0;
            })           
            
            return antecipacao   
        },

        preencheCaptacao(midia) {
            let captacao = []
            
            const p_captacao = parseFloat(midia.plan_porc_captacao)
            const v_capital = parseFloat((midia.valor_capital * p_captacao) /100)
            const v_teste = parseFloat(midia.valor_teste)
            // const p_cpl = parseFloat(midia.plan_porc_camp_pre_lanc)
            // const p_venda = parseFloat(midia.plan_porc_vendas)
            const lanc_in_teste = this.lancamento.data_inicio_teste
            const lanc_fm_teste = this.lancamento.data_fim_teste
            const lanc_in_lanc = this.lancamento.data_inicio
            const lanc_fm_lanc = this.lancamento.data_final

            const durLanc = moment(lanc_fm_lanc).diff(lanc_in_lanc, 'days')
            

            if (this.lancamento.teste) {
                const durTeste = moment(lanc_fm_teste).diff(lanc_in_teste, 'days')

                captacao.push(
                    {
                        etapa: 'Teste', 
                        orcamento: v_teste.toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}), 
                        valor_por_dia: (v_teste / durTeste).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}) , 
                        data_inicial: moment(lanc_in_teste).format('DD/MM/YY'), 
                        data_final: moment(lanc_fm_teste).format('DD/MM/YY'), 
                        duracao: durTeste
                    },
                    {
                        etapa: 'Escala', 
                        orcamento: (v_capital - v_teste).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}), 
                        valor_por_dia: ((v_capital - v_teste) / (durLanc - durTeste)).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}) , 
                        data_inicial: moment(lanc_in_lanc).add(durTeste, 'days').format('DD/MM/YY'), 
                        data_final: moment(lanc_fm_lanc).format('DD/MM/YY'), 
                        duracao: durLanc - durTeste
                    }
                )

            } else {
                captacao.push(
                    {
                        etapa: 'Escala', 
                        orcamento: v_capital.toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}), 
                        valor_por_dia: (v_capital / durLanc).toLocaleString('pt-br',{minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}) , 
                        data_inicial: moment(lanc_in_lanc).format('DD/MM/YY'), 
                        data_final: moment(lanc_fm_lanc).format('DD/MM/YY'), 
                        duracao: durLanc
                    }
                )
            }

            return captacao

        },

        validaAba01() {
            if (this.lancamento.teste) {
                if ((this.lancamento.verba_total_teste <= 0 || this.lancamento.verba_total_teste > this.lancamento.verba_total)) {
                    this.$toasted.error('Verba para teste não pode ser zero e nem ultrapassar o valor da verba total.')
                    return false
                }

                if (                
                    (this.lancamento.data_inicio_teste < this.lancamento.data_inicio) ||
                    (this.lancamento.data_inicio_teste > this.lancamento.data_final) ||
                    (this.lancamento.data_fim_teste < this.lancamento.data_inicio) ||
                    (this.lancamento.data_fim_teste > this.lancamento.data_final)
                ) {
                    this.$toasted.error('As datas de teste devem estar dentro das datas de Inicio e Fim de Captação.')
                    return false
                }

                if (                
                    (this.lancamento.data_inicio_teste > this.lancamento.data_fim_teste) 
                ) {
                    this.$toasted.error('A data de Início do teste não pode ser maior que a data de Fim de teste.')
                    return false
                }

            }


            if (!this.lancamento.teste) {
                this.lancamento.verba_total_teste = 0
            }

            if (this.lancamento.descricao.trim().length < 1) {
                this.$toasted.error('Informe um nome de lançamento válido.')
                return false
            } 

            if (this.lancamento.id_lancamento_tipo < 1) {
                this.$toasted.error('Escolha um tipo de lançamento.')
                return false
            } 

            if (!this.lancamento.data_inicio) {
                this.$toasted.error('Preencha o campo Início Captação')
                return false
            } 

            if (!this.lancamento.data_final) {
                this.$toasted.error('Preencha o campo Fim Captação')
                return false
            } 

            if (!this.lancamento.abertura_carrinho) {
                this.$toasted.error('Preencha o campo Abertura Carrinho')
                return false
            } 

            if (!this.lancamento.fechamento_carrinho) {
                this.$toasted.error('Preencha o campo Fechamento Carrinho')
                return false
            } 

            if (this.lancamento.data_inicio > this.lancamento.data_final) {
                this.$toasted.error('A data de Início de captação não pode ser maior que a data de Fim de Captação.')
                return false
            } 

            if (this.lancamento.abertura_carrinho > this.lancamento.fechamento_carrinho) {
                this.$toasted.error('A data de Abertura de Carrinho não pode ser maior que a data de Fechamento do Carrinho.')
                return false
            } 

            if (this.lancamento.abertura_carrinho < this.lancamento.data_inicio) {
                this.$toasted.error('A data de Abertura de Carrinho não pode ser menor que a data de Início de Captação.')
                return false
            } 

            if (this.lancamento.fechamento_carrinho < this.lancamento.data_final) {
                this.$toasted.error('A data de Fechamento de Carrinho não pode ser menor que a data de Fim de Captação.')
                return false
            } 

            return true
        },

        valida0a100(v) {
            return (v >= 0) && (v <= 100) ? null : false
        },

        validaAba02() {
            let fb = 0
            let gg = 0
            let tt = 0

            let fbt = 0
            let ggt = 0
            let ttt = 0

            // if (this.lembretesSelecionados.length <= 0) {
            //     this.facebookADS.plan_porc_antecipacao = 0
            //     this.facebookADS.plan_porc_captacao = 87

            //     this.googleADS.plan_porc_antecipacao = 0
            //     this.googleADS.plan_porc_captacao = 87

            //     this.tiktokADS.plan_porc_antecipacao = 0
            //     this.tiktokADS.plan_porc_captacao = 87
            // }

            const vt = parseFloat(this.lancamento.verba_total_teste) 

            if (this.metricasSelecionadas.length <= 0) {
                this.$toasted.error('Selecione ao menos uma mídia social.')
                return false
            }

            if (this.ativaMidia('facebookADS')) {
                if(parseFloat(this.facebookADS.plan_porc_capital) <= 0) {
                    this.$toasted.error('A porcetagem da mídia selecionada facebookADS deve ser maior que zero.')
                    return false
                } else {
                    fb = parseFloat(this.facebookADS.plan_porc_capital)
                }

                if(parseFloat(this.facebookADS.valor_teste) > this.facebookADS.valor_capital) {
                    this.$toasted.error('O valor de Teste do Facebook ADS não pode ultrapassar sua verba total.')
                    return false
                } else {
                    fbt = parseFloat(this.facebookADS.valor_teste)
                } 
                
                this.facebookADS.data_inicio_captacao = this.lancamento.data_inicio
                this.facebookADS.data_fim_captacao = this.lancamento.data_final
            }

            if (this.ativaMidia('googleADS')) {
                if(parseFloat(this.googleADS.plan_porc_capital) <= 0) {
                    this.$toasted.error('A porcetagem da mídia selecionada googleADS deve ser maior que zero.')
                    return false
                } else {
                    gg = parseFloat(this.googleADS.plan_porc_capital)
                }

                if(parseFloat(this.googleADS.valor_teste) > this.googleADS.valor_capital) {
                    this.$toasted.error('O valor de Teste do Google ADS não pode ultrapassar sua verba total.')
                    return false
                } else {
                    ggt = parseFloat(this.googleADS.valor_teste)
                }   
                
                this.googleADS.data_inicio_captacao = this.lancamento.data_inicio
                this.googleADS.data_fim_captacao = this.lancamento.data_final
            }

            if (this.ativaMidia('tiktokADS')) {
                if(parseFloat(this.tiktokADS.plan_porc_capital) <= 0) {
                    this.$toasted.error('A porcetagem da mídia selecionada tiktokADS deve ser maior que zero.')
                    return false
                } else {
                    tt = parseFloat(this.tiktokADS.plan_porc_capital)
                }

                if(parseFloat(this.tiktokADS.valor_teste) > this.tiktokADS.valor_capital) {
                    this.$toasted.error('O valor de Teste do Tiktok ADS não pode ultrapassar sua verba total.')
                    return false
                } else {
                    ttt = parseFloat(this.tiktokADS.valor_teste)
                } 
                
                this.tiktokADS.data_inicio_captacao = this.lancamento.data_inicio
                this.tiktokADS.data_fim_captacao = this.lancamento.data_final
            }

            if ((fb + gg + tt) != 100 ) {
                this.$toasted.error('A soma das porcetagens das mídias sociais dever ser 100%')
                return false
            }

            if (this.lancamento.teste) {
                if (((fbt + ggt + ttt) < vt) || ((fbt + ggt + ttt) > vt)) {
                    this.$toasted.error('A soma dos valores de teste deve ser igual ao total disponível para teste!')
                    return false
                }  
            } else {
                this.facebookADS.valor_teste = 0
                this.googleADS.valor_teste = 0
                this.tiktokADS.valor_teste = 0
            }   
            
            this.preencheAba03()

            return true
        },

        validaAba03() {  

            // facebookADS
            if (this.ativaMidia('facebookADS')) {
                const facebookADS = (
                    parseFloat(this.facebookADS.plan_porc_captacao ? this.facebookADS.plan_porc_captacao : 0) + 
                    parseFloat(this.facebookADS.plan_porc_antecipacao ? this.facebookADS.plan_porc_antecipacao : 0) + 
                    parseFloat(this.facebookADS.plan_porc_camp_pre_lanc ? this.facebookADS.plan_porc_camp_pre_lanc : 0) + 
                    parseFloat(this.facebookADS.plan_porc_vendas ? this.facebookADS.plan_porc_vendas : 0)
                )

                if (facebookADS != 100) {
                    this.$toasted.error('facebookADS: A soma da Captação, Antecipação, CPL e Vendas deve ser de 100%')
                    return false
                }              

                if (
                    (this.valida0a100(this.facebookADS.plan_porc_captacao) !== null) ||
                    (this.valida0a100(this.facebookADS.plan_porc_antecipacao) !== null) ||
                    (this.valida0a100(this.facebookADS.plan_porc_camp_pre_lanc) !== null) ||
                    (this.valida0a100(this.facebookADS.plan_porc_vendas) !== null)               
                ) {
                    this.$toasted.error('facebookADS: Verifique os campos em vermelho.')
                    return false
                }
            }

            // googleADS
            if (this.ativaMidia('googleADS')) {
                const googleADS = (
                    parseFloat(this.googleADS.plan_porc_captacao ? this.googleADS.plan_porc_captacao : 0) + 
                    parseFloat(this.googleADS.plan_porc_antecipacao ? this.googleADS.plan_porc_antecipacao : 0) + 
                    parseFloat(this.googleADS.plan_porc_camp_pre_lanc ? this.googleADS.plan_porc_camp_pre_lanc : 0) + 
                    parseFloat(this.googleADS.plan_porc_vendas ? this.googleADS.plan_porc_vendas : 0)
                )

                if (googleADS != 100) {
                    this.$toasted.error('googleADS: A soma da Captação, Antecipação, CPL e Vendas deve ser de 100%')
                    return false
                } 
                
                if (
                    (this.valida0a100(this.googleADS.plan_porc_captacao) !== null) ||
                    (this.valida0a100(this.googleADS.plan_porc_antecipacao) !== null) ||
                    (this.valida0a100(this.googleADS.plan_porc_camp_pre_lanc) !== null) ||
                    (this.valida0a100(this.googleADS.plan_porc_vendas) !== null)             
                ) {
                    this.$toasted.error('googleADS: Verifique os campos em vermelho.')
                    return false
                }
            }

            // tiktokADS
            if (this.ativaMidia('tiktokADS')) {
                const tiktokADS = (
                    parseFloat(this.tiktokADS.plan_porc_captacao ? this.tiktokADS.plan_porc_captacao : 0) + 
                    parseFloat(this.tiktokADS.plan_porc_antecipacao ? this.tiktokADS.plan_porc_antecipacao : 0) + 
                    parseFloat(this.tiktokADS.plan_porc_camp_pre_lanc ? this.tiktokADS.plan_porc_camp_pre_lanc : 0) + 
                    parseFloat(this.tiktokADS.plan_porc_vendas ? this.tiktokADS.plan_porc_vendas : 0)
                )

                if (tiktokADS != 100) {
                    this.$toasted.error('tiktokADS: A soma da Captação, Antecipação, CPL e Vendas deve ser de 100%')
                    return false
                }                

                if (
                    (this.valida0a100(this.tiktokADS.plan_porc_captacao) !== null) ||
                    (this.valida0a100(this.tiktokADS.plan_porc_antecipacao) !== null) ||
                    (this.valida0a100(this.tiktokADS.plan_porc_camp_pre_lanc) !== null) ||
                    (this.valida0a100(this.tiktokADS.plan_porc_vendas) !== null)                 
                ) {
                    this.$toasted.error('tiktokADS: Verifique os campos em vermelho.')
                    return false
                }
            }            

            return true
        },
        
        // Lançamento
        novaConsulta() {
            this.page = 1 
            this.loadLancamentos()
        }, 

        loadLancamentos() {
            const dtIn= this.pesquisa.dataInicial
            const dtFi = this.pesquisa.dataFinal
            const tp = this.pesquisa.id_tipo
            const st = this.pesquisa.status

            const url = `${baseApiUrlV2}/lancamentos/consulta/?data_inicio=${dtIn}&data_fim=${dtFi}&id_tipo=${tp}&status=${st}&page=${this.page}`

            axios.get(url).then(res => {
                if( res.data.count  === 0 ) this.$toasted.global.nadaEncontrado()
                this.lancamentos = res.data.data
                this.count = res.data.count 
                this.limit = res.data.limit
            })  
        },

        async loadLancamento(lancamento) {

            const url = `${baseApiUrlV2}/lancamentos/editar/${lancamento.id_lancamento}`  

            await axios.get(url).then(res => {     
                this.lancamento.id_lancamento = res.data.id_lancamento
                this.lancamento.data_inicio = moment(res.data.data_inicio).format('YYYY-MM-DD')
                this.lancamento.data_final = moment(res.data.data_final).format('YYYY-MM-DD')
                this.lancamento.descricao = res.data.descricao
                this.lancamento.id_lancamento_tipo = res.data.id_lancamento_tipo
                this.lancamento.descricaoTipo = res.data.descricaoTipo
                this.lancamento.id_produto = res.data.id_produto
                this.lancamento.idproduto = res.data.descricaoProduto
                this.lancamento.verba_total = res.data.verba_total
                this.lancamento.abertura_carrinho = moment(res.data.abertura_carrinho).format('YYYY-MM-DD')
                this.lancamento.fechamento_carrinho = moment(res.data.fechamento_carrinho).format('YYYY-MM-DD')
                this.lancamento.data_inicio_cpl1 = moment(res.data.data_inicio_cpl1).format('YYYY-MM-DD')
                this.lancamento.data_inicio_cpl2 = moment(res.data.data_inicio_cpl2).format('YYYY-MM-DD')
                this.lancamento.data_inicio_cpl3 = moment(res.data.data_inicio_cpl3).format('YYYY-MM-DD')
                this.lancamento.verba_total_teste = res.data.verba_total_teste
                this.lancamento.teste = res.data.teste === 'S' ? true : false
                this.lancamento.data_inicio_teste = moment(res.data.data_inicio_teste).format('YYYY-MM-DD')
                this.lancamento.data_fim_teste = moment(res.data.data_fim_teste).format('YYYY-MM-DD')
                this.lancamento.detalhes = res.data.detalhes
                this.lancamento.obs = res.data.obs
                
                this.lancamento.mideas = res.data.mideas
                const lembretes = res.data.lembretes.split(',')
                this.lembretesSelecionados = []
                this.lembretes.forEach((l)=>{ l.ativo = true })

                lembretes.forEach((id) => {  
                    switch (parseInt(id)) {
                        case 1: 
                            this.lembretesSelecionados.push({id: 1, nome: 'Faltam 10 dias!', dias: 10, cor: 'secondary', ativo: true})
                            this.lembretes.forEach((l)=>{ if (l.id === 1) {l.ativo = false}})
                        break

                        case 2: 
                            this.lembretesSelecionados.push({id: 2, nome: 'Falta pouco!', dias: 5, cor: 'info', ativo: true})
                            this.lembretes.forEach((l)=>{ if (l.id === 2) {l.ativo = false}})
                        break

                        case 3: 
                            this.lembretesSelecionados.push({id: 3, nome: 'É nessa segunda!', dias: 2, cor: 'warning', ativo: true})
                            this.lembretes.forEach((l)=>{ if (l.id === 3) {l.ativo = false}})
                        break

                        case 4: 
                            this.lembretesSelecionados.push({id: 4, nome: 'É amanhã!', dias: 1, cor: 'danger', ativo: true})
                            this.lembretes.forEach((l)=>{ if (l.id === 4) {l.ativo = false}})
                        break
                    }
                })

                this.lancamento.mideas.forEach((item) => {                    
                    
                    switch (parseInt(item.id_lancamento_canal_mkt)) {
                        case 1:
                            this.metricasSelecionadas.push('facebookADS')

                            this.facebookADS.plan_porc_capital      = item.plan_porc_capital                            
                            this.facebookADS.id_lancamento = item.id_lancamento
                            this.facebookADS.id_lancamento_metricas_canal_mkt = item.id_lancamento_metricas_canal_mkt
                            this.facebookADS.id_lancamento_canal_mkt = item.id_lancamento_canal_mkt
                            this.facebookADS.valor_teste = item.valor_teste
                            this.facebookADS.plan_porc_captacao = item.plan_porc_captacao
                            this.facebookADS.plan_porc_antecipacao = item.plan_porc_antecipacao
                            this.facebookADS.plan_porc_camp_pre_lanc = item.plan_porc_camp_pre_lanc
                            this.facebookADS.plan_porc_vendas = item.plan_porc_vendas

                            break
                        case 2: 
                            this.metricasSelecionadas.push('googleADS')

                            this.googleADS.plan_porc_capital      = item.plan_porc_capital                            
                            this.googleADS.id_lancamento = item.id_lancamento
                            this.googleADS.id_lancamento_metricas_canal_mkt = item.id_lancamento_metricas_canal_mkt
                            this.googleADS.id_lancamento_canal_mkt = item.id_lancamento_canal_mkt
                            this.googleADS.valor_teste = item.valor_teste
                            this.googleADS.plan_porc_captacao = item.plan_porc_captacao
                            this.googleADS.plan_porc_antecipacao = item.plan_porc_antecipacao
                            this.googleADS.plan_porc_camp_pre_lanc = item.plan_porc_camp_pre_lanc
                            this.googleADS.plan_porc_vendas = item.plan_porc_vendas

                            break
                        case 3: 
                            this.metricasSelecionadas.push('tiktokADS')

                            this.tiktokADS.plan_porc_capital      = item.plan_porc_capital                            
                            this.tiktokADS.id_lancamento = item.id_lancamento
                            this.tiktokADS.id_lancamento_metricas_canal_mkt = item.id_lancamento_metricas_canal_mkt
                            this.tiktokADS.id_lancamento_canal_mkt = item.id_lancamento_canal_mkt
                            this.tiktokADS.valor_teste = item.valor_teste
                            this.tiktokADS.plan_porc_captacao = item.plan_porc_captacao
                            this.tiktokADS.plan_porc_antecipacao = item.plan_porc_antecipacao
                            this.tiktokADS.plan_porc_camp_pre_lanc = item.plan_porc_camp_pre_lanc
                            this.tiktokADS.plan_porc_vendas = item.plan_porc_vendas
                            
                            break
                    }
                })                
            }) 
            .catch(showError)
        },

        salvarLancamento() {
            const method = this.lancamento.id_lancamento ? 'put' : 'post'            
            const id = this.lancamento.id_lancamento ? `/${this.lancamento.id_lancamento}` : ''  
            
            if (this.ativaMidia('facebookADS')) { this.lancamento.facebookADS = { ...this.facebookADS }}
            if (this.ativaMidia('googleADS')) { this.lancamento.googleADS = { ...this.googleADS }}
            if (this.ativaMidia('tiktokADS')) { this.lancamento.tiktokADS = { ...this.tiktokADS }} 
           
           if (this.lembretesSelecionados.length > 0) {
                let lemb = '' 
                this.lembretesSelecionados.forEach((it) => {
                    lemb = lemb + `${it.id},`
                })
                this.lancamento.lembretes = lemb
            }  
            
            this.lancamento.teste ? this.lancamento.teste = 'S': this.lancamento.teste = 'N' 

            axios[method](`${baseApiUrlV2}/lancamentos${id}`, this.lancamento)
                .then(() => {
                    this.$toasted.global.defaultSuccess()    
                    this.novoLancModal = false                
                    this.resetLancamento()
                    this.loadLancamentos()
                })
            .catch(showError)
        },

        removeLancamento(idlancamento) {
            axios['delete'](`${baseApiUrl}/lancamentos/${idlancamento}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.loadLancamentos()
                })
            .catch(showError)
        },

        resetLancamento() {
            this.edicaoLancModal = false

            this.e1 = 1
            this.produtos = []
            this.lembretesSelecionados = []
            this.metricasSelecionadas = []  
            
            delete this.lancamento.id_lancamento
            this.lancamento.descricao = ''
            this.lancamento.detalhes = ''
            this.lancamento.obs = ''
            this.lancamento.verba_total = 0
            this.lancamento.id_lancamento_tipo = 0
            this.lancamento.id_produto = 0
            this.lancamento.idproduto = ''
            this.lancamento.status = 'A'
            this.lancamento.ativo = 'S'
            this.lancamento.verba_total_teste = 0
            this.lancamento.lembretes = ''
            this.lancamento.teste = false,
            this.lancamento.abertura_carrinho = moment().add(15, 'days').format('YYYY-MM-DD'),
            this.lancamento.financeiro = 'S'
            this.metricasSelecionadas = []          



            
            this.mode = 'new'
            // this.show = !this.show
        },

        // Receitas
        salvarReceita(){
            this.showReceita = false
            this.loadLancamento(this.lancamento, false)
        },  

        // Despesas
        salvarDespesa(){
            this.showDespesa = false
            this.loadLancamento(this.lancamento, false)
        }, 

        // Lançamento Itens
        onSelectProduto(produto) {
            this.lancamento.id_produto = produto.id_produto
        },

        loadProdutos() {
            const url = `${baseApiUrl}/produtos`
            axios.get(url).then(res => {
                this.produtos = res.data.map(produto => {
                    return { ...produto,  value: produto.id_produto, text: produto.descricao}
                })
            })
        },

        adicionarItem() {
            if((!this.lancamentoItem.valor) || (this.lancamentoItem.valor <= 0)) return this.$toasted.error('Informe o valor do produto.')            
            this.lancamentoItem.descricao = this.lancamentoItem.idproduto
            this.lancamentoItem.idproduto = this.lancamentoItem.id_produto
            
            this.lancamentoItens.push({ ...this.lancamentoItem })
            this.lancamentoItem = {}
            this.resetItem()

            this.lancamentoItens.forEach((item, i) => {item.index = i})
        },

        resetItem() {

            this.modeItens = 'save'
            
            this.lancamentoItem = {
                descricao: '',
                valor: 0,
                id_produto: '',
                idproduto: ''
            }
        },

        saveItem() {  
            
                if (this.index < 0) {
                    this.vendaItem.descricao = this.vendaItem.idproduto
                    this.vendaItem.idproduto = this.vendaItem.id_produto

                    this.vendasItens.push({...this.vendaItem})
                } else {
                    this.index = this.vendaItem.seq - 1
                    this.vendaItem.descricao = this.vendaItem.idproduto
                    this.vendaItem.idproduto = this.vendaItem.id_produto
                    this.vendasItens.splice(this.index, 1, {...this.vendaItem})
                } 
            this.enumeraItens()
            this.resetItem()
        },

        removeItem(item) {
            const idx = item.index
            const it = { ...item }     

            axios['delete'](`${baseApiUrl}/lancamentos/itens/${it.id_lancamento}/?iditem=${it.id_lancamento_produto}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.lancamentoItens.splice(idx, 1)
                    this.loadLancItens(it.id_lancamento)
                    this.loadLancamento(it, false)
                })
            .catch(showError)
        },

        loadLancItens(idLanc) {
            const url = `${baseApiUrl}/lancamentos/itens/${idLanc}`
            
            axios.get(url).then(res => {
                this.lancamentoItens = res.data
                this.lancamentoItens.forEach((item, i) => {item.index = i})
            })
        },

        loadLancItem(lancItem, modeItens = 'save') {            
            this.modeItens = modeItens
            this.lancItem = { ...lancItem }                     
            //this.index = this.lancItem.seq - 1
            this.lancItem.idproduto = lancItem.descricao
        },

        // Tipos
        loadTipos() {
            const url = `${baseApiUrl}/lancamentos/tipos`
            axios.get(url).then(res => {
                this.tipos = res.data.map(tipo => {
                    return { ...tipo,  value: tipo.id_lancamento_tipo, text: tipo.descricao}
                })
                this.tipos.unshift( { "id_lancamento_tipo": 0, "descricao": "- TODOS -", "ativo": "S", "value": 0, "text": "- TODOS -" })
            }).catch(showError)
        },
        
        loadTipo(cadTipo, modeTipo = 'save') {
            this.modeTipo = modeTipo
            this.cadTipo = { ...cadTipo }
            this.cadTipo.ativo==='S' ? this.cadTipo.ativo=true : this.cadTipo.ativo= false
        }, 

        salvarTipo() {
            const method = this.cadTipo.id_lancamento_tipo ? 'put' : 'post'
            const cadTipo = this.cadTipo.id_lancamento_tipo ? `/${this.cadTipo.id_lancamento_tipo}` : ''
            this.cadTipo.ativo == true ? this.cadTipo.ativo='S' : this.cadTipo.ativo='N'          

            axios[method](`${baseApiUrl}/lancamentos/tipos${cadTipo}`, this.cadTipo)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.resetTipo()
                })
                .catch(showError)
        },

        loadCadTipos() {
            const url = `${baseApiUrl}/lancamentos/tipos/todos`
            axios.get(url).then(res => {
                this.cadTipos = res.data.map(tipo => {
                    return { ...tipo,  value: tipo.id_lancamento_tipo, text: tipo.descricao}
                })
            }).catch(showError)
        },

        resetTipo() {
            this.modeTipo = 'save'

            this.cadTipo = {
                id_lancamento_tipo: '',
                descricao: '',
                ativo: true
            }

            this.loadTipos()
            this.loadCadTipos()
        },

        removeTipo() {
            const idtipo = this.cadTipo.id_lancamento_tipo
            axios['delete'](`${baseApiUrl}/lancamentos/tipos/${idtipo}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    
                    this.resetItem()
                    this.resetTipo()
                })
            .catch(showError)
        },

        // Carrega Stats
        loadLancRecDespVenda(idLanc) {
            const url = `${baseApiUrl}/lancamentos/stats/${idLanc}`
            
            axios.get(url).then(res => {
                let totRec = 0
                this.lancamentoReceitas = res.data.r
                this.lancamentoReceitas.forEach((item, i) => {
                    item.index = i
                    totRec += item.valor
                })
                this.lancamento.total_receitas = totRec.toLocaleString('pt-br', {minimumFractionDigits: 2})

                let totDesp = 0
                this.lancamentoDespesas = res.data.d
                this.lancamentoDespesas.forEach((item, i) => {
                    item.index = i
                    totDesp += item.valor
                })
                this.lancamento.total_despesas = totDesp.toLocaleString('pt-br', {minimumFractionDigits: 2})                
                
                this.lancamento.total_vendas = res.data.v
            })
        },

        // Outros
        showModalExc(m) {
            this.showModalExcluir = !this.showModalExcluir
            this.lancamentoItem = { ...m }
        },
        closeModalExc() {
            this.showModalExcluir = !this.showModalExcluir
        },
        upperCase(value) {
            return value.toUpperCase()
        }
    },
    watch: {
        page() {
            this.loadLancamentos()
        },
        'lancamento.verba_total': function() {
            this.calculaCapital()
        }
    },
    mounted() {
        this.loadTipos()
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.7rem;
    }  

    .vlr-capital {
        margin-top: 30px !important;
    }

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }
    
    .faturas-numerarios {
        margin: -10px 0 -10px 0;
    }

    .moeda-totais-lancamento {
        display: flex;
        box-shadow: 0 0 0 0;
        border: 0 none;
        outline: 0;
        background: transparent;
        width: 100%;
        font-size: 23px;
        color: white;
    }
    .moeda-totais-verba-total {
        display: flex;
        align-self: center;
        box-shadow: 0 0 0 0;
        border: 0 none;
        outline: 0;
        background: transparent;
        width: 100%;
        font-size: 23px;
        color: white;
    }

    .moeda-totais-midias {
        margin-top: 25px;
        display: flex;
        box-shadow: 0 0 0 0;
        border: 0 none;
        outline: 0;
        background: transparent;
        width: 100%;
        font-size: 22px;
        color: rgb(27, 27, 27);
    }

    .totais {
        display: flex;
    }

    .moeda-totais-verbas {
        display: flex;
        flex-direction: row;
        box-shadow: 0 0 0 0;
        border: 0 none;
        outline: 0;
        background: transparent;
        font-size: 20px;
        color: rgb(27, 27, 27);
    }

    .moeda-totais-verbas-perc {
        width: 100px;
    }

    .moeda-totais-verbas-perc-err {
        width: 100px;
        color: rgb(238, 38, 38);
    }

    .moeda-totais-verbas-perc-succ {
        width: 100px;
        color: rgb(32, 180, 3);
    }

    .moeda-totais-verbas-vlr {
        width: 100%;
    }

    .moeda-totais-verbas-vlr-err {
        width: 100%;
        color: rgb(238, 38, 38);
    }

    .moeda-totais-verbas-vlr-succ {
        width: 100%;
        color: rgb(32, 180, 3);
    }

    .stepper-buttons {
        margin: 10px 15px 15px 25px;
        background: #5d5cfc !important;
        color: #ffffff !important;
    }

    .stepper-buttons-salvar {
        margin: 10px 15px 15px 25px;
        background: #5dc289 !important;
        color: #ffffff !important;
    }

    .stepper-content {
        min-height: 300px;
    }

    .modal-dash-lanc-indicadores {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .top-info{
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
    }

    .top-info-title {
        flex: 1;
        color: rgb(139, 139, 139);
    }

    .top-info-title i {
        margin-right: 5px;
    }

    .top-info-value {
        flex: 1;
        font-size: 1.5rem;
        color: rgb(65, 65, 65);
    }

    .top-info-divisor {
        height: 60px;
        width: 2px;
        margin: 0 5px 0 5px;
        background: #ccc;
    }

    .dash-col-title {
        margin: 15px 0 15px 0;
        font-weight: 600;
    }

</style>