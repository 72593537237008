<template>
    <div class="cartoes-pages">
        <PageTitle icon="fa fa-credit-card " main="Cartões"
            sub="Cadastro de administradoras & Condições" />
        <div class="cartoes-pages-tabs">
            <b-card no-body>
                <b-tabs card>
                    <b-tab title="Administradoras">
                        <CadAdministradora />
                    </b-tab>
                    <b-tab title="Condições">
                        <CondicoesCartoes />
                    </b-tab>
                </b-tabs>
            </b-card>
        </div>        
        
    </div>
</template>

<script>
import PageTitle from '@/components/template/PageTitle'
import CadAdministradora from './CadAdministradora'
import CondicoesCartoes from './CondicoesCartoes'

export default {
    name: 'PlanoContasPages',
    components: { PageTitle, CadAdministradora, CondicoesCartoes }
}
</script>

<style>

</style>
