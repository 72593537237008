<template>
    <div class="vendas-pages">
        <PageTitle icon="fa fa-shopping-basket" main="Vendas"
            sub="Cadastros" 
            @set-title="addTitle" />
        <CadastroVendas />         
    </div>
</template>

<script>
import PageTitle from '../template/PageTitle'
import CadastroVendas from './CadastroVendas'

export default {
    name: 'VendasPages',
    main: 'sdfgsdfgf',
    components: { PageTitle , CadastroVendas },    
    methods: {        
        addTitle(payload){
           return payload.main
        }
    }

}
</script>

<style>

</style>
