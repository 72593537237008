<template>
    <div v-if="aviso">
        <b-alert show :variant="licenca.variance" class="alert-div">            
            {{ aviso }}            
        </b-alert>
        
    </div>  
</template>

<script>
import { baseApiUrl, showError } from '@/global'
//const moment = require('moment')
import axios from 'axios'

export default {
    name: 'LicenceAlert',
    data: function() {
        return {
            aviso: '',
            licenca: {
                dias: 0,
                variance: 'warning',
            }
        }
    },
    methods: {       
        loadLicenca() {            
            const url = `${baseApiUrl}/empresas/licenca/`    

            axios.get(url).then(res => {
                this.licenca = res.data
            
                // const start = moment(this.licenca., "YYYY-MM-DD");
                // const end = moment().format('YYYY-MM-DD')
                // //Difference in number of days
                // this.licenca.dias = moment.duration(start.diff(end)).asDays();
                // this.licenca.dias > 5 ? this.licenca.variance = 'warning' : this.licenca.variance = 'danger'


                const expr = this.licenca.status_licenca;
                switch (expr) {
                case 2:
                    this.aviso = 'A fatura de uso do sistema está vencida. Aguardando confirmação de pagamento!'
                    this.licenca.variance = 'warning'
                    break;
                case 3:
                    this.aviso = 'É necessário renovar o contrato para continuar o uso do sistema!'
                    this.licenca.variance = 'danger'
                    break;
                case 7:
                    this.aviso = 'Fatura de uso do sistema atrasada. Entre em contato com o financeiro para regularizar a situação!'
                    this.licenca.variance = 'danger'
                    break;
                default:
                    this.aviso = ''
                }

            })
            .catch(showError)
        }
    },
    mounted() {
        this.loadLicenca()
    }

}
</script>

<style>
.btn-ativar {
    margin-left: 20px;
}

</style>