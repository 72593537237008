<template>
    <div class="empresas-pages">
        <PageTitle icon="fa fa-building " main="Empresas"
            sub="Cadastros de empresas" />
        
        <CadastroEmpresas />   
    </div>
</template>

<script>
import PageTitle from '../template/PageTitle'
import CadastroEmpresas from './CadastroEmpresas.vue'

export default {
    name: 'EmpresasPages',
    components: { PageTitle, CadastroEmpresas }
}
</script>

<style>

</style>
