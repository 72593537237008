<template>
    <div class="graficoContent">
        <div class="graficoTitulo">
            <h5>{{ titulo }} </h5> 
        </div>   
        <Doughnut
            :chart-options="chartOptions"
            :chart-data="chartData"
            :chart-id="chartId"
            :dados="dados"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
            :titulo="titulo"
        />
    </div>
</template>

<script>
import { Doughnut } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale)

export default {
    name: 'BarChart',
    components: { Doughnut },
    props: {
        chartId: {
            type: String,
            default: 'Doughnut-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 1
        },
        height: {
            type: Number,
            default: 1000
        },
        cssClasses: {
            type: String,
            default: 'grafico'
        },
        styles: {
            type: Object,
            default: () => {}
        },
        plugins: {
            type: Object,
            default: () => {}
        },
        dados: {
            type: Object
        },
        titulo: {
            type: String
        }
    },
    data() {
        return {
            chartData: this.dados,
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false
            }
        }
    },    
    methods: {
        renderLineChart() {
            this.chartData = this.dados
        }
    },
    mounted () {
        this.renderLineChart();
    },    
    watch: {
        chartData() {
            this.$nextTick(() => {
                this.renderLineChart();
            })
        }
    }
}
</script>

<style>
    .graficoContent {
        flex: 1;
        min-width: 15rem;
        padding: 10px 15px 15px 0px;  
        align-items: center;     
        
        flex-direction: column;
    }

    .graficoTitulo {
        flex: 1;
        display: flex;    
        align-items: center;     
        
        flex-direction: column;
    }

    .grafico {
        flex: 1;
        display: flex;   
        flex-direction: column;
        /* border: solid 1px red; */
        height: 22rem !important;
        padding-bottom: 10px;
    }
</style>