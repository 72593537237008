<template>
    <div class="cadastro-filiais">
        
        <b-card class="cadastros-card-pesquisa" v-if="show">
            <b-row align-v="center">
                <b-col md="2" sm="12" class="cadastros-pesquisa-coluna">
                    <b-button class="cadastros-button-novo" variant="primary" 
                        @click="show = !show">
                            <i class="fa fa-plus button-icons"></i>
                            Novo
                    </b-button>
                </b-col>
                <b-col md="5" sm="12" class="cadastros-pesquisa-coluna">                        
                    <b-input-group >
                        <b-form-input id="localizar" type="search"
                            :formatter="upperCase"
                            v-model="parcial"
                            :readonly="mode === 'remove'"
                            placeholder="Informe os dados da pesquisa..." />
                        <b-input-group-append> 
                            <b-button variant="primary" @click="novaConsulta">
                                <i class="fa fa-search button-icons"></i>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>                      
                </b-col> 
                <b-col md="3" sm="12" class="radio-consulta">
                    <b-form-group  v-slot="{ ariaDescribedby }">
                        <b-form-radio-group 
                            id="radio-slots"
                            v-model="ativos"
                            :aria-describedby="ariaDescribedby"
                            name="radio-options-slots"
                        >
                            <b-form-radio value="S">Ativos</b-form-radio>
                            <b-form-radio value="N">Inativos</b-form-radio>
                            <b-form-radio value="T">Todos</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
                <b-col md="2" sm="12" class="d-flex justify-content-end cadastros-pesquisa-coluna">                        
                    <div class="cadastro-total-card"> {{ count }} <br> cadastros </div>                     
                </b-col> 
            </b-row>
        </b-card>

        <b-card class="cadastros-card-corpo" v-if="!show">
            <b-form>
                <input id="filial-id" type="hidden" v-model="filial.idfilial" />

                <b-row>
                    <b-col md="4" sm="12">
                        <b-form-group label="Razão Social" label-for="filial-razao-social">
                            <b-form-input id="filial-razao-social" type="text"
                                :formatter="upperCase"
                                v-model="filial.razao_social" required
                                :readonly="mode === 'remove'" />
                        </b-form-group>
                    </b-col>          
                    <b-col md="4" sm="12">
                        <b-form-group label="Fantasia" label-for="filial-fantasia">
                            <b-form-input id="filial-fantasia" type="text"
                                v-model="filial.fantasia" required
                                :readonly="mode === 'remove'" />
                        </b-form-group>
                    </b-col>
                    <b-col md="2" sm="12">
                        <b-form-group label="Tipo" label-for="filial-tipo-pessoa">
                            <b-form-select id="filial-tipo-pessoa" v-model="filial.tipo_fj" :options="optionsTipo" />
                        </b-form-group>
                    </b-col>
                    <b-col md="2" sm="12">
                        <b-form-group label="Regime" label-for="filial-regime">
                            <b-form-select id="filial-regimea" v-model="filial.regime" :options="optionsRegime" />
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col md="3" sm="12">
                        <b-form-group label="CPF/CNPJ" label-for="filial-cpf-cnpj">
                            <b-form-input id="filial-cpf-cnpj" type="text"
                                v-model="filial.cnpj_cpf"
                                :readonly="mode === 'remove'"/>
                        </b-form-group>
                    </b-col>   
                    <b-col md="3" sm="12">
                        <b-form-group label="RG/IE" label-for="filial-rg-ie">
                            <b-form-input id="filial-rg-ie" type="text"
                                v-model="filial.ie_rg"
                                :readonly="mode === 'remove'"/>
                        </b-form-group>
                    </b-col>    
                    <b-col md="3" sm="12">
                        <b-form-group label="Insc. Municipal" label-for="filial-im">
                            <b-form-input id="filial-im" type="text"
                                v-model="filial.im"
                                :readonly="mode === 'remove'"/>
                        </b-form-group>
                    </b-col>   
                    <b-col md="3" sm="12">
                        <b-form-group label="CNAE" label-for="filial-cnae">
                            <b-form-input id="filial-cnae" type="number"
                                v-model="filial.cnae"
                                :readonly="mode === 'remove'"/>
                        </b-form-group>
                    </b-col> 
                </b-row>

                <b-row>
                    <b-col md="3" sm="12">
                        <b-form-group label="Telefone" label-for="filial-telefone">
                            <b-form-input id="filial-telefone" type="text"
                                v-model="filial.telefone"
                                :readonly="mode === 'remove'"/>
                        </b-form-group>
                    </b-col>   
                    <b-col md="3" sm="12">
                        <b-form-group label="FAX" label-for="filial-fax">
                            <b-form-input id="filial-fax" type="text"
                                v-model="filial.fax"
                                :readonly="mode === 'remove'"/>
                        </b-form-group>
                    </b-col>    
                    <b-col md="3" sm="12">
                        <b-form-group label="Celular" label-for="filial-celular">
                            <b-form-input id="filial-celular" type="text"
                                v-model="filial.celular"
                                :readonly="mode === 'remove'"/>
                        </b-form-group>
                    </b-col>   
                    <b-col md="3" sm="12">
                        <b-form-group label="E-mail" label-for="filial-email">
                            <b-form-input id="filial-email" type="text"
                                v-model="filial.email"
                                :readonly="mode === 'remove'"/>
                        </b-form-group>
                    </b-col> 
                </b-row>

                <b-row>
                    <b-col md="4" sm="12">
                        <b-form-group label="Endereço" label-for="filial-endereco">
                            <b-form-input id="filial-endereco" type="text"
                                v-model="filial.endereco"
                                :readonly="mode === 'remove'"/>
                        </b-form-group>
                    </b-col>   
                    <b-col md="1" sm="12">
                        <b-form-group label="Nº" label-for="filial-numero">
                            <b-form-input id="filial-numero" type="text"
                                v-model="filial.numero"
                                :readonly="mode === 'remove'"/>
                        </b-form-group>
                    </b-col>    
                    <b-col md="4" sm="12">
                        <b-form-group label="Bairro" label-for="filial-bairro">
                            <b-form-input id="filial-bairro" type="text"
                                v-model="filial.bairro"
                                :readonly="mode === 'remove'"/>
                        </b-form-group>
                    </b-col>   
                    <b-col md="3" sm="12">
                        <b-form-group label="Complemento" label-for="filial-complemento">
                            <b-form-input id="filial-complemento" type="text"
                                v-model="filial.complemento"
                                :readonly="mode === 'remove'"/>
                        </b-form-group>
                    </b-col> 
                </b-row>

                <b-row>
                    <b-col md="4" sm="12">
                        <b-form-group label="Cidade" label-for="filial-cidade">
                            <b-form-select id="filial-cidade" v-model="filial.id_cidade" :options="cidades" />
                        </b-form-group>
                    </b-col>
                    <b-col md="3" sm="12">
                        <b-form-group label="Cód. Contribuinte" label-for="filial-cod-contribuinte">
                            <b-form-input id="filial-cod-contribuinte" type="text"
                                v-model="filial.codigo_contribuinte"
                                :readonly="mode === 'remove'"/>
                        </b-form-group>
                    </b-col> 
                </b-row>

                <b-card class="cadastros-opcoes-row-card">
                    <b-row class="cadastros-opcoes-row" align-h="around">                        
                        <b-col md="2" sm="12">
                            <b-form-checkbox id="filial-ativo" v-show="mode === 'save'" v-model="filial.ativo" class="mt-3 mb-3" switch>Ativo</b-form-checkbox>
                        </b-col>                        
                    </b-row>
                </b-card>
                
                <br>
                <hr>
                <b-row>
                    <b-col md="10" sm="10">
                        <b-button variant="primary" v-if="mode === 'save'"
                            @click="save">Salvar</b-button>
                        <b-button variant="danger" v-if="mode === 'remove'"
                            @click="remove">Excluir</b-button>
                        <b-button class="ml-2" @click="reset">Cancelar</b-button>
                    </b-col>

                    <b-col md="2" sm="2" class="d-flex justify-content-end">                        

                        <i id="popover-target-1-filiais"  class="fa fa-question-circle info-alteracoes"></i>

                        <b-popover target="popover-target-1-filiais" triggers="hover" placement="top">
                            <template #title><strong>Alterações</strong></template>
                            <b>Usuário Cadastro:</b> {{filial.usu_cad}} <br>
                            <b>Data de Cadastro:</b> {{filial.data_cad}} <br>
                            <b>Usuário Alteração:</b> {{filial.usu_alt}} <br>
                            <b>Data de Alteração:</b> {{filial.data_alt}}
                        </b-popover>                        
                    </b-col>

                </b-row>

            </b-form>
        </b-card>      

        <b-card class="cadastros-card-corpo" v-show="show">
            <b-table hover striped :items="filiais" :fields="fields">  
                <template v-slot:cell(actions)="data">
                    <b-button variant="warning" @click="loadFilial(data.item)" class="mr-2">
                        <i class="fa fa-pencil"></i>
                    </b-button>
                    <b-button variant="danger" @click="loadFilial(data.item, 'remove')">
                        <i class="fa fa-trash"></i>
                    </b-button>
                </template>
            </b-table>
            <hr>

            <b-pagination 
                align="center" 
                size="md" 
                v-model="page"  
                :total-rows="count" 
                :per-page="limit" 
                first-number 
                last-number>
            </b-pagination>
        </b-card>  
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'

export default {
    name: 'CadastroFiliais',
    data: function() {
        return {
            mode: 'save',
            filial: {
                ativo: true,
                tipo_fj: 'J',
                regime: 'S'
            },
            filiais: [],
            cidades: [],
            page: 1,
            count: 0,
            limit: 0,
            parcial: '',
            ativos: 'S',
            show: true,
            fields: [
                {key: 'idfilial', label: 'Código', sortable: true},
                {key: 'razao_social', label: 'Razão Social', sortable: true},
                {key: 'email', label: 'E-mail', sortable: true},
                {key: 'ativo', label: 'Ativo', sortable: true, formatter: value => value ==='S' ? 'Sim' : 'Não'},
                {key: 'actions', label: 'Ações'}
            ],
            optionsTipo: [
                { value: 'F', text: 'Pessoa Física' },
                { value: 'J', text: 'Pessoa Jurídica'}
            ],       
            optionsRegime: [
                { value: 'S', text: 'Simples', disable: false },
                { value: 'R', text: 'Lucro Real', disabled: false  },
                { value: 'P', text: 'Lucro Presumido', disabled: false },
            ]       
        }
    },
    methods: {
        upperCase(value) {
            return value.toUpperCase()
        },
        lowerCase(value) {
            return value.toLowerCase()
        },
        novaConsulta() {
            this.page = 1 
            this.loadFiliais()
        },
        loadFiliais() { 
            const parc = (this.parcial.trim().length > 0) ? this.parcial : 'xxxxxxxxxx' 
            const url = `${baseApiUrl}/filiais/consulta/${parc}/ativos/${this.ativos}/?page=${this.page}`    

            axios.get(url).then(res => {
                this.filiais = res.data.data
                this.count = res.data.count 
                this.limit = res.data.limit
            })
            .catch(err => {
                showError(err)
            })          
        },        
        loadCidades() {
            const url = `${baseApiUrl}/cidades`
            axios.get(url).then(res => {
                this.cidades = res.data.map(cidade => {
                    return { ...cidade, value: cidade.id_cidade, text: `${cidade.nome}` }
                })
            })
        },
        reset() {
            this.mode = 'save'

            this.filial = {}
            this.filial.ativo = true

            this.loadFiliais()
            this.show = !this.show
        },
        save() {
            const method = this.filial.idfilial ? 'put' : 'post'
            const idfilial = this.filial.idfilial ? `/${this.filial.idfilial}` : ''
            this.filial.ativo == true ? this.filial.ativo='S' : this.filial.ativo='N'
           
            axios[method](`${baseApiUrl}/filiais${idfilial}`, this.filial)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
            .catch(err => {
                showError(err)
                this.filial.ativo === 'S' ? this.filial.ativo=true : this.filial.ativo=false
            })
        },
        
        remove() {
            const idfilial = this.filial.idfilial
            axios.delete(`${baseApiUrl}/filiais/${idfilial}`)
            .then(() => {
                this.$toasted.global.defaultSuccess()
                this.reset()
            })
            .catch(err => {
                showError(err)
            })
        },

        loadFilial(filial, mode = 'save') {
            this.mode = mode
            this.filial = { ...filial }
            this.filial.ativo==='S' ? this.filial.ativo=true : this.filial.ativo= false

            this.show = !this.show
        }
    },
    watch: {
        page() {
            this.loadFiliais()
        },
        'filial.tipo_fj': function() {
            this.optionsRegime.forEach(item => {
                if(item.value !== 'S' && this.filial.tipo_fj === 'F') { 
                    item.disabled = true
                    this.filial.regime = 'S' 
                } else {
                   item.disabled = false  
                }
            })
        }
    },
    mounted() {
        this.loadFiliais(),
        this.loadCidades()
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.9rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

    



</style>