<template>
    <div class="faturas-receber-pages">
        <PageTitle icon="fa fa-usd" main="Faturas a Pagar"
            sub="Cadastro e Manutenção de Faturas" />
        
        <CadastroFaturaPagar />  
    </div>
</template>

<script>
import PageTitle from '@/components/template/PageTitle'
import CadastroFaturaPagar from './CadastroFaturaPagar'

export default {
    name: 'PessoasPages',
    components: { PageTitle, CadastroFaturaPagar }
}
</script>

<style>

</style>
