import 'font-awesome/css/font-awesome.css'
import Vue from 'vue'
import App from './App'
import './config/bootstrap'
import './config/msgs'
import './config/axios'
import store from './config/store'
import router from './config/router'
import VueMask from "v-mask";
import money from 'v-money'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

// start app
Vue.use(Vuetify)
Vue.use(VueMask)
Vue.use(money, {precision: 2})

Vue.config.productionTip = false

new Vue({
    store,
    router,
    vuetify : new Vuetify(),
    //vuetify,
    render: h => h(App)
}).$mount('#app')
