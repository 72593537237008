<template>
    <div class="cont-stat">
        <div class="stat">
            <div class="stat-icon" :style="style">
                <i :class="icon"> </i>
            </div>
            <div class="stat-info">
                <span class="stat-title">{{ title }}</span>
                <span class="stat-value">{{ valor }}</span>
                
            </div> 
            
        </div>
        <hr v-if="exibeDetail === 'true'">
        <div class="stat-detail" v-if="exibeDetail === 'true'">
            <span><i :class="iconDetail || 'fa fa-circle'"> </i>{{ detail }}</span>
        </div>
    </div>
    
</template>

<script>
export default {
    name: 'Stat',
    props: ['title', 'value', 'icon', 'iconDetail', 'color', 'detail', 'exibeDetail', 'tipo'],
    computed: {
        style() {
            return "background-color: " + (this.color || "#000")
        },
        valor() {
            switch (this.tipo) {
                case 'moeda':
                    return this.value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                case 'porcentagem':
                    return `${this.value.toLocaleString('pt-br', {minimumFractionDigits: 0,maximumFractionDigits: 2})}%`            
                default:
                    return this.value
            }
        },

    }
    
}
</script>

<style>
    .cont-stat {
        flex: 1;
        margin: 20px 20px 20px 0;
        padding: 10px 15px 15px 15px;
        background-color: #FFF;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.096);
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.164);
        min-width: 15rem;
    }

    .cont-stat hr {
        margin-top: 25px;
    }

    .stat {
        flex: 1;
        display: flex;        
    }

    .stat-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        min-width: 100px;
        min-height: 100px;
        border-radius: 5%;
        margin-top: -30px;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.322);
    }

    .stat-icon i {
        font-size: 1.8rem;
        color: #FFF;
    }

    .stat-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .stat-detail{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin: -10px 0 -10px 0;
        color: rgba(0, 0, 0, 0.432);
        font-size: 0.8rem;
    }

    .stat-detail i{
        margin-right: 10px;
    }

    .stat-title {
        font-size: 1.2rem;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.5);
    }

    .stat-value {
        font-size: 1.7rem;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.644);
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }
</style>
